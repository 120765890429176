import type { FilterMembersBottomsheetProps } from '../../types/bottomsheetTypes';
import { View } from '../common/View';
import { FilterMembersView } from '../massMessage/FilterMembersView';

export function FilterMembersBottomsheet({ artistHandle }: FilterMembersBottomsheetProps) {
  return (
    <View className="w-full">
      <FilterMembersView artistHandle={artistHandle} />
    </View>
  );
}
