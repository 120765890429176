import { useCallback, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException, captureMessage } from '@sentry/react';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faHeart } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faTrashCan } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faHeart as faHeartSolid } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faEdit } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faTicket } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { loadTrack, seek } from '../../audio/AudioController';
import { play } from '../../audio/AudioEngineHTML5';
import { BASE_EMOJI_KEYWORDS } from '../../constants/emojis';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  useBottomsheetContainer,
  useExtraBottomsheetContainer,
} from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { type ExecutionResultWithData, useMutation, useQuery } from '../../graphql/client';
import {
  DeleteCommentDocument,
  type FragmentType,
  getFragment,
  MessageReactionType,
  MessagesByIdDocument,
  type MessagesByIdQuery,
  ReactCommentDocument,
  RemoveReactCommentDocument,
  ReplyToMessageFragmentDoc,
  TierTypename,
  TrackCommentRowFragmentDoc,
} from '../../graphql/generated';
import { useIsClamped } from '../../hooks/useIsClamped';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useUserDisplayName } from '../../hooks/useUserDisplayName';
import { Sentry } from '../../sentry';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getFromList } from '../../utils/arrayUtils';
import { createBatchingInputStore } from '../../utils/batchInput';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { parseTimestamps, splitOnTimestamps } from '../../utils/timestampParse';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { DropdownEllipsis } from '../common/Dropdown';
import { Text } from '../common/Text';
import { TimestampText } from '../common/TimestampText';
import { View } from '../common/View';
import { closeFullScreen } from '../main/AudioPlayer';
import { UserProfileImage } from '../user/UserProfileImage';
import {
  getTrackCommentsOwnComments,
  useTrackCommentReplies,
} from '../views/hooks/useTrackCommentsReplies';

gql(/* GraphQL */ `
  fragment TrackCommentThreadPreview on Message {
    id
    createdAt
    updatedAt
    content
    source
    activeSubscriptionTier
    asArtist {
      __typename
      id
      linkValue
      createdAt
      profileImage {
        id
        artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      }
      name
    }
    user {
      __typename
      id
      createdAt
      avatar {
        id
        userSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      }
      displayName
      username
    }
    reactionsSummary {
      type
      count
      emojiKeyword
    }
    replyTo {
      ...replyToMessage
    }
    threadRootId
    threadMessagesCount
    artistReactions {
      id
      type
      emojiKeyword
    }
    myReactionsInfo(asArtistId: $asArtistId) {
      id
      type
      emojiKeyword
    }
  }

  fragment TrackCommentRow on Message {
    id
    content
    source
    createdAt
    updatedAt
    reactionsSummary {
      type
      emojiKeyword
      count
    }
    artistReactions {
      id
      type
      emojiKeyword
    }
    myReactionsInfo(asArtistId: $asArtistId) {
      id
      type
      emojiKeyword
    }
    asArtist {
      __typename
      id
      linkValue
      profileImage {
        id
        artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        dominantColor
      }
      createdAt
      name
    }
    user {
      __typename
      id
      createdAt
      avatar {
        id
        userSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        dominantColor
      }
      displayName
      username
    }
    activeSubscriptionTier
    replyTo {
      ...replyToMessage
    }
    threadMessagesPreview {
      ...TrackCommentThreadPreview
    }
    threadRootId
    threadMessagesCount
  }

  query MessagesById($input: QueryMessagesByIdInput!, $asArtistId: UUID) {
    messagesById(input: $input) {
      id
      updatedAt
      ...TrackCommentRow
    }
  }
`);

const { useBatchedKey: useBatchedTrackCommentId } = createBatchingInputStore({
  chunkLimit: 50,
});

export function TrackCommentRow({
  rootIndex,
  comment,
  trackDuration,
  vaultId,
  contentId: vaultContentId,
  vaultArtistHandle,
  vaultArtistId,
  vaultArtistProfileImageUrl,
  type,
  className,
  isFreeTierSubscription,
  hasTrackCommentsWriteAccess,
  isDisabled,
  onReply,
  onHideReplies,
  folderId,
}: {
  rootIndex: number;
  comment: FragmentType<typeof TrackCommentRowFragmentDoc>;
  trackDuration: number;
  vaultId: string;
  contentId: string;
  vaultArtistId: string | undefined | null;
  vaultArtistHandle: string | undefined | null;
  vaultArtistProfileImageUrl: string | null;
  type: 'caption' | 'comment';
  className?: string;
  isFreeTierSubscription: boolean;
  hasTrackCommentsWriteAccess: boolean;
  isDisabled: boolean;
  onReply: (data: {
    comment: FragmentType<typeof TrackCommentRowFragmentDoc>;
    userDisplayName: string;
    rootIndex: number;
  }) => void;
  onHideReplies: () => void;
  folderId: string | null;
}) {
  const { id: commentId, updatedAt: propUpdatedAt } = getFragment(
    TrackCommentRowFragmentDoc,
    comment,
  );

  const { loggedInUser } = useAuthContext();

  const adminArtist = useMemo(() => {
    return getFromList(
      loggedInUser?.adminArtists,
      adminArtist => adminArtist.artistId === vaultArtistId && adminArtist,
    );
  }, [loggedInUser?.adminArtists, vaultArtistId]);

  const asArtistId = adminArtist?.artistId;

  const { openToast } = useToast();

  const messageIds = useBatchedTrackCommentId({
    key: commentId,
  });

  const { data: commentQuery } = useQuery(MessagesByIdDocument, {
    variables: !!messageIds && {
      input: {
        messageIds,
      },
      asArtistId,
    },
    staleTime: 0,
    select: useStableCallback((data: ExecutionResultWithData<MessagesByIdQuery>) => {
      const commentQuery = data.data.messagesById.find(comment => comment.id === commentId);

      if (!commentQuery) return null;

      /**
       * If comment from prop is newer than the value from query, fallback to the prop comment
       */
      return commentQuery.updatedAt > propUpdatedAt ? commentQuery : null;
    }),
    keepPreviousData: true,
  });

  const {
    content,
    asArtist,
    user,
    createdAt,
    myReactionsInfo,
    reactionsSummary,
    artistReactions,
    activeSubscriptionTier,
    threadRootId,
    replyTo,
    threadMessagesCount,
  } = getFragment(TrackCommentRowFragmentDoc, commentQuery ?? comment);

  const { mutate: addReactComment } = useMutation(ReactCommentDocument, {
    onSuccess(data) {
      switch (data.data.createMessageReaction.__typename) {
        case 'MutationCreateMessageReactionSuccess': {
          break;
        }
        default: {
          openToast({
            text: 'This message reaction could not be added at this time. Try again.',
            variant: 'error',
          });
          Sentry.captureMessage(data.data.createMessageReaction.message, {
            extra: {
              data,
            },
            tags: {
              type: 'createMessageReaction',
            },
          });
        }
      }
    },
    onError(error) {
      openToast({
        text: 'This message reaction could not be added at this time. Try again.',
        variant: 'error',
      });
      Sentry.captureException(error, {
        extra: {
          message: error.message,
          type,
        },
        tags: {
          type: 'createMessageReaction',
        },
      });
    },
  });
  const { mutate: removeReactComment } = useMutation(RemoveReactCommentDocument, {
    onSuccess(data) {
      switch (data.data.deleteMessageReaction.__typename) {
        case 'MutationDeleteMessageReactionSuccess': {
          break;
        }
        default: {
          openToast({
            text: 'This message reaction could not be removed at this time. Try again.',
            variant: 'error',
          });
          Sentry.captureMessage(data.data.deleteMessageReaction.message, {
            extra: {
              data,
            },
            tags: {
              type: 'deleteMessageReaction',
            },
          });
        }
      }
    },
    onError(error) {
      openToast({
        text: 'This message reaction could not be removed at this time. Try again.',
        variant: 'error',
      });
      Sentry.captureException(error, {
        extra: {
          message: error.message,
          type,
        },
        tags: {
          type: 'deleteMessageReaction',
        },
      });
    },
  });
  const { mutate: removeComment, isLoading: isRemoving } = useMutation(DeleteCommentDocument, {
    onSuccess(data) {
      switch (data.data.deleteMessage.__typename) {
        case 'MutationDeleteMessageSuccess': {
          break;
        }
        default: {
          openToast({
            text: 'This comment could not be deleted at this time. Try again.',
            variant: 'error',
          });
          Sentry.captureMessage(data.data.deleteMessage.message, {
            extra: {
              data,
            },
            tags: {
              type: 'deleteMessage',
            },
          });
        }
      }
    },

    onError(error) {
      openToast({
        text: 'This comment could not be deleted at this time. Try again.',
        variant: 'error',
      });
      Sentry.captureException(error, {
        extra: {
          message: error.message,
          type,
        },
        tags: {
          type: 'deleteMessage',
        },
      });
    },
  });
  const isVaultArtist = asArtist?.id != null && vaultArtistId === asArtist.id;
  const isLikedByUser = myReactionsInfo.some(
    reaction => reaction.type === 'EMOJI' && reaction.emojiKeyword === BASE_EMOJI_KEYWORDS.HEART,
  );

  const actor = asArtist || user;

  const userDisplayName = useUserDisplayName({
    artistName: actor.__typename === 'Artist' ? actor.name : undefined,
    userDisplayName: actor.__typename !== 'Artist' ? actor.displayName : undefined,
    userId: user.id,
    userUsername: actor.__typename !== 'Artist' ? actor.username : undefined,
  });

  const { closeBottomsheet } = useBottomsheetContainer();
  const { openExtraBottomsheet, closeExtraBottomsheet } = useExtraBottomsheetContainer();

  const openProfileBottomsheet = () => {
    openExtraBottomsheet({
      type: 'USER_PROFILE',
      shared: {
        withVaultTheme: true,
      },
      userProfileBottomsheetProps: {
        joinDate: isVaultArtist ? asArtist.createdAt : null,
        vaultId,
        avatarUrl: asArtist
          ? asArtist.profileImage?.artistSmallProfileImageUrl
          : user.avatar?.userSmallProfileImageUrl,
        displayName: asArtist ? asArtist.name : user.displayName,
        showAdminOptions: asArtistId != null && asArtistId === vaultArtistId,
        userId: user.id,
        username: asArtist ? asArtist.linkValue : user.username,
        vaultArtistId,
        isVaultArtist,
        activeSubscriptionTier,
        withVaultTheme: true,
        userLocation: null,
      },
    });
  };

  const navigate = useNavigate();

  const manageCommentButtons = useMemo(() => {
    const buttons: ActionBottomsheetProps['buttons'] = [];

    if (type === 'caption' && asArtistId && asArtistId === vaultArtistId) {
      buttons.push({
        type: 'secondary',
        label: 'Edit description',
        buttonType: 'button',
        className:
          'border-b-vault_text/5 bg-transparent hover:bg-vault_text/10 text-vault_text ease-in-out duration-300 transition-all',
        leadingIcon: faEdit,
        onClick() {
          closeBottomsheet('button');
          closeExtraBottomsheet('button');
          closeFullScreen();
          if (vaultArtistHandle)
            navigate(artistNavigationPath(vaultArtistHandle, `/edit/${vaultContentId}`));
        },
      });
    }

    if (
      type === 'comment' &&
      ((vaultArtistId && asArtistId === vaultArtistId) ||
        (actor.__typename === 'Artist' ? actor.id === asArtistId : actor.id === loggedInUser?.id))
    ) {
      buttons.push({
        leadingIcon: faTrashCan,
        label: 'Delete message',
        type: 'secondary',
        className: 'border-b-vault_text/5 bg-transparent hover:bg-vault_text/20 text-vault_text',
        disabled: isRemoving,
        loading: isRemoving,
        onClick() {
          removeComment(
            {
              input: {
                messageId: commentId,
              },
            },
            {
              onSuccess({ data: { deleteMessage } }) {
                switch (deleteMessage.__typename) {
                  case 'MutationDeleteMessageSuccess': {
                    closeExtraBottomsheet('button');
                    openToast({
                      variant: 'success',
                      text: 'Comment deleted',
                    });

                    const ownComments = getTrackCommentsOwnComments(vaultContentId);

                    if (ownComments.has(commentId)) {
                      ownComments.delete(commentId);
                    }

                    return;
                  }
                  default: {
                    openToast({
                      variant: 'error',
                      text: 'An error occurred while deleting the comment. Please try again.',
                    });
                    captureMessage('Error deleting comment', {
                      extra: {
                        deleteMessage,
                        comment,
                        vaultArtistId,
                      },
                      level: 'error',
                    });
                  }
                }
              },
              onError(error) {
                openToast({
                  variant: 'error',
                  text: 'An error occurred while deleting the comment. Please try again.',
                });
                captureException(error, {
                  extra: {
                    comment,
                    vaultArtistId,
                  },
                  level: 'error',
                });
              },
            },
          );
        },
        event: {
          type: EVENTS.DELETE_COMMENT_MESSAGE,
          properties: {
            messageId: commentId,
            trackId: vaultContentId,
          },
        },
      });
    }

    return buttons;
  }, [
    type,
    vaultArtistId,
    asArtistId,
    actor.__typename,
    actor.id,
    loggedInUser?.id,
    isRemoving,
    removeComment,
    commentId,
    closeExtraBottomsheet,
    openToast,
    comment,
    vaultArtistHandle,
    vaultContentId,
    navigate,
    closeBottomsheet,
  ]);

  const splitContent = useMemo(() => {
    const timestamps = isFreeTierSubscription ? null : parseTimestamps(content);

    return splitOnTimestamps({
      content,
      timestamps,
    });
  }, [content, isFreeTierSubscription]);

  const isArtistReaction = useMemo(
    () =>
      artistReactions.some(
        reaction =>
          reaction.type === 'EMOJI' && reaction.emojiKeyword === BASE_EMOJI_KEYWORDS.HEART,
      ),
    [artistReactions],
  );

  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncated = useCallback(() => {
    setIsTruncated(value => !value);
  }, [setIsTruncated]);

  const textRef = useRef<HTMLDivElement>(null);

  const { isClamped } = useIsClamped(textRef);

  const HEART_SUMMARY_COUNT = useMemo(() => {
    return (
      reactionsSummary.find(v => v.type === 'EMOJI' && v.emojiKeyword === BASE_EMOJI_KEYWORDS.HEART)
        ?.count ?? 0
    );
  }, [reactionsSummary]);

  const replyToUsername = useMemo(() => {
    const replyToFrag = getFragment(ReplyToMessageFragmentDoc, replyTo);
    if (replyToFrag?.id && threadRootId !== replyToFrag.id) {
      return (
        <span className="bg-transparent !text-base-m text-vault_text">
          {`@${
            replyToFrag.asArtist?.name || replyToFrag.user.displayName || replyToFrag.user.username
          } `}
        </span>
      );
    }
    return null;
  }, [replyTo, threadRootId]);

  const loadingAnimation = (
    <FontAwesomeIcon
      icon={faSpinner}
      size="sm"
      className="ml-2 inline-block animate-spin rounded-full font-medium text-vault_text/50"
    />
  );

  const { isFetchingNextPage, isInitialLoading, orderedList, handleViewMoreHideReplies, state } =
    useTrackCommentReplies({
      comment,
      vaultContentId,
    });

  const renderFooter = () => {
    const additionalReplies = Math.max(threadMessagesCount - orderedList.length, 0);

    if (isInitialLoading || isFetchingNextPage) {
      return (
        <View className="mt-2 flex w-16 flex-1 justify-center">
          <Text className="!text-base-l text-vault_text/50">{loadingAnimation}</Text>
        </View>
      );
    }

    let type: 'hide' | 'more' | 'collapse';

    switch (state.status) {
      case 'collapsed': {
        type = 'more';
        break;
      }
      case 'preview':
      case 'expanded': {
        if (orderedList.length <= 0) {
          type = 'hide';
        } else if (orderedList.length >= threadMessagesCount) {
          type = 'collapse';
        } else {
          type = 'more';
        }
        break;
      }
      default: {
        return;
      }
    }

    if (type === 'hide') return null;

    return (
      <View
        className="mt-2 flex cursor-pointer flex-row items-center gap-2"
        onClick={() => {
          handleViewMoreHideReplies();
        }}
      >
        <View className="h-[1px] w-5 bg-vault_text/5" />
        <Text className="!text-base-s font-medium text-vault_text/50">
          {type === 'collapse'
            ? 'Hide replies'
            : `View ${additionalReplies} more ${additionalReplies === 1 ? 'reply' : 'replies'}`}
        </Text>
      </View>
    );
  };

  const isOwner = adminArtist?.artistId === vaultArtistId;

  return (
    <View
      className={twMerge(
        'mt-1 flex min-h-12 flex-row items-center justify-start gap-2 py-2 align-middle',
        isDisabled && 'opacity-50',
        className,
      )}
    >
      <UserProfileImage
        className="mt-1 h-7 w-7 cursor-pointer self-start"
        profileImageUrl={
          actor.__typename === 'Artist'
            ? actor.profileImage?.artistSmallProfileImageUrl
            : actor.avatar?.userSmallProfileImageUrl
        }
        onClick={openProfileBottomsheet}
        withVaultTheme
        fallbackColor={
          actor.__typename === 'Artist'
            ? actor.profileImage?.dominantColor
            : actor.avatar?.dominantColor
        }
      />
      <View className="flex h-full flex-col gap-1">
        <View className="flex flex-row items-center gap-1.5">
          <Text
            className={clsx(
              '!text-base-s font-semibold',
              isVaultArtist ? 'text-vault_accent' : 'text-vault_text',
            )}
          >
            {userDisplayName}
          </Text>
          {(isVaultArtist || activeSubscriptionTier === TierTypename.PaidTier) && (
            <FontAwesomeIcon
              icon={isVaultArtist ? faBadgeCheck : faTicket}
              className="select-none text-[12px] text-vault_accent"
            />
          )}
          <TimestampText
            date={createdAt}
            formatType="short"
            shouldIncludeYearsMonths
            className="!text-base-s text-vault_text/50"
          />
        </View>
        <View className="flex flex-col gap-2">
          <View className="flex cursor-pointer flex-row" onClick={toggleTruncated}>
            <View
              className={clsx(
                '!text-base-m font-normal text-vault_text',
                isTruncated ? 'line-clamp-2' : '',
              )}
              containerRef={textRef}
            >
              {replyToUsername}
              {splitContent.map((value, index) => {
                if (typeof value === 'string') {
                  return <span key={index}>{value}</span>;
                }

                /**
                 * If the parsed timestamp is actually longer than the track duration, we don't want to render it as a button
                 */
                if (value.seconds > trackDuration) {
                  return <span key={index}>{value.timestamp}</span>;
                }

                return (
                  <button
                    key={index}
                    className={twMerge(
                      'cursor-pointer rounded-md border-none p-[2px_4px] font-base font-medium',
                      'bg-vault_text/10 text-vault_text',
                    )}
                    disabled={isDisabled}
                    onClick={() => {
                      loadTrack({
                        trackId: vaultContentId,
                        vaultId,
                        component: 'track_comments',
                        folderId,
                      }).then(() => {
                        seek(value.seconds);
                        play();
                      });
                      trackEvent({
                        type: EVENTS.CLICK_COMMENT_TIMESTAMP,
                        properties: {
                          trackId: vaultContentId,
                          messageId: commentId,
                          seconds: value.seconds,
                        },
                      });
                    }}
                  >
                    {value.timestamp}
                  </button>
                );
              })}
            </View>
            {isTruncated && isClamped && (
              <span className="self-end !text-base-m font-normal text-vault_text/50">more</span>
            )}
          </View>

          <View className="mb-2 mt-1 flex flex-row items-center gap-4">
            {type === 'comment' && (
              <Button
                label="Reply"
                className="font-base !text-base-s font-medium text-vault_text/50"
                onClick={() => {
                  if (!isOwner && !hasTrackCommentsWriteAccess) return;

                  onReply({
                    comment,
                    userDisplayName,
                    rootIndex,
                  });
                }}
              />
            )}
            <View className="flex flex-row items-center gap-3">
              <button
                className={twMerge(
                  'flex flex-row items-center gap-1 border-none bg-transparent',
                  hasTrackCommentsWriteAccess ? 'cursor-pointer' : 'cursor-default',
                )}
                disabled={isDisabled}
                onClick={() => {
                  if (!hasTrackCommentsWriteAccess) return;
                  if (isLikedByUser) {
                    removeReactComment({
                      input: {
                        messageId: commentId,
                        reactionType: 'EMOJI',
                        asArtistId,
                        emojiKeyword: BASE_EMOJI_KEYWORDS.HEART,
                      },
                    });
                  } else {
                    addReactComment({
                      input: {
                        messageId: commentId,
                        reactionType: 'EMOJI',
                        asArtistId,
                        emojiKeyword: BASE_EMOJI_KEYWORDS.HEART,
                      },
                    });
                    trackEvent({
                      type: EVENTS.REACT_TO_COMMENT_MESSAGE,
                      properties: {
                        trackId: vaultContentId,
                        messageId: commentId,
                        reactionType: MessageReactionType.Heart,
                      },
                    });
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={isLikedByUser ? faHeartSolid : faHeart}
                  className={clsx(
                    'text-[12px]',
                    hasTrackCommentsWriteAccess ? 'cursor-pointer' : 'cursor-default',
                    isLikedByUser ? 'text-[#FF54A6]' : 'text-vault_text/50',
                  )}
                />
                <Text className="!text-base-s text-vault_text/50">
                  {isLikedByUser && HEART_SUMMARY_COUNT === 0
                    ? 1
                    : HEART_SUMMARY_COUNT === 0
                      ? null
                      : HEART_SUMMARY_COUNT}
                </Text>
              </button>
              {isArtistReaction && (
                <ArtistProfileImage
                  className="h-4 w-4 rounded-full border-[1px] border-solid border-[#FF54A6]"
                  profileImageUrl={vaultArtistProfileImageUrl}
                />
              )}
              {manageCommentButtons.length > 0 && (
                <DropdownEllipsis
                  dropdownClassName="!overflow-visible"
                  buttons={manageCommentButtons}
                  dropdownType="Manage Comment"
                  withVaultTheme
                  sharedClassname="!text-base-s text-vault_text/50"
                  onClick={() => {
                    if (isDisabled) return;

                    openExtraBottomsheet({
                      type: 'ACTION',
                      shared: {
                        withVaultTheme: true,
                      },
                      actionBottomsheetProps: {
                        buttons: manageCommentButtons,
                        withVaultTheme: true,
                      },
                    });
                  }}
                />
              )}
            </View>
          </View>
          {type === 'comment' && threadMessagesCount > 0 && (
            <View className="flex flex-col">
              {orderedList.map(message => {
                return (
                  <TrackCommentRow
                    key={message.id}
                    rootIndex={rootIndex}
                    comment={message}
                    type="comment"
                    vaultArtistId={vaultArtistId}
                    contentId={vaultContentId}
                    vaultId={vaultId}
                    trackDuration={trackDuration}
                    isFreeTierSubscription={isFreeTierSubscription}
                    vaultArtistProfileImageUrl={vaultArtistProfileImageUrl}
                    vaultArtistHandle={vaultArtistHandle}
                    hasTrackCommentsWriteAccess={hasTrackCommentsWriteAccess}
                    isDisabled={'optimistic' in message}
                    onReply={onReply}
                    onHideReplies={onHideReplies}
                    folderId={folderId}
                  />
                );
              })}

              {renderFooter()}
            </View>
          )}
        </View>
      </View>
    </View>
  );
}
