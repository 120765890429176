import { useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';

import type { ExecutionResultWithData } from '@soundxyz/graphql-react-query';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { type Maybe } from '@soundxyz/utils/helpers';
import { throttledRefetchVaultUpdateCount } from '../../../audio/AudioController';
import { invalidateOperations, useQuery } from '../../../graphql/client';
import {
  type VaultMessageUnreadCountsQueryVariables,
  VaultUpdateCountsDocument,
  type VaultUpdateCountsQuery,
} from '../../../graphql/generated';
import { useVaultScheduledEventUpdatesSubscription } from '../../../hooks/announcements/useVaultScheduledEventUpdatesSubscription';
import { useMessageChannelUpdatesSubscription } from '../../../hooks/useMessageChannelUpdatesSubscription';
import { createBatchingInputStore } from '../../../utils/batchInput';
import { ONE_MINUTE_IN_MS } from '../../../utils/dateUtils';
import { UpdatingVaultMessageEngagement } from './useVaultMessageCountEngagement';

gql(/* GraphQL */ `
  query VaultUpdateCounts($vaultIds: [UUID!]!) {
    vaultUpdateCounts(vaultIds: $vaultIds) {
      __typename
      ... on QueryVaultUpdateCountsSuccess {
        data {
          vaultId
          unseenCount
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

const { useBatchedKey } = createBatchingInputStore({
  chunkLimit: 25,
});

export function useBatchedVaultUnseenUpdateCount({
  enabled,
  messageChannelId,
  vaultId,
}: {
  enabled: boolean;
  vaultId: string;
  messageChannelId: Maybe<string>;
}) {
  const { inProgress } = useSnapshot(UpdatingVaultMessageEngagement);

  const ids = useBatchedKey({
    key: vaultId,
  });

  const variables = useMemo<VaultMessageUnreadCountsQueryVariables | false>(() => {
    if (!ids) return false;

    return {
      vaultIds: ids,
    };
  }, [ids]);

  const isQueryEnabled = enabled && !!variables;

  const { data: updateCount, isLoading } = useQuery(VaultUpdateCountsDocument, {
    enabled: isQueryEnabled,
    variables,
    staleTime: 0,
    cacheTime: ONE_MINUTE_IN_MS * 10,
    keepPreviousData: true,
    select: useStableCallback((data: ExecutionResultWithData<VaultUpdateCountsQuery>) => {
      return data.data.vaultUpdateCounts.__typename === 'QueryVaultUpdateCountsSuccess'
        ? data.data.vaultUpdateCounts.data.find(value => value.vaultId === vaultId)?.unseenCount
        : null;
    }),
  });

  const shouldRefetch = inProgress === 0 && isQueryEnabled;

  useEffect(() => {
    if (shouldRefetch) {
      invalidateOperations({
        operations: [VaultUpdateCountsDocument],
      });
    }
  }, [shouldRefetch]);

  useMessageChannelUpdatesSubscription({
    messageChannelId,
    onSubscriptionData({ data: { messageChannelUpdates } }) {
      if (messageChannelUpdates.__typename !== 'SubscriptionMessageChannelUpdatesSuccess') return;

      switch (messageChannelUpdates.data.__typename) {
        case 'DeleteMessageSubscription':
        case 'CreateMessageSubscription': {
          throttledRefetchVaultUpdateCount();
        }
      }
    },
  });

  useVaultScheduledEventUpdatesSubscription({
    vaultId,
    onSubscriptionData({ data: { scheduledEventUpdates } }) {
      if (scheduledEventUpdates.__typename !== 'SubscriptionScheduledEventUpdatesSuccess') return;
      switch (scheduledEventUpdates.data.__typename) {
        case 'AnnouncementUpdateSubscription': {
          throttledRefetchVaultUpdateCount();
        }
      }
    },
  });

  return {
    updateCount,
    isLoading,
  };
}
