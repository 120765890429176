import { twMerge } from 'tailwind-merge';
import { faMicrophone } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  faMicrophone as faFilledMicrophone,
  faStop,
} from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { Button } from '../buttons/Button';
import { View } from '../common/View';

export const RecordButton = ({
  className,
  filled = true,
  isRecording,
  onStartRecording,
  onStopRecording,
}: {
  className?: string;
  filled?: boolean;
  isRecording: boolean;
  onStartRecording: () => void;
  onStopRecording: () => Promise<void>;
}) => {
  return (
    <View>
      <Button
        label=""
        iconOnly
        leadingIconClassName="text-[20px]"
        leadingIcon={isRecording ? faStop : filled ? faFilledMicrophone : faMicrophone}
        className={twMerge(className, isRecording ? 'text-delete' : 'text-vault_text')}
        onClick={async () => {
          if (isRecording) {
            await onStopRecording();
          } else {
            onStartRecording();
          }
        }}
      />
    </View>
  );
};
