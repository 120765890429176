import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum-slim';

const IS_LOCAL_HOST =
  typeof window !== 'undefined' &&
  (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1');

const DEPLOY_ENV = import.meta.env.VITE_DEPLOY_ENVIRONMENT;
const APP_VERSION = import.meta.env.NEXT_PUBLIC_WEBAPP_VERSION;

if (typeof window !== 'undefined' && DEPLOY_ENV !== 'development' && !IS_LOCAL_HOST) {
  const DATADOG_SAMPLING = 100;

  datadogRum.init({
    applicationId: 'd64c7d5c-d516-4e46-b0d8-0b61820783fb',
    clientToken: 'pub41b71d6ffb194e6a519b5084b4389432',
    site: 'datadoghq.com',
    service: 'vault-webapp',
    env: DEPLOY_ENV,
    proxy: 'https://info-k9.vault.fm/',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: APP_VERSION,
    sessionSampleRate: DATADOG_SAMPLING,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogLogs.init({
    clientToken: 'pub1f561b22ac4a61900a4250354cb2f440',
    site: 'datadoghq.com',
    service: 'vault-webapp',
    env: DEPLOY_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: APP_VERSION,
    // Sending all logs instead of sampling
    sessionSampleRate: 100,
    // This can be very noisy so we're turning it off for now
    forwardErrorsToLogs: false,
  });
}
