import { type ReactNode, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { View } from './View';

export function PaginatedView({
  children,
  hasNextPage,
  fetchNextPage,
  className,
  paginationThreshold,
}: {
  children: ReactNode;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => void;
  className: string | undefined;
  paginationThreshold: number | undefined;
}) {
  const [bottomRef, isAtBottom] = useInView({
    threshold: paginationThreshold,
  });

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isAtBottom, fetchNextPage]);

  return (
    <View className={className}>
      {children}
      <div ref={bottomRef} />
    </View>
  );
}
