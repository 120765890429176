import { Link } from 'react-router-dom';
import { useArtistEvents } from '../../hooks/membership/useArtistEvents';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { EventItem, EventItemSkeleton } from './EventItem';
import { EmptyEvents, Item } from './shared';

export const EventsSection = ({ artistHandle }: { artistHandle: string }) => {
  const { artistEvents: events = [], isInitialLoading } = useArtistEvents({
    artistHandle,
    limit: 4,
    source: 'membership_page',
  });

  const hasEvents = events.length > 0;
  const hasMoreEvents = events.length > 3;

  if (isInitialLoading) {
    return (
      <Item header="Drops">
        <div>
          {Array.from({ length: 3 }).map((_, index) => (
            <EventItemSkeleton key={index} />
          ))}
        </div>
      </Item>
    );
  }

  if (!hasEvents) {
    return (
      <Item header="Drops">
        <EmptyEvents />
      </Item>
    );
  }

  return (
    <Item
      header="Drops"
      headerLink={
        hasMoreEvents ? (
          <Link
            to={artistNavigationPath(artistHandle, '/drops/events')}
            className="text-base text-[14px]/[16px] text-vault_text/50 no-underline transition-all duration-200 ease-in hover:text-vault_text/80"
          >
            See all
          </Link>
        ) : null
      }
    >
      {events.slice(0, 3).map(event => (
        <EventItem key={event.id} eventData={event} />
      ))}
    </Item>
  );
};
