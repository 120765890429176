import { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { chunk, compact, isArray } from 'lodash-es';
import millify from 'millify';
import { Virtuoso } from 'react-virtuoso';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faImage, faMessage } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faThumbtack } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faMessages } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faReceipt } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCancel } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import {
  faImage as faImageSolid,
  faMessage as faMessageSolid,
  faThumbtack as faThumbtackSolid,
} from '@soundxyz/font-awesome/pro-solid-svg-icons';

import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useInfiniteQuery, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import type { MediaType } from '../../graphql/generated';
import {
  CreateMessageReactionDocument,
  DeleteMessageDocument,
  DeleteMessageReactionDocument,
  FeatureTypename,
  GetAttachmentsInMessageChannelDocument,
  GetSubscriberBanStatusDocument,
  MessageBubbleFragmentDoc,
  MessageChannelDetailsFragmentDoc,
  MessageChannelType,
  PinMessageDocument,
  RemovePinnedMessageDocument,
  TierTypename,
} from '../../graphql/generated';
import {
  type FragmentType,
  GetArtistMessagesDocument,
  getFragment,
  GetPinnedMessagesDocument,
  SeeDetailsHeaderFragmentDoc,
} from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useWindow } from '../../hooks/useWindow';
import { DetailsTabStore, type SeeDetailsTab } from '../../screens/SeeDetailsMessageChannelPage';
import { SkeletonProfiePicture } from '../../screens/settings/EditArtistPage';
import { getFromList } from '../../utils/arrayUtils';
import { compareDates, dateToTime, getMonthAndDate, isSamePeriod } from '../../utils/dateUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { MediaViewer } from '../message/MediaViewer';
import { MessageBubble, SkeletonMessageBubble } from '../message/MessageBubble';
import { UserProfileImage } from '../user/UserProfileImage';
import { EmptyStateView } from './EmptyStateView';

gql(/* GraphQL */ `
  fragment SeeDetailsHeader on MessageChannel {
    id
    channelType
    details {
      singleRecipientActorId
      ...messageChannelDetails
    }
    artist {
      id
      isAuthUserAdmin
      name
      linkValue
      profileImage {
        id
        artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      }
      mainVaultId
    }
    vault {
      id
      isUserArtistAdmin
      artistProfile {
        id
        name
        linkValue
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
      }
      activeSubscription {
        id
        ...ActiveSubscriptionFeatures
      }
      tiers {
        __typename
        enabledFeatures {
          feature {
            __typename
          }
        }
      }
    }
  }

  query GetArtistMessages(
    $artistHandle: String!
    $asArtistId: UUID
    $after: String
    $first: Int = 20
  ) {
    artistMessagesInChannel(
      artistHandle: $artistHandle
      includeTrackComments: false
      after: $after
      first: $first
    ) {
      edges {
        node {
          id
          createdAt
          ...messageBubble
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  query GetPinnedMessages($input: QueryMessageChannelInput!, $asArtistId: UUID) {
    messageChannel(input: $input) {
      __typename

      ... on QueryMessageChannelSuccess {
        data {
          id
          vault {
            id
            artistProfile {
              id
            }
          }
          pinnedMessages {
            id
            createdAt
            ...messageBubble
            ...pinnedMessage
          }
        }
      }
    }
  }

  query GetAttachmentsInMessageChannel($channelId: UUID!) {
    messageChannelAttachments(channelId: $channelId, mediaTypes: [VIDEO, IMAGE]) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          createdAt
          uploadedMedia {
            id
            mediaType
            cdnUrl
            fullImageUrl: imageOptimizedUrl
            mediumImageUrl: imageOptimizedUrl(input: { width: 600, height: 600 })
          }
        }
      }
    }
  }
`);

type Props = {
  tab: SeeDetailsTab;
  artistHandle: string | undefined;
  isHeaderLoading: boolean;
  messageChannel: FragmentType<SeeDetailsHeaderFragmentDoc> | null;
  isAtTop: boolean;
  setIsAtTop: (isAtTop: boolean) => void;
};

const LIMIT = 20;

RefetchOnComplete({
  trigger: [
    CreateMessageReactionDocument,
    DeleteMessageReactionDocument,
    DeleteMessageDocument,
    PinMessageDocument,
    RemovePinnedMessageDocument,
  ],
  refetch: [GetPinnedMessagesDocument, GetArtistMessagesDocument],
});

export const SeeDetailsMessageChannelView = ({
  messageChannel,
  isHeaderLoading,
  tab,
  artistHandle,
  isAtTop,
  setIsAtTop,
}: Props) => {
  const [isAtBottom, setIsAtBottom] = useState(false);
  const { isDesktop } = useWindow();
  const { loggedInUser } = useAuthContext();
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const [hasScrolled, setHasScrolled] = useState(false);

  const { value: isUnifiedInboxEnabled } = useGate(FEATURE_GATES.UNIFIED_INBOX);

  const adminArtist = useMemo(() => {
    if (!artistHandle) return null;
    return getFromList(
      loggedInUser?.adminArtists,
      adminArtist => adminArtist.artistLinks.includes(artistHandle.toLowerCase()) && adminArtist,
    );
  }, [loggedInUser?.adminArtists, artistHandle]);

  const onSetTab = useStableCallback((tab: SeeDetailsTab) => {
    if (!artistHandle) return;

    setIsAtBottom(false);

    DetailsTabStore.produceExistingState(
      draft => {
        draft[artistHandle] = tab;
      },
      { [artistHandle]: tab },
    );
  });

  const messageChannelFrag = getFragment(SeeDetailsHeaderFragmentDoc, messageChannel);

  const now = useMemo(() => new Date(), []);

  const isAuthor = !!adminArtist;

  const channelId = messageChannelFrag?.id;

  const channelArtistId =
    messageChannelFrag?.artist?.id || messageChannelFrag?.vault?.artistProfile?.id;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: messageChannelFrag?.vault?.activeSubscription,
    isOwner: adminArtist?.artistId === channelArtistId,
  });

  const hasChatWriteAccess =
    messageChannelFrag?.channelType === MessageChannelType.ArtistDm
      ? activeSubscriptionFeatures?.enabledFeatures.DMWrite === true
      : activeSubscriptionFeatures?.enabledFeatures.ChatWrite === true;

  const {
    orderedList: artistMessages,
    isLoading: isLoadingArtistMessages,
    isError: isErrorArtistMessages,
    hasNextPage: hasNextArtistMessagesPage,
    loadMoreNextPage: loadMoreArtistMessages,
    refetch: refetchArtistMessages,
  } = useInfiniteQuery(GetArtistMessagesDocument, {
    enabled: tab === 'artist',
    staleTime: 0,
    getNextPageParam: ({ data }) => {
      return (
        data.artistMessagesInChannel.pageInfo.hasNextPage && {
          after: data.artistMessagesInChannel.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      (({ pageParam }) => {
        return {
          artistHandle,
          after: pageParam?.after ?? null,
          first: LIMIT,
          asArtistId: adminArtist?.artistId,
        };
      }),
    list: ({ artistMessagesInChannel }) => {
      return artistMessagesInChannel.edges.map(e => ({ ...e.node, tab: 'artist' as const }));
    },
    uniq: ({ id }) => id,
    filterQueryKey: {
      artistHandle,
      channelId,
    },
  });

  const {
    data: pinnedMessagesData,
    isLoading: isLoadingPinnedMessages,
    isError: isErrorPinnedMessages,
    refetch: refetchPinnedMessages,
  } = useQuery(GetPinnedMessagesDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      input: { artistHandle: { handle: artistHandle, type: MessageChannelType.Vault } },
      asArtistId: adminArtist?.artistId,
    },
    enabled: tab === 'pinned',
    select: data => {
      if (data.data.messageChannel?.__typename !== 'QueryMessageChannelSuccess') return null;
      return data.data.messageChannel.data;
    },
  });

  const {
    orderedList: attachmentsData,
    isLoading: isLoadingAttachments,
    isError: isErrorAttachments,
    hasNextPage: hasNextAtttachmentsPage,
    isLoadingNewPage: isLoadingNewAttachments,
    loadMoreNextPage: loadMoreAttachments,
    refetch: refetchAttachments,
  } = useInfiniteQuery(GetAttachmentsInMessageChannelDocument, {
    enabled: tab === 'attachments' && !!channelId,
    staleTime: 0,
    getNextPageParam: ({ data }) => {
      return (
        data.messageChannelAttachments.pageInfo.hasNextPage && {
          after: data.messageChannelAttachments.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!channelId &&
      (({ pageParam }) => {
        return {
          channelId,
          after: pageParam?.after ?? null,
          first: LIMIT,
        };
      }),
    list: ({ messageChannelAttachments }) => {
      return compact(
        messageChannelAttachments.edges.map(
          e =>
            e.node.uploadedMedia != null &&
            (e.node.uploadedMedia.mediaType === 'VIDEO' ||
              e.node.uploadedMedia.mediaType === 'IMAGE') && {
              createdAt: e.node.createdAt,
              ...e.node.uploadedMedia,
              tab: 'attachments' as const,
            },
        ),
      );
    },
    uniq: ({ id }) => id,
    filterQueryKey: {
      artistHandle,
      channelId,
      tab,
    },
  });

  const onEndReached = useCallback(() => {
    switch (tab) {
      case 'artist':
        if (hasNextArtistMessagesPage) {
          loadMoreArtistMessages();
        }
        break;
      case 'pinned':
        break;
      case 'attachments':
        if (hasNextAtttachmentsPage) {
          loadMoreAttachments();
        }
        break;
    }
  }, [
    hasNextArtistMessagesPage,
    hasNextAtttachmentsPage,
    loadMoreArtistMessages,
    loadMoreAttachments,
    tab,
  ]);

  const onRetryClick = useCallback(() => {
    switch (tab) {
      case 'artist':
        return refetchArtistMessages();
      case 'pinned':
        return refetchPinnedMessages();
      case 'attachments':
        return refetchAttachments();
    }
  }, [refetchArtistMessages, refetchAttachments, refetchPinnedMessages, tab]);

  const pinnedMessages = useMemo(
    () => pinnedMessagesData?.pinnedMessages ?? [],
    [pinnedMessagesData?.pinnedMessages],
  );

  const data = useMemo(() => {
    switch (tab) {
      case 'artist':
        return artistMessages;
      case 'pinned':
        return pinnedMessages.map(m => ({ ...m, tab: 'pinned' as const }));
      case 'attachments':
        return chunk(attachmentsData, isDesktop ? 4 : 3);
    }
  }, [artistMessages, attachmentsData, isDesktop, pinnedMessages, tab]);

  const onView = useCallback(
    (index: number) => {
      openOverlay(
        <MediaViewer
          medias={attachmentsData.map(m => ({
            id: m.id,
            url: m.fullImageUrl ?? m.cdnUrl,
            type: m.mediaType,
          }))}
          titles={attachmentsData.map(m => getMonthAndDate(new Date(m.createdAt)))}
          subtitles={attachmentsData.map(m => dateToTime(m.createdAt))}
          startAt={index}
          onClose={closeOverlay}
          title=""
          isLoadingNextPage={isLoadingNewAttachments}
        />,
      );
    },
    [attachmentsData, closeOverlay, isLoadingNewAttachments, openOverlay],
  );

  const renderItems = useCallback(
    (
      index: number,
      message:
        | ({ tab: 'artist' | 'pinned' } & FragmentType<MessageBubbleFragmentDoc>)
        | {
            id: string;
            cdnUrl: string;
            mediaType: MediaType;
            tab: 'attachments';
            mediumImageUrl: string | null;
            fullImageUrl: string | null;
          }[],
    ) => {
      if (isArray(message)) {
        const medias = message;
        return (
          <View className="grid w-full grid-cols-3 md2:grid-cols-4">
            {medias.map((media, i) => {
              return (
                <Media
                  key={media.id}
                  {...media}
                  index={index * (isDesktop ? 4 : 3) + i}
                  onView={onView}
                  url={media.mediumImageUrl ?? media.cdnUrl}
                />
              );
            })}
          </View>
        );
      }

      const { user, asArtist, createdAt } = getFragment(MessageBubbleFragmentDoc, message);
      const isAuthor = asArtist
        ? asArtist.id === adminArtist?.artistId
        : user.id === loggedInUser?.id;

      const isVaultArtist = asArtist?.id != null && channelArtistId === asArtist.id;

      const areSubscriptionTierBadgesVisible = messageChannelFrag?.vault?.tiers
        ?.find(tier => tier.__typename === TierTypename.FreeTier)
        ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatWrite);

      const nextMessage = data[index - 1];

      const nextMessageIsInDifferentGroup =
        nextMessage == null ||
        (!isArray(nextMessage) &&
          (nextMessage?.tab === 'artist' || nextMessage?.tab === 'pinned') &&
          !isSamePeriod(new Date(createdAt), new Date(nextMessage.createdAt), 2));

      const dateMemo = nextMessageIsInDifferentGroup ? (
        <p className="pb-2 text-center !text-base-s text-vault_text/50">
          <b>{compareDates(new Date(createdAt), now)}</b> {dateToTime(createdAt)}
        </p>
      ) : null;

      return (
        <>
          {dateMemo}
          <MessageBubble
            message={message}
            artistProfileImageUrl={asArtist?.profileImage?.artistSmallProfileImageUrl ?? null}
            artistName={asArtist?.name}
            artistLinkValue={asArtist?.linkValue}
            isElevated={false}
            areSubscriptionTierBadgesVisible={!!areSubscriptionTierBadgesVisible}
            isOwner={isAuthor}
            isVaultArtist={isVaultArtist}
            vaultArtistId={messageChannelFrag?.vault?.artistProfile?.id}
            containerMarginRight="-60px"
            type="see_details"
            hasChatWriteAccess={hasChatWriteAccess || isAuthor}
            vaultId={messageChannelFrag?.vault?.id}
          />
        </>
      );
    },
    [
      adminArtist?.artistId,
      channelArtistId,
      data,
      hasChatWriteAccess,
      isDesktop,
      loggedInUser?.id,
      messageChannelFrag?.vault?.artistProfile?.id,
      messageChannelFrag?.vault?.id,
      messageChannelFrag?.vault?.tiers,
      now,
      onView,
    ],
  );

  const EmptyFooter = useCallback(() => <View className="h-20" />, []);
  const EmptyHeader = useCallback(() => tab !== 'attachments' && <View className="h-4" />, [tab]);

  const isLoading =
    isHeaderLoading || isLoadingArtistMessages || isLoadingPinnedMessages || isLoadingAttachments;

  const isError = isErrorArtistMessages || isErrorPinnedMessages || isErrorAttachments;

  return (
    <>
      <Header
        messageChannel={messageChannel}
        tab={tab}
        onSetTab={onSetTab}
        isHeaderLoading={isHeaderLoading}
        hasScrolled={hasScrolled}
        isMinimized={!isDesktop && !isAtTop}
        isGroupChat={messageChannelFrag?.channelType === MessageChannelType.Vault}
      />

      <View
        className={twMerge(
          'w-full flex-1',
          isUnifiedInboxEnabled &&
            messageChannelFrag?.channelType === MessageChannelType.ArtistDm &&
            'mt-4 border-0 border-t border-solid border-vault_text/5',
        )}
      >
        {isError ? (
          <ErrorView className="flex-1" onRetryClick={onRetryClick} withVaultTheme />
        ) : isLoading ? (
          <LoadingList tab={tab} isVaultArtist={isAuthor} />
        ) : data.length === 0 ? (
          <EmptyState tab={tab} />
        ) : (
          <Virtuoso
            onScroll={() => {
              setHasScrolled(true);
            }}
            data={data}
            endReached={onEndReached}
            overscan={{ reverse: 100, main: 100 }}
            itemContent={renderItems}
            className="scrollbar-theme w-full flex-1"
            atTopStateChange={state => !isAtBottom && setIsAtTop(state)}
            atBottomStateChange={setIsAtBottom}
            components={{ Footer: EmptyFooter, Header: EmptyHeader }}
          />
        )}
      </View>
    </>
  );
};

const Header = ({
  messageChannel,
  isHeaderLoading,
  tab,
  onSetTab,
  isMinimized = false,
  hasScrolled,
  isGroupChat,
}: {
  isHeaderLoading: boolean;
  messageChannel: FragmentType<SeeDetailsHeaderFragmentDoc> | null;
  tab: SeeDetailsTab;
  onSetTab: (tab: SeeDetailsTab) => void;
  isMinimized: boolean;
  hasScrolled: boolean;
  isGroupChat: boolean;
}) => {
  const { value: isUnifiedInboxEnabled } = useGate(FEATURE_GATES.UNIFIED_INBOX);
  const { openBottomsheet } = useBottomsheetContainer();

  const messageChannelFrag = getFragment(SeeDetailsHeaderFragmentDoc, messageChannel);
  const details = getFragment(MessageChannelDetailsFragmentDoc, messageChannelFrag?.details);

  const userId = messageChannelFrag?.details?.singleRecipientActorId;

  const artistProfile = messageChannelFrag?.artist ?? messageChannelFrag?.vault?.artistProfile;

  const title = isUnifiedInboxEnabled ? details?.titleText : artistProfile?.name;
  const coverImage = isUnifiedInboxEnabled
    ? details?.coverImage?.imageSmallUrl
    : artistProfile?.profileImage?.artistSmallProfileImageUrl;

  const { data: isBanned, isLoading: isLoadingBanStatus } = useQuery(
    GetSubscriberBanStatusDocument,
    {
      enabled: isUnifiedInboxEnabled,
      variables: !!artistProfile && !!userId && { userId, artistId: artistProfile.id },
      staleTime: 0,
      select: data => data.data.getArtistSubscriberBanStatus,
    },
  );

  const onBanClick = () => {
    if (!isUnifiedInboxEnabled || !messageChannelFrag?.artist || !userId) return;

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.BAN_USER,
      shared: {
        withVaultTheme: true,
      },
      banUserBottomsheetProps: {
        artistId: messageChannelFrag.artist.id,
        userId,
      },
    });
  };

  if (isHeaderLoading || messageChannelFrag == null || artistProfile == null) {
    return (
      <View className="flex w-full flex-col items-center">
        <SkeletonProfiePicture className="mb-4 h-[86px] w-[86px] rounded-full bg-vault_text/10" />
        <LoadingSkeleton className="mb-2 h-[34px] w-[250px] bg-vault_text/10" />
        <LoadingSkeleton className="h-[14px] w-[200px] bg-vault_text/10" />
        <View className="flex w-full flex-row pt-2">
          <Button
            className={twMerge(
              'flex-1 border-0 border-b border-solid py-5 text-[20px]',
              'border-vault_background text-vault_text',
              tab === 'artist' && 'border-vault_text',
            )}
            label=""
            iconOnly
            leadingIcon={faMessageSolid}
          />
          <Button
            className={twMerge(
              'flex-1 border-0 border-b border-solid py-5 text-[20px]',
              'border-vault_background text-vault_text',
              tab === 'pinned' && 'border-vault_text',
            )}
            label=""
            iconOnly
            leadingIcon={faThumbtackSolid}
          />
          <Button
            className={twMerge(
              'flex-1 border-0 border-b border-solid py-5 text-[20px]',
              'border-vault_background text-vault_text',
              tab === 'attachments' && 'border-vault_text',
            )}
            label=""
            iconOnly
            leadingIcon={faImageSolid}
          />
        </View>
      </View>
    );
  }

  return (
    <View className="flex w-full flex-col items-center">
      <AnimatePresence>
        {!isMinimized && (
          <motion.div
            className="flex w-full flex-col items-center"
            initial={hasScrolled && { opacity: 0, height: 0 }}
            animate={hasScrolled && { opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            {isGroupChat && isUnifiedInboxEnabled ? (
              <View className="mb-4 flex h-[86px] w-[86px] items-center justify-center rounded-full bg-vault_text">
                <FontAwesomeIcon
                  icon={faMessages}
                  className="text-[34px] text-vault_text_opposite"
                />
              </View>
            ) : details?.showVerifiedBadge ? (
              <ArtistProfileImage
                profileImageUrl={coverImage}
                className="mb-4 h-[86px] w-[86px] rounded-full"
              />
            ) : (
              <UserProfileImage
                profileImageUrl={coverImage}
                className="mb-4 h-[86px] w-[86px] rounded-full"
                withVaultTheme
              />
            )}
            <View className="mb-1 flex flex-row items-center gap-1">
              <Text className="line-clamp-1 font-title !text-title-xl font-medium text-vault_text">
                {title}
              </Text>
              {details?.showVerifiedBadge && isUnifiedInboxEnabled && (
                <FontAwesomeIcon icon={faBadgeCheck} className="text-[16px] text-vault_accent" />
              )}
            </View>

            {isUnifiedInboxEnabled && !!details?.username && (
              <Text className="mb-2 line-clamp-1 font-base text-[16px]/[20px] font-normal text-vault_text/50">
                @{details.username}
              </Text>
            )}

            <View className="flex flex-row items-center gap-1">
              <Text className="line-clamp-1 font-base text-[16px]/[20px] font-normal text-vault_text/50">
                {isGroupChat
                  ? 'Artist messages and media'
                  : isUnifiedInboxEnabled
                    ? details?.showVerifiedBadge
                      ? details?.subtitleText
                      : `${details?.subtitleText} · ${millify(details?.receiptCount || 0)}`
                    : ''}
              </Text>
              {isUnifiedInboxEnabled && !details?.showVerifiedBadge && !isGroupChat && (
                <FontAwesomeIcon icon={faReceipt} className="text-[14px] text-vault_text/50" />
              )}
            </View>

            {isUnifiedInboxEnabled &&
              !details?.showVerifiedBadge &&
              !isGroupChat &&
              !isLoadingBanStatus && (
                <View
                  className="mt-5 flex cursor-pointer flex-col items-center gap-4 rounded-xl px-4 py-2 hover:bg-vault_text/10 md2:gap-2"
                  onClick={onBanClick}
                >
                  <FontAwesomeIcon icon={faCancel} className="text-[20px] text-destructive300" />

                  <Text className="font-base text-[12px]/[14px] font-medium text-destructive300">
                    {isBanned ? 'Unban' : 'Ban'}
                  </Text>
                </View>
              )}
          </motion.div>
        )}
      </AnimatePresence>

      {(!isUnifiedInboxEnabled || isGroupChat) && (
        <View className={twMerge('flex w-full flex-row', !isMinimized && 'pt-2')}>
          <Button
            onClick={() => onSetTab('artist')}
            className={twMerge(
              'flex-1 border-0 border-b border-solid py-5 text-[20px]',
              'border-vault_background text-vault_text',
              tab === 'artist' && 'border-vault_text',
            )}
            label=""
            iconOnly
            leadingIcon={faMessageSolid}
          />
          <Button
            onClick={() => onSetTab('pinned')}
            className={twMerge(
              'flex-1 border-0 border-b border-solid py-5 text-[20px]',
              'border-vault_background text-vault_text',
              tab === 'pinned' && 'border-vault_text',
            )}
            label=""
            iconOnly
            leadingIcon={faThumbtackSolid}
          />
          <Button
            onClick={() => onSetTab('attachments')}
            className={twMerge(
              'flex-1 border-0 border-b border-solid py-5 text-[20px]',
              'border-vault_background text-vault_text',
              tab === 'attachments' && 'border-vault_text',
            )}
            label=""
            iconOnly
            leadingIcon={faImageSolid}
          />
        </View>
      )}
    </View>
  );
};

const Media = ({
  url,
  mediaType,
  index,
  onView,
}: {
  url: string;
  mediaType: MediaType;
  index: number;
  onView: (index: number) => void;
}) => {
  const onClick = () => onView(index);

  const { isDesktop } = useWindow();

  if (mediaType === 'VIDEO') {
    return (
      <View className="box-border flex aspect-1 w-full border border-solid border-vault_background">
        <video
          preload="metadata"
          src={url}
          className="block h-full w-full cursor-pointer bg-vault_text/80"
          onClick={e => {
            // on mobile web, the video automatically goes full screen
            if (isDesktop) {
              e.preventDefault();
              onClick();
            }
          }}
          controls
          controlsList="nodownload"
        />
      </View>
    );
  }

  return (
    <Image
      alt="Attachment"
      src={url}
      className={twMerge(
        'box-border aspect-1 w-full cursor-pointer border border-solid object-cover',
        'border-vault_background bg-vault_text/80',
      )}
      onClick={onClick}
    />
  );
};

const LoadingList = ({ tab, isVaultArtist }: { tab: SeeDetailsTab; isVaultArtist: boolean }) => {
  if (tab === 'attachments') {
    return (
      <View className="scrollbar-theme grid w-full grid-cols-3 gap-[1px] md2:grid-cols-4">
        <LoadingSkeleton className="aspect-1 w-full rounded-none bg-vault_text/10" />
        <LoadingSkeleton className="aspect-1 w-full rounded-none bg-vault_text/10" />
        <LoadingSkeleton className="aspect-1 w-full rounded-none bg-vault_text/10" />
        <LoadingSkeleton className="aspect-1 w-full rounded-none bg-vault_text/10" />
        <LoadingSkeleton className="aspect-1 w-full rounded-none bg-vault_text/10" />
        <LoadingSkeleton className="aspect-1 w-full rounded-none bg-vault_text/10" />
      </View>
    );
  }
  return (
    <View className="scrollbar-theme mt-4 box-border flex w-full flex-1 flex-col gap-4 px-4">
      <SkeletonMessageBubble isAuthor={isVaultArtist} />
      <SkeletonMessageBubble isAuthor={isVaultArtist} />
      <SkeletonMessageBubble isAuthor={isVaultArtist} />
    </View>
  );
};

const EmptyState = ({ tab }: { tab: SeeDetailsTab }) => {
  if (tab === 'artist') {
    return (
      <EmptyStateView
        title="No messages"
        subtitle="This artist has not sent any messages yet."
        icon={faMessage}
        className="h-full text-vault_text"
        iconClassName="text-[60px]"
        subtitleClassName="text-vault_text/50"
      />
    );
  }
  if (tab === 'pinned') {
    return (
      <EmptyStateView
        title="No pinned messages"
        subtitle="Messages pinned by the artist will appear here."
        icon={faThumbtack}
        className="h-full text-vault_text"
        iconClassName="text-[60px]"
        subtitleClassName="text-vault_text/50"
      />
    );
  }

  return (
    <EmptyStateView
      title="No images"
      subtitle="Photos and videos shared by the artist will appear here"
      icon={faImage}
      className="h-full text-vault_text"
      iconClassName="text-[60px]"
      subtitleClassName="text-vault_text/50"
    />
  );
};
