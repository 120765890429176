import { useEffect, useState } from 'react';

const TEAM_USERS = [
  {
    author: 'Kunal',
    profileImageUrl:
      'https://d3jznoddta6qri.cloudfront.net/user-uploads/b6ffda2f-19e1-4eb9-bab5-4da8a7de527f/IMAGE/1420152-newImage.png',
  },
  {
    author: 'Geoffrey',
    profileImageUrl:
      'https://d3jznoddta6qri.cloudfront.net/user-uploads/510ccf9d-aefa-4b4a-ba7e-df7d31cecac7/IMAGE/9667005-newImage.png',
  },
  {
    author: 'PabloSz',
    profileImageUrl:
      'https://d3jznoddta6qri.cloudfront.net/user-uploads/dbf4ea33-eb17-4400-9576-3571c70c620b/IMAGE/1885108-newImage.png',
  },
  {
    author: 'Anto',
    profileImageUrl:
      'https://d3jznoddta6qri.cloudfront.net/user-uploads/25c3d042-c7c8-4389-a925-9cfa12454850/IMAGE/9839034-newImage.png',
  },
  {
    author: 'Kevin',
    profileImageUrl:
      'https://d3jznoddta6qri.cloudfront.net/user-uploads/4290083c-c17f-46f0-88ba-8c6cc959d8f9/IMAGE/1056913-newImage.png',
  },
  {
    author: 'Big Simpin',
    profileImageUrl:
      'https://d3jznoddta6qri.cloudfront.net/user-uploads/282bc456-342a-4863-ba9d-4c6530a76773/IMAGE/3553967-newImage.png',
  },
  {
    author: 'geli',
    profileImageUrl:
      'https://d3jznoddta6qri.cloudfront.net/user-uploads/a97617e5-c27b-46b7-8b39-c32fd0aada70/IMAGE/9247558-B9225FF5-D3AE-451E-96DF-BD8C1352A7D0.jpg',
  },
  {
    author: 'adib',
    profileImageUrl:
      'https://d3jznoddta6qri.cloudfront.net/user-uploads/cc7c93b9-6445-423a-8ee3-691f118dd568/IMAGE/9427781-newImage.png',
  },
  { author: 'Juan Latorre', profileImageUrl: undefined },
  { author: 'Omid', profileImageUrl: undefined },
];

export const useLockedMessages = ({
  vaultArtistName,
  vaultArtistImage,
  messageCount,
}: {
  vaultArtistName: string | undefined;
  vaultArtistImage: string | undefined | null;
  messageCount?: number;
}) => {
  const [mockMessages, setMockMessages] = useState<
    {
      content: string;
      reactionSummary: {
        HEART: number;
        THUMBS_UP: number;
        FIRE: number;
      };
      isVaultArtist: boolean;
      hasVaultContent: boolean;
      author?: string | undefined;
      profileImageUrl?: string | undefined | null;
    }[]
  >([]);

  useEffect(() => {
    const getRandomUser = (
      availableUsers: { author: string; profileImageUrl: string | undefined }[],
    ) => {
      const randomIndex = Math.floor(Math.random() * availableUsers.length);
      return availableUsers.splice(randomIndex, 1)[0];
    };

    const availableUsers = [...TEAM_USERS];
    const initialMessages = [
      {
        author: vaultArtistName || 'Artist',
        content: 'Just uploaded a new song from the vault',
        profileImageUrl: vaultArtistImage,
        reactionSummary: { HEART: 10, THUMBS_UP: 7, FIRE: 15 },
        isVaultArtist: true,
        hasVaultContent: true,
      },
      {
        ...getRandomUser(availableUsers),
        content: "let's go! i love this part",
        reactionSummary: { HEART: 0, THUMBS_UP: 0, FIRE: 0 },
        isVaultArtist: false,
        hasVaultContent: false,
      },
      {
        ...getRandomUser(availableUsers),
        content: `I am in love with this new track ${vaultArtistName}. Thank you so much for sharing`,
        reactionSummary: { HEART: 6, THUMBS_UP: 0, FIRE: 3 },
        isVaultArtist: false,
        hasVaultContent: false,
      },
      {
        ...getRandomUser(availableUsers),
        content: 'Waiting patiently for the end of year mix',
        reactionSummary: { HEART: 0, THUMBS_UP: 0, FIRE: 0 },
        isVaultArtist: false,
        hasVaultContent: false,
      },
    ];

    const messagesToSet = messageCount ? initialMessages.slice(0, messageCount) : initialMessages;
    setMockMessages(messagesToSet);
  }, [vaultArtistImage, vaultArtistName, messageCount]);

  return mockMessages;
};
