import { useEffect, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { BackButton } from '../../../components/buttons/BackButton';
import { Button } from '../../../components/buttons/Button';
import { CampaignHowItWorksView } from '../../../components/campaign/CampaignHowItWorksView';
import { CampaignScanLinkView } from '../../../components/campaign/CampaignScanLinkView';
import { LinksView } from '../../../components/campaign/LinksView';
import { MusicCampaignView } from '../../../components/campaign/MusicCampaignView';
import { ReleaseView } from '../../../components/campaign/ReleaseView';
import { SetupView } from '../../../components/campaign/SetupView';
import {
  CampaignLinksType,
  CampaignSteps,
  CampaignType,
} from '../../../components/campaign/schema';
import { useCampaignForm } from '../../../components/campaign/useCampaignForm';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { LoadingSkeleton } from '../../../components/loading/LoadingSkeleton';
import { FEATURE_GATES } from '../../../constants/flagConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { LoginStatus } from '../../../types/authTypes';
import { getFromList } from '../../../utils/arrayUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';

export function CreateCampaignPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { value: presaveEnabled, isLoading: isLoadingPresaveGate } = useGate(
    FEATURE_GATES.PRESAVES,
  );
  const { value: streamsEnabled, isLoading: isLoadingStreamGate } = useGate(FEATURE_GATES.STREAMS);

  const navigate = useNavigate();
  const { loggedInUser, loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const { openBottomsheet } = useBottomsheetContainer();

  useVaultTheme();

  const {
    fields,
    isSetupComplete,
    isLinksComplete,
    isReleaseComplete,
    enableSubmit,
    releaseCampaignCreationLoading,
    setField,
    clearErrors,
    clearFields,
    onSubmit,
    determineNextStep,
    determinePrevStep,
  } = useCampaignForm();

  const step = searchParams.get('step') || CampaignSteps.Intro;

  const adminArtist = useMemo(() => {
    if (!artistHandle) return undefined;
    return getFromList(loggedInUser?.adminArtists, artist => {
      const isMatch = artist.artistLinks?.includes(artistHandle) ?? false;
      return isMatch ? artist : false;
    });
  }, [loggedInUser?.adminArtists, artistHandle]);

  const artistId = adminArtist?.artistId;
  const artistName = adminArtist?.artistName;

  const onBackClick = useStableCallback(() => {
    const prevStep = determinePrevStep(step as CampaignSteps);
    if (prevStep) {
      setSearchParams({ step: prevStep });
    } else if (step === CampaignSteps.Intro || step === CampaignSteps.Scan) {
      navigate(artistNavigationPath(artistHandle, '/'));
    } else {
      openBottomsheet({
        type: 'EXIT_FLOW',
        exitFlowBottomsheetProps: {
          onConfirm: () => {
            navigate(artistNavigationPath(artistHandle, '/'));
            clearFields();
            clearErrors();
          },
        },
      });
    }
  });

  const handleNextClick = () => {
    const currentStep = step as CampaignSteps;
    const nextStep = determineNextStep(currentStep);
    if (nextStep && nextStep !== step) {
      setSearchParams({ step: nextStep });
    } else if (currentStep === CampaignSteps.Preview && enableSubmit) {
      onSubmit();
    }
  };

  const buttonText = useMemo(() => {
    switch (step) {
      case CampaignSteps.Intro:
        return 'Get started';
      case CampaignSteps.Setup:
      case CampaignSteps.PresavePrereleaseLinks:
      case CampaignSteps.PresaveReleaseLinks:
      case CampaignSteps.StreamReleaseLinks:
        return 'Next';
      case CampaignSteps.Release:
        return 'Preview';
      case CampaignSteps.Preview:
        return 'Publish drop';
      default:
        return null;
    }
  }, [step]);

  const isButtonDisabled = useMemo(() => {
    if (step === CampaignSteps.Intro) {
      return false;
    }
    if (step === CampaignSteps.Setup) {
      return !isSetupComplete;
    }
    if (
      step === CampaignSteps.PresavePrereleaseLinks ||
      step === CampaignSteps.PresaveReleaseLinks ||
      step === CampaignSteps.StreamReleaseLinks
    ) {
      return !isLinksComplete;
    }
    if (step === CampaignSteps.Release) {
      return !isReleaseComplete;
    }
    return !enableSubmit;
  }, [enableSubmit, isLinksComplete, isReleaseComplete, isSetupComplete, step]);

  const content = useMemo(() => {
    switch (step) {
      case CampaignSteps.Intro:
        return <CampaignHowItWorksView />;
      case CampaignSteps.Scan:
        return <CampaignScanLinkView />;
      case CampaignSteps.Setup:
        return <SetupView artistId={artistId} />;
      case CampaignSteps.PresavePrereleaseLinks:
        return <LinksView type={CampaignLinksType.PresavePrereleaseLinks} />;
      case CampaignSteps.PresaveReleaseLinks:
        return <LinksView type={CampaignLinksType.PresaveReleaseLinks} />;
      case CampaignSteps.StreamReleaseLinks:
        return <LinksView type={CampaignLinksType.StreamReleaseLinks} />;
      case CampaignSteps.Release:
        return <ReleaseView />;
      case CampaignSteps.Preview:
        return <MusicCampaignView artistId={artistId} fields={fields} isPreview />;
      default:
        return <Navigate to={ROUTES.NOT_FOUND} />;
    }
  }, [artistId, fields, step]);

  const ctaButton = (
    <>
      {step !== CampaignSteps.Scan && buttonText && (
        <View
          className={twMerge(
            'sticky z-mobileNav flex h-[68px] max-h-[68px] w-full items-center justify-center border-0 border-t border-solid py-2',
            'border-t-vault_text/5 bg-vault_background',
          )}
        >
          <Button
            type="primary-themed"
            label={buttonText}
            className="mx-4 w-full self-center"
            loading={releaseCampaignCreationLoading}
            disabled={isButtonDisabled}
            disabledClassName="opacity-50 cursor-not-allowed"
            onClick={handleNextClick}
          />
        </View>
      )}
    </>
  );

  useEffect(() => {
    if (artistName) setField('artist', artistName);
  }, [artistName, setField]);

  if (!artistHandle || (!artistId && loginStatus !== LoginStatus.LOADING)) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (
    !isLoadingPresaveGate &&
    !isLoadingStreamGate &&
    (!fields.campaignType ||
      (fields.campaignType === CampaignType.Presave && !presaveEnabled) ||
      (fields.campaignType === CampaignType.Stream && !streamsEnabled))
  ) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      headerLeft={
        <BackButton
          onClick={onBackClick}
          className={step !== CampaignSteps.Preview ? 'text-vault_text' : undefined}
        />
      }
      headerCenter={
        <>
          {step === CampaignSteps.Preview && (
            <Text className="font-title !text-title-m font-medium text-white">Drop preview</Text>
          )}
          {step === CampaignSteps.Intro && (
            <Text className="font-title !text-title-m font-medium text-white">How it works</Text>
          )}
        </>
      }
      shouldSkipMargin
      childrenWrapperClassName="h-full"
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      customBottomNavigator={<View className="w-full md2:hidden">{ctaButton}</View>}
      headerClassName="grow-0"
      contentClassName={twMerge(
        'flex-1',
        step !== CampaignSteps.Preview ? 'md2:bg-vault_text/3' : 'bg-black',
      )}
      extend={
        step === CampaignSteps.Preview ||
        step === CampaignSteps.Scan ||
        step === CampaignSteps.Intro
      }
      footer={<View className="hidden w-full md2:block">{ctaButton}</View>}
    >
      <View className="flex h-full w-full flex-1 flex-col">
        <View
          className={twMerge(
            step !== CampaignSteps.Preview
              ? 'px-4 md2:px-6'
              : 'relative box-border overflow-clip pb-8',
            (step === CampaignSteps.Intro || step === CampaignSteps.Scan) && 'flex flex-1',
          )}
        >
          {content}
        </View>
      </View>
    </DefaultLayout>
  );
}

export const CampaignSkeleton = ({ onBackClick }: { onBackClick: () => void }) => {
  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      headerLeft={<BackButton onClick={onBackClick} className="text-vault_text" />}
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">Campaign Setup</Text>
      }
      childrenWrapperClassName="h-full"
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5"
      contentClassName="md2:bg-vault_text/3"
      stretch
    >
      <View className="flex h-full w-full flex-col justify-between py-6 md2:max-w-[600px]">
        <View className="flex h-full w-full flex-col gap-6 md2:gap-7">
          <View className="flex h-full w-full flex-col">
            <LoadingSkeleton className="aspect-square w-full rounded-lg bg-vault_text/10" />

            <LoadingSkeleton className={twMerge('mt-4 h-[30px] w-[150px]', 'bg-vault_text/10')} />
            <LoadingSkeleton className={twMerge('mt-4 h-[30px] w-full', 'bg-vault_text/10')} />

            <LoadingSkeleton className={twMerge('mt-4 h-[30px] w-[150px]', 'bg-vault_text/10')} />
            <LoadingSkeleton className={twMerge('mt-4 h-[30px] w-full', 'bg-vault_text/10')} />
          </View>
        </View>
      </View>
    </DefaultLayout>
  );
};
