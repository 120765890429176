import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AsYouType, type CountryCode } from 'libphonenumber-js/min';
import { Navigate } from 'react-router';
import { faComment } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPhone } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faVideo } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faEnvelope } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { ErrorView } from '../components/error/ErrorView';
import { DEFAULT_AVATAR } from '../constants/imageConstants';
import { ROUTES } from '../constants/routeConstants';
import { useQuery } from '../graphql/client';
import { ArtistByHandleDocument } from '../graphql/generated';
import { useArtistHandle } from '../hooks/useArtistHandle';

export function TextMe() {
  const { artistHandle } = useArtistHandle();

  const { data, isLoading, isError, refetch } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
  });

  if (isError && !isLoading) {
    return (
      <div className="flex h-full">
        <ErrorView onRetryClick={refetch} withVaultTheme={false} />
      </div>
    );
  }

  const artist = data?.data.artistLink?.artist;
  const artistImage = artist?.profileImage?.artistMediumProfileImageUrl;
  const artistImageDominantColor = artist?.profileImage?.dominantColor ?? '#707070';
  const artistName = artist?.name ?? artistHandle;
  const artistPhoneNumber = artist?.phoneNumbers[0]?.phoneNumber ?? '';
  const smsLink = `sms:${artistPhoneNumber}`;

  const formatter = new AsYouType('US' as CountryCode);
  const formattedPhoneNumber = formatter.input(artistPhoneNumber);

  // automatically open up the sms link once loaded
  useEffect(() => {
    if (!isLoading && artistPhoneNumber) {
      window.location.assign(smsLink);
    }
  }, [isLoading, artistPhoneNumber, smsLink]);

  if (!isLoading && (!artist || !artistPhoneNumber)) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <div className=" flex h-full items-center justify-center overflow-hidden py-10 sm:py-0">
      <div className="relative flex h-[681px] w-[335px] items-start justify-center overflow-hidden p-5 pt-0 sm:h-[818px] sm:w-[402px] ">
        <img
          src="https://d3jznoddta6qri.cloudfront.net/public/iPhone.png"
          alt="Text Me"
          className="z-above1 h-auto w-full cursor-pointer object-contain"
          onClick={() => window.location.assign(smsLink)}
        />
        <div className="absolute -mt-1 flex h-[668px] w-[306px] flex-col overflow-hidden rounded-xl sm:h-[804px] sm:w-[358px]">
          <div className="flex h-full  flex-col items-center overflow-hidden pt-4">
            <div
              style={{
                // add alpha values to create gradient and slightly darken the background color overall
                background: `linear-gradient(to bottom, ${artistImageDominantColor}CC, ${artistImageDominantColor}80)`,
              }}
              className="flex h-[40%] w-full flex-shrink-0 flex-col items-center gap-4 rounded-t-xl pb-4 pt-[18%] text-white sm:gap-5 sm:pb-2 sm:pt-[24%]"
            >
              {isLoading ? (
                <div className="aspect-square h-auto w-[167px] animate-pulse rounded-full bg-neutral500 sm:w-[190px]" />
              ) : (
                <img
                  src={artistImage ?? DEFAULT_AVATAR}
                  alt="Artist"
                  className="aspect-square w-[167px] rounded-full object-cover object-center sm:w-[190px]"
                />
              )}

              <div className="box-border flex w-full flex-col items-center justify-center gap-3 px-2 sm:gap-4 sm:px-1.5">
                <h2 className=" max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap text-[24px] font-normal sm:text-title-xl">
                  {artistName ?? ''}
                </h2>
                <div className="flex w-full gap-1 sm:gap-2">
                  <div className="sm:gap:0.5 flex h-10 w-1/4 flex-col items-center justify-center gap-[3px] rounded-md bg-white/7 sm:h-[50px] sm:gap-1">
                    <FontAwesomeIcon icon={faComment} className="text-[14px] sm:text-[16px]" />
                    <p className="text-[10px] ">message</p>
                  </div>
                  <div className="sm:gap:0.5 flex h-10 w-1/4 flex-col items-center justify-center gap-[3px] rounded-md bg-white/7 sm:h-[50px] sm:gap-1">
                    <FontAwesomeIcon icon={faPhone} className=" text-[14px] sm:text-[16px]" />
                    <p className="text-[10px] ">call</p>
                  </div>
                  <div className="sm:gap:0.5 flex h-10 w-1/4 flex-col items-center justify-center gap-[3px] rounded-md bg-white/7 sm:h-[50px] sm:gap-1">
                    <FontAwesomeIcon icon={faVideo} className="text-[14px] sm:text-[16px]" />
                    <p className="text-[10px] ">video</p>
                  </div>
                  <div className="sm:gap:0.5 flex h-10 w-1/4 flex-col items-center justify-center gap-[3px] rounded-md bg-white/7 sm:h-[50px] sm:gap-1">
                    <FontAwesomeIcon icon={faEnvelope} className="text-[14px] sm:text-[16px]" />
                    <p className="text-[10px]">mail</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-border flex h-[60%] w-full flex-shrink flex-col gap-5 rounded-b-xl bg-base200 p-5">
              <div className="box-border flex w-full flex-col gap-1 rounded-lg bg-white px-3 py-3">
                <p className="text-base-s text-black">mobile</p>
                <p className="text-base-m font-normal text-[#007AFF]">
                  {formattedPhoneNumber.toString() ?? '...'}
                </p>
              </div>
              <div className="box-border flex h-[100px] w-full flex-col gap-1 rounded-lg bg-white px-3 py-3">
                <p className="text-base-s text-black">Notes</p>
              </div>
              <div className="box-border flex w-full flex-col gap-1 rounded-lg bg-white px-3 py-4">
                <p className="border-b-2 border-base900 text-base-m font-normal text-[#007AFF]">
                  Send Message
                </p>
                <hr className="my-1.5 h-[1px]  border-0 bg-base300" />
                <p className="border-b border-base900 text-base-m font-normal text-[#007AFF]">
                  Share Contacts
                </p>
                <hr className="my-1.5 h-[1px]  border-0 bg-base300" />
                <p className="border-b border-base900 text-base-m font-normal text-[#007AFF]">
                  Add to Favorites
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
