import { useMemo } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { getFromList } from '../utils/arrayUtils';

type AdminArtistParams = {
  artistHandle?: string;
  artistId?: string;
  artistMainVaultId?: string;
};

export function useAdminArtist(params?: AdminArtistParams) {
  const { loggedInUser } = useAuthContext();

  return useMemo(() => {
    if (!loggedInUser?.adminArtists || !params) {
      return null;
    }

    if (params.artistHandle) {
      return getFromList(loggedInUser.adminArtists, artist => {
        return artist.artistLinks.includes(params.artistHandle!) ? artist : null;
      });
    }

    if (params.artistId) {
      return getFromList(loggedInUser.adminArtists, artist => {
        return artist.artistId === params.artistId ? artist : null;
      });
    }

    if (params.artistMainVaultId) {
      return getFromList(loggedInUser.adminArtists, artist => {
        return artist.artistMainVaultId === params.artistMainVaultId ? artist : null;
      });
    }

    return null;
  }, [loggedInUser?.adminArtists, params]);
}
