import type { FC, ReactNode } from 'react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { type FragmentType, getFragment, UserRowFragmentDoc } from '../../graphql/generated';
import type { EventObject, EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserProfileImage } from './UserProfileImage';

gql(/* GraphQL */ `
  fragment userRow on User {
    id
    username
    displayName
    createdAt
    avatar {
      id
      avatarImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      mediaType
      dominantColor
    }
  }
`);

type Props<Event extends EventType> = {
  user: FragmentType<UserRowFragmentDoc>;
  phone: string | null;
  onClick?: () => void;
  subText?: string;
  subTextComponent?: ReactNode;
  href?: string;
  className?: string;
  rightComponent?: ReactNode;
  profileImageClassName?: string;
  event?: EventObject<Event>;
  titleClassName?: string;
  withVaultTheme?: boolean;
  badgeText?: string;
};

const UserRow = <Event extends EventType>({
  user,
  phone,
  onClick,
  subText,
  href,
  className,
  rightComponent,
  subTextComponent,
  profileImageClassName,
  event,
  titleClassName,
  withVaultTheme = false,
  badgeText,
}: Props<Event>) => {
  const { pathname } = useLocation();
  const { avatar, username } = getFragment(UserRowFragmentDoc, user);
  return (
    <Container
      className={twMerge(
        'mb-[12px] flex w-full flex-row justify-between rounded-xl py-[12px] pl-[8px] text-[unset] no-underline',
        onClick != null ? 'hover:cursor-pointer' : '',
        className,
      )}
      href={href}
      onClick={
        onClick != null
          ? () => {
              if (event != null) {
                trackEvent({ ...event, pathname });
              }

              onClick();
            }
          : undefined
      }
    >
      <View className={twMerge('flex flex-row', subText == null && 'items-center')}>
        <UserProfileImage
          profileImageUrl={avatar?.avatarImageUrl}
          className={twMerge('mr-[16px] w-[48px]', profileImageClassName)}
          withVaultTheme={withVaultTheme}
          fallbackColor={avatar?.dominantColor}
        />
        <View className="flex flex-col justify-center">
          <View className="flex flex-row items-center gap-2">
            <Text
              className={twMerge(
                'mb-[5px] text-title-s text-white',
                subText == null && 'mb-0',
                titleClassName,
              )}
            >
              {username || phone}
            </Text>
            {badgeText && (
              <View
                className={twMerge(
                  'rounded-full px-3 py-1',
                  withVaultTheme ? 'bg-vault_accent' : 'bg-yellow100',
                )}
              >
                <Text
                  className={twMerge(
                    'text-center font-base text-[12px]/[14px] font-medium',
                    withVaultTheme ? 'text-vault_accent_text' : 'text-black',
                  )}
                >
                  {badgeText}
                </Text>
              </View>
            )}
          </View>
          {subTextComponent != null
            ? subTextComponent
            : subText != null && (
                <Text className="font-base text-base-m font-normal text-base500">{subText}</Text>
              )}
        </View>
      </View>
      {rightComponent != null && rightComponent}
    </Container>
  );
};

const Container: FC<{
  href?: string;
  children: ReactNode;
  onClick?: () => void;
  className?: string;
}> = ({ href, children, onClick, className }) => {
  if (href == null) {
    return (
      <View onClick={onClick} className={className}>
        {children}
      </View>
    );
  }
  return (
    <Link to={href} onClick={onClick} className={className}>
      {children}
    </Link>
  );
};

const SkeletonUserRow = ({
  rightComponent = false,
  className,
  profileImageClassName,
  rightComponentClassName,
  subText = true,
  withVaultTheme = false,
}: {
  rightComponent?: boolean;
  className?: string;
  profileImageClassName?: string;
  rightComponentClassName?: string;
  subText?: boolean;
  withVaultTheme?: boolean;
}) => {
  return (
    <View
      className={twMerge(
        'mb-[12px] flex w-full flex-row justify-between rounded-xl py-[12px] pl-[8px]',
        className,
      )}
    >
      <View className="flex flex-row">
        <LoadingSkeleton
          className={twMerge(
            'mr-[16px] aspect-square w-[48px] rounded-full',
            profileImageClassName,
          )}
          withVaultTheme={withVaultTheme}
        />
        <View className="flex flex-col justify-center">
          <LoadingSkeleton className="h-[16px] w-[60px]" withVaultTheme={withVaultTheme} />
          {subText && (
            <LoadingSkeleton
              className="mt-[5px] h-[16px] w-[60px]"
              withVaultTheme={withVaultTheme}
            />
          )}
        </View>
      </View>
      {rightComponent && (
        <LoadingSkeleton
          className={twMerge('h-[16px] w-[60px]', rightComponentClassName)}
          withVaultTheme={withVaultTheme}
        />
      )}
    </View>
  );
};

export { UserRow, SkeletonUserRow };
