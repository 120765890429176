import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWaveformLines, type IconDefinition } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faFiles } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faTicket } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import {
  ArtistSubscribeHeaderInfoFragmentDoc,
  type FragmentType,
  getFragment,
} from '../../graphql/generated';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Text } from '../common/Text';
import { View } from '../common/View';

gql(/* GraphQL */ `
  fragment ArtistSubscribeHeaderInfo on Artist {
    id
    name
    profileImage {
      id
      artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
    }
    mainVault {
      price
    }
  }
`);

const SubscribeHeader = ({
  artist,
  subscriptionType,
  freeMonths,
}: {
  artist: FragmentType<ArtistSubscribeHeaderInfoFragmentDoc>;
  subscriptionType: 'referral_claim' | 'subscribe';
  freeMonths?: number;
}) => {
  const { name, profileImage, mainVault } = getFragment(
    ArtistSubscribeHeaderInfoFragmentDoc,
    artist,
  );

  const freeDays = (freeMonths || 0) * 30;
  const showReferralClaimBanner = subscriptionType === 'referral_claim';
  const title = showReferralClaimBanner
    ? `Free ${freeDays} days of All Access Pass`
    : 'All access pass';
  const priceText = showReferralClaimBanner ? `${freeDays} days free` : `$${mainVault.price}`;
  const priceSubtitleText = showReferralClaimBanner
    ? `then $${mainVault.price} per month`
    : 'per month';

  return (
    <View className="flex w-full flex-col gap-6 pb-3">
      <SubscriptionDetails subscriptionType={subscriptionType} freeDays={freeDays} title={title} />

      <View className="flex w-full flex-row items-center">
        <ArtistProfileImage
          profileImageUrl={profileImage?.artistSmallProfileImageUrl}
          className="w-[50px] rounded-full object-fill"
          withVaultTheme
        />
        <View className="flex w-full flex-row justify-between">
          <View className="ml-2 flex flex-col">
            <Text className="mb-1 font-base text-[16px]/[18px] font-medium text-vault_text">
              {name}
            </Text>
            <Text className="font-base text-[14px]/[16px] font-medium text-vault_text/50">
              All access pass
            </Text>
          </View>
          <View className="flex flex-col text-right">
            <Text className="mb-1 font-base text-[16px]/[18px] font-medium text-vault_text">
              {priceText}
            </Text>
            <Text className="font-body text-[14px]/[18px] text-vault_text/50">
              {priceSubtitleText}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const FULL_ACCESS_ITEMS = [
  {
    icon: faWaveformLines,
    text: 'Unlimited access to full-length songs',
  },
  {
    icon: faTicket,
    text: 'Early access to tour presales & merch drops',
  },
  {
    icon: faFiles,
    text: 'Exclusive content from the artist',
  },
];

const SubscriptionDetails = ({
  subscriptionType,
  title,
  freeDays = 0,
}: {
  subscriptionType: 'referral_claim' | 'subscribe';
  title: string;
  freeDays?: number;
}) => {
  const REFERRAL_CLAIM_ITEMS = [
    {
      icon: faCheck,
      text: `Full experience for ${freeDays} days`,
    },
    {
      icon: faCheck,
      text: 'Reminder before free pass ends',
    },
    {
      icon: faCheck,
      text: 'Unsubscribe anytime',
    },
  ];
  const items = subscriptionType === 'referral_claim' ? REFERRAL_CLAIM_ITEMS : FULL_ACCESS_ITEMS;

  return (
    <View className="flex flex-col items-center justify-center border-x-0 border-b-[1px] border-t-0 border-solid border-vault_text/5 pb-8 pt-4">
      <Text className="mb-4 max-w-[300px] text-center font-title !text-title-xl text-vault_text">
        {title}
      </Text>
      <View className="flex flex-col items-center gap-2">
        {items.map((item, index) =>
          subscriptionItem({
            key: `subscription-item-${index}`,
            icon: item.icon,
            text: item.text,
          }),
        )}
      </View>
    </View>
  );
};

const subscriptionItem = ({
  key,
  icon,
  text,
}: {
  key: string;
  icon: IconDefinition;
  text: string;
}) => {
  return (
    <View className="flex items-center gap-2" key={key}>
      <View className="flex h-5 w-5 items-center justify-center">
        <FontAwesomeIcon
          icon={icon}
          fontSize={14}
          className="text-center !text-base-m text-vault_text"
        />
      </View>
      <span className="line-clamp-1 font-base !text-base-m font-normal text-vault_text md:!text-base-l">
        {text}
      </span>
    </View>
  );
};

export { SubscribeHeader };
