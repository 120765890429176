import React from 'react';
import { useDropzone } from 'react-dropzone';
import { twMerge } from 'tailwind-merge';
import {
  ACCEPTED_AUDIO_MIME_TYPES,
  ACCEPTED_IMAGE_TYPES,
  ACCEPTED_VIDEO_TYPES,
  MAX_AUDIO_UPLOAD_SIZE_BYTES,
  MAX_AUDIO_UPLOAD_SIZE_TEXT,
} from '../../constants/fileConstants';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useToast } from '../../contexts/ToastContext';
import { TrackUpload } from '../../contexts/TrackUploadContext';
import { useUploadVaultContentFile } from '../../hooks/useUploadVaultContentFile';
import { View } from '../common/View';

export function VaultUploadPromptView({
  vaultId,
  artistLinkValue,
  folderId,
}: {
  vaultId: string;
  artistLinkValue: string;
  folderId: string | null;
}) {
  const { isVaultCustomizeOpen } = useMenuContainer();

  const isDisabled = isVaultCustomizeOpen;

  const { openToast } = useToast();

  const { saveVaultContentFile } = useUploadVaultContentFile({
    artistLinkValue,
    vaultId,
    folderId,
  });

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    noDragEventsBubbling: true,
    async onDropAccepted([file]) {
      TrackUpload.isPromptOpen = true;

      await saveVaultContentFile(file);
    },
    onDropRejected() {
      openToast({
        text: `File could not be uploaded. Make sure it is a valid audio, video, or image file and less than ${MAX_AUDIO_UPLOAD_SIZE_TEXT}.`,
        variant: 'error',
      });
      TrackUpload.isPromptOpen = false;
    },
    onFileDialogCancel() {
      TrackUpload.isPromptOpen = false;
    },
    accept: {
      ...ACCEPTED_AUDIO_MIME_TYPES,
      ...ACCEPTED_IMAGE_TYPES,
      ...ACCEPTED_VIDEO_TYPES,
    },
    maxFiles: 1,
    maxSize: MAX_AUDIO_UPLOAD_SIZE_BYTES,
    disabled: isDisabled,
  });

  const rootProps = getRootProps();

  return (
    <div
      className={twMerge(
        'my-2 box-border w-full',
        isDisabled ? 'cursor-default' : 'cursor-pointer',
      )}
      {...rootProps}
      onClick={(...args) => {
        TrackUpload.isPromptOpen = true;

        rootProps.onClick?.(...args);
      }}
    >
      <View
        className={twMerge(
          'flex flex-col items-center justify-center gap-3 rounded-xl border border-dashed px-4 py-10',
          'border-vault_text/5 bg-vault_text/10',
        )}
      >
        <CloudUploadIcon className="fill-vault_accent" />
        <View className="text-center font-title text-title-s font-medium text-vault_accent">
          Choose file
        </View>
        <View className="text-center font-base text-base-s font-normal text-vault_text/50">
          Share music, photos, or videos with your members
        </View>
        <input {...getInputProps()} accept=".wav, .mp3, .m4a" disabled={isDisabled} />
      </View>
    </div>
  );
}

function CloudUploadIcon({ className }: { className?: string }) {
  return (
    <svg width="50" height="36" viewBox="0 0 50 36" fill="none">
      <path
        opacity="0.6"
        d="M30.3906 7.84375C28.3594 4.95312 25 3 21.25 3C15.1562 3 10.2344 7.76562 10 13.7812C9.92188 14.7969 9.29688 15.6562 8.28125 16.0469C4.92188 17.2188 2.5 20.5 2.5 24.25C2.5 29.0938 6.40625 33 11.25 33H40C44.1406 33 47.5 29.6406 47.5 25.5C47.5 22.6875 45.8594 20.1875 43.4375 18.9375C42.4219 18.3125 41.875 17.1406 42.2656 15.9688C42.4219 15.5 42.5 14.875 42.5 14.25C42.5 10.8125 39.6875 8 36.25 8C35.2344 8 34.375 8.23438 33.5156 8.625C32.4219 9.17188 31.0938 8.78125 30.3906 7.84375ZM21.25 0.5C25.8594 0.5 30 2.84375 32.5 6.35938C33.5938 5.8125 34.8438 5.5 36.25 5.5C41.0156 5.5 45 9.48438 45 14.25C45 15.1094 44.8438 15.8906 44.6094 16.6719C47.8125 18.3906 50 21.6719 50 25.5C50 31.0469 45.4688 35.5 40 35.5H11.25C5 35.5 0 30.5 0 24.25C0 19.4062 3.125 15.1875 7.5 13.7031C7.8125 6.35938 13.8281 0.5 21.25 0.5ZM17.8125 17.1406L24.0625 10.8906C24.5312 10.4219 25.3906 10.4219 25.8594 10.8906L32.1094 17.1406C32.5781 17.6094 32.5781 18.4688 32.1094 18.9375C31.6406 19.4062 30.7812 19.4062 30.3125 18.9375L26.25 14.7969V26.75C26.25 27.4531 25.625 28 25 28C24.2969 28 23.75 27.4531 23.75 26.75V14.7969L19.6094 18.9375C19.1406 19.4062 18.2812 19.4062 17.8125 18.9375C17.3438 18.4688 17.3438 17.6094 17.8125 17.1406Z"
        className={className}
      />
    </svg>
  );
}
