import { useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { MessageChannelLayout } from '../../components/layouts/MessageChannelLayout';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { LockedChatView } from '../../components/views/LockedChatView';
import {
  LoadingChat,
  VaultMessageChannelView,
} from '../../components/views/VaultMessageChannelView';
import { useVaultMessageCountEngagement } from '../../components/views/hooks/useVaultMessageCountEngagement';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useQuery } from '../../graphql/client';

import {
  ArtistByHandleDocument,
  ArtistLayoutFragmentDoc,
  FeatureTypename,
  getFragment,
  TierTypename,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useUpsellInterstitials } from '../../hooks/useUpsellInterstitials';
import {
  useSetActiveArtistChatHandle,
  useVaultMessageChannel,
} from '../../hooks/useVaultMessageChannel';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { constructQueryParams } from '../../utils/stringUtils';

/**
 *
 * @deprecated Please use MessagesPage.tsx instead
 *
 */
export const VaultMessageChannelPage = () => {
  const { loggedInUser, loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { value: isUnifiedInboxEnabled } = useGate(FEATURE_GATES.UNIFIED_INBOX);

  useVaultTheme();

  const code = searchParams.get('code'); // referral link code
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const bottomSheetType = searchParams.get('openBottomSheet');

  const { openBottomsheet } = useBottomsheetContainer();

  const { data: artist, isLoading } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && { link: artistHandle.toLowerCase() },
    enabled: artistHandle != null,
  });

  useVaultMessageCountEngagement({
    vaultId: artist?.data.artistLink?.artist.mainVaultId,
    triggerQuery: loggedInUser != null,
  });

  const artistLayoutFragment =
    artist?.data.artistLink?.artist != null
      ? getFragment(ArtistLayoutFragmentDoc, artist.data.artistLink.artist)
      : null;

  useSetMetaHeaders({
    title: artistLayoutFragment ? `${artistLayoutFragment.name}'s Vault Chat` : null,
    imageUrl: artistLayoutFragment?.profileImage?.artistSmallProfileImageUrl,
  });

  //Here as a prefetch (So we don't have to wait for the access check to load to start to fetch the messages)
  useSetActiveArtistChatHandle();
  useVaultMessageChannel();

  const isOwner = artist?.data.artistLink?.artist.mainVault.isUserArtistAdmin || false;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: artistLayoutFragment?.mainVault.activeSubscription,
    isOwner,
  });

  const showUpsellInterstitial =
    upsellInterstitials?.firstChat === false &&
    isMobile &&
    isIOS &&
    loginStatus === LoginStatus.LOGGED_IN &&
    !wasShowedInSession &&
    !activeSubscriptionFeatures?.enabledFeatures.ChatRead;

  useEffect(() => {
    if (showUpsellInterstitial) {
      setTimeout(() => {
        const vaultId = artist?.data.artistLink?.artist.mainVaultId ?? null;

        openBottomsheet({
          type: 'GET_APP',
          getAppBottomsheetProps: {
            vaultId,
            interstitial: 'first_chat',
            onContinue: null,
          },
        });

        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: 'GET_APP',
            vaultId,
            interstitial: 'first_chat',
          },
        });
      }, 2000);
    }
  }, [
    artist?.data.artistLink?.artist.mainVaultId,
    loginStatus,
    openBottomsheet,
    showUpsellInterstitial,
    upsellInterstitials,
    wasShowedInSession,
  ]);

  useEffect(() => {
    if (loginStatus !== LoginStatus.LOGGED_IN || !loggedInUser || !!code) return;

    if (loggedInUser?.username == null) {
      const queryParams = constructQueryParams({
        artistHandle,
        invite,
        smsCampaignResponseShortcode,
        openBottomSheet: bottomSheetType,
        redirect: 'chat',
      });
      navigate(`${ROUTES.ONBOARDING_USERNAME}${queryParams ? `?${queryParams}` : ''}`);
    }
  }, [
    artistHandle,
    bottomSheetType,
    code,
    invite,
    loggedInUser,
    loggedInUser?.username,
    loginStatus,
    navigate,
    smsCampaignResponseShortcode,
  ]);

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const vaultArtistId = artistLayoutFragment?.id;
  const vaultArtistLinkValue = artistLayoutFragment?.linkValue;
  const vaultArtistName = artistLayoutFragment?.name;
  const vaultContentCount = artistLayoutFragment?.mainVault?.contentCount;
  const vaultArtistProfileImage = artistLayoutFragment?.profileImage;
  const vaultArtistMembershipCardImage =
    artistLayoutFragment?.membershipCardImage?.membershipCardImageUrl;

  const showLockedChatView = !activeSubscriptionFeatures?.enabledFeatures.ChatRead && !isOwner;
  const showJoinFree = isUnifiedInboxEnabled && activeSubscriptionFeatures === null;

  const hasChatReadAccess = activeSubscriptionFeatures?.enabledFeatures.ChatRead === true;

  const chatAvailableForFreeUsers = artistLayoutFragment?.mainVault?.tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead);

  if (loginStatus === LoginStatus.LOADING || isLoading) {
    return (
      <LoadingChat
        vaultArtistName={vaultArtistName}
        vaultArtistImage={vaultArtistProfileImage?.artistSmallProfileImageUrl}
        activeSubscriptionFeatures={activeSubscriptionFeatures}
        messageChannelId={artistLayoutFragment?.mainVault.messageChannelId}
        isGroupChat={false}
        showVaultNav
        vaultId={artistLayoutFragment?.mainVault.id}
        artistHandle={vaultArtistLinkValue}
        chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
      />
    );
  }

  return (
    <>
      {showLockedChatView ? (
        <MessageChannelLayout
          artistHandle={vaultArtistLinkValue}
          artistName={vaultArtistName}
          stretch
          artistProfileImage={vaultArtistProfileImage?.artistSmallProfileImageUrl}
          activeSubscriptionFeatures={activeSubscriptionFeatures}
          messageChannelId={artistLayoutFragment?.mainVault.messageChannelId}
          hasChatReadAccess={hasChatReadAccess}
          chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
          vaultId={artistLayoutFragment?.mainVault.id}
        >
          <LockedChatView
            vaultId={artist?.data.artistLink?.artist.mainVault.id}
            vaultType={artist?.data.artistLink?.artist.mainVault.type}
            linkValue={vaultArtistLinkValue}
            vaultArtistName={vaultArtistName}
            vaultArtistImage={vaultArtistProfileImage?.artistSmallProfileImageUrl}
            vaultArtistMembershipCardImage={vaultArtistMembershipCardImage}
            monthlySubPrice={artistLayoutFragment?.mainVault.price || DEFAULT_PRICE}
            chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
            showJoinFree={showJoinFree}
            dominantColor={vaultArtistProfileImage?.dominantColor}
          />
        </MessageChannelLayout>
      ) : (
        <VaultMessageChannelView
          artistId={vaultArtistId}
          artistName={vaultArtistName}
          contentCount={vaultContentCount}
          isOwner={isOwner}
          artistLinkValue={vaultArtistLinkValue}
          artistProfileImage={vaultArtistProfileImage}
          activeSubscriptionFeatures={activeSubscriptionFeatures}
          messageChannelId={artistLayoutFragment?.mainVault.messageChannelId}
          showVaultNav
        />
      )}
    </>
  );
};
