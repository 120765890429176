import { useCallback, useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { Navigate, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ListDetailLayout } from '../../components/layouts/ListDetailLayout';
import { NewMessageLayout } from '../../components/layouts/MessageChannelLayout';
import { UserChannels } from '../../components/messages/UserChannels';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { LockedChatView } from '../../components/views/LockedChatView';
import { VaultMessageChannelView } from '../../components/views/VaultMessageChannelView';
import { useVaultMessageCountEngagement } from '../../components/views/hooks/useVaultMessageCountEngagement';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useQuery } from '../../graphql/client';
import {
  ArtistByHandleDocument,
  ArtistLayoutFragmentDoc,
  FeatureTypename,
  getFragment,
  TierTypename,
} from '../../graphql/generated';
import { useAdminArtist } from '../../hooks/useAdminArtist';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useSetLastReadMessageChannel } from '../../hooks/useSetLastReadMessageChannel';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useUpsellInterstitials } from '../../hooks/useUpsellInterstitials';
import {
  useSetActiveArtistChatChannelId,
  useSetActiveArtistChatHandle,
  useVaultMessageChannel,
} from '../../hooks/useVaultMessageChannel';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { constructQueryParams } from '../../utils/stringUtils';

export function MessagesPage({ showOnMobile }: { showOnMobile: 'list' | 'detail' }) {
  const { channelId } = useParams<{
    channelId: string;
  }>();

  const { loggedInUser, loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useVaultTheme();

  const code = searchParams.get('code'); // referral link code
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const bottomSheetType = searchParams.get('openBottomSheet');

  const { openBottomsheet } = useBottomsheetContainer();

  const { data: artist, isLoading } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && { link: artistHandle.toLowerCase() },
    enabled: artistHandle != null,
  });

  useVaultMessageCountEngagement({
    vaultId: artist?.data.artistLink?.artist.mainVaultId,
    triggerQuery: loggedInUser != null,
  });

  const artistLayoutFragment =
    artist?.data.artistLink?.artist != null
      ? getFragment(ArtistLayoutFragmentDoc, artist.data.artistLink.artist)
      : null;

  useSetMetaHeaders({
    title: artistLayoutFragment ? `${artistLayoutFragment.name}'s Vault Chat` : null,
    imageUrl: artistLayoutFragment?.profileImage?.artistSmallProfileImageUrl,
  });
  const adminArtist = useAdminArtist({ artistId: artistLayoutFragment?.mainVault.artist?.id });

  //Here as a prefetch (So we don't have to wait for the access check to load to start to fetch the messages)
  useSetActiveArtistChatHandle();
  useSetActiveArtistChatChannelId({
    channelId: channelId || artistLayoutFragment?.mainVault.messageChannelId,
  });

  useSetLastReadMessageChannel({
    messageChannelId: channelId || artistLayoutFragment?.mainVault.messageChannelId,
    asArtistId: adminArtist?.artistId,
  });

  useVaultMessageChannel();

  const isOwner = artist?.data.artistLink?.artist.mainVault.isUserArtistAdmin || false;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: artistLayoutFragment?.mainVault.activeSubscription,
    isOwner,
  });

  const showUpsellInterstitial =
    upsellInterstitials?.firstChat === false &&
    isMobile &&
    isIOS &&
    loginStatus === LoginStatus.LOGGED_IN &&
    !wasShowedInSession &&
    !activeSubscriptionFeatures?.enabledFeatures.ChatRead;

  useEffect(() => {
    if (showUpsellInterstitial) {
      setTimeout(() => {
        const vaultId = artist?.data.artistLink?.artist.mainVaultId ?? null;

        openBottomsheet({
          type: 'GET_APP',
          getAppBottomsheetProps: {
            vaultId,
            interstitial: 'first_chat',
            onContinue: null,
          },
        });

        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: 'GET_APP',
            vaultId,
            interstitial: 'first_chat',
          },
        });
      }, 2000);
    }
  }, [
    artist?.data.artistLink?.artist.mainVaultId,
    loginStatus,
    openBottomsheet,
    showUpsellInterstitial,
    upsellInterstitials,
    wasShowedInSession,
  ]);

  useEffect(() => {
    if (loginStatus !== LoginStatus.LOGGED_IN || !loggedInUser || !!code) return;

    if (loggedInUser?.username == null) {
      const queryParams = constructQueryParams({
        artistHandle,
        invite,
        smsCampaignResponseShortcode,
        openBottomSheet: bottomSheetType,
        redirect: 'chat',
      });
      navigate(`${ROUTES.ONBOARDING_USERNAME}${queryParams ? `?${queryParams}` : ''}`);
    }
  }, [
    artistHandle,
    bottomSheetType,
    code,
    invite,
    loggedInUser,
    loggedInUser?.username,
    loginStatus,
    navigate,
    smsCampaignResponseShortcode,
  ]);

  const onJoinFreeClick = useCallback(async () => {
    if (isOwner || !artistLayoutFragment?.mainVault.id) return;

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
      membershipConfirmationBottomsheetProps: {
        vaultId: artistLayoutFragment?.mainVault.id,
        isLoading: false,
        artistHandle: artistLayoutFragment?.linkValue,
        artistName: artistLayoutFragment?.name,
        imageUrl: artistLayoutFragment?.membershipCardImage?.membershipCardImageUrl,
        loggedInUserUsername: loggedInUser?.username,
        loginStatus,
        inviteCode: invite,
        smsCampaignResponseShortcode,
        sourceReleaseCampaignId: null,
      },
      shared: {
        hideCloseBottomsheetButton: false,
        preventSwipeToDismiss: false,
        preventOutsideAutoClose: true,
        hidePulleyBar: true,
        withVaultTheme: true,
      },
    });
  }, [
    isOwner,
    artistLayoutFragment?.mainVault.id,
    artistLayoutFragment?.linkValue,
    artistLayoutFragment?.name,
    artistLayoutFragment?.membershipCardImage?.membershipCardImageUrl,
    loginStatus,
    openBottomsheet,
    loggedInUser?.username,
    invite,
    smsCampaignResponseShortcode,
  ]);

  useEffect(() => {
    if (loginStatus === LoginStatus.LOGGED_OUT) {
      const queryParams = constructQueryParams({
        openBottomSheet: 'freeTierModal',
      });

      setSearchParams(queryParams);
    }
  }, [loggedInUser, loginStatus, setSearchParams]);

  useEffect(() => {
    if (
      bottomSheetType === 'freeTierModal' &&
      loginStatus === LoginStatus.LOGGED_IN &&
      !artistLayoutFragment?.mainVault.activeSubscription &&
      !isOwner
    ) {
      onJoinFreeClick();
    }
  }, [
    loginStatus,
    navigate,
    onJoinFreeClick,
    bottomSheetType,
    artistLayoutFragment?.mainVault.activeSubscription,
    isOwner,
  ]);

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const vaultArtistId = artistLayoutFragment?.id;
  const vaultArtistLinkValue = artistLayoutFragment?.linkValue;
  const vaultArtistName = artistLayoutFragment?.name;
  const vaultContentCount = artistLayoutFragment?.mainVault?.contentCount;
  const vaultArtistProfileImage = artistLayoutFragment?.profileImage;
  const vaultArtistMembershipCardImage =
    artistLayoutFragment?.membershipCardImage?.membershipCardImageUrl;

  const showLockedChatView =
    !channelId && !activeSubscriptionFeatures?.enabledFeatures.ChatRead && !isOwner;
  const showJoinFree = activeSubscriptionFeatures === null;

  const chatAvailableForFreeUsers = artistLayoutFragment?.mainVault?.tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead);

  if (isLoading) {
    return <div className="h-full w-full bg-vault_background" />;
  }

  return (
    <ListDetailLayout>
      <UserChannels
        vaultId={artistLayoutFragment?.mainVault.id}
        artistHandle={artistHandle}
        asArtistId={isOwner ? vaultArtistId : undefined}
        showOnMobile={showOnMobile === 'list'}
        artistName={vaultArtistName}
        artistCoverImage={vaultArtistProfileImage?.artistSmallProfileImageUrl}
      />

      <ListDetailLayout.Detail showOnMobile={showOnMobile === 'detail'}>
        {showLockedChatView ? (
          <NewMessageLayout
            artistName={vaultArtistName}
            contentCount={vaultContentCount}
            artistProfileImage={vaultArtistProfileImage?.artistSmallProfileImageUrl}
            activeSubscriptionFeatures={activeSubscriptionFeatures}
            messageChannelDetails={null}
            vaultId={artistLayoutFragment?.mainVault.id}
            messageChannelId={artistLayoutFragment?.mainVault.messageChannelId}
            chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
            artistHandle={artistHandle}
            // Don't show vault nav on mobile detail page
            showVaultNav={showOnMobile !== 'detail'}
            isGroupChat
          >
            <LockedChatView
              vaultId={artist?.data.artistLink?.artist.mainVault.id}
              vaultType={artist?.data.artistLink?.artist.mainVault.type}
              linkValue={vaultArtistLinkValue}
              vaultArtistName={vaultArtistName}
              vaultArtistImage={vaultArtistProfileImage?.artistSmallProfileImageUrl}
              vaultArtistMembershipCardImage={vaultArtistMembershipCardImage}
              monthlySubPrice={artistLayoutFragment?.mainVault.price || DEFAULT_PRICE}
              chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
              showJoinFree={showJoinFree}
              dominantColor={vaultArtistProfileImage?.dominantColor}
            />
          </NewMessageLayout>
        ) : (
          <VaultMessageChannelView
            artistId={vaultArtistId}
            artistName={vaultArtistName}
            contentCount={vaultContentCount}
            isOwner={isOwner}
            artistLinkValue={vaultArtistLinkValue}
            artistProfileImage={vaultArtistProfileImage}
            activeSubscriptionFeatures={activeSubscriptionFeatures}
            // Don't show vault nav on mobile detail page
            showVaultNav={showOnMobile !== 'detail'}
            messageChannelId={channelId ?? artistLayoutFragment?.mainVault.messageChannelId}
          />
        )}
      </ListDetailLayout.Detail>
    </ListDetailLayout>
  );
}
