import type { ReactNode } from 'react';
import { forwardRef, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isSafari } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faMessages } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useAudioController } from '../../audio/AudioController';
import { togglePlayPause } from '../../audio/AudioEngineHTML5';
import { setHideAudioPlayer } from '../../audio/AudioMeta';
import { useAudioPosition } from '../../audio/AudioPosition';
import { FEATURE_GATES } from '../../constants/flagConstants';
import {
  type FragmentType,
  getFragment,
  MessageChannelDetailsFragmentDoc,
} from '../../graphql/generated';
import type { TierFeatures } from '../../hooks/useTierFeatures';
import { useWindow } from '../../hooks/useWindow';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { PlayButtonView } from '../audioPlayer/PlayButtonView';
import { BackButton } from '../buttons/BackButton';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ViewHeader } from '../common/ViewHeader';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { AudioPlayer } from '../main/AudioPlayer';
import { UserProfileImage } from '../user/UserProfileImage';
import { VaultNav } from '../vault/VaultNav';
import { DefaultLayout } from './DefaultLayout';

gql(/* GraphQL */ `
  fragment MessageChannelLayoutInfo on MessageChannel {
    id
    vault {
      id
      contentCount
      artist: artistProfile {
        id
        name
      }
    }
  }
`);

/**
 *
 * @deprecated Please use NewMessageLayout instead
 *
 */
export const MessageChannelLayout = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    artistName?: string;
    artistProfileImage?: string | null;

    secondaryFooter?: ReactNode;
    nonScrollingChildren?: ReactNode;
    stretch?: boolean;
    activeSubscriptionFeatures: TierFeatures;

    // VaultNav related props
    messageChannelId: string | undefined;
    hasChatReadAccess: boolean;
    chatAvailableForFreeUsers: boolean;

    childrenContainerClassName?: string;

    vaultId: string | undefined;
    artistHandle: string | undefined;
  }
>(
  (
    {
      children,
      artistName,
      secondaryFooter,
      nonScrollingChildren,
      stretch,
      artistProfileImage,
      activeSubscriptionFeatures,
      messageChannelId,
      hasChatReadAccess,
      chatAvailableForFreeUsers,
      childrenContainerClassName,
      vaultId,
      artistHandle,
    },
    ref,
  ) => {
    const { pathname } = useLocation();
    const { isDesktop } = useWindow();

    const showVaultNav = !isDesktop;

    const { track, playing } = useAudioController();
    const { percentComplete } = useAudioPosition();

    useEffect(() => {
      if (!isDesktop) {
        setHideAudioPlayer(true);
      }

      return () => {
        if (!isDesktop) {
          setHideAudioPlayer(false);
        }
      };
    }, [isDesktop]);

    const onPlayClick = () => {
      track != null &&
        trackEvent({
          type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
          properties: {
            trackId: track.id,
            percentComplete,
            artistId: track.vault.artist?.id,
            vaultId: track.vault.id,
            isPreview: !track.isFullVersionAvailable,
            component: 'message_channel_layout',
          },
          pathname,
        });

      togglePlayPause();
    };

    const strokeDashoffset = isSafari
      ? ((100 - percentComplete) * 2 * 14 * Math.PI) / 100
      : -((100 - percentComplete) * 2 * 14 * Math.PI) / 100;

    return (
      <DefaultLayout
        withVaultTheme
        showRoundedTop
        showBorder
        ref={ref}
        headerCenter={
          <HeaderCenter
            artistName={artistName}
            artistProfileImage={artistProfileImage}
            activeSubscriptionFeatures={activeSubscriptionFeatures}
            artistHandle={artistHandle}
            isGroupChat={false}
            messageChannelDetails={null}
            withBackButton={false}
            messageChannelId={undefined}
          />
        }
        headerRight={
          track != null ? (
            <View
              className="relative flex h-[30px] w-[30px] cursor-pointer items-center justify-center overflow-hidden rounded-full"
              onClick={onPlayClick}
            >
              <svg className="absolute" height={30} width={30}>
                <circle
                  r="14"
                  cx={15}
                  cy={15}
                  strokeWidth={3}
                  stroke="yellow"
                  fillOpacity={0}
                  strokeDasharray={2 * 14 * Math.PI}
                  transform="rotate(-90 15 15)"
                  strokeDashoffset={strokeDashoffset}
                />
              </svg>
              <PlayButtonView isPlaying={playing} isDisabled={false} size={24} />
            </View>
          ) : (
            <View className="w-[50px]" />
          )
        }
        isHeaderTransparent={false}
        shouldSkipMargin
        secondaryFooter={secondaryFooter}
        nonScrollingChildren={nonScrollingChildren}
        stretch={stretch}
        headerGridClassName="flex"
        headerLeftClassName="mr-4"
        headerCenterClassName="flex-1 justify-start"
        headerClassName={twMerge(
          'border-b border-t-0 border-solid border-b-base700 bg-base900_alpha90 pt-8 backdrop:blur-[50px] md2:border-b',
          'border-x-0 border-b border-white/5 md2:mt-1 md2:rounded-t-[20px] md2:border-y md2:bg-white/3',
          'bg-vault_background md2:bg-vault_text/3',
        )}
        withBottomNavigator={showVaultNav}
        messageChannelId={messageChannelId}
        hasChatReadAccess={hasChatReadAccess}
        chatAvailableForFreeUsers={chatAvailableForFreeUsers}
        vaultId={vaultId}
        childrenContainerClassName={childrenContainerClassName}
        contentClassName="md2:bg-vault_text/3"
      >
        {children}
      </DefaultLayout>
    );
  },
);

type Props = {
  children: ReactNode;
  artistName?: string;
  contentCount?: number;
  artistProfileImage?: string | null;

  secondaryFooter?: ReactNode;
  nonScrollingChildren?: ReactNode;
  activeSubscriptionFeatures: TierFeatures;
  vaultId: string | undefined;
  messageChannelDetails: FragmentType<MessageChannelDetailsFragmentDoc> | null;
  messageChannelId: string | undefined;
  chatAvailableForFreeUsers: boolean;
  isGroupChat: boolean;
  artistHandle: string | undefined;
  showVaultNav: boolean;
};

export const NewMessageLayout = ({
  secondaryFooter,
  children,
  artistName,
  nonScrollingChildren,
  artistProfileImage,
  activeSubscriptionFeatures,
  vaultId,
  messageChannelDetails,
  messageChannelId,
  chatAvailableForFreeUsers,
  isGroupChat,
  artistHandle,
  showVaultNav,
}: Props) => {
  const { pathname } = useLocation();
  const viewHeaderRef = useRef<HTMLDivElement>(null);

  const { value: isUnifiedInboxEnabled } = useGate(FEATURE_GATES.UNIFIED_INBOX);

  const { isDesktop } = useWindow();

  const withVaultNav = showVaultNav && !isDesktop;

  const { track, playing } = useAudioController();
  const { percentComplete } = useAudioPosition();
  const strokeDashoffset = isSafari
    ? ((100 - percentComplete) * 2 * 14 * Math.PI) / 100
    : -((100 - percentComplete) * 2 * 14 * Math.PI) / 100;

  const hasChatReadAccess = activeSubscriptionFeatures?.enabledFeatures.ChatRead === true;

  useEffect(() => {
    setHideAudioPlayer(!isDesktop);

    return () => {
      setHideAudioPlayer(false);
    };
  }, [isDesktop]);

  const onPlayClick = () => {
    track != null &&
      trackEvent({
        type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
        properties: {
          trackId: track.id,
          percentComplete,
          artistId: track.vault.artist?.id,
          vaultId: track.vault.id,
          isPreview: !track.isFullVersionAvailable,
          component: 'message_channel_layout',
        },
        pathname,
      });

    togglePlayPause();
  };

  return (
    <View
      className={twMerge(
        'flex h-full w-full select-none flex-col items-center overflow-y-clip overscroll-none bg-vault_background text-vault_text',
        isUnifiedInboxEnabled && 'md2:bg-transparent',
      )}
    >
      <ViewHeader
        ref={viewHeaderRef}
        center={
          <HeaderCenter
            artistName={artistName}
            artistProfileImage={artistProfileImage}
            activeSubscriptionFeatures={activeSubscriptionFeatures}
            isGroupChat={isGroupChat && isUnifiedInboxEnabled}
            messageChannelDetails={isUnifiedInboxEnabled ? messageChannelDetails : null}
            artistHandle={artistHandle}
            withBackButton={!withVaultNav && isUnifiedInboxEnabled}
            messageChannelId={messageChannelId}
          />
        }
        right={
          !isDesktop && track != null ? (
            <View
              className="relative flex h-[30px] w-[30px] cursor-pointer select-none items-center justify-center overflow-hidden rounded-full"
              onClick={onPlayClick}
            >
              <svg className="absolute" height={30} width={30}>
                <circle
                  r="14"
                  cx={15}
                  cy={15}
                  strokeWidth={3}
                  className="stroke-vault_accent"
                  fillOpacity={0}
                  strokeDasharray={2 * 14 * Math.PI}
                  transform="rotate(-90 15 15)"
                  strokeDashoffset={strokeDashoffset}
                />
              </svg>
              <PlayButtonView
                isPlaying={playing}
                isDisabled={false}
                size={24}
                className="text-vault_text"
              />
            </View>
          ) : (
            <View className="w-[50px]" />
          )
        }
        className={twMerge(
          'select w-full border-b border-solid border-vault_text/5 bg-vault_background pt-6',
          isUnifiedInboxEnabled
            ? 'border-0 border-b md2:bg-transparent'
            : 'absolute top-0 z-above3 md2:mt-1 md2:w-[600px] md2:rounded-t-[20px] md2:border-y md2:border-l md2:border-r md2:bg-vault_text/3 md2:max-lt:ml-[312px]',
        )}
        gridClassName="flex"
        leftClassName="mr-4"
        centerClassName="flex-1 justify-start"
      />

      <View
        className={twMerge(
          'flex h-full w-full flex-col overflow-y-clip overscroll-y-none bg-vault_background',
          isUnifiedInboxEnabled
            ? 'mt-0 md2:bg-transparent'
            : 'mt-[78px] border-t border-vault_text/5 md2:mt-[90px] md2:w-[600px] md2:border-0 md2:border-l md2:border-r md2:border-solid md2:bg-vault_text/3 md2:max-lt:ml-[312px]',
        )}
      >
        {children}
        {nonScrollingChildren}
        <View className="z-above4 w-full">{secondaryFooter}</View>
      </View>

      <AudioPlayer withBottomNavigator={withVaultNav} withVaultTheme />
      {withVaultNav && (
        <View className="w-full md2:hidden">
          <VaultNav
            vaultId={vaultId}
            messageChannelId={messageChannelId}
            hasChatReadAccess={hasChatReadAccess}
            chatAvailableForFreeUsers={chatAvailableForFreeUsers}
            variant="borderless"
            withVaultTheme
            folderId={null}
          />
        </View>
      )}
    </View>
  );
};

function HeaderCenter({
  activeSubscriptionFeatures,
  artistHandle,
  artistName,
  messageChannelId,
  isGroupChat,
  messageChannelDetails,
  artistProfileImage,
  withBackButton,
}: {
  activeSubscriptionFeatures: TierFeatures | null;
  artistHandle: string | undefined;
  artistName: string | undefined;
  artistProfileImage?: string | null;
  messageChannelId: string | undefined;
  isGroupChat: boolean;
  messageChannelDetails: FragmentType<MessageChannelDetailsFragmentDoc> | null;
  withBackButton: boolean;
}) {
  const { value: isUnifiedInboxEnabled } = useGate(FEATURE_GATES.UNIFIED_INBOX);

  const details = getFragment(MessageChannelDetailsFragmentDoc, messageChannelDetails);
  const titleText = isUnifiedInboxEnabled ? details?.titleText ?? artistName : artistName;
  const coverImage = details?.coverImage;

  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const onClick = () => {
    if (!artistHandle) return;

    if (isUnifiedInboxEnabled) {
      if (isGroupChat && activeSubscriptionFeatures?.enabledFeatures.ChatRead) {
        navigate(artistNavigationPath(artistHandle, '/messages/details'));
        return;
      }

      if (!isGroupChat && activeSubscriptionFeatures?.enabledFeatures.DMRead) {
        navigate(artistNavigationPath(artistHandle, `/messages/${messageChannelId}/details`));
        return;
      }
    }

    navigate(artistNavigationPath(artistHandle, '/chat/details'));
  };

  return (
    <View
      className={twMerge(
        'flex cursor-pointer select-none flex-row items-center justify-start gap-3',
        !activeSubscriptionFeatures?.enabledFeatures.ChatRead && 'cursor-default',
      )}
      onClick={onClick}
      containerRef={ref}
    >
      {withBackButton && <BackButton className="mr-3 md2:hidden" withVaultTheme />}
      {isGroupChat && isUnifiedInboxEnabled ? (
        <View className="flex h-9 w-9 items-center justify-center rounded-full bg-vault_text md2:h-10 md2:w-10">
          <FontAwesomeIcon icon={faMessages} className="text-[16px] text-vault_text_opposite" />
        </View>
      ) : (
        <UserProfileImage
          profileImageUrl={isUnifiedInboxEnabled ? coverImage?.imageSmallUrl : artistProfileImage}
          fallbackColor={isUnifiedInboxEnabled ? coverImage?.dominantColor : undefined}
          className="h-9 w-9 rounded-full md2:h-10 md2:w-10"
          withVaultTheme
        />
      )}
      <View className="flex select-none flex-col items-start">
        {artistName != null || (isGroupChat && isUnifiedInboxEnabled) ? (
          <>
            <View className="flex flex-row items-center gap-1">
              <Text className="line-clamp-1 font-title !text-title-s font-normal text-vault_text md2:!text-title-m">
                {isGroupChat && isUnifiedInboxEnabled ? 'Group chat' : titleText}
              </Text>
              {details?.showVerifiedBadge && (
                <FontAwesomeIcon icon={faBadgeCheck} className="text-[14px] text-vault_accent" />
              )}
            </View>
            <Text
              className={twMerge(
                'font-base text-[12px]/[14px] font-normal md:text-[14px]/[16px]',
                'text-vault_text/60 hover:text-vault_text/70',
                ((isGroupChat && activeSubscriptionFeatures?.enabledFeatures.ChatRead) ||
                  (!isGroupChat && activeSubscriptionFeatures?.enabledFeatures.DMRead)) &&
                  'hover:text-vault_text/60',
              )}
            >
              See details
            </Text>
          </>
        ) : (
          <>
            <LoadingSkeleton className="h-[22px] w-[100px] bg-vault_text/10" />
            <LoadingSkeleton className="mt-1 h-[14px] w-[60px] bg-vault_text/10" />
          </>
        )}
      </View>
    </View>
  );
}
