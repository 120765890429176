import { gql } from '@soundxyz/gql-string';
import { RepeatMode } from '../../audio/AudioMeta';
import { useSpotifyContext } from '../../contexts/SpotifyContext';
import { useQuery } from '../../graphql/client';
import { GetCampaignForAudioPlayerDocument } from '../../graphql/generated';
import { useWindow } from '../../hooks/useWindow';
import { BottomAudioPlayer, DesktopBottomAudioPlayer } from '../audioPlayer/BottomAudioPlayer';

gql(/* GraphQL */ `
  query GetCampaignForAudioPlayer($id: UUID!) {
    releaseCampaignByIdOrSlug(id: $id) {
      id
      title
      artist {
        id
        name
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
      }
    }
  }
`);

export function SpotifyBottomAudioPlayer({
  withBottomNavigator,
  withVaultTheme,
}: {
  withBottomNavigator: boolean;
  withVaultTheme: boolean;
}) {
  const { isDesktop } = useWindow();

  const { isPlaybackReady, player, playbackState, deviceId, activeCampaignId } =
    useSpotifyContext();

  const { data } = useQuery(GetCampaignForAudioPlayerDocument, {
    variables: !!activeCampaignId && { id: activeCampaignId },
    staleTime: 0,
    select: data => data.data.releaseCampaignByIdOrSlug,
  });

  if (
    !isPlaybackReady ||
    player == null ||
    deviceId == null ||
    playbackState == null ||
    activeCampaignId == null
  ) {
    return null;
  }

  const { duration, paused, repeat_mode, position } = playbackState;

  const { title, artist } = data || {};

  if (isDesktop) {
    //Passing empty string so that the player doesn't show the default "Spotify" text and play/pause is not disabled
    return (
      <DesktopBottomAudioPlayer
        withVaultTheme={withVaultTheme}
        type="spotify"
        artistName={artist?.name ?? ''}
        disableNextPrev
        isNextTrackDisabled
        title={title ?? ''}
        loading={false}
        duration={duration / 1000}
        playing={!paused}
        repeatMode={
          repeat_mode === 0
            ? RepeatMode.NO_REPEAT
            : repeat_mode === 1
              ? RepeatMode.REPEAT_ONE
              : RepeatMode.REPEAT_ALL
        }
        shuffleEnabled={false}
        loadingActiveTrack={false}
        togglePlayPause={() => {
          player.activateElement().then(() => {
            player.togglePlay();
          });
        }}
        position={position / 1000}
        seek={position => {
          player.seek(position * 1000);
        }}
      />
    );
  }

  //Passing empty string so that the player doesn't show the default "Spotify" text and play/pause is not disabled
  return (
    <BottomAudioPlayer
      withBottomNavigator={withBottomNavigator}
      withVaultTheme={withVaultTheme}
      type="spotify"
      subtitle={artist?.name ?? ''}
      title={title ?? ''}
      duration={duration / 1000}
      playing={!paused}
      seek={position => {
        player.seek(position * 1000);
      }}
      leftImageUrl={artist?.profileImage?.artistSmallProfileImageUrl ?? null}
      togglePlayPause={() => {
        player.activateElement().then(() => {
          player.togglePlay();
        });
      }}
      position={position / 1000}
      shouldOpenFullscreen={false}
    />
  );
}
