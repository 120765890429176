import {
  createRef,
  type FC,
  forwardRef,
  type RefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { keyBy } from 'lodash-es';
import millify from 'millify';
import * as emoji from 'node-emoji';
import { twMerge } from 'tailwind-merge';
import { type LongPressPointerHandlers, useLongPress } from 'use-long-press';
import { gql } from '@soundxyz/gql-string';
import { convertEmojiToKeyword, FALLBACK_EMOJI, getReactionColor } from '../../constants/emojis';
import type {
  ArtistReactionsMessageReactionRowFragment,
  MessageReactionType,
  MyReactionsMessageReactionRowFragment,
} from '../../graphql/generated';
import {
  type FragmentType,
  getFragment,
  MessageReactionRowFragmentDoc,
} from '../../graphql/generated';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Text } from '../common/Text';
import { View } from '../common/View';

gql(/* GraphQL */ `
  fragment messageReactionRow on ReactionSummary {
    type
    emojiKeyword
    count
  }

  fragment myReactionsMessageReactionRow on MessageReactionInfo {
    id
    type
    emojiKeyword
  }

  fragment artistReactionsMessageReactionRow on MessageReaction {
    id
    type
    emojiKeyword
  }
`);

type MessageReactionButtonProps = {
  reactionType: MessageReactionType;
  label: string;
  emoji: string;
  artistProfileImageUrl: string | null | undefined;
  isArtistReaction: boolean;
  isMyReaction: boolean;
  onClick: () => void;
  onLongPress?: LongPressPointerHandlers<Element>;
  disabled?: boolean;
};

export const MessageReactionButton = forwardRef<HTMLDivElement, MessageReactionButtonProps>(
  (
    {
      emoji,
      label,
      artistProfileImageUrl,
      isArtistReaction,
      isMyReaction,
      onClick,
      onLongPress,
      disabled,
    }: MessageReactionButtonProps,
    ref,
  ) => {
    const reactionColor = useMemo(() => {
      return getReactionColor(convertEmojiToKeyword(emoji));
    }, [emoji]);

    return (
      <View className="rounded-full bg-vault_background p-[1px]">
        <View
          className={twMerge(
            disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            isArtistReaction ? 'pr-1' : 'pr-2',
            'flex flex-row items-center gap-1 rounded-full border border-solid py-[2px] pl-2 !text-base-s transition-all',
            'border-0',
            isMyReaction
              ? 'bg-vault_accent text-vault_accent_text'
              : 'bg-vault_text/20 text-vault_text',
          )}
          onClick={disabled ? undefined : onClick}
          onLongPress={disabled ? undefined : onLongPress}
          containerRef={ref}
        >
          <Text className="!text-base-s">{emoji}</Text>
          <View className="select-none !text-base-s">{label}</View>
          {isArtistReaction && (
            <ArtistProfileImage
              className="ml-1 w-[16px] rounded-full"
              profileImageUrl={artistProfileImageUrl}
              borderColor={reactionColor}
            />
          )}
        </View>
      </View>
    );
  },
);

type Props = {
  messageId: string;
  artistProfileImageUrl: string | null | undefined;
  reactionsSummary: FragmentType<MessageReactionRowFragmentDoc>[];
  artistReactions: ArtistReactionsMessageReactionRowFragment[];
  myReactions: MyReactionsMessageReactionRowFragment[];
  onMessageReactionPress?: (args: { emojiKeyword: string }) => void;
  onMessageReactionLongPress?: () => void;
  setReactionButtonRefs: (refs: RefObject<HTMLDivElement>[]) => void;
};

const MessageReactionRow: FC<Props> = ({
  artistProfileImageUrl,
  reactionsSummary,
  artistReactions,
  myReactions,
  onMessageReactionPress,
  onMessageReactionLongPress,
  setReactionButtonRefs,
}) => {
  const [refs] = useState<RefObject<HTMLDivElement>[]>([
    createRef<HTMLDivElement>(),
    createRef<HTMLDivElement>(),
    createRef<HTMLDivElement>(),
  ]);

  useEffect(() => {
    setReactionButtonRefs(refs);
  }, [refs, setReactionButtonRefs]);

  const onLongPress = useLongPress(() => {
    onMessageReactionLongPress?.();
  })();

  const artistReactionsObj = useMemo(
    () => keyBy(artistReactions, v => v.type + v.emojiKeyword),
    [artistReactions],
  );

  const myReactionsObj = useMemo(
    () => keyBy(myReactions, v => v.type + v.emojiKeyword),
    [myReactions],
  );

  const sortedReactions = useMemo(() => {
    return reactionsSummary
      .map(reaction => getFragment(MessageReactionRowFragmentDoc, reaction))
      .sort((a, b) => b.count - a.count);
  }, [reactionsSummary]);

  const displayedReactions = sortedReactions.slice(0, sortedReactions.length > 3 ? 2 : 3);
  const additionalReactionsCount = sortedReactions.length - displayedReactions.length;

  if (sortedReactions.reduce((acc, reaction) => acc + reaction.count, 0) === 0) {
    return null;
  }

  return (
    <View className="absolute -bottom-[18px] select-none">
      <View className=" relative mx-2 flex select-none flex-row gap-1">
        {displayedReactions.map((reaction, index) => {
          const { type, emojiKeyword, count } = reaction;

          if (type !== 'EMOJI') return null;

          if (count <= 0) return null;

          return (
            <MessageReactionButton
              key={type + emojiKeyword}
              artistProfileImageUrl={artistProfileImageUrl}
              emoji={emoji.get(emojiKeyword) || FALLBACK_EMOJI}
              isArtistReaction={artistReactionsObj[type + emojiKeyword] != null}
              label={millify(count)}
              isMyReaction={myReactionsObj[type + emojiKeyword] != null}
              onClick={() => onMessageReactionPress?.({ emojiKeyword })}
              ref={refs[index]}
              reactionType="EMOJI"
              onLongPress={onLongPress}
              disabled={onMessageReactionPress == null}
            />
          );
        })}
        {additionalReactionsCount > 0 && (
          <View
            className="rounded-full bg-vault_background p-[1px]"
            onClick={() => onMessageReactionLongPress?.()}
          >
            <View className="flex  flex-row items-center justify-center rounded-full border border-solid border-transparent bg-vault_text/30 px-2 py-0.5 pl-2 !text-base-s transition-all">
              <Text className="select-none !text-base-s text-vault_text_opposite">{`${additionalReactionsCount} more`}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export { MessageReactionRow };
