import React, { useCallback, useEffect, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { gql } from '@soundxyz/gql-string/gql';
import { Button } from '../../../components/buttons/Button';
import { MenuButton } from '../../../components/buttons/MenuButton';
import { View } from '../../../components/common/View';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { EventsSection } from '../../../components/membership/EventsSection';
import { ReceiptsSection } from '../../../components/membership/ReceiptsSection';
import { FullPageLoading } from '../../../components/views/FullPageLoading';
import { MembershipView } from '../../../components/views/MembershipView';
import { BOTTOMSHEET_TYPES } from '../../../constants/bottomsheetConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useQuery } from '../../../graphql/client';
import { MembershipArtistDocument } from '../../../graphql/generated/documents';
import { useMembership } from '../../../hooks/membership/useMembership';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { useWindow } from '../../../hooks/useWindow';
import { LoginStatus } from '../../../types/authTypes';
import { constructQueryParams } from '../../../utils/stringUtils';

gql(/* GraphQL */ `
  query MembershipArtist($link: String!) {
    artistLink(link: $link) {
      artist {
        id
        name
        linkValue
        membershipCardImage {
          id
          membershipCardImageOptimizedUrl: imageOptimizedUrl
        }
        mainVaultId
      }
    }
  }
`);

export const MembershipPage = () => {
  useVaultTheme();

  const { isDesktop } = useWindow();
  const { loginStatus, loggedInUser } = useAuthContext();
  const { artistHandle } = useArtistHandle();

  const isOwner =
    !!artistHandle &&
    (loggedInUser?.adminArtists?.some(({ artistLinks }) => artistLinks.includes(artistHandle)) ??
      false);

  const { membership, isLoading } = useMembership({
    artistHandle,
    hasActiveSubscription: !isOwner,
  });

  const randomSerialId = useMemo(() => Math.floor(Math.random() * 1000), []);

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code'); // referral link code
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const bottomSheetType = searchParams.get('openBottomSheet');
  const navigate = useNavigate();

  const { openBottomsheet } = useBottomsheetContainer();

  const now = useMemo(() => new Date(), []);

  const { data } = useQuery(MembershipArtistDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null && (loginStatus === LoginStatus.LOGGED_OUT || !membership),
  });

  const unAuthArtist = data?.data.artistLink?.artist;

  const { artist, user, serialNumber, createdAt, receipts } = membership || {};

  const hasMembership = !!membership;

  const artistName = artist?.name ?? unAuthArtist?.name ?? artistHandle;
  const artistMembershipCardImage =
    artist?.membershipCardImage?.membershipCardImageOptimizedUrl ??
    unAuthArtist?.membershipCardImage?.membershipCardImageOptimizedUrl;
  const vaultId = artist?.mainVault.id ?? unAuthArtist?.mainVaultId;
  const linkValue = artist?.linkValue ?? unAuthArtist?.linkValue;

  const username =
    user?.displayName ??
    user?.username ??
    loggedInUser?.displayName ??
    loggedInUser?.username ??
    'username';
  const createdAtDate = createdAt ?? now.toISOString();

  const onJoinFreeClick = useCallback(async () => {
    if (!vaultId || !linkValue) return;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
        membershipConfirmationBottomsheetProps: {
          vaultId,
          isLoading: false,
          artistHandle: artistHandle,
          artistName: artistName,
          imageUrl: artistMembershipCardImage,
          loggedInUserUsername: loggedInUser?.username,
          loginStatus,
          inviteCode: null,
          smsCampaignResponseShortcode,
          sourceReleaseCampaignId: null,
        },
        shared: {
          hideCloseBottomsheetButton: false,
          preventSwipeToDismiss: false,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          withVaultTheme: true,
        },
      });
    } else {
      const queryParams = constructQueryParams({
        artistHandle: linkValue,
        openBottomSheet: 'freeTierModal',
        c: smsCampaignResponseShortcode,
      });

      navigate(`${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
      return;
    }
  }, [
    vaultId,
    linkValue,
    loginStatus,
    openBottomsheet,
    artistHandle,
    artistName,
    artistMembershipCardImage,
    loggedInUser?.username,
    smsCampaignResponseShortcode,
    navigate,
  ]);

  useEffect(() => {
    if (loginStatus !== LoginStatus.LOGGED_IN || !loggedInUser || !!code) return;

    if (loggedInUser?.username == null) {
      const queryParams = constructQueryParams({
        artistHandle,
        invite,
        smsCampaignResponseShortcode,
        openBottomSheet: bottomSheetType,
        redirect: 'drops',
      });
      navigate(`${ROUTES.ONBOARDING_USERNAME}${queryParams ? `?${queryParams}` : ''}`);
    }
  }, [
    artistHandle,
    bottomSheetType,
    code,
    invite,
    loggedInUser,
    loggedInUser?.username,
    loginStatus,
    navigate,
    smsCampaignResponseShortcode,
  ]);

  if (artistHandle == null || isOwner) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (!membership && !unAuthArtist) {
    return <FullPageLoading withVaultTheme />;
  }

  return (
    <DefaultLayout
      messageChannelId={undefined}
      withBottomNavigator
      vaultId={vaultId}
      hasChatReadAccess={false}
      shouldSkipMargin={false}
      headerClassName="md2:bg-vault_text/3"
      contentClassName="md2:bg-vault_text/3"
      showBorder
      showRoundedTop
      withVaultTheme
      isHeaderTransparent
    >
      {!isDesktop && (
        <View className="z-above1 -mt-5 mb-5 w-full">
          <MenuButton className="h-12" withVaultTheme />
        </View>
      )}
      <MembershipV2Page
        isLoading={isLoading || (!membership && !unAuthArtist)}
        artistName={artistName ?? ''}
        serialNumber={serialNumber || randomSerialId}
        imageUrl={artistMembershipCardImage}
        displayName={username}
        createdAt={createdAtDate}
        receiptCount={receipts}
        hasMembership={hasMembership}
        onJoinFreeClick={onJoinFreeClick}
        artistHandle={artistHandle}
      />
    </DefaultLayout>
  );
};

const MembershipV2Page = ({
  isLoading,
  artistName,
  serialNumber,
  imageUrl,
  displayName,
  createdAt,
  receiptCount,
  hasMembership,
  onJoinFreeClick,
  artistHandle,
}: {
  className?: string;
  isLoading: boolean;
  artistName: string;
  serialNumber: number | null | undefined;
  imageUrl: string | null | undefined;
  displayName: string | null | undefined;
  createdAt: string | undefined;
  receiptCount: number | undefined;
  hasMembership: boolean;
  onJoinFreeClick: () => void;
  artistHandle: string;
}) => {
  const { isDesktop } = useWindow();

  const aspectRatio = isDesktop ? 0.8 : 0.55;
  return (
    <MembershipView
      aspectRatio={aspectRatio}
      isLoading={isLoading}
      artistName={artistName ?? ''}
      serialNumber={serialNumber}
      imageUrl={imageUrl}
      displayName={displayName}
      lanyardHeight={isDesktop ? 300 : 200}
      createdAt={createdAt}
      receiptCount={receiptCount}
      useThreeJs={false}
    >
      <View className="flex w-full flex-col gap-8 pb-10 pt-2 lg:gap-10">
        {!isLoading && !hasMembership && (
          <Button
            label="Join for free"
            type="primary-themed"
            buttonType="submit"
            disabledClassName="opacity-30"
            className="w-full"
            onClick={onJoinFreeClick}
          />
        )}
        <EventsSection artistHandle={artistHandle} />
        <ReceiptsSection />
      </View>
    </MembershipView>
  );
};
