import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException } from '@sentry/react';
import { debounce } from 'lodash-es';
import { useInView } from 'react-intersection-observer';
import { Virtuoso, type VirtuosoHandle } from 'react-virtuoso';
import { faArrowDown, faBadgeCheck, faReceipt } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';

import type {
  MessageChannelUpdatesDocument,
  MessageChannelUpdatesSubscription,
  MessageSource,
  PinnedMessageChannelQuery,
} from '../../graphql/generated';
import {
  FeatureTypename,
  type FragmentType,
  getFragment,
  MessageBubbleFragmentDoc,
  MessageChannelDetailsFragmentDoc,
  MessageChannelViewFragmentDoc,
  TierTypename,
} from '../../graphql/generated';
import type { ExecutionResultWithData, OnData } from '../../graphql/wsClient';
import { useDrag } from '../../hooks/useDrag';
import { useMessageChannelUpdatesSubscription } from '../../hooks/useMessageChannelUpdatesSubscription';
import { useStableCallback } from '../../hooks/useStableCallback';
import type { TierFeatures } from '../../hooks/useTierFeatures';
import { compareDates, dateToTime, isSamePeriod } from '../../utils/dateUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import {
  MessageBubble,
  MessageBubbleInteractions,
  SkeletonMessageBubble,
} from '../message/MessageBubble';
import { MessageCommentBubble } from '../message/MessageCommentBubble';
import { PinnedMessage } from '../message/PinnedMessage';
import { UserProfileImage } from '../user/UserProfileImage';

gql(/* GraphQL */ `
  fragment messageChannelView on MessageChannel {
    id
    artist {
      id
      name
      linkValue
      profileImage {
        id
        artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      }
    }
    details {
      ...messageChannelDetails
    }
    vault {
      id
      artist: artistProfile {
        id
        name
        linkValue
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
      }
      tiers {
        __typename
        enabledFeatures {
          feature {
            __typename
          }
        }
      }
    }
    messages: messagesPagination(after: $after, first: $first, includeTrackComments: false) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          source
          id
          createdAt
          ...messageBubble
        }
        cursor
      }
    }
  }
`);

type Props = {
  messageChannel: FragmentType<MessageChannelViewFragmentDoc>;
  messages: (FragmentType<MessageBubbleFragmentDoc> & {
    id: string;
    createdAt: string;
    source: MessageSource;
  })[];
  loadMoreNextPage: () => void;
  onMessageChannelUpdate: OnData<MessageChannelUpdatesDocument>;
  isOwner?: boolean;
  pinnedMessages:
    | NonNullable<
        Extract<
          PinnedMessageChannelQuery['messageChannel'],
          { __typename: 'QueryMessageChannelSuccess' }
        >['data']
      >['pinnedMessages']
    | undefined;
  artistLinkValue: string;
  hasNextPage?: boolean;
  virtuosoRef: React.RefObject<VirtuosoHandle>;
  activeSubscriptionFeatures: TierFeatures | null;
  vaultId: string | undefined;
  isGroupChat: boolean;
};

const CONTAINER_SPACING = -65;
const DEFAULT_MARGIN_RIGHT = `${CONTAINER_SPACING}px`;

export const MessageChannelView: FC<Props> = ({
  messageChannel,
  loadMoreNextPage,
  onMessageChannelUpdate,
  messages,
  isOwner = false,
  pinnedMessages,
  hasNextPage,
  virtuosoRef,
  artistLinkValue,
  activeSubscriptionFeatures,
  vaultId,
  isGroupChat,
}) => {
  const messageChannelFrag = getFragment(MessageChannelViewFragmentDoc, messageChannel);
  const details = getFragment(MessageChannelDetailsFragmentDoc, messageChannelFrag.details);
  const isScrolling = useRef(false);
  const [newMessages, setNewMessages] = useState<
    ExecutionResultWithData<MessageChannelUpdatesSubscription>[]
  >([]);

  // Feature Access
  const areSubscriptionTierBadgesVisible = useMemo(
    () =>
      messageChannelFrag.vault?.tiers
        ?.find(tier => tier.__typename === TierTypename.FreeTier)
        ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatWrite),
    [messageChannelFrag.vault?.tiers],
  );
  const artist = messageChannelFrag.artist ?? messageChannelFrag.vault?.artist;
  const hasChatWriteAccess = activeSubscriptionFeatures?.enabledFeatures.ChatWrite === true;
  const hasTrackCommentsReadAccess =
    activeSubscriptionFeatures?.enabledFeatures.TrackCommentsRead === true;

  const [hasSeenNewMessages, setHasSeenNewMessages] = useState(true);
  const {
    ref: inViewRef,
    inView: isBottomInView,
    entry,
  } = useInView({
    delay: 100,
  });

  useMessageChannelUpdatesSubscription({
    messageChannelId: messageChannelFrag.id,
    onSubscriptionData: data => {
      const update = data.data.messageChannelUpdates;

      if (update.__typename !== 'SubscriptionMessageChannelUpdatesSuccess') {
        return;
      }

      // if user is at bottom, process message immediately
      if (isBottomInView) {
        onMessageChannelUpdate(data);
        setNewMessages([]);
        return;
      }

      // we don't want message to be processed rigth away if the user is scrolled up but we want to process everything else
      if (update.data.__typename === 'CreateMessageSubscription') {
        setHasSeenNewMessages(false);
      } else {
        onMessageChannelUpdate(data);
      }

      // queue messages to be processed when user scrolls to bottom
      setNewMessages(prev => [...prev, data]);
    },
  });
  const processMessages = useCallback(() => {
    setHasSeenNewMessages(true);

    if (!!newMessages.length) {
      try {
        newMessages.forEach(onMessageChannelUpdate);
        setNewMessages([]);
      } catch (error) {
        captureException(error, {
          level: 'warning',
          extra: {
            channelId: messageChannelFrag.id,
          },
        });
      }
    }
  }, [messageChannelFrag.id, newMessages, onMessageChannelUpdate]);

  const debouncedProcessMessages = useMemo(() => debounce(processMessages, 50), [processMessages]);
  useEffect(() => {
    if (!isBottomInView) return;

    setHasSeenNewMessages(true);

    if (!newMessages.length) return;

    // Process queued messages when user scrolls to bottom
    newMessages.forEach(onMessageChannelUpdate);
    setNewMessages([]);
  }, [isBottomInView, newMessages, onMessageChannelUpdate]);

  const ref = useRef<HTMLDivElement>(null);

  const scrollerRef = useRef<HTMLElement | Window | null>(null);

  const handleScrollerRef = useCallback((ref: HTMLElement | Window | null) => {
    scrollerRef.current = ref;
  }, []);

  const [containerMarginRight, setContainerMarginRight] = useState(DEFAULT_MARGIN_RIGHT);

  const resetMarginRight = useStableCallback(() => {
    setContainerMarginRight(DEFAULT_MARGIN_RIGHT);
    MessageBubbleInteractions.enableLongPress = true;
  });

  useEffect(() => {
    let maxLeft = 0;
    const handleScroll = (e: WheelEvent) => {
      e.preventDefault();
      const currentTarget = e.currentTarget as HTMLElement;

      if (currentTarget) {
        currentTarget.scrollTop -= e.deltaY;

        if (e.deltaX > 0 && e.deltaY === 0) {
          MessageBubbleInteractions.enableLongPress = false;

          maxLeft += e.deltaX;
          setContainerMarginRight(`${Math.min(CONTAINER_SPACING + maxLeft, 0)}px`);
        } else {
          maxLeft = 0;
          resetMarginRight();
        }
      }
    };

    const ref = scrollerRef.current as HTMLElement;

    ref.addEventListener('wheel', handleScroll, {
      passive: false,
    });

    return () => {
      ref.removeEventListener('wheel', handleScroll);
    };
  }, [resetMarginRight]);

  const { resetDragging } = useDrag(ref, {
    onDragLeft({ amount }) {
      if (isScrolling.current) return;

      MessageBubbleInteractions.enableLongPress = false;
      setContainerMarginRight(`${Math.min(CONTAINER_SPACING + amount, 0)}px`);
    },
    onPointerUp: resetMarginRight,
    onDragRight: resetMarginRight,
    directionThreshold: 20,
  });

  const now = useMemo(() => new Date(), []);

  const firstPin = pinnedMessages?.[0];

  const LoadingFooter = React.useCallback(
    () =>
      hasNextPage ? (
        <SkeletonMessageBubble
          isAuthor={false}
          className="flex flex-1 rotate-180 scale-x-[-1] pt-3"
        />
      ) : (
        <View className="rotate-180 scale-x-[-1]">
          {!isGroupChat && details && (
            <View className="flex w-full flex-col items-center pb-12 pt-6">
              <View className="mb-4 h-[100px] w-[100px] overflow-hidden rounded-full">
                <UserProfileImage
                  profileImageUrl={details.coverImage?.imageSmallUrl}
                  fallbackColor={details.coverImage?.dominantColor}
                  className="h-full w-full text-vault_text"
                  withVaultTheme
                />
              </View>
              <Text className="mb-2 font-title !text-title-l font-medium text-vault_text">
                {details.titleText}
              </Text>
              <Text className="mb-1 flex items-center !text-base-m text-vault_text/50">
                @{details.username}
                {details.showVerifiedBadge && (
                  <FontAwesomeIcon icon={faBadgeCheck} className="text-accent ml-1" />
                )}
              </Text>
              {details.subtitleText && (
                <View className="flex flex-row items-center">
                  <Text className="!text-base-m text-vault_text/50">{details.subtitleText}</Text>
                  {details.receiptCount && details.receiptCount > 0 && (
                    <Text className="ml-1 !text-base-m text-vault_text/50">
                      · {details.receiptCount}
                      <FontAwesomeIcon icon={faReceipt} className="ml-1 text-vault_text/50" />
                    </Text>
                  )}
                </View>
              )}
            </View>
          )}
        </View>
      ),
    [hasNextPage, isGroupChat, details],
  );

  const Header = React.useCallback(() => <div ref={inViewRef} />, [inViewRef]);

  const messageContent = useCallback(
    (
      i: number,
      message: FragmentType<MessageBubbleFragmentDoc> & {
        id: string;
        createdAt: string;
        source: MessageSource;
      },
    ) => {
      const { asArtist } = getFragment(MessageBubbleFragmentDoc, message);
      return (
        <MessageBubbleContainer
          key={message.id}
          i={i}
          areSubscriptionTierBadgesVisible={!!areSubscriptionTierBadgesVisible}
          hasChatWriteAccess={hasChatWriteAccess}
          hasTrackCommentsReadAccess={hasTrackCommentsReadAccess}
          message={message}
          now={now}
          messages={messages}
          isVaultArtist={artist?.id === asArtist?.id}
          isOwner={isOwner}
          artistId={artist?.id}
          artistLinkValue={artist?.linkValue}
          artistName={artist?.name}
          artistProfileImageUrl={artist?.profileImage?.artistSmallProfileImageUrl ?? null}
          containerMarginRight={containerMarginRight}
          resetDragging={resetDragging}
          resetMarginRight={resetMarginRight}
          hasNextPage={hasNextPage}
          vaultId={vaultId}
        />
      );
    },
    [
      areSubscriptionTierBadgesVisible,
      artist?.id,
      artist?.linkValue,
      artist?.name,
      artist?.profileImage?.artistSmallProfileImageUrl,
      containerMarginRight,
      hasChatWriteAccess,
      hasNextPage,
      hasTrackCommentsReadAccess,
      isOwner,
      messages,
      now,
      resetDragging,
      resetMarginRight,
      vaultId,
    ],
  );

  const scrollToBottom = useCallback(() => {
    virtuosoRef.current?.scrollToIndex({
      index: 0,
      behavior: 'smooth',
    });
    processMessages();
  }, [processMessages, virtuosoRef]);

  return (
    <View
      className="relative box-border flex w-full flex-1 flex-col items-center overflow-x-clip overscroll-none px-[10px] text-vault_text"
      containerRef={ref}
    >
      {firstPin != null && pinnedMessages && (
        <PinnedMessage
          message={firstPin}
          artistHandle={artistLinkValue}
          pinnedMessageCount={pinnedMessages.length}
        />
      )}

      {entry && !isBottomInView ? (
        <Button
          label={null}
          labelComponent={
            !hasSeenNewMessages ? (
              <Text className="!text-base-m font-medium text-vault_text_opposite">
                New messages
              </Text>
            ) : null
          }
          leadingIcon={faArrowDown}
          iconOnly={hasSeenNewMessages}
          className="absolute bottom-[10px] z-stickyHeader rounded-full bg-vault_text p-2 text-vault_text_opposite"
          leadingIconClassName="h-[16px] w-[16px]"
          onClick={scrollToBottom}
        />
      ) : null}
      <Virtuoso
        data={messages}
        itemContent={messageContent}
        components={{ Footer: LoadingFooter, Header }}
        alignToBottom
        className="no-scrollbar w-full rotate-180 scale-x-[-1] overflow-x-hidden overscroll-none"
        ref={virtuosoRef}
        endReached={loadMoreNextPage}
        overscan={{ reverse: 100, main: 1000 }}
        scrollerRef={handleScrollerRef}
        isScrolling={scrolling => (isScrolling.current = scrolling)}
        atTopStateChange={atTop => {
          if (!atTop) return;
          debouncedProcessMessages();
        }}
      />
    </View>
  );
};

const MessageBubbleContainer = ({
  i,
  message,
  messages,
  areSubscriptionTierBadgesVisible,
  hasChatWriteAccess,
  hasTrackCommentsReadAccess,
  now,
  artistProfileImageUrl,
  isOwner,
  resetDragging,
  resetMarginRight,
  artistName,
  artistLinkValue,
  artistId,
  containerMarginRight,
  isVaultArtist,
  hasNextPage,
  vaultId,
}: {
  i: number;
  message: FragmentType<MessageBubbleFragmentDoc> & {
    id: string;
    createdAt: string;
    source: MessageSource;
  };
  areSubscriptionTierBadgesVisible: boolean;
  hasChatWriteAccess: boolean;
  hasTrackCommentsReadAccess: boolean;
  messages: (FragmentType<MessageBubbleFragmentDoc> & { id: string; createdAt: string })[];
  now: Date;
  artistName: string | undefined;
  artistLinkValue: string | undefined;
  artistId: string | undefined;
  artistProfileImageUrl: string | null;
  isOwner: boolean;
  containerMarginRight: string;
  resetMarginRight: () => void;
  resetDragging: () => void;
  isVaultArtist: boolean;
  hasNextPage?: boolean;
  vaultId: string | undefined;
}) => {
  const { id, createdAt, source } = message;

  const nextMessage = messages[i + 1];

  const nextMessageIsInDifferentGroup =
    !!nextMessage && !isSamePeriod(new Date(createdAt), new Date(nextMessage.createdAt), 2);

  const dateMemo = useMemo(
    () =>
      nextMessageIsInDifferentGroup || (!nextMessage && !hasNextPage) ? (
        <p className="py-2 text-center !text-base-s text-vault_text/50">
          <b>{compareDates(new Date(createdAt), now)}</b> {dateToTime(createdAt)}
        </p>
      ) : null,
    [createdAt, hasNextPage, nextMessage, nextMessageIsInDifferentGroup, now],
  );

  return (
    <View key={id} className="rotate-180 scale-x-[-1]">
      {dateMemo}
      {source === 'VAULT_CHAT' ? (
        <MessageBubble
          message={message}
          key={id}
          isOwner={isOwner}
          containerMarginRight={containerMarginRight}
          onLongPress={() => {
            resetMarginRight();
            resetDragging();
          }}
          onReplyPress={() => {
            resetMarginRight();
            resetDragging();
          }}
          artistName={artistName}
          artistLinkValue={artistLinkValue}
          vaultArtistId={artistId}
          isVaultArtist={isVaultArtist}
          artistProfileImageUrl={artistProfileImageUrl}
          areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
          hasChatWriteAccess={hasChatWriteAccess}
          vaultId={vaultId}
        />
      ) : (
        <MessageCommentBubble
          hasTrackCommentsReadAccess={hasTrackCommentsReadAccess}
          message={message}
          isVaultArtist={isVaultArtist}
          containerMarginRight={containerMarginRight}
        />
      )}
    </View>
  );
};

export const EmptyMessageChannel = ({
  sendMessage,
}: {
  sendMessage: (content: string) => Promise<void> | void;
}) => {
  return (
    <View className="z-above4 box-border flex h-full w-full flex-1 flex-col items-center justify-center px-5">
      <Text className="mb-2 font-title !text-title-s font-medium text-vault_text">
        It's a bit quiet in here...
      </Text>
      <Text className="mb-5 text-center font-base !text-base-m font-medium text-vault_text/50">
        Be the first to send a message or tap the wave below
      </Text>
      <Button
        label="👋"
        className="h-[100px] w-[100px] items-center justify-center rounded-full border-4 border-solid border-vault_text/20 bg-vault_text/10 text-[50px]"
        onClick={() => sendMessage('👋')}
      />
    </View>
  );
};

export const SkeletonMessageChannel = () => {
  return (
    <View className="mx-[10px] box-border flex w-full flex-col items-center bg-vault_background px-5 pt-2 text-vault_text md2:bg-transparent md2:pt-4">
      <View className="flex w-full flex-1 flex-col-reverse gap-4 px-[10px] scrollbar-none">
        {Array.from({ length: 11 }, (_, i) => (
          <SkeletonMessageBubble key={i} isAuthor={i % 2 !== 0} />
        ))}
      </View>
    </View>
  );
};
