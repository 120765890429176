import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import {
  faArrowUpFromBracket,
  faCalendar,
  faComment,
  faEllipsis,
  faHome,
  faMessages,
  faVault,
  type IconDefinition,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  faCalendar as faCalendarSolid,
  faComment as faCommentSolid,
  faHome as faHomeSolid,
  faInbox,
  faMessages as faMessagesSolid,
  faVault as faVaultSolid,
} from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useStableCallback } from '../../hooks/useStableCallback';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { type EventObject, EVENTS, type EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { usePageChecks } from '../../utils/pathUtils';
import { getSubdomain, isValidSubdomain } from '../../utils/subdomainUtils';
import { Button } from '../buttons/Button';
import { ActionDropdown, Dropdown } from '../common/Dropdown';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { CreateButton } from '../vault/CreateButton';
import { useBatchedVaultMessageUnreadCount } from '../views/hooks/useVaultMessageUnreadCount';

export function NavigationLinks({
  artistId,
  artistName,
  chatAvailableForFreeUsers,
  createButtonClassName,
  hasChatReadAccess,
  isLoading,
  messageChannelId,
  selectedHandleMemo,
  vaultId,
}: {
  artistId: string;
  artistName: string | undefined;
  chatAvailableForFreeUsers: boolean;
  createButtonClassName?: string;
  hasChatReadAccess: boolean;
  isLoading: boolean;
  messageChannelId: string;
  selectedHandleMemo: string;
  vaultId: string;
}) {
  const { loggedInUser } = useAuthContext();
  const { folderId } = useMenuContainer();

  const { value: isUnifiedInboxEnabled } = useGate(FEATURE_GATES.UNIFIED_INBOX);

  const isOwner =
    !!selectedHandleMemo &&
    !!loggedInUser?.adminArtists?.some(admin => admin.artistLinks.includes(selectedHandleMemo));

  const chatEnabled =
    (isOwner || hasChatReadAccess || !!chatAvailableForFreeUsers) && !!loggedInUser?.id;

  const { unreadCount: messageNotificationCount } = useBatchedVaultMessageUnreadCount({
    messageChannelId,
    vaultId,
    enabled: chatEnabled,
  });

  const subdomainArtistHandle = isValidSubdomain() ? getSubdomain() : null;
  const isSubdomain = !!subdomainArtistHandle;

  const getIsHomeSelected = (
    isSubdomain: boolean,
    pathname: string,
    selectedHandleMemo?: string,
  ) => {
    const paths = isSubdomain
      ? ['/dashboard', '/dashboard/', '/']
      : [
          `/${selectedHandleMemo}/dashboard`,
          `/${selectedHandleMemo}/dashboard/`,
          `/${selectedHandleMemo}`,
          `/${selectedHandleMemo}/`,
        ];

    return paths.some(path => {
      return isSubdomain ? pathname === path : pathname.endsWith(path);
    });
  };

  const isHomeSelected = getIsHomeSelected(isSubdomain, location.pathname, selectedHandleMemo);
  const isMessagesSelected = (() => {
    const path = location.pathname;
    if (path.includes('/messages/insights')) return false;
    return path.endsWith('/messages') || path.endsWith('/chat') || /\/messages\/[^\/]+$/.test(path);
  })();

  const [isChatSelected = false, isMembershipSelected = false, isVaultSelected = false] =
    usePageChecks({
      pages: ['chat', 'drops', 'vault'],
    });

  return (
    <>
      <MenuItem
        href={artistNavigationPath(selectedHandleMemo, isOwner ? '/dashboard' : '/')}
        icon={isHomeSelected ? faHomeSolid : faHome}
        label="Home"
        selected={isHomeSelected}
        showCountIndicator={false}
        isLoading={isLoading}
        event={{
          type: EVENTS.SECONDARY_MENU_NAVIGATE,
          properties: { artistHandle: selectedHandleMemo, path: '/' },
        }}
      />

      {isOwner && (
        <MenuItem
          href={artistNavigationPath(selectedHandleMemo, '/vault')}
          icon={isVaultSelected ? faVaultSolid : faVault}
          label="Vault"
          selected={isVaultSelected}
          showCountIndicator={false}
          isLoading={isLoading}
          event={{
            type: EVENTS.SECONDARY_MENU_NAVIGATE,
            properties: { artistHandle: selectedHandleMemo, path: '/vault' },
          }}
        />
      )}

      <MenuItem
        href={artistNavigationPath(
          selectedHandleMemo,
          isUnifiedInboxEnabled ? '/messages' : '/chat',
        )}
        icon={
          isUnifiedInboxEnabled
            ? isMessagesSelected || isChatSelected
              ? faMessagesSolid
              : faMessages
            : isChatSelected
              ? faCommentSolid
              : faComment
        }
        label={isUnifiedInboxEnabled ? 'Messages' : 'Chat'}
        selected={isChatSelected || (isUnifiedInboxEnabled && isMessagesSelected)}
        showCountIndicator={!!messageNotificationCount && hasChatReadAccess}
        isLoading={isLoading}
        event={{
          type: EVENTS.SECONDARY_MENU_NAVIGATE,
          properties: { artistHandle: selectedHandleMemo, path: '/chat' },
        }}
      />

      {!isOwner && (
        <MenuItem
          href={artistNavigationPath(selectedHandleMemo, '/drops')}
          icon={isMembershipSelected ? faCalendarSolid : faCalendar}
          label="Drops"
          selected={isMembershipSelected}
          showCountIndicator={false}
          isLoading={isLoading}
          event={{
            type: EVENTS.SECONDARY_MENU_NAVIGATE,
            properties: { artistHandle: selectedHandleMemo, path: '/drops' },
          }}
        />
      )}

      {isOwner && !isLoading && (
        <CreateButton
          artistHandle={selectedHandleMemo}
          mainVaultId={vaultId}
          folderId={folderId}
          className={createButtonClassName}
        />
      )}

      {!isOwner && !isLoading && (
        <MoreButton
          artistHandle={selectedHandleMemo}
          artistId={artistId}
          artistName={artistName}
          isOwner={isOwner}
        />
      )}
    </>
  );
}

function MenuItem<Event extends EventType>(
  props: {
    icon: IconDefinition;
    label: string;
    selected: boolean;
    showCountIndicator: boolean;
    isLoading: boolean;
  } & ({ onClick: () => void } | { event: EventObject<Event> | undefined; href: string }),
) {
  const location = useLocation();

  if (props.isLoading) {
    return <MenuItemSkeleton />;
  }

  return (
    <View className="relative">
      <Button
        href={'href' in props ? props.href : undefined}
        className={twMerge(
          'flex h-14 max-h-14 min-h-14 w-full flex-row items-center justify-start gap-3 rounded-xl p-3 font-title text-[18px] font-medium outline-none transition-all duration-500 ease-in-out focus:outline-none active:outline-none',
          props.selected ? 'text-vault_text' : 'text-vault_text/50',
          'hover:bg-vault_text/10 hover:text-vault_text',
        )}
        label={props.label}
        leadingIcon={props.icon}
        leadingIconClassName="mr-1 aspect-square w-8 text-[24px]"
        onClick={() => {
          if ('event' in props && !!props.event) {
            trackEvent({ ...props.event, pathname: location.pathname });
          } else if ('onClick' in props) {
            props.onClick();
          }
        }}
      />

      {props.showCountIndicator && (
        <View className="absolute left-[30px] top-[14px] h-2 w-2 items-center justify-center rounded-full border-2 border-solid border-vault_background bg-vault_text" />
      )}
    </View>
  );
}

function MoreButton({
  artistHandle,
  artistId,
  artistName,
  isOwner,
}: {
  artistHandle: string;
  artistId: string;
  artistName: string | undefined;
  isOwner: boolean;
}) {
  const { loggedInUser } = useAuthContext();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const onShareClick = useStableCallback(() => {
    const link = generateShareLink({
      artistLinkValue: artistHandle,
      inviteCode: isOwner ? null : loggedInUser?.inviteCode,
      path: null,
    });

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.SHARE,
      shared: {
        withVaultTheme: true,
      },
      shareBottomsheetProps: {
        link,
        artistName: artistName ?? 'vault',
        withVaultTheme: true,
      },
    });
  });

  const buttons = useMemo(() => {
    const options: ActionBottomsheetProps['buttons'] = [];

    const buttonClassName =
      'border-b-vault_text/5 bg-vault_text/10 hover:bg-vault_text/20 text-vault_text ease-in-out duration-300 transition-all md2:h-[45px] text-[16px]/[20px] justify-between gap-4';

    if (loggedInUser) {
      options.push({
        label: 'Notification settings',
        trailingIcon: faInbox,
        type: 'secondary',
        className: buttonClassName,
        href: `${ROUTES.SETTINGS}/artist-notification/${artistId}`,
        onClick: () => {
          closeBottomsheet();
        },
      });
    }

    options.push({
      label: 'Share',
      trailingIcon: faArrowUpFromBracket,
      type: 'secondary',
      className: buttonClassName,
      onClick: e => {
        e.stopPropagation();
        onShareClick();
      },
      event: {
        type: EVENTS.OPEN_BOTTOMSHEET,
        properties: {
          bottomsheetType: BOTTOMSHEET_TYPES.SHARE,
          entity: 'vault',
          artistId,
        },
      },
    });

    return options;
  }, [artistId, closeBottomsheet, loggedInUser, onShareClick]);

  return (
    <Dropdown
      align="center"
      disabled={false}
      className="shadow-none border border-solid border-vault_text/5"
      sideOffset={12}
      trigger={
        // Has to be a <div> to enable the trigger to be clickable
        <div className="relative">
          <Button
            className={twMerge(
              'flex h-14 max-h-14 min-h-14 w-full flex-row items-center justify-start gap-3 rounded-xl p-3 outline-none transition-all duration-500 ease-in-out focus:outline-none active:outline-none',
              '!font-title !text-[18px] !font-medium text-vault_text/50 hover:bg-vault_text/10 hover:text-vault_text',
            )}
            label="More"
            leadingIcon={faEllipsis}
            leadingIconClassName="mr-1 aspect-square w-8 text-[24px]"
          />
        </div>
      }
    >
      <ActionDropdown buttons={buttons} withVaultTheme />
    </Dropdown>
  );
}

function MenuItemSkeleton() {
  return (
    <View className="relative">
      <View
        className={twMerge(
          'flex h-10 w-full flex-row items-center justify-start gap-3 rounded-xl p-3',
        )}
      >
        <LoadingSkeleton className="aspect-square h-8 w-8 rounded-full bg-vault_text/10" />
        <LoadingSkeleton className="h-6 w-32 rounded-lg bg-vault_text/10" />
      </View>
    </View>
  );
}
