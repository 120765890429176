import type { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';

import { faChevronRight, faMobile } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faUser } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { LoginStatus } from '../../types/authTypes';

gql(/* GraphQL */ `
  fragment userNotificationSettings on PrivateUser {
    id
    phone
    notificationSettings {
      isEmailEnabled
      isSmsEnabled
      smsNotifications {
        newArtistTrack
        newSubscriber
      }
    }
  }

  query UserNotificationSettings {
    currentUser {
      __typename
      ... on QueryCurrentUserSuccess {
        data {
          id
          ...userNotificationSettings
        }
      }
      ... on Error {
        message
      }
    }
  }

  mutation UpdateSmsNotificationSettings($input: MutationUpdateSmsNotificationsSettingsInput!) {
    updateSmsNotificationsSettings(input: $input) {
      id
      ...userNotificationSettings
    }
  }
`);

export const NotificationSettingsPage: FC = () => {
  const { loginStatus } = useAuthContext();

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <SettingsLayout title="Notification settings">
      <View className="flex w-full flex-col gap-8">
        <Link
          to={`${ROUTES.SETTINGS}/sms-notification-settings`}
          className="text-[unset] no-underline"
        >
          <View className="flex w-full flex-row justify-between">
            <View className="flex flex-row items-center gap-3">
              <FontAwesomeIcon icon={faMobile} className="w-5" />
              <Text className="text-title-s font-medium">SMS notifications</Text>
            </View>

            <FontAwesomeIcon icon={faChevronRight} className="text-[20px]" />
          </View>
        </Link>
        <Link to={`${ROUTES.SETTINGS}/artist-notification`} className="text-[unset] no-underline">
          <View className="flex w-full flex-row justify-between ">
            <View className=" flex flex-row items-center gap-3">
              <FontAwesomeIcon icon={faUser} className="w-5" />
              <Text className="text-title-s font-medium">Artist notifications</Text>
            </View>

            <FontAwesomeIcon icon={faChevronRight} className="text-[20px]" />
          </View>
        </Link>
      </View>
    </SettingsLayout>
  );
};
