import type { ReactNode } from 'react';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { BackButton } from '../buttons/BackButton';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { DefaultLayout } from './DefaultLayout';

export function SettingsLayout({
  children,
  title,
  subtitle,
  right,
  nonScrollingChildren,
  titleComponent,
  stretch,
  withVaultTheme = false,
  extend,
  contentClassName,
  childrenWrapperClassName,
}: {
  children?: ReactNode;
  right?: ReactNode;
  nonScrollingChildren?: ReactNode;
  stretch?: boolean;
  withVaultTheme?: boolean;
  extend?: boolean;
  contentClassName?: string;
  childrenWrapperClassName?: string;
} & (
  | { title: string; subtitle?: string; titleComponent?: null }
  | { title?: null; subtitle?: null; titleComponent: ReactNode }
)) {
  return (
    <DefaultLayout
      withVaultTheme={withVaultTheme}
      stretch={stretch}
      showRoundedTop
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerLeft={<BackButton withVaultTheme={withVaultTheme} />}
      headerCenter={
        titleComponent ?? (
          <View className="flex flex-col items-center justify-center">
            <Text className="font-title text-title-m font-medium text-white">{title}</Text>
            {!!subtitle && (
              <Text className="font-base text-[12px]/[14px] font-medium text-base400 md2:text-title-xs">
                {subtitle}
              </Text>
            )}
          </View>
        )
      }
      headerRight={right}
      nonScrollingChildren={nonScrollingChildren}
      headerClassName={
        withVaultTheme ? 'bg-vault_background md2:bg-vault_text/3' : 'bg-black md2:bg-white/3'
      }
      contentClassName={twMerge(
        withVaultTheme
          ? 'md2:bg-vault_text/3'
          : 'border-0 md2:border-t md2:border-solid md2:border-t-white/10 md2:bg-white/3',
        contentClassName,
      )}
      childrenWrapperClassName={twMerge('px-4 pt-6 md2:px-6', childrenWrapperClassName)}
      extend={extend}
    >
      {children}
    </DefaultLayout>
  );
}
