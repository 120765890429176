import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useWindow } from '../../hooks/useWindow';
import { View } from '../common/View';
import { ViewHeader } from '../common/ViewHeader';
import { AudioPlayer } from '../main/AudioPlayer';
import { VaultNav } from '../vault/VaultNav';

export const DefaultLayout = forwardRef<
  HTMLDivElement,
  {
    children?: ReactNode;
    headerLeft?: ReactNode;
    headerCenter?: ReactNode;
    headerRight?: ReactNode;
    footer?: ReactNode;
    isHeaderTransparent?: boolean;
    shouldSkipMargin?: boolean;
    nonScrollingChildren?: ReactNode; // Will override children if isDesktop
    secondaryFooter?: ReactNode;
    shouldShowFullScreenOnDesktop?: boolean;
    shouldShowFullWidthOnDesktop?: boolean;
    childrenWrapperClassName?: string;
    childrenContainerClassName?: string;
    banner?: ReactNode;
    hideOverflowX?: boolean;
    stretch?: boolean; // stretch content to fit layout and default layout does not scroll
    extend?: boolean;

    withVaultTheme: boolean; // Applies the vault theme to the layout

    // VaultNav related props
    withBottomNavigator: boolean;
    customBottomNavigator?: ReactNode;
    vaultId: string | undefined;
    messageChannelId: string | undefined;
    hasChatReadAccess: boolean | undefined;

    // Vault Page Personalization
    showBorder: boolean;
    showRoundedTop: boolean;

    contentClassName?: string;
    headerGridClassName?: string;
    headerLeftClassName?: string;
    headerRightClassName?: string;
    headerCenterClassName?: string;
    headerClassName?: string;
    chatAvailableForFreeUsers?: boolean;

    hideAudioPlayer?: boolean;
  }
>(
  (
    {
      children,
      headerLeft,
      headerCenter,
      headerRight,
      footer,
      isHeaderTransparent = false,
      shouldSkipMargin = false,
      nonScrollingChildren,
      secondaryFooter,
      shouldShowFullScreenOnDesktop = false,
      shouldShowFullWidthOnDesktop = false,
      childrenWrapperClassName,
      childrenContainerClassName,
      banner,
      hideOverflowX = false,
      withBottomNavigator = false,
      customBottomNavigator,
      stretch,
      extend,
      vaultId,
      messageChannelId,
      hasChatReadAccess,
      chatAvailableForFreeUsers,

      showBorder,
      showRoundedTop,
      withVaultTheme,

      headerCenterClassName,
      headerGridClassName,
      headerLeftClassName,
      headerRightClassName,
      headerClassName,
      contentClassName,

      hideAudioPlayer = false,
    },
    ref,
  ) => {
    const { isDesktop } = useWindow();

    const showDefaultBottomNavigator = withBottomNavigator && !customBottomNavigator;

    const showVaultNav = !isDesktop;

    return (
      <>
        <View
          className={twMerge(
            'flex h-full w-full flex-col items-center text-white',
            (shouldShowFullScreenOnDesktop || shouldShowFullWidthOnDesktop) &&
              'md:relative md:z-above4',
            withVaultTheme && 'bg-vault_background',
          )}
        >
          {banner}
          <View
            className={twMerge(
              'no-scrollbar flex h-full w-full flex-col items-center overflow-y-scroll overscroll-y-contain',
              hideOverflowX && 'overflow-x-hidden',
            )}
            containerRef={ref}
          >
            <ViewHeader
              left={headerLeft}
              center={headerCenter}
              right={headerRight}
              className={twMerge(
                'z-above3 w-full pt-10 outline-none max-md2:sticky max-md2:top-0',
                showRoundedTop && 'md2:mt-1 md2:rounded-t-[20px]',
                shouldShowFullWidthOnDesktop
                  ? // Keep header at 870px even when full width is enabled
                    'md2:w-[910px]'
                  : shouldShowFullScreenOnDesktop
                    ? 'md2:w-[600px]'
                    : showBorder
                      ? withVaultTheme
                        ? 'box-border md2:w-[600px] md2:border-0 md2:border-l md2:border-r md2:border-t md2:border-solid md2:border-vault_text/5 md2:max-lt:ml-[310px]'
                        : 'box-border md2:w-[600px] md2:border-0 md2:border-l md2:border-r md2:border-t md2:border-solid md2:border-white/5 md2:max-lt:ml-[310px]'
                      : 'md2:ml-[2px] md2:w-[600px] md2:max-lt:ml-[312px]',
                isHeaderTransparent ? 'bg-transparent' : contentClassName ?? 'bg-black',
                headerClassName,
              )}
              gridClassName={headerGridClassName}
              leftClassName={headerLeftClassName}
              centerClassName={headerCenterClassName}
              rightClassName={headerRightClassName}
            />
            <View
              className={twMerge(
                'w-full md2:z-above2',
                shouldShowFullWidthOnDesktop
                  ? 'md2:w-full'
                  : shouldShowFullScreenOnDesktop
                    ? 'md2:w-[600px]'
                    : 'md2:w-[600px] md2:max-lt:ml-[310px]',
                isDesktop && 'flex-1',
                stretch && 'flex-1 overflow-y-clip',
                childrenContainerClassName,
                contentClassName,
              )}
            >
              <View
                className={twMerge(
                  'flex w-full flex-col max-md2:-z-above2',
                  !shouldShowFullScreenOnDesktop &&
                    (showBorder
                      ? withVaultTheme
                        ? 'md2:min-h-full md2:border-0 md2:border-l md2:border-r md2:border-solid md2:border-vault_text/5'
                        : 'md2:min-h-full md2:border-0 md2:border-l md2:border-r md2:border-solid md2:border-white/5'
                      : 'md2:min-h-full'),
                  secondaryFooter == null && 'pb-0',
                  (extend || stretch) && 'h-full',
                  'box-border outline-none',
                )}
              >
                <View
                  className={twMerge(
                    'box-border flex h-full w-full flex-col items-center',
                    !shouldSkipMargin && 'px-4 md2:px-6',
                    childrenWrapperClassName,
                  )}
                >
                  {children}
                </View>
                {isDesktop && nonScrollingChildren}
              </View>
            </View>
            {!isDesktop && nonScrollingChildren}
          </View>
          <View
            className={twMerge(
              'w-full md2:z-above2',
              shouldShowFullWidthOnDesktop
                ? 'md2:w-full'
                : shouldShowFullScreenOnDesktop
                  ? 'md2:w-[600px]'
                  : showBorder
                    ? withVaultTheme
                      ? 'md2:w-[600px] md2:border-0 md2:border-l md2:border-r md2:border-solid md2:border-vault_text/5 md2:max-lt:ml-[310px]'
                      : 'md2:w-[600px] md2:border-0 md2:border-l md2:border-r md2:border-solid md2:border-white/5 md2:max-lt:ml-[310px]'
                    : 'md2:ml-[2px] md2:w-[600px] md2:max-lt:ml-[312px]',
              contentClassName,
            )}
          >
            {secondaryFooter}
          </View>
          <View
            className={twMerge(
              'w-full md2:z-above2',
              shouldShowFullWidthOnDesktop
                ? 'md2:w-full'
                : shouldShowFullScreenOnDesktop
                  ? 'md2:w-[600px]'
                  : showBorder
                    ? withVaultTheme
                      ? 'box-border md2:w-[600px] md2:border-0 md2:border-l md2:border-r md2:border-solid md2:border-vault_text/5 md2:max-lt:ml-[310px]'
                      : 'box-border md2:w-[600px] md2:border-0 md2:border-l md2:border-r md2:border-solid md2:border-white/5 md2:max-lt:ml-[310px]'
                    : 'md2:ml-[2px] md2:w-[600px] md2:max-lt:ml-[312px]',
              contentClassName,
            )}
          >
            {footer}
          </View>

          {!hideAudioPlayer && (
            <AudioPlayer
              withBottomNavigator={withBottomNavigator || !!customBottomNavigator}
              // Only show the theme on the Audio Player if there is a valid vaultId
              // This will avoid showing the theme on settings page for example
              withVaultTheme={withVaultTheme && !!vaultId}
            />
          )}

          {showDefaultBottomNavigator && showVaultNav && (
            <View className="w-full md2:hidden">
              <VaultNav
                vaultId={vaultId}
                messageChannelId={messageChannelId}
                hasChatReadAccess={!!hasChatReadAccess}
                chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
                variant="default"
                withVaultTheme
                folderId={null}
              />
            </View>
          )}

          {customBottomNavigator}
        </View>
      </>
    );
  },
);
