import { type LegacyRef, type RefObject, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { faFilm } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPlay } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { type FragmentType, getFragment, VideoItemFragmentDoc } from '../../../graphql/generated';
import { useBatchedTracksViewed } from '../../../hooks/useBatchedTracksViewed';
import { useActiveSubscriptionFeatures } from '../../../hooks/useTierFeatures';
import { useWindow } from '../../../hooks/useWindow';
import { getDurationAsTime } from '../../../utils/dateUtils';
import { formatDateString } from '../../../utils/textUtils';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

gql(/* GraphQL */ `
  fragment VideoItem on VaultVideo {
    id
    title
    linkValue
    createdAt

    isFullVersionAvailable
    blurredMediaUrl
    uploadedMedia {
      id
      mediaType
      url: cdnUrl
    }

    vaultId
    duration

    parentVaultContentId

    featureAccess {
      feature {
        __typename
      }
    }

    vault {
      id
      artist: artistProfile {
        id
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
      }
      isUserArtistAdmin
      activeSubscription {
        id
        createdAt
        ...ActiveSubscriptionFeatures
      }
    }
  }
`);
export const VideoItem = ({
  video,
  containerRef,
  onPlayClick,
  videoRef,
  hasEllipsis,
}: {
  video: FragmentType<VideoItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  videoRef: RefObject<HTMLVideoElement>;
  onPlayClick?: () => void;
  hasEllipsis?: boolean;
}) => {
  const { isDesktop } = useWindow();
  const {
    title,
    createdAt,
    duration,
    id: videoId,
    vault,
    uploadedMedia,
    blurredMediaUrl,
  } = getFragment(VideoItemFragmentDoc, video);

  useEffect(() => {
    if (videoRef.current != null) {
      videoRef.current.load();
    }
  }, [videoRef]);

  const { isVaultCustomizeOpen } = useMenuContainer();

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });

  const { isViewed, isLoading } = useBatchedTracksViewed({ vaultContentId: videoId });

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = (!isOwner && activeSubscriptionFeatures?.tier == null) || uploadedMedia == null;

  const couldBeNew =
    !isOwner && vault.activeSubscription != null && vault.activeSubscription.createdAt < createdAt;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsNew((couldBeNew && isViewed === false) || isVaultCustomizeOpen);
  }, [couldBeNew, isLoading, isVaultCustomizeOpen, isViewed]);

  return (
    <View
      className="group flex w-full flex-grow cursor-pointer select-none flex-col gap-3 pb-3 text-left"
      containerRef={containerRef}
      onClick={onPlayClick}
    >
      <View className="relative flex min-h-[110px] w-full items-center justify-center rounded-md  bg-vault_text/10 transition-all duration-300 ease-in-out group-hover:bg-vault_text/20">
        <AnimatePresence>
          {isNew && (
            <motion.div
              className="absolute right-3 top-2 rounded-full font-base !text-base-xs font-semibold text-vault_accent"
              exit={{ opacity: 0 }}
            >
              New
            </motion.div>
          )}
        </AnimatePresence>
        <View className="relative flex h-[74px] items-start justify-center">
          {!isLocked ? (
            <video
              ref={videoRef}
              className="block h-full w-14 cursor-pointer rounded-lg bg-vault_text/10 object-cover"
              preload="metadata"
              controls={!isDesktop}
              controlsList="nodownload"
              src={uploadedMedia?.url}
              onClick={e => e.preventDefault()}
            />
          ) : (
            <>
              {blurredMediaUrl ? (
                <View className="relative flex h-full w-14 items-center justify-center">
                  <img
                    className="block h-full w-14 cursor-pointer rounded-lg bg-vault_text/10 object-cover"
                    src={blurredMediaUrl}
                    onClick={e => e.preventDefault()}
                  />
                  <div className="absolute flex h-6 w-6 items-center justify-center rounded-full bg-vault_text_opposite/50">
                    <FontAwesomeIcon icon={faPlay} className="text-[10px] text-vault_text/50" />
                  </div>
                </View>
              ) : (
                <>
                  <View className="bg-new-file-background h-[74px] w-[54px] bg-contain bg-no-repeat" />
                  <View className="absolute top-5 flex flex-col items-center justify-center gap-1.5">
                    <FontAwesomeIcon icon={faFilm} className="text-[24px] text-vault_text" />
                    <FontAwesomeIcon icon={faLock} className="text-[11px] text-vault_text" />
                  </View>
                </>
              )}
            </>
          )}
        </View>
      </View>
      <View className="flex flex-col gap-1">
        <View className="flex w-full justify-between gap-1">
          <Text className="line-clamp-2 text-ellipsis break-words font-base !text-base-m font-medium text-vault_text">
            {title ?? 'Untitled'}
          </Text>
          {!!hasEllipsis && <div className="h-5 w-5 flex-shrink-0" />}
        </View>
        <Text className="text-[12px] font-normal leading-[16px] text-vault_text/50">
          {formatDateString({ date: createdAt, format: 'numerical_month_day_year' })}
          &nbsp;&middot;&nbsp;
          {getDurationAsTime(duration)}
        </Text>
      </View>
    </View>
  );
};
