import { type ChangeEventHandler, type FormEventHandler, useEffect } from 'react';
import { useLoginWithSms } from '@privy-io/react-auth';
import * as Popover from '@radix-ui/react-popover';
import { AsYouType, type CountryCode } from 'libphonenumber-js/min';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../constants/urlConstants';
import { useSignIn } from '../../hooks/useSignIn';
import { useTimer } from '../../hooks/useTimer';
import { SignInStore, THIRTY_SECONDS_IN_MS } from '../../screens/auth/store';
import { Sentry } from '../../sentry';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { wrapSendCode } from '../../utils/privy';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Item, Select } from '../forms/Select';

export function SignInForm({
  setView,
  dropEnded,
  isLateRSVPEnabled = false,
  disabled,
  isPreview,
}: {
  setView: (view: 'sign_in' | 'verify') => void;
  dropEnded: boolean;
  isLateRSVPEnabled?: boolean;
  disabled: boolean;
  isPreview: boolean;
}) {
  const { pathname } = useLocation();

  const {
    countryCode,
    errorText,
    isOpen,
    phone,
    setCountryCode,
    setErrorText,
    setIsOpen,
    setPhone,
    validPhoneNumber,
    codeRenabled,
  } = useSignIn();

  const hasError = errorText != null;

  const {
    sendCode,
    state: { status },
  } = useLoginWithSms();

  const { seconds: codeSentDisabledSecondsRemaining } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  const isSubmitLoading = status === 'sending-code' || status === 'submitting-code';
  const isSubmitDisabled =
    isSubmitLoading ||
    (codeSentDisabledSecondsRemaining !== 0 && !!validPhoneNumber && codeRenabled !== 1) ||
    disabled;

  useEffect(() => {
    if (codeSentDisabledSecondsRemaining === 0 || !validPhoneNumber || codeRenabled === 1) {
      setErrorText(null);
    } else {
      setErrorText(`Please wait ${codeSentDisabledSecondsRemaining} seconds before trying again`);
    }
  }, [codeRenabled, codeSentDisabledSecondsRemaining, setErrorText, validPhoneNumber]);

  const onNextClick: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (isOpen || disabled) return;

    trackEvent({ type: EVENTS.NEXT, properties: { type: 'Sign In' }, pathname });

    if (!validPhoneNumber) {
      setErrorText('This phone number cannot be used for verification');
      return;
    }

    try {
      setErrorText(null);
      await wrapSendCode(() => sendCode({ phoneNumber: validPhoneNumber }));

      const codeRenabled = Date.now() + THIRTY_SECONDS_IN_MS;

      SignInStore.produceExistingState(
        draft => {
          draft.lastActivePhoneNumber = validPhoneNumber;
          draft.codeRenabled[validPhoneNumber] = codeRenabled;
        },
        {
          codeRenabled: {
            [validPhoneNumber]: codeRenabled,
          },
          lastActivePhoneNumber: validPhoneNumber,
        },
      );

      setView('verify');
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          errorText: 'We encountered an error sending your verification code',
          phoneNumber: validPhoneNumber,
        },
      });
      setErrorText('We encountered an error sending your verification code');
      return;
    }
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    if (disabled) return;

    const currentInput = e.target.value;
    let rawInput = currentInput.replace(/\D/g, ''); // Remove non-digit characters

    // Check if the last character was removed and was a formatting character
    if (phone.length > currentInput.length && '()- '.includes(phone?.[phone.length - 1] ?? '')) {
      rawInput = rawInput.substring(0, rawInput.length - 1);
    }

    const formatter = new AsYouType(countryCode as CountryCode);
    const formatted = formatter.input(rawInput);
    setPhone(formatted);
  };

  return (
    <View
      className={twMerge(
        'mt-6 box-border h-[174px] min-h-[174px] w-full max-w-[364px] flex-col rounded-[24px] bg-white/10 p-4 backdrop-blur-2xl',
        !isPreview && 'md2:max-w-none',
      )}
    >
      <Text className="text-center font-title text-[22px] font-medium text-white">
        {dropEnded ? (isLateRSVPEnabled ? 'Get Access' : 'Join my vault') : 'Get Notified'}
      </Text>

      <form onSubmit={onNextClick} className="mt-4 box-border flex w-full items-center">
        <View className="box-content flex w-full flex-col rounded-l-full border border-solid border-white/20 py-4">
          <View className="relative box-border flex items-center overflow-hidden">
            <Popover.Root open={hasError}>
              <Popover.Anchor asChild>
                <Select
                  value={COUNTRY_CODES.find(({ code }) => code === countryCode)?.code ?? ''}
                  onValueChange={setCountryCode}
                  itemProps={{
                    className:
                      'bg-transparent text-black hover:bg-transparent focus:bg-transparent',
                  }}
                  className="w-[4.5em] bg-transparent p-0 font-base !text-base-l font-normal text-white hover:bg-transparent"
                  contentClassName="w-[5em] border border-solid border-white/20 bg-black/90 backdrop-blur-2xl"
                  onOpenChange={open => {
                    setIsOpen(open);
                    setErrorText(null);
                  }}
                  disabled={status === 'sending-code' || disabled}
                  shouldHideIcon
                >
                  {COUNTRY_CODES.map(({ code, flag, dial_code }) => (
                    <Item
                      key={code}
                      value={code}
                      className="box-border flex w-[5em] flex-row justify-center bg-transparent py-0 pl-0 font-base !text-base-l font-normal text-white hover:bg-transparent focus:bg-transparent"
                      dropDownClassName="box-border justify-center overflow-x-clip rounded-sm bg-transparent pr-1 font-base !text-base-l font-normal hover:bg-transparent focus:bg-transparent"
                    >
                      <Text className="mx-2 text-[24px]">{flag}</Text>
                      <Text className="pr-2 text-[12px] md2:pr-0">{dial_code}</Text>
                    </Item>
                  ))}
                </Select>
              </Popover.Anchor>
              <Popover.Portal>
                <Popover.Content
                  side="top"
                  sideOffset={26}
                  align="start"
                  className="z-bottomsheet box-border flex max-w-[320px] flex-col self-center rounded-lg bg-destructive300 p-1 px-2 outline-none will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade md2:w-full md2:max-w-none"
                >
                  <Text className="p-3 text-left font-base !text-base-m font-normal text-white">
                    {errorText}
                  </Text>

                  <Popover.Arrow className="fill-destructive300" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>

            <input
              type="tel"
              value={phone}
              placeholder="Your number"
              onChange={e => {
                onChange(e);
                setErrorText(null);
              }}
              disabled={status === 'sending-code' || disabled}
              autoFocus
              className={twMerge(
                'w-full border-none bg-transparent font-base !text-base-l font-normal placeholder:text-white/40 focus:border-none focus:outline-none',
                hasError ? 'text-destructive300' : 'text-white',
              )}
            />
          </View>
        </View>

        <Button
          label={dropEnded ? 'Join' : 'RSVP'}
          type="primary"
          buttonType="submit"
          loading={isSubmitLoading}
          disabled={isSubmitDisabled}
          disabledClassName="opacity-30"
          className="w-full max-w-[80px] !rounded-l-none border border-solid border-yellow100 md2:w-[112px] md2:max-w-[112px]"
          event={{ type: EVENTS.NEXT, properties: { type: 'Sign In' } }}
        />
      </form>

      <Text className="mt-4 w-full text-center font-base text-[12px]/[14px] font-normal text-white/40">
        By signing up, you agree
        <br />
        to the{' '}
        <a
          href={disabled ? undefined : TOS_URL}
          target="_blank"
          className={twMerge(
            'text-yellow100 no-underline',
            disabled ? 'hover:cursor-text' : 'hover:cursor-pointer',
          )}
        >
          Terms
        </a>{' '}
        &{' '}
        <a
          href={disabled ? undefined : PRIVACY_POLICY_URL}
          target="_blank"
          className={twMerge(
            'text-yellow100 no-underline',
            disabled ? 'hover:cursor-text' : 'hover:cursor-pointer',
          )}
        >
          Privacy Policy
        </a>
        .
      </Text>
    </View>
  );
}
