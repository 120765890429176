import { gql } from '@soundxyz/gql-string';
import { useInfiniteQuery } from '../../graphql/client';
import { SortDirection } from '../../graphql/generated';
import { ArtistEventsDocument } from '../../graphql/generated/documents';

gql(/* GraphQL */ `
  fragment RsvpEventItem on RsvpEvent {
    id
    __typename
    linkValue

    ... on PublicRsvpEvent {
      title
      description
      eventDate
      status
      userRsvp {
        id
      }
      coverImage {
        id
        rsvpCoverImageUrl: imageOptimizedUrl
      }
      artist {
        id
        isAuthUserAdmin
        linkValue
        profileImage {
          id
          artistFullProfileImageUrl: imageOptimizedUrl
        }
      }
    }

    ... on RsvpEventPrivateInfo {
      title
      description
      eventDate
      status
      createdAt
      coverImage {
        id
        rsvpCoverImageUrl: imageOptimizedUrl
      }
      artist {
        id
        isAuthUserAdmin
        linkValue
        profileImage {
          id
          artistFullProfileImageUrl: imageOptimizedUrl
        }
      }
      releaseAnnouncement {
        id
        content
        status
      }
      receiptCount
    }
  }

  fragment ArtistEvent on ArtistEvent {
    id
    createdAt
    artist {
      id
      linkValue
      profileImage {
        id
        artistFullProfileImageUrl: imageOptimizedUrl
      }
    }

    ... on ArtistRsvpEvent {
      id
      __typename
      createdAt
      rsvpEvent {
        id
        __typename
        linkValue
        ...RsvpEventItem
      }
    }

    ... on ArtistReleaseCampaignEvent {
      id
      __typename
      createdAt
      releaseCampaign {
        id
        __typename
        ... on ReleaseCampaignPrivateInfo {
          receiptCount
        }
        ...ReleaseCampaignEventItem
      }
    }
  }

  query ArtistEvents(
    $artistHandle: String!
    $after: String
    $first: Int!
    $artistEventType: ArtistEventType
    $sort: SortDirection!
  ) {
    artistEventsByArtistHandle(
      artistHandle: $artistHandle
      artistEventType: $artistEventType
      first: $first
      after: $after
      sort: $sort
    ) {
      edges {
        node {
          id
          createdAt
          ...ArtistEvent
          ...FilterMembersEventItem
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

export const useArtistEvents = ({
  artistHandle,
  limit = 15,
  sort = SortDirection.Desc,
  source,
}: {
  artistHandle?: string;
  limit?: number;
  sort?: SortDirection;
  source: 'events_page' | 'membership_page' | 'mass_message_filter';
}) => {
  const {
    orderedList: artistEvents,
    isInitialLoading,
    isError,
    refetch,
    hasNextPage,
    loadMoreNextPage,
    isLoadingNewPage,
    isRefetching,
  } = useInfiniteQuery(ArtistEventsDocument, {
    filterQueryKey: { artistHandle, source },
    staleTime: 0,
    enabled: !!artistHandle,
    getNextPageParam: ({ data }) => {
      return (
        data.artistEventsByArtistHandle.pageInfo.hasNextPage && {
          after: data.artistEventsByArtistHandle.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      (({ pageParam }) => {
        return {
          artistHandle,
          after: pageParam?.after ?? null,
          first: limit,
          sort,
        };
      }),

    list: ({ artistEventsByArtistHandle }) => {
      return artistEventsByArtistHandle.edges.map(edge => edge.node);
    },
    uniq: ({ id }) => id,
  });

  return {
    artistEvents,
    isInitialLoading,
    isError,
    refetch,
    hasNextPage,
    loadMoreNextPage,
    isLoadingNewPage,
    isRefetching,
  };
};
