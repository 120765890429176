import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { View } from '../../../components/common/View';
import { FilterMembersView } from '../../../components/massMessage/FilterMembersView';
import { ROUTES } from '../../../constants/routeConstants';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useOwnerOnlyAccess } from '../../../hooks/useOwnerOnlyAcess';
import { useWindow } from '../../../hooks/useWindow';

export function FilterMembersScreen() {
  const { artistHandle } = useArtistHandle();
  const { isDesktop } = useWindow();
  const navigate = useNavigate();

  useOwnerOnlyAccess();

  useEffect(() => {
    if (isDesktop) {
      navigate(-1);
    }
  }, [isDesktop, navigate]);

  if (!artistHandle) {
    return <Navigate to={ROUTES.NOT_FOUND} replace />;
  }

  return (
    <View className="flex h-full w-full flex-col">
      <FilterMembersView artistHandle={artistHandle} />
    </View>
  );
}
