import { Navigate } from 'react-router-dom';
import { faClose } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BackButton } from '../../../components/buttons/BackButton';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { ViewHeader } from '../../../components/common/ViewHeader';
import { PreviewMassMessageView } from '../../../components/massMessage/PreviewMassMessageView';
import { FullPageLoading } from '../../../components/views/FullPageLoading';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useMassMessageForm } from '../../../hooks/message/useMassMessageForm';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { LoginStatus } from '../../../types/authTypes';

export function MassMessagePreviewPage() {
  const { loggedInUser, loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();

  const artist = loggedInUser?.adminArtists?.find(({ artistLinks }) =>
    artistLinks.includes(artistHandle ?? ''),
  );

  const { fields } = useMassMessageForm({ vaultId: artist?.artistMainVaultId });

  if (loginStatus === LoginStatus.LOADING) {
    return <FullPageLoading withVaultTheme />;
  }

  if (artist == null) {
    return <Navigate to={ROUTES.NOT_FOUND} replace />;
  }

  return (
    // Where 300px is the menu width
    <View className="relative z-above2 box-border flex h-full flex-col items-center bg-vault_background md2:ml-[300px] md2:w-[calc(100%-300px)] md2:px-2">
      <View className="mt-1 box-border flex h-full w-full flex-col md2:mt-2 md2:rounded-t-[20px] md2:border md2:border-solid md2:border-vault_text/10">
        <ViewHeader
          className="box-border py-5 md2:px-2 md2:pb-6 md2:pt-8"
          left={<BackButton withVaultTheme icon={faClose} className="text-vault_text" />}
          center={
            <Text className="font-title text-[18px]/[22px] font-medium text-vault_text md2:text-[22px]/[26px]">
              Message preview
            </Text>
          }
        />
        <View className="flex w-full flex-1 flex-col items-center justify-center pb-4">
          <PreviewMassMessageView
            message={fields.message}
            artistName={artist?.artistName ?? ''}
            artistProfileImageUrl={artist.artistProfileImage?.artistSmallProfileImageUrl}
          />
        </View>
      </View>
    </View>
  );
}
