import type { FC, ReactNode } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { EVENTS } from '../../types/eventTypes';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';

type Props = {
  title?: string;
  customTitle?: ReactNode;
  subtitle?: string;
  customSubtitle?: ReactNode;
  buttonText?: string;
  onButtonClick?: () => void;
  buttonHref?: string;
  className?: string;
  icon?: IconProp;
  iconClassName?: string;
  image?: string;
  imageClassName?: string;
  buttonLeadingIcon?: IconProp;
  buttonClassName?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  withVaultTheme?: boolean;
};

export const EmptyStateView: FC<Props> = ({
  title,
  customTitle,
  subtitle,
  customSubtitle,
  buttonText,
  onButtonClick,
  buttonHref,
  className,
  icon,
  iconClassName,
  image,
  imageClassName,
  buttonLeadingIcon,
  buttonClassName,
  titleClassName,
  subtitleClassName,
  withVaultTheme = false,
}) => {
  return (
    <View
      className={twMerge(
        'flex flex-1 flex-col items-center justify-center px-16 text-center',
        className,
      )}
    >
      {icon && <FontAwesomeIcon icon={icon} size="2x" className={twMerge('mb-2', iconClassName)} />}
      {image && <Image src={image} alt="Empty state" className={twMerge('mb-2', imageClassName)} />}
      {customTitle || (
        <Text
          className={twMerge(
            'font-title text-[22px]',
            withVaultTheme && 'text-vault_text',
            titleClassName,
          )}
        >
          {title}
        </Text>
      )}
      {customSubtitle || (
        <Text
          className={twMerge(
            'mt-2 font-normal text-neutral400',
            withVaultTheme && 'text-vault_text opacity-50',
            subtitleClassName,
          )}
        >
          {subtitle}
        </Text>
      )}
      <View className="mt-4 flex w-full flex-row justify-center">
        {buttonText && (onButtonClick || buttonHref) && (
          <Button
            type={withVaultTheme ? 'primary-themed' : 'primary'}
            label={buttonText}
            onClick={onButtonClick}
            href={buttonHref}
            event={{ type: EVENTS.EMPTY_VIEW, properties: null }}
            leadingIcon={buttonLeadingIcon}
            className={buttonClassName}
          />
        )}
      </View>
    </View>
  );
};
