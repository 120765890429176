import { type LegacyRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { heardVaults, useAudioController } from '../../../audio/AudioController';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { type FragmentType, getFragment, TrackItemFragmentDoc } from '../../../graphql/generated';
import { useBatchedTracksViewed } from '../../../hooks/useBatchedTracksViewed';
import { useActiveSubscriptionFeatures } from '../../../hooks/useTierFeatures';
import { VaultThemeStore } from '../../../hooks/useVaultTheme';
import { getDurationAsTime } from '../../../utils/dateUtils';
import { formatDateString } from '../../../utils/textUtils';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

import { LargePlayIndicatorBars } from '../../track/PlayIndicatorBars';

gql(/* GraphQL */ `
  fragment TrackItem on VaultTrack {
    id
    title
    linkValue
    createdAt

    vaultId
    duration
    commentMessageCount
    parentVaultContentId

    isFullVersionAvailable

    featureAccess {
      feature {
        __typename
      }
    }
    downloadEnabled

    vault {
      id
      isUserArtistAdmin
      artist: artistProfile {
        id
        name
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
        membershipCardImage {
          id
          membershipCardImageUrl: imageOptimizedUrl
        }
      }
      activeSubscription {
        id
        createdAt
        ...ActiveSubscriptionFeatures
      }
    }
  }
`);
export const TrackItem = ({
  track,
  containerRef,
  onPlayClick,
  hasEllipsis,
}: {
  track: FragmentType<TrackItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  onPlayClick?: () => void;
  hasEllipsis?: boolean;
}) => {
  const {
    title,
    createdAt,
    duration,
    id: trackId,
    vault,
    isFullVersionAvailable,
  } = getFragment(TrackItemFragmentDoc, track);

  const { isVaultCustomizeOpen } = useMenuContainer();

  const vaultTheme = useSnapshot(VaultThemeStore);

  const { activeTrackId, playing } = useAudioController();

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });

  const { isViewed, isLoading } = useBatchedTracksViewed({ vaultContentId: trackId });

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = !isOwner && activeSubscriptionFeatures?.tier == null;
  const isActiveTrack = activeTrackId === trackId;
  const heardVaultIds = useSnapshot(heardVaults).vaultIds;
  const isPlaying = playing && isActiveTrack;

  const couldBeNew =
    !isOwner &&
    !heardVaultIds.has(trackId) &&
    vault.activeSubscription != null &&
    vault.activeSubscription.createdAt < createdAt;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsNew((couldBeNew && isViewed === false) || isVaultCustomizeOpen);
  }, [couldBeNew, isLoading, isVaultCustomizeOpen, isViewed]);

  return (
    <View
      className="group flex w-full flex-grow cursor-pointer select-none flex-col gap-3 pb-3 text-left"
      containerRef={containerRef}
      onClick={onPlayClick}
    >
      <View className="relative flex min-h-[110px] w-full items-center justify-center rounded-md bg-vault_text/10 transition-all duration-300 ease-in-out group-hover:bg-vault_text/20">
        <AnimatePresence>
          {isNew && (
            <motion.div
              className="absolute right-3 top-2 rounded-full font-base !text-base-xs font-semibold text-vault_accent"
              exit={{ opacity: 0 }}
            >
              New
            </motion.div>
          )}
        </AnimatePresence>
        <View className="relative flex h-[74px] items-start justify-center">
          <View
            className={twMerge(
              'h-[74px] w-[54px] bg-contain bg-no-repeat',
              vaultTheme.mode === 'light'
                ? 'bg-new-file-background-black'
                : 'bg-new-file-background-white',
            )}
          />
          <View className="absolute top-5 flex flex-col items-center justify-center gap-1.5">
            {isActiveTrack ? (
              <LargePlayIndicatorBars isPaused={!isPlaying} sharedBarClassName="bg-vault_accent" />
            ) : (
              <FontAwesomeIcon icon={faWaveformLines} className="text-[24px] text-vault_text" />
            )}

            {isLocked ? (
              <FontAwesomeIcon icon={faLock} className="text-[11px] text-vault_text" />
            ) : isFullVersionAvailable ? null : (
              <Text className="text-[10px] font-medium text-vault_text/50">Snippet</Text>
            )}
          </View>
        </View>
      </View>
      <View className="flex flex-col gap-1">
        <View className="flex w-full justify-between gap-1">
          <Text className="line-clamp-2 text-ellipsis  break-words font-base !text-base-m font-medium text-vault_text">
            {title ?? 'Untitled'}
          </Text>
          {!!hasEllipsis && <div className="h-5 w-5 flex-shrink-0" />}
        </View>
        <Text className="text-[12px] font-normal leading-[16px] text-vault_text/50">
          {formatDateString({ date: createdAt, format: 'numerical_month_day_year' })}
          &nbsp;&middot;&nbsp;
          {getDurationAsTime(duration)}
        </Text>
      </View>
    </View>
  );
};
