import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { BubbleArtistFragmentDoc, type FragmentType, getFragment } from '../../graphql/generated';
import { pluralizeText } from '../../utils/textUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { ArtistProfileImage } from './ArtistProfileImage';

export const ArtistBubble: FC<{
  artist: FragmentType<BubbleArtistFragmentDoc>;
  className?: string;
  isSubscribed?: boolean;
}> = ({ artist, className, isSubscribed = false }) => {
  const {
    name,
    profileImage,
    linkValue,
    mainVault: { contentCount },
  } = getFragment(BubbleArtistFragmentDoc, artist);
  return (
    <Link
      to={`/${linkValue}`}
      className={twMerge(
        'aspect-h-1 aspect-w-[0.93] relative w-full overflow-clip rounded-[20px] text-[unset] no-underline',
        className,
      )}
    >
      <View>
        <ArtistProfileImage
          className="z-below h-full w-full rounded-none object-cover object-center"
          profileImageUrl={profileImage?.artistFullProfileImageUrl}
        />
      </View>
      <View>
        <View className="absolute bottom-0 z-above1 box-border flex w-full flex-col bg-neutral800 bg-opacity-80 p-[16px] backdrop-blur-xl">
          <View className="flex w-full flex-row justify-between">
            <View className="flex flex-col">
              <Text className="font-title text-title-m font-medium text-white">{name}</Text>
              <Text className="font-base text-base-l font-normal text-base400">
                {contentCount} {pluralizeText({ text: 'track', count: contentCount })}
              </Text>
            </View>
            <Button
              label={isSubscribed ? 'View' : 'Subscribe'}
              className={twMerge(
                'px-[12px] py-[8px] text-[14px] md2:hidden',
                isSubscribed && 'px-4',
              )}
              type="primary"
            />
          </View>
        </View>
      </View>
    </Link>
  );
};

export const SkeletonArtistBubble: FC<{ className?: string }> = ({ className }) => {
  return (
    <LoadingSkeleton
      className={twMerge(
        'aspect-h-1 aspect-w-[0.93] relative w-full overflow-clip rounded-[20px] text-[unset] no-underline',
        className,
      )}
    >
      <LoadingSkeleton className="h-full w-full rounded-none bg-neutral700" />
    </LoadingSkeleton>
  );
};
