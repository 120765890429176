import { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from 'date-fns';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { faCalendarDays, faMegaphone, faMobile } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faMegaphone as faMegaphoneRegular } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPenToSquare } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { Image } from '../../../components/common/Image';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { ErrorView } from '../../../components/error/ErrorView';
import { ListDetailLayout } from '../../../components/layouts/ListDetailLayout';
import { LoadingSkeleton } from '../../../components/loading/LoadingSkeleton';
import { MassMessageInsightsView } from '../../../components/massMessage/MassMessageInsightsView';
import { EmptyStateView } from '../../../components/views/EmptyStateView';
import { FullPageLoading } from '../../../components/views/FullPageLoading';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import {
  type FragmentType,
  getFragment,
  MassMessageRowFragmentDoc,
} from '../../../graphql/generated';
import { usePaginatedVaultAnnouncements } from '../../../hooks/announcements/usePaginatedVaultAnnouncements';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useOwnerOnlyAccess } from '../../../hooks/useOwnerOnlyAcess';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { useWindow } from '../../../hooks/useWindow';
import { LoginStatus } from '../../../types/authTypes';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { pluralizeText } from '../../../utils/textUtils';

gql(/* GraphQL */ `
  fragment MassMessageRow on Announcement {
    id
    content
    scheduledAt
    status
    featureAccess {
      feature {
        __typename
      }
    }
    memberCount
    rsvpEvent {
      __typename
      id
      title
      ... on RsvpEventPrivateInfo {
        coverImage {
          id
          smallRsvpEventCoverImage: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
      }
    }
    vault {
      id
      artist: artistProfile {
        id
        name
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
      }
    }
  }
`);

type SectionedMessage =
  | {
      sectionType: 'title';
      title: string;
    }
  | ({
      sectionType: 'message';
    } & FragmentType<typeof MassMessageRowFragmentDoc>);

export function MassMessagesPage() {
  const { artistHandle } = useArtistHandle();

  const { loginStatus } = useAuthContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const messageId = searchParams.get('messageId');

  const [selectedMessageId, setSelectedMessageId] = useState<string | null>(messageId ?? null);

  const {
    orderedList: announcements,
    refetch,
    isError,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = usePaginatedVaultAnnouncements({ artistHandle, enabled: !!artistHandle });

  const { isLg } = useWindow();

  useOwnerOnlyAccess();

  useVaultTheme();

  const sectionedMessages = useMemo(() => {
    const firstMessage = announcements[0];

    if (firstMessage == null) return [];

    const arr: SectionedMessage[] = announcements.map(message => {
      return {
        ...message,
        sectionType: 'message',
      };
    });

    if (new Date(firstMessage.scheduledAt) > new Date()) {
      arr.unshift({ sectionType: 'title', title: 'Scheduled' });
      const sentMessages = announcements.findIndex(
        ({ scheduledAt }) => new Date(scheduledAt) <= new Date(),
      );

      if (sentMessages !== -1) {
        arr.splice(sentMessages + 1, 0, { sectionType: 'title', title: 'Sent' });
      }
    } else {
      arr.unshift({ sectionType: 'title', title: 'Sent' });
    }

    return arr;
  }, [announcements]);

  useEffect(() => {
    if (!isLg) return;

    if (announcements.length === 0) return;

    if (messageId != null && announcements.some(a => a.id === messageId)) {
      setSelectedMessageId(messageId);
      setSearchParams({}, { replace: true });
      return;
    }

    if (selectedMessageId == null || !announcements.some(a => a.id === selectedMessageId)) {
      setSelectedMessageId(announcements[0]?.id ?? null);
    }
  }, [announcements, isLg, messageId, selectedMessageId, setSearchParams]);

  const Footer = useCallback(() => <View className="h-10" />, []);

  const selectedMessage = useMemo(() => {
    if (selectedMessageId == null) return null;

    return announcements.find(a => a.id === selectedMessageId);
  }, [announcements, selectedMessageId]);

  const EmptyPlaceholder = useCallback(() => {
    if (isLoading || loginStatus === LoginStatus.LOADING) {
      return (
        <View className="flex h-full w-full flex-col">
          <SkeletonArtistAnnouncementRow />
          <SkeletonArtistAnnouncementRow />
          <SkeletonArtistAnnouncementRow />
        </View>
      );
    }

    if (isError || loginStatus === LoginStatus.LOGGED_OUT) {
      return (
        <View className="h-full w-full">
          <ErrorView withVaultTheme onRetryClick={refetch} className="h-full w-full md2:w-full" />
        </View>
      );
    }

    return (
      <View className="h-full w-full">
        <EmptyStateView
          withVaultTheme
          className="box-border h-full w-full px-8 md2:w-full"
          title="Connect with your fans!"
          subtitle="Send a text blast to your fans about a new song, upcoming show or just to connect"
          buttonHref={artistNavigationPath(artistHandle, '/messages/create')}
          buttonLeadingIcon={faMegaphoneRegular}
          buttonText="Create text blast"
          buttonClassName="mt-4 font-normal"
          icon={faMobile}
          iconClassName="text-[60px]/[60px] mb-8"
        />
      </View>
    );
  }, [artistHandle, isError, isLoading, loginStatus, refetch]);

  const renderItem = useCallback(
    (_index: number, item: SectionedMessage) => {
      if (!artistHandle) return null;

      if (item.sectionType === 'title') {
        const paddingTop = _index === 0 ? 'md2:pt-6 pt-4' : 'md2pt-[36px] pt-6';
        return (
          <Text
            className={twMerge(
              'pb-1 font-title text-[18px]/[22px] text-vault_text md2:px-3',
              paddingTop,
            )}
          >
            {item.title}
          </Text>
        );
      }

      const announcement = getFragment(MassMessageRowFragmentDoc, item);
      return (
        <ArtistAnnouncementRow
          announcement={item}
          artistHandle={artistHandle}
          isSelected={selectedMessageId === announcement.id}
          onSelect={setSelectedMessageId}
        />
      );
    },
    [artistHandle, selectedMessageId],
  );

  if (!artistHandle) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <ListDetailLayout>
      <ListDetailLayout.List showOnMobile>
        <ListDetailLayout.ListTitle
          title="Text blasts insights"
          actionButton={{
            label: '',
            iconOnly: true,
            leadingIcon: faPenToSquare,
            href: artistNavigationPath(artistHandle, '/messages/create'),
          }}
          titleOverride={null}
          withBackButton
          withDivider
        />

        <ListDetailLayout.ListContent>
          <Virtuoso
            itemContent={renderItem}
            data={sectionedMessages}
            className="no-scrollbar h-full w-full"
            endReached={hasNextPage && !isFetchingNextPage ? () => fetchNextPage() : undefined}
            components={{
              Footer,
              EmptyPlaceholder,
            }}
          />
        </ListDetailLayout.ListContent>
      </ListDetailLayout.List>

      <ListDetailLayout.Detail showOnMobile={false}>
        {selectedMessageId && announcements.length > 0 ? (
          <MassMessageInsightsView
            messageId={selectedMessageId}
            isOwnPage={false}
            artistHandle={artistHandle}
            prefetchedMessage={selectedMessage}
          />
        ) : (
          isLoading && (
            <View className="flex h-full w-full">
              <FullPageLoading withVaultTheme className="bg-transparent" />
            </View>
          )
        )}
      </ListDetailLayout.Detail>
    </ListDetailLayout>
  );
}

function ArtistAnnouncementRow({
  announcement,
  artistHandle,
  isSelected,
  onSelect,
}: {
  announcement: FragmentType<MassMessageRowFragmentDoc>;
  artistHandle: string;
  isSelected: boolean;
  onSelect: (messageId: string) => void;
}) {
  const { isLg } = useWindow();
  const navigate = useNavigate();

  const {
    id,
    content,
    scheduledAt,
    memberCount,
    rsvpEvent,
    vault: { artist },
  } = getFragment(MassMessageRowFragmentDoc, announcement);

  const artistSmallProfileImageUrl = artist?.profileImage?.artistSmallProfileImageUrl;

  const coverImageUrl =
    rsvpEvent?.__typename === 'RsvpEventPrivateInfo'
      ? rsvpEvent.coverImage?.smallRsvpEventCoverImage
      : null;

  const imageUrl = coverImageUrl ?? artistSmallProfileImageUrl;

  const isRsvpMessage = rsvpEvent != null;

  const dateString = formatDate(scheduledAt, 'E MMM d, yyyy h:mma');

  const title = isRsvpMessage
    ? rsvpEvent.title
    : `${memberCount} ${pluralizeText({ count: memberCount, text: 'member' })}`;

  return (
    <View
      className={twMerge(
        'box-border flex w-full cursor-pointer flex-row gap-3 rounded-xl py-2 no-underline md2:my-1 md2:px-3 md2:hover:bg-vault_text/3',
        isSelected && 'md2:hover:bg-vault_text/3 lg:bg-vault_text/10 lg:hover:bg-vault_text/15',
      )}
      onClick={() => {
        if (!isLg) {
          navigate(artistNavigationPath(artistHandle, `/messages/insights/${id}`));
          return;
        }

        onSelect(id);
      }}
    >
      <View className="flex h-[60px] w-[60px] flex-col items-center justify-center rounded-full bg-vault_text/5">
        {isRsvpMessage && !!imageUrl ? (
          <Image className="h-[60px] w-[60px] rounded-full" alt="rsvp image" src={imageUrl} />
        ) : (
          <FontAwesomeIcon
            icon={isRsvpMessage ? faCalendarDays : faMegaphone}
            className="text-[24px]/[24px] text-vault_text"
          />
        )}
      </View>
      <View className="flex flex-1 flex-shrink flex-col gap-1">
        <Text className="line-clamp-1 font-title text-[16px]/[18px] font-medium text-vault_text">
          {title}
        </Text>
        <Text className="line-clamp-1 break-all font-base text-[16px]/[20px] font-normal text-vault_text">
          {content}
        </Text>
        <Text className="line-clamp-1 font-base text-[14px]/[18px] font-normal text-vault_text/50">
          {dateString}
        </Text>
      </View>
    </View>
  );
}

function SkeletonArtistAnnouncementRow() {
  return (
    <View className="box-border flex w-full flex-row gap-3 rounded-xl py-2 md2:my-1 md2:px-3">
      <LoadingSkeleton
        className="flex h-[60px] w-[60px] flex-col items-center justify-center rounded-full bg-vault_text/5"
        withVaultTheme
      />
      <View className="box-border flex flex-1 flex-shrink flex-col gap-1">
        <LoadingSkeleton className="h-[18px] w-[100px]" withVaultTheme />
        <LoadingSkeleton className="box-border h-[20px] w-full" withVaultTheme />
        <LoadingSkeleton className="h-[18px] w-[200px]" withVaultTheme />
      </View>
    </View>
  );
}
