import { gql } from '@soundxyz/gql-string';
import { useQuery } from '../graphql/client';
import { ArtistReferralCodeDocument } from '../graphql/generated';

gql(/* GraphQL */ `
  fragment referralCodeInfo on ArtistReferralCode {
    id
    artist {
      id
      name
      linkValue
      profileImage {
        id
        artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      }
      mainVault {
        id
        price
        activeSubscription {
          id
          ...ActiveSubscriptionFeatures
        }
      }
    }
    code
    description
    freeMonths
    validUntil
    isActive
    postPurchaseMessage
  }
  query ArtistReferralCode($input: QueryGetArtistReferralCodeByCodeInput!) {
    getArtistReferralCodeByCode(input: $input) {
      id
      ...referralCodeInfo
    }
  }
`);

export function useReferralLinks(code?: string | null) {
  const {
    data,
    error,
    isLoading: isLoadingReferralCode,
    refetch,
  } = useQuery(ArtistReferralCodeDocument, {
    staleTime: 0,
    variables: !!code && {
      input: { code },
    },
  });

  return {
    referralCodeData: data?.data.getArtistReferralCodeByCode,
    isLoadingReferralCode,
    error,
    refetch,
  };
}
