import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBellSlash,
  faCancel,
  faCog,
  faMessage,
  faMessageSlash,
  type IconDefinition,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  BanSubscriberDocument,
  GetSubscriberBanStatusDocument,
  GetUserChannelsDocument,
  GetUserToBanInformationDocument,
  UnbanSubscriberDocument,
} from '../../graphql/generated';
import { useAdminArtist } from '../../hooks/useAdminArtist';
import type { BanUserBottomsheetProps } from '../../types/bottomsheetTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserProfileImage } from '../user/UserProfileImage';

gql(/* GraphQL */ `
  query GetUserToBanInformation($input: QueryVaultSubscriberByUserIdInput!) {
    vaultSubscriberByUserId(input: $input) {
      __typename
      ... on Error {
        message
      }
      ... on QueryVaultSubscriberByUserIdSuccess {
        data {
          id
          user {
            id
            avatar {
              id
              imageOptimizedUrl(input: { height: 200, width: 200 })
              dominantColor
            }
            username
            displayName
          }
        }
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [BanSubscriberDocument, UnbanSubscriberDocument],
  refetch: [
    GetUserToBanInformationDocument,
    GetUserChannelsDocument,
    GetSubscriberBanStatusDocument,
  ],
});

export function BanUserBottomsheet({ artistId, userId }: BanUserBottomsheetProps) {
  const { closeBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();
  const artist = useAdminArtist({ artistId });

  const {
    data: userToBan,
    isLoading: isLoadingUserToBan,
    isError: isErrorUserToBan,
    refetch: refetchUserToBan,
  } = useQuery(GetUserToBanInformationDocument, {
    variables: !!artist && { input: { userId, vaultId: artist?.artistMainVaultId } },
    staleTime: 0,
    select: data =>
      data.data.vaultSubscriberByUserId?.__typename === 'QueryVaultSubscriberByUserIdSuccess'
        ? data.data.vaultSubscriberByUserId.data.user
        : null,
  });

  const {
    data: isBanned,
    isLoading: isLoadingBanStatus,
    isError: isErrorBanStatus,
    refetch: refetchBanStatus,
  } = useQuery(GetSubscriberBanStatusDocument, {
    variables: !!artist && { userId, artistId: artist.artistId },
    staleTime: 0,
    select: data => data.data.getArtistSubscriberBanStatus,
  });

  const { mutateAsync: banSubscriberMutation, isLoading: isBanLoading } = useMutation(
    BanSubscriberDocument,
    {},
  );

  const { mutateAsync: unbanSubscriberMutation, isLoading: isUnBanLoading } = useMutation(
    UnbanSubscriberDocument,
    {},
  );

  const isLoading = isLoadingBanStatus || isLoadingUserToBan;
  const isError = isErrorBanStatus || isErrorUserToBan;
  const refetch = refetchUserToBan || refetchBanStatus;

  const userName = userToBan?.displayName || userToBan?.username || 'User';

  const onBanClick = useCallback(async () => {
    if (isBanned) return;

    const { data } = await banSubscriberMutation({
      input: {
        userId,
        artistId,
      },
    });

    if (data?.banSubscriberFromVault) {
      openToast({
        text: `${userName} has been banned`,
        variant: 'success',
      });
    } else {
      openToast({
        text: 'Failed to ban user. Please try again.',
        variant: 'error',
      });
    }

    closeBottomsheet();
  }, [artistId, banSubscriberMutation, closeBottomsheet, isBanned, openToast, userId, userName]);

  const onUnbanClick = useCallback(async () => {
    if (!isBanned) return;

    const { data } = await unbanSubscriberMutation({
      input: {
        userId,
        artistId,
      },
    });

    if (data?.unbanSubscriberFromVault) {
      openToast({
        text: `${userName} has been unbanned`,
        variant: 'success',
      });
    } else {
      openToast({
        text: 'Failed to unban user. Please try again.',
        variant: 'error',
      });
    }

    closeBottomsheet();
  }, [artistId, closeBottomsheet, isBanned, openToast, unbanSubscriberMutation, userId, userName]);

  if (isLoading) {
    return (
      <View className="flex h-full w-full flex-col items-center gap-8">
        <View className="flex flex-col items-center justify-center gap-4 pt-5 md2:pt-0">
          <LoadingSkeleton className="h-[100px] w-[100px] shrink-0 rounded-full" withVaultTheme />
          <LoadingSkeleton className="h-[22px] w-[150px]" withVaultTheme />
        </View>
        <View className="flex w-full flex-col items-center gap-5">
          <View className="flex items-center gap-4">
            <LoadingSkeleton className="h-[20px] w-[20px]" withVaultTheme />
            <LoadingSkeleton className="h-[20px] w-[250px]" withVaultTheme />
          </View>
          <View className="flex items-center gap-4">
            <LoadingSkeleton className="h-[20px] w-[20px]" withVaultTheme />
            <LoadingSkeleton className="h-[20px] w-[250px]" withVaultTheme />
          </View>
          <View className="flex items-center gap-4">
            <LoadingSkeleton className="h-[20px] w-[20px]" withVaultTheme />
            <LoadingSkeleton className="h-[20px] w-[250px]" withVaultTheme />
          </View>
        </View>
        <View className="flex w-full flex-col gap-4 pb-4">
          <LoadingSkeleton className="h-[52px] w-full rounded-full" withVaultTheme />
          <LoadingSkeleton className="h-[52px] w-full rounded-full" withVaultTheme />
        </View>
      </View>
    );
  }

  if (isError || userToBan == null) {
    // Return error state
    return (
      <View className="flex h-full w-full flex-col items-center gap-8">
        <ErrorView onRetryClick={refetch} withVaultTheme />
      </View>
    );
  }

  return (
    <View className="flex h-full w-full flex-col items-center gap-8">
      <View className="flex flex-col items-center justify-center gap-4 pt-5 md2:pt-0">
        <UserProfileImage
          profileImageUrl={userToBan.avatar?.imageOptimizedUrl}
          className="h-[100px] w-[100px] shrink-0 rounded-full"
          fallbackColor={userToBan.avatar?.dominantColor}
          withVaultTheme
        />

        <Text className="line-clamp-1 font-title text-[18px]/[22px] font-medium text-vault_text">
          {isBanned ? 'Unban' : 'Ban'} {userName}?
        </Text>
      </View>
      <View className="flex flex-col gap-5">
        {isBanned ? (
          <>
            <InfoRow
              icon={faMessage}
              text="Everyone will see their messages going forward in your Vault"
            />
            <InfoRow
              icon={faBellSlash}
              text="They won’t be notified that you
unbanned them"
            />
          </>
        ) : (
          <>
            <InfoRow icon={faMessageSlash} text="No one will see messages from this user" />
            <InfoRow icon={faBellSlash} text="They won't be notified that you banned them" />
            <InfoRow icon={faCog} text="You can unban them in settings" />
          </>
        )}
      </View>

      <View className="flex w-full flex-col gap-4 pb-4">
        {isBanned ? (
          <Button
            label="Unban"
            type="primary-themed"
            className="w-full rounded-full px-[28px] py-4 font-title text-[16px]/[20px] font-medium"
            onClick={onUnbanClick}
            loading={isUnBanLoading}
          />
        ) : (
          <>
            <Button
              label="Ban member"
              leadingIcon={faCancel}
              className="w-full rounded-full bg-vault_text/10 px-[28px] py-4 font-title text-[16px]/[20px] font-medium text-destructive300"
              onClick={onBanClick}
              loading={isBanLoading}
            />

            <Button
              label="Not now"
              className="w-full rounded-full bg-transparent px-[28px] py-4 font-title text-[16px]/[20px] font-medium text-vault_text"
              onClick={closeBottomsheet}
              disabled={isBanLoading}
            />
          </>
        )}
      </View>
    </View>
  );
}

function InfoRow({ icon, text }: { icon: IconDefinition; text: string }) {
  return (
    <View className="flex items-center gap-4">
      <FontAwesomeIcon icon={icon} className="text-[20px] text-vault_text" />
      <Text className="text-left font-base text-[16px]/[20px] text-vault_text/50">{text}</Text>
    </View>
  );
}
