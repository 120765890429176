import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import millify from 'millify';
import { twMerge } from 'tailwind-merge';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { MessageReactionType } from '../../graphql/generated';
import { PlayButtonView } from '../audioPlayer/PlayButtonView';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { UserProfileImage } from '../user/UserProfileImage';
import { MessageReactionButton } from './MessageReactionRow';

export const MockMessageBubble = ({
  className,
  author,
  content,
  profileImageUrl,
  reactionSummary,
  isVaultArtist,
  hasVaultContent,
}: {
  className?: string;
  author: string | undefined;
  content: string;
  profileImageUrl: string | undefined | null;
  reactionSummary: { HEART: number; THUMBS_UP: number; FIRE: number };
  isVaultArtist: boolean;
  hasVaultContent: boolean;
}) => {
  return (
    <View
      className={twMerge(
        'mx-4 flex w-full select-none flex-row items-end justify-start',
        className,
      )}
    >
      <UserProfileImage
        profileImageUrl={profileImageUrl}
        className="mr-[4px] h-[30px] cursor-pointer"
        withVaultTheme
      />
      <View
        className={twMerge(
          'flex max-w-[75%] translate-y-2 flex-col gap-1.5 md2:max-w-[430px]',
          hasVaultContent && 'w-[75%]',
        )}
      >
        <View className="box-border flex flex-col break-words rounded-xl bg-vault_text/20 p-3">
          <Text className="m-0 pb-1 font-base !text-base-s font-semibold text-vault_text">
            {author || 'Anonymous'}
            {isVaultArtist && (
              <span>
                {' '}
                <FontAwesomeIcon
                  icon={faBadgeCheck}
                  className="select-none text-[12px] text-vault_accent"
                />
              </span>
            )}
          </Text>

          {/* Render Text Content */}
          {content.length !== 0 && (
            <Text className="m-0 select-none whitespace-pre-line break-words font-base !text-base-m font-normal text-vault_text">
              {content}
            </Text>
          )}

          {/* Render Vault Content */}
          {hasVaultContent && (
            <View className="mb-0 mt-2 box-border min-h-[110px] w-full">
              <View className="my-1 box-border flex flex-col rounded-md bg-vault_background/50 p-4">
                <View className="flex flex-row items-start">
                  <View className="box-border flex max-w-full flex-1 flex-col">
                    <View className="mr-3 flex flex-row items-center justify-start gap-1">
                      <Text className="mr-1 line-clamp-1 font-base !text-base-m font-semibold text-vault_text">
                        untitled.wav
                      </Text>
                      <View
                        className={twMerge(
                          'rounded-full px-1.5 py-0.5 font-base !text-base-xs font-semibold',
                          'bg-vault_accent text-vault_accent_text',
                        )}
                      >
                        New
                      </View>
                    </View>

                    <View className="font-base text-[11px]/[14px] text-vault_text/60">3:34</View>
                  </View>
                  <PlayButtonView isPlaying={false} isDisabled className="text-vault_text" />
                </View>

                <View className="mt-2 flex w-full items-center gap-4">
                  <MockVaultContent />
                </View>
              </View>
            </View>
          )}
        </View>
        {/* Render Reaction Summary */}
        <MockMessageReactionRow reactionSummary={reactionSummary} />
      </View>
    </View>
  );
};

const MockVaultContent = () => {
  const [waveformPeaks, setWaveformPeaks] = useState<number[]>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const isDesktop = currentWidth >= 768;
      const wasDesktop = windowWidth >= 768;

      if ((isDesktop && !wasDesktop) || (!isDesktop && wasDesktop)) {
        setWaveformPeaks(generateWaveformPeaks(isDesktop ? 80 : 65));
        setWindowWidth(currentWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    setWaveformPeaks(generateWaveformPeaks(windowWidth >= 768 ? 80 : 60));

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  const generateWaveformPeaks = (count: number) => {
    const peaks = [];
    for (let i = 0; i < count; i++) {
      peaks.push(Math.floor(Math.random() * 15) + 5);
    }
    return peaks;
  };

  return (
    <View className="mt-[10px] flex w-full flex-row items-center gap-4">
      <View className="flex flex-1 flex-row items-center justify-between">
        {waveformPeaks.map((peaks, i) => (
          <div
            key={i}
            className="w-[2px] rounded-full bg-vault_text/30"
            style={{
              height: peaks,
            }}
          />
        ))}
      </View>
    </View>
  );
};

const MockMessageReactionRow = ({
  reactionSummary,
}: {
  reactionSummary: { HEART: number; THUMBS_UP: number; FIRE: number };
}) => {
  return (
    <View className="relative -top-2 bottom-2 mx-2 flex select-none flex-row gap-1">
      {reactionSummary.HEART > 0 && (
        <MessageReactionButton
          reactionType={MessageReactionType.Heart}
          label={millify(reactionSummary.HEART)}
          emoji="❤️"
          artistProfileImageUrl={null}
          isArtistReaction={false}
          isMyReaction={false}
          disabled
          onClick={() => null}
        />
      )}
      {reactionSummary.THUMBS_UP > 0 && (
        <MessageReactionButton
          reactionType={MessageReactionType.ThumbsUp}
          label={millify(reactionSummary.THUMBS_UP)}
          emoji="👍"
          artistProfileImageUrl={null}
          isArtistReaction={false}
          isMyReaction={false}
          disabled
          onClick={() => null}
        />
      )}
      {reactionSummary.FIRE > 0 && (
        <MessageReactionButton
          reactionType={MessageReactionType.Fire}
          label={millify(reactionSummary.FIRE)}
          emoji="🔥"
          artistProfileImageUrl={null}
          isArtistReaction={false}
          isMyReaction={false}
          disabled
          onClick={() => null}
        />
      )}
    </View>
  );
};
