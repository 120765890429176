import type { FC } from 'react';
import React from 'react';
import {
  ERROR_TYPE,
  GLOBAL_ERROR_ACTIONS,
  PILLARS,
} from '@soundxyz/vault-logs-utils/dist/constants';
import { BackButton } from '../components/buttons/BackButton';
import { Button } from '../components/buttons/Button';

import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { useAuthContext } from '../contexts/AuthContext';
import { useToast } from '../contexts/ToastContext';
import { logError } from '../hooks/logger/useLogError';

export const TestPage: FC = () => {
  const { loggedInUser } = useAuthContext();

  const { openToast } = useToast();
  const triggerError = () => {
    logError({
      error: new Error('Test error'),
      level: 'error',
      errorType: ERROR_TYPE.UNKNOWN,
      message: 'Failed to notify played content',
      indexedTags: {
        loggedInUserId: loggedInUser?.id,
      },
      action: GLOBAL_ERROR_ACTIONS.GLOBAL_PAGE_CRASH,
      pillar: PILLARS.GLOBAL,
      toast: 'Error triggered',
      openToast,
    });
  };

  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      showBorder
      withBottomNavigator={false}
      headerLeft={<BackButton href="/" />}
      contentClassName="md2:bg-white/3"
    >
      <div className="flex flex-col items-center gap-10">
        <h2>Test</h2>
        <Button label="Trigger Error" onClick={triggerError} type="primary" />
      </div>
    </DefaultLayout>
  );
};
