import { datadogRum } from '@datadog/browser-rum-slim';
import type { RequireAtLeastOne } from '@soundxyz/utils';
import type { Feature, InfoAction, Pillar } from '@soundxyz/vault-logs-utils/constants';
import { logger } from '../../utils/logger';

type LogInfoProps = {
  // label for the event that is necessary for data dog
  action: InfoAction;
  message: string;
  data: Record<string, unknown>;
  // identifier for the pillar (set of pages / flow) this error comes from or feature (non pillar)
} & RequireAtLeastOne<{ pillar?: Pillar; feature?: Feature }>;

export const logInfo = ({ action, message, data, pillar }: LogInfoProps) => {
  logger.info({ action, ...data }, message);

  datadogRum.addAction(action, {
    action,
    message,
    level: 'info',
    pillar,
    ...data,
  });
};

export function useLogInfo(): (arg: LogInfoProps) => void {
  return logInfo;
}
