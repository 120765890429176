import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import millify from 'millify';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';
import { faGripLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faVideoCamera } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCamera } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  FolderItemFragmentDoc,
  type FragmentType,
  getFragment,
  ImageItemFragmentDoc,
  TrackItemFragmentDoc,
  VaultItemFragmentDoc,
  VideoItemFragmentDoc,
} from '../../../graphql/generated';
import { VaultThemeStore } from '../../../hooks/useVaultTheme';
import { getDurationAsTime } from '../../../utils/dateUtils';
import { formatDateString, pluralizeText } from '../../../utils/textUtils';
import { Image } from '../../common/Image';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

export const VaultItemRow = ({
  item,
  isActive,
}: {
  item: FragmentType<VaultItemFragmentDoc>;
  isActive?: boolean;
}) => {
  const vaultTheme = useSnapshot(VaultThemeStore);

  const itemInfo = getFragment(VaultItemFragmentDoc, item);

  const getContent = () => {
    switch (itemInfo.__typename) {
      case 'VaultTrack': {
        const {
          title: trackTitle,
          createdAt,
          duration,
        } = getFragment(TrackItemFragmentDoc, itemInfo);
        return (
          <View className="flex items-center gap-3">
            <View className="relative flex h-[45px] w-[48px] items-center justify-center">
              <View
                className={twMerge(
                  'h-[45px] w-[36px] bg-contain bg-no-repeat',
                  vaultTheme.mode === 'light'
                    ? 'bg-new-file-background-black'
                    : 'bg-new-file-background-white',
                )}
              />
              <FontAwesomeIcon
                icon={faWaveformLines}
                fontSize="18"
                className="absolute text-vault_text"
              />
            </View>

            <View className="flex flex-col gap-1">
              <Text className="line-clamp-2 text-ellipsis font-base !text-base-m font-medium text-vault_text">
                {trackTitle ?? 'Untitled'}
              </Text>
              <Text className="!text-base-s font-normal text-vault_text/50">
                {formatDateString({ date: createdAt, format: 'numerical_month_day_year' })}
                &nbsp;&middot;&nbsp;
                {getDurationAsTime(duration)}
              </Text>
            </View>
          </View>
        );
      }
      case 'VaultFolder': {
        const {
          title: folderName,
          createdAt: folderCreatedAt,
          childrenVaultCount,
        } = getFragment(FolderItemFragmentDoc, itemInfo);
        return (
          <View className="flex items-center gap-3">
            <View className="h-[45px] w-[48px]">
              <View
                className={twMerge(
                  'h-[45px] w-[48px] bg-contain bg-no-repeat',
                  vaultTheme.mode === 'light'
                    ? 'bg-folder-background-black'
                    : 'bg-folder-background-white',
                )}
              />
            </View>

            <View className="flex flex-col gap-1">
              <Text className="line-clamp-2 text-ellipsis font-base !text-base-m font-medium text-vault_text">
                {folderName ?? 'Untitled'}
              </Text>
              <Text className="!text-base-s font-normal text-vault_text/50">
                {formatDateString({ date: folderCreatedAt, format: 'numerical_month_day_year' })}
                &nbsp;&middot;&nbsp;
                {millify(childrenVaultCount, { precision: 0 })}&nbsp;
                {pluralizeText({
                  count: childrenVaultCount,
                  text: 'file',
                })}
              </Text>
            </View>
          </View>
        );
      }
      case 'VaultImage': {
        const { title, createdAt, uploadedMedia } = getFragment(ImageItemFragmentDoc, itemInfo);
        return (
          <View className="flex items-center gap-3">
            <View className="relative flex h-[45px] w-[48px] items-center justify-center">
              {uploadedMedia != null ? (
                <Image
                  src={uploadedMedia.smallImageUrl ?? uploadedMedia.cdnUrl}
                  alt="image"
                  className="h-full w-full rounded-sm object-cover"
                />
              ) : (
                <>
                  <View
                    className={twMerge(
                      'h-[45px] w-[36px] bg-contain bg-no-repeat',
                      vaultTheme.mode === 'light'
                        ? 'bg-new-file-background-black'
                        : 'bg-new-file-background-white',
                    )}
                  />
                  <FontAwesomeIcon
                    icon={faCamera}
                    fontSize="18"
                    className="absolute text-vault_text"
                  />
                </>
              )}
            </View>

            <View className="flex flex-col gap-1">
              <Text className="line-clamp-2 text-ellipsis font-base !text-base-m font-medium text-vault_text">
                {title ?? 'Untitled'}
              </Text>
              <Text className="!text-base-s font-normal text-vault_text/50">
                {formatDateString({ date: createdAt, format: 'numerical_month_day_year' })}
              </Text>
            </View>
          </View>
        );
      }
      case 'VaultVideo': {
        const {
          title: videoName,
          createdAt: videoCreatedAt,
          duration,
          uploadedMedia,
        } = getFragment(VideoItemFragmentDoc, itemInfo);
        return (
          <View className="flex items-center gap-3">
            <View className="relative flex h-[45px] w-[48px] items-center justify-center">
              {uploadedMedia != null ? (
                <video
                  src={uploadedMedia.url}
                  className="h-full w-full rounded-sm object-cover"
                  onClick={e => e.preventDefault()}
                />
              ) : (
                <>
                  <View
                    className={twMerge(
                      'h-[45px]  w-[36px] bg-contain bg-no-repeat',
                      vaultTheme.mode === 'light'
                        ? 'bg-new-file-background-black'
                        : 'bg-new-file-background-white',
                    )}
                  />
                  <FontAwesomeIcon
                    icon={faVideoCamera}
                    fontSize="18"
                    className="absolute text-vault_text"
                  />
                </>
              )}
            </View>

            <View className="flex flex-col gap-1">
              <Text className="line-clamp-2 text-ellipsis font-base !text-base-m font-medium text-vault_text">
                {videoName ?? 'Untitled'}
              </Text>
              <Text className="!text-base-s font-normal text-vault_text/50">
                {formatDateString({ date: videoCreatedAt, format: 'numerical_month_day_year' })}
                &nbsp;&middot;&nbsp;
                {getDurationAsTime(duration)}
              </Text>
            </View>
          </View>
        );
      }
      default:
        return null;
    }
  };
  return (
    <View
      className={twMerge(
        'flex w-full flex-row items-center justify-between gap-5 rounded-md border border-solid p-1 px-4 py-3',
        'border-vault_text/5 bg-vault_background md2:bg-transparent',
        isActive ? 'opacity-10' : 'opacity-100',
      )}
    >
      {getContent()}

      <FontAwesomeIcon
        icon={faGripLines}
        fontSize="16"
        className={isActive ? 'text-vault_text' : 'text-vault_text/50'}
      />
    </View>
  );
};
