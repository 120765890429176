import { Fragment, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns-tz/format';
import millify from 'millify';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faUsers } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCalendar } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { formatSelectedDate, getZonedDate } from '../../../components/announcement/helpers';
import { useAnnouncementForm } from '../../../components/announcement/useAnnouncementForm';
import { BackButton } from '../../../components/buttons/BackButton';
import { Button } from '../../../components/buttons/Button';
import { LinkifyText } from '../../../components/common/LinkifyText';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ScheduledEventAccessFeatureInput } from '../../../graphql/generated';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useMySubscribers } from '../../../hooks/useMySubscribers';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { getFromList } from '../../../utils/arrayUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';

export function ReviewAnnouncement() {
  const navigate = useNavigate();
  const onBackClick = useStableCallback(() => {
    navigate(-1);
  });
  const { loggedInUser } = useAuthContext();
  const { artistHandle } = useArtistHandle();

  const adminArtist = useMemo(() => {
    if (!artistHandle) return undefined;
    return getFromList(loggedInUser?.adminArtists, artist => {
      const isMatch = artist.artistLinks?.includes(artistHandle) ?? false;
      return isMatch ? artist : false;
    });
  }, [loggedInUser?.adminArtists, artistHandle]);

  const artistId = adminArtist?.artistId;
  const vaultId = adminArtist?.artistMainVaultId;

  useVaultTheme();

  //get param artistHandle
  const { fields, enableSubmit, onSubmit, clearFields, isSubmitting } = useAnnouncementForm();
  const { subscribersSummary, isLoadingSummary } = useMySubscribers({
    vaultId,
    searchQueryString: undefined,
  });

  const sharedStyles = {
    iconItem: 'flex-row gap-3 !items-center',
    item: 'flex items-start self-stretch rounded-md border border-solid border-vault_text/5 p-4',
    mainText: 'font-base !text-base-l font-normal !text-vault_text leading-5',
    subText: 'font-base !text-base-l font-normal text-vault_text/50',
    textWrapper: 'flex flex-col gap-0.5',
  };

  const paidSubsCount =
    subscribersSummary?.data.mySubscribersSummary.activePaidSubscriptionsCount ?? 0;
  const activeSubsCount =
    subscribersSummary?.data.mySubscribersSummary.activeSubscriptionsCount ?? 0;

  const subscriberCount =
    fields.accessType === ScheduledEventAccessFeatureInput.FreeEvent
      ? activeSubsCount
      : paidSubsCount;

  const currDate = useMemo(() => new Date(), []);

  const timeZone = useMemo(() => {
    return format(getZonedDate(currDate), 'z');
  }, [currDate]);

  const formattedDate = formatSelectedDate({ date: fields.date });

  const onClickSubmit = useStableCallback(async () => {
    if (!artistHandle || !artistId) return;
    await onSubmit({
      artistHandle,
      artistId,
    });
    clearFields();
  });

  const buttonLabel = `${formattedDate === 'Now' ? 'Send' : 'Schedule'} SMS`;

  useEffect(() => {
    //redirect to create page if announcement is not set
    if (!fields.announcement && !isSubmitting) {
      navigate(artistNavigationPath(artistHandle, '/announcements/create'));
    }
  }, [artistHandle, fields, isSubmitting, navigate]);

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      headerLeft={<BackButton onClick={onBackClick} className="text-vault_text" />}
      headerRight={
        <button
          className="hidden appearance-none border-none bg-transparent outline-none hover:cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 disabled:active:opacity-70 md2:block"
          disabled={!enableSubmit}
          onClick={onClickSubmit}
        >
          <Text className="font-title !text-base-m font-medium text-vault_accent active:opacity-70">
            {buttonLabel}
          </Text>
        </button>
      }
      stretch
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">
          Review Announcement
        </Text>
      }
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5"
      contentClassName="md2:bg-vault_text/3"
    >
      <View className="flex h-full w-full max-w-[600px] flex-col justify-between gap-4 py-6">
        <View className="flex flex-col gap-4">
          <View className={twMerge(sharedStyles.item, 'min-h-56 p-3')}>
            <LinkifyText>
              <Text className={twMerge(sharedStyles.mainText, 'w-full break-words')}>
                {fields.announcement.split('\n').map((line, index) => (
                  <Fragment key={index}>
                    {line}
                    {index < fields.announcement.split('\n').length - 1 && <br />}
                  </Fragment>
                ))}
              </Text>
            </LinkifyText>
          </View>
          <View className={twMerge(sharedStyles.item, sharedStyles.iconItem)}>
            <FontAwesomeIcon icon={faUsers} fontSize={20} className="text-vault_text/50" />
            <View className={sharedStyles.textWrapper}>
              <Text className={sharedStyles.mainText}>
                {fields.accessType === ScheduledEventAccessFeatureInput.FreeEvent
                  ? 'All members'
                  : 'Paid members only'}
              </Text>
              {isLoadingSummary ? (
                <Text className={sharedStyles.subText}>...</Text>
              ) : (
                !!subscriberCount && (
                  <Text className={sharedStyles.subText}>
                    {millify(subscriberCount)} fan{subscriberCount > 1 && 's'}
                  </Text>
                )
              )}
            </View>
          </View>

          <View className={twMerge(sharedStyles.item, sharedStyles.iconItem)}>
            <FontAwesomeIcon icon={faCalendar} fontSize={20} className="text-vault_text/50" />
            <View className={sharedStyles.textWrapper}>
              <Text className={sharedStyles.mainText}>{formattedDate}</Text>
              <Text className={sharedStyles.subText}>{timeZone}</Text>
            </View>
          </View>
        </View>

        <Button
          type="primary-themed"
          label={buttonLabel}
          className="w-fit self-center disabled:opacity-50 md2:hidden"
          disabledClassName="text-vault_accent_text"
          disabled={!enableSubmit}
          onClick={onClickSubmit}
        />
      </View>
    </DefaultLayout>
  );
}
