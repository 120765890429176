import { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import {
  faCalendar,
  faComment,
  faHome,
  faMessages,
  faVault,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  faCalendar as faCalendarSolid,
  faComment as faCommentSolid,
  faHome as faHomeSolid,
  faMessages as faMessagesSolid,
  faVault as faVaultSolid,
} from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMembership } from '../../hooks/membership/useMembership';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { usePageChecks } from '../../utils/pathUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { useFullScreenAudioPlayerState } from '../main/AudioPlayer';
import { useBatchedVaultMessageUnreadCount } from '../views/hooks/useVaultMessageUnreadCount';
import { CreateButton } from './CreateButton';

export const VaultNav = memo(function VaultNav({
  messageChannelId,
  vaultId,
  hasChatReadAccess,
  chatAvailableForFreeUsers,
  variant,
  withVaultTheme,
  folderId,
}: {
  messageChannelId: string | undefined;
  vaultId: string | undefined;
  hasChatReadAccess: boolean;
  chatAvailableForFreeUsers: boolean;
  variant: 'default' | 'borderless';
  withVaultTheme: boolean;
  folderId: string | null;
}) {
  const navigate = useNavigate();

  const { artistHandle } = useArtistHandle();
  const { isFullscreenAudioOpen, isBottomAudioPlayerOpen } = useFullScreenAudioPlayerState();

  const { loggedInUser } = useAuthContext();

  const { value: isUnifiedInboxEnabled } = useGate(FEATURE_GATES.UNIFIED_INBOX);

  const isOwner =
    !!artistHandle &&
    !!loggedInUser?.adminArtists?.some(admin => admin.artistLinks.includes(artistHandle));

  const { membership } = useMembership({ artistHandle });

  const { unseenUpdates } = membership || {};

  const chatEnabled =
    (isOwner || hasChatReadAccess || chatAvailableForFreeUsers) && !!loggedInUser?.id;
  const showMembershipTab = !isOwner;

  const { unreadCount: messageNotificationCount } = useBatchedVaultMessageUnreadCount({
    messageChannelId,
    vaultId,
    enabled: chatEnabled,
  });

  const isMessagesCurrentPage = (() => {
    const path = location.pathname;
    if (path.includes('/messages/insights')) return false;
    return path.endsWith('/messages') || path.endsWith('/chat') || /\/messages\/[^\/]+$/.test(path);
  })();

  const [isChatCurrentPage, isMembershipCurrentPage, isVaultCurrentPage, isFolderPage] =
    usePageChecks({
      pages: ['chat', 'drops', 'vault', 'folder'],
    });

  const showSolidIcon =
    !isChatCurrentPage &&
    !isFolderPage &&
    !isMembershipCurrentPage &&
    !isVaultCurrentPage &&
    !isMessagesCurrentPage;

  const showCreateButton = !isChatCurrentPage && isOwner && !!vaultId && !isMessagesCurrentPage;

  const onHomeClicked = () => {
    if (
      (!isChatCurrentPage &&
        !isFolderPage &&
        !isMembershipCurrentPage &&
        !isVaultCurrentPage &&
        !isMessagesCurrentPage) ||
      !artistHandle
    )
      return;

    trackEvent({
      type: EVENTS.TAB_NAVIGATE,
      properties: {
        type: 'vault_page',
      },
    });

    navigate(artistNavigationPath(artistHandle, '/'));
  };

  const onVaultClicked = () => {
    if (isVaultCurrentPage || !artistHandle) return;

    trackEvent({
      type: EVENTS.TAB_NAVIGATE,
      properties: {
        type: 'vault_page',
      },
    });

    navigate(artistNavigationPath(artistHandle, '/vault'));
  };

  const onChatClicked = () => {
    if (isChatCurrentPage || !artistHandle || isMessagesCurrentPage) return;

    trackEvent({
      type: EVENTS.TAB_NAVIGATE,
      properties: {
        type: 'message_channel_page',
      },
    });

    navigate(artistNavigationPath(artistHandle, isUnifiedInboxEnabled ? '/messages' : '/chat'));
  };

  const onMembershipClicked = () => {
    if (isMembershipCurrentPage || !artistHandle) return;

    trackEvent({
      type: EVENTS.TAB_NAVIGATE,
      properties: {
        type: 'membership_page',
      },
    });

    navigate(artistNavigationPath(artistHandle, '/drops'));
  };

  // We don't want to show the vault nav if the fullscreen audio player is open
  if (isFullscreenAudioOpen) {
    return null;
  }

  return (
    <View
      className={twMerge(
        'sticky z-mobileNav flex h-[68px] max-h-[68px] w-full items-center justify-center border-0 py-2',
        variant === 'default' &&
          (withVaultTheme
            ? 'border-t border-solid border-t-vault_text/5'
            : 'border-t border-solid border-t-base700'),
        withVaultTheme ? 'bg-vault_background' : 'bg-black',
      )}
    >
      <View className="relative flex h-full w-full items-center justify-center">
        {showCreateButton && (
          <View
            className={twMerge(
              'absolute right-[22px] flex flex-row justify-end',
              isBottomAudioPlayerOpen ? 'top-[-122px]' : 'top-[-72px]',
            )}
          >
            <CreateButton
              artistHandle={artistHandle}
              mainVaultId={vaultId}
              iconOnly
              folderId={folderId}
            />
          </View>
        )}
        <View
          className={twMerge(
            'flex w-full  items-center justify-around overflow-y-hidden',
            isOwner ? 'max-w-[320px]' : showMembershipTab ? 'max-w-full' : 'max-w-[250px]',
          )}
        >
          <button
            className="flex flex-1 appearance-none flex-col items-center justify-center gap-1 border-none bg-transparent outline-none hover:cursor-pointer focus:outline-none"
            onClick={onHomeClicked}
          >
            <FontAwesomeIcon
              icon={showSolidIcon ? faHomeSolid : faHome}
              fontSize={24}
              className={
                showSolidIcon
                  ? withVaultTheme
                    ? 'text-vault_text'
                    : 'text-white'
                  : withVaultTheme
                    ? 'text-vault_text/50'
                    : 'text-base50/50'
              }
            />

            <Text
              className={twMerge(
                'mt-0.5 text-center font-base text-[10px] font-semibold leading-[18px]',
                showSolidIcon
                  ? withVaultTheme
                    ? 'text-vault_text'
                    : 'text-white'
                  : withVaultTheme
                    ? 'text-vault_text/50'
                    : 'text-base50/50',
              )}
            >
              Home
            </Text>
          </button>

          {isOwner && (
            <button
              className="flex flex-1 appearance-none flex-col items-center justify-center gap-1 border-none bg-transparent outline-none hover:cursor-pointer focus:outline-none"
              onClick={onVaultClicked}
            >
              <FontAwesomeIcon
                icon={isVaultCurrentPage ? faVaultSolid : faVault}
                fontSize={24}
                className={
                  isVaultCurrentPage
                    ? withVaultTheme
                      ? 'text-vault_text'
                      : 'text-white'
                    : withVaultTheme
                      ? 'text-vault_text/50'
                      : 'text-base50/50'
                }
              />

              <Text
                className={twMerge(
                  'mt-0.5 text-center font-base text-[10px] font-semibold leading-[18px]',
                  isVaultCurrentPage
                    ? withVaultTheme
                      ? 'text-vault_text'
                      : 'text-white'
                    : withVaultTheme
                      ? 'text-vault_text/50'
                      : 'text-base50/50',
                )}
              >
                Vault
              </Text>
            </button>
          )}

          <button
            className="flex flex-1 appearance-none flex-col items-center justify-center border-none bg-transparent outline-none hover:cursor-pointer focus:outline-none"
            onClick={onChatClicked}
          >
            <View className="relative">
              <FontAwesomeIcon
                icon={
                  isUnifiedInboxEnabled
                    ? isMessagesCurrentPage || isChatCurrentPage
                      ? faMessagesSolid
                      : faMessages
                    : isChatCurrentPage
                      ? faCommentSolid
                      : faComment
                }
                fontSize={24}
                className={
                  isChatCurrentPage || isMessagesCurrentPage
                    ? 'text-vault_text'
                    : 'text-vault_text/50'
                }
              />
              {!!messageNotificationCount && !hasChatReadAccess && (
                <View
                  className={twMerge(
                    'absolute -right-1 -top-1 flex h-[9px] w-[9px] items-center justify-center rounded-full border-[2px] border-solid font-base text-[10px] font-semibold',
                    'border-vault_background bg-vault_text',
                  )}
                />
              )}
            </View>

            <Text
              className={twMerge(
                'mt-0.5 text-center font-base text-[10px] font-semibold leading-[18px]',
                isChatCurrentPage || isMessagesCurrentPage
                  ? 'text-vault_text'
                  : 'text-vault_text/50',
              )}
            >
              {isUnifiedInboxEnabled ? 'Messages' : 'Chat'}
            </Text>
          </button>

          {showMembershipTab && (
            <button
              className="flex flex-1 appearance-none flex-col items-center justify-center border-none bg-transparent outline-none hover:cursor-pointer focus:outline-none"
              onClick={onMembershipClicked}
            >
              <View className="relative">
                <FontAwesomeIcon
                  icon={isMembershipCurrentPage ? faCalendarSolid : faCalendar}
                  fontSize={24}
                  className={isMembershipCurrentPage ? 'text-vault_text' : 'text-vault_text/50'}
                />
                {!!unseenUpdates && (
                  <View
                    className={twMerge(
                      'absolute -right-1 -top-1 flex h-[9px] w-[9px] items-center justify-center rounded-full border-[2px] border-solid font-base text-[10px] font-semibold',
                      'border-vault_background bg-vault_text',
                    )}
                  />
                )}
              </View>

              <Text
                className={twMerge(
                  'mt-0.5 text-center font-base text-[10px] font-semibold leading-[18px]',
                  isMembershipCurrentPage ? 'text-vault_text' : 'text-vault_text/50',
                )}
              >
                Drops
              </Text>
            </button>
          )}
        </View>
      </View>
    </View>
  );
});
