import { type FC, useState } from 'react';
import { motion } from 'framer-motion';
import { useSnapshot } from 'valtio';
import { AudioAttachment } from '../../contexts/AudioRecordContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { ImagesAttachment } from '../../contexts/ImagesMessageAttachmentContext';
import { VideoAttachment } from '../../contexts/VideoMessageAttachmentContext';
import type { ArtistMediaAttachmentBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { Button } from '../buttons/Button';
import { ImageMessageUpload } from '../chat/ImageMessageUpload';
import { VideoMessageUpload } from '../chat/VideoMessageUpload';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { HorizontalTrackFileCarousel } from '../track/HorizontalTrackFileCarousel';

const ArtistMediaAttachmentBottomsheet: FC<ArtistMediaAttachmentBottomsheetProps> = ({
  onAttachTrack,
  vaultId,
  alreadyAttachedTrack = null,
  artist,
  hasImageAttachmentAccess,
  hasVideoAttachmentAccess,
}) => {
  const { clearVideo } = useSnapshot(VideoAttachment);
  const { clearImages } = useSnapshot(ImagesAttachment);
  const { clearRecording } = useSnapshot(AudioAttachment);
  const [selectedTrack, setSelectedTrack] = useState<string | null>(alreadyAttachedTrack);
  const { closeBottomsheet } = useBottomsheetContainer();

  return (
    <View className="relative mb-4 flex w-full flex-col items-center pb-16 pt-5">
      <HorizontalTrackFileCarousel
        vaultId={vaultId}
        artist={artist}
        selectedTrack={selectedTrack}
        setSelectedTrack={setSelectedTrack}
      />

      <View className="flex w-full flex-col gap-4 pl-4">
        <Text className="font-title !text-title-m font-medium text-vault_text">
          {hasImageAttachmentAccess && hasVideoAttachmentAccess
            ? 'Photos and videos'
            : hasImageAttachmentAccess
              ? 'Attach Photos'
              : hasVideoAttachmentAccess
                ? 'Attach Video'
                : ''}
        </Text>
        <View className="mt-4 flex flex-col gap-5">
          {hasImageAttachmentAccess && (
            <ImageMessageUpload
              artistId={artist.id}
              onImageSelect={() => {
                // when images are selected, make sure to remove any attached tracks, recording and videos
                clearVideo();
                clearRecording();
                onAttachTrack(null);
              }}
            />
          )}

          {hasVideoAttachmentAccess && (
            <VideoMessageUpload
              artistId={artist.id}
              onVideoSelect={() => {
                // when video is selected, make sure to remove any attached tracks, images, and recording
                clearImages();
                clearRecording();
                onAttachTrack(null);
              }}
            />
          )}
        </View>
      </View>

      {selectedTrack && (
        <View className="absolute bottom-2 flex w-full justify-center px-5">
          <motion.div
            initial={{ y: 25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
          >
            <Button
              label="Attach"
              type="primary-themed"
              onClick={() => {
                if (selectedTrack) {
                  onAttachTrack(selectedTrack);
                  clearVideo(); // Make sure when track is selected we clear any selected video
                  clearImages(); // Make sure when track is selected we clear any selected images
                  clearRecording(); // Make sure when track is selected we clear any recorded audio
                  closeBottomsheet();
                }
              }}
              disabled={selectedTrack == null}
              disabledClassName="invisible"
              event={
                selectedTrack
                  ? {
                      type: EVENTS.ATTACH_TRACK,
                      properties: { trackId: selectedTrack, vaultId },
                    }
                  : undefined
              }
            />
          </motion.div>
        </View>
      )}
    </View>
  );
};

export { ArtistMediaAttachmentBottomsheet };
