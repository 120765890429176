import {
  type CSSProperties,
  forwardRef,
  type LegacyRef,
  type ReactNode,
  type UIEventHandler,
} from 'react';
import type { SwipeableHandlers } from 'react-swipeable';
import type { LongPressPointerHandlers } from 'use-long-press';
import type { OnClickType } from '../buttons/Button';

interface ExtendedCSSProperties extends CSSProperties {
  '--tw-gradient-from'?: string;
}

export interface ViewProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
  containerRef?: LegacyRef<HTMLDivElement>;
  onClick?: OnClickType;
  swipeableHandlers?: SwipeableHandlers;
  onLongPress?: LongPressPointerHandlers<Element>;
  onTouchDown?: (event?: React.MouseEvent | React.TouchEvent) => void;
  onScroll?: UIEventHandler<HTMLDivElement>;
  onTouchMove?: (event: React.TouchEvent) => void;
  style?: ExtendedCSSProperties;
}

export const View = forwardRef<HTMLDivElement, ViewProps>(
  (
    {
      children,
      className,
      containerRef,
      onClick,
      style,
      swipeableHandlers,
      onLongPress,
      onTouchDown,
      onScroll,
      onTouchMove,
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        className={className}
        ref={containerRef ?? ref}
        onClick={onClick}
        style={style}
        {...swipeableHandlers}
        {...onLongPress}
        onMouseDown={onTouchDown}
        onTouchStart={onTouchDown}
        onScroll={onScroll}
        onTouchMove={onTouchMove}
        {...rest}
      >
        {children}
      </div>
    );
  },
);

View.displayName = 'View';
