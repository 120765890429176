import * as z from 'zod';
import { useAuthContext } from '../contexts/AuthContext';
import { LoginStatus } from '../types/authTypes';
import { PersistenceStorage } from '../utils/storeUtils';

export const SelectedArtistSchema = z.object({
  artistHandle: z.string(),
  artistId: z.string(),
  artistName: z.string(),
});

export type SelectedArtistSchema = z.infer<typeof SelectedArtistSchema>;

export const SelectedArtistPersistence = PersistenceStorage({
  schema: SelectedArtistSchema,
  key: 'selectedArtist',
  eager: true,
});

type SelectedArtistState =
  | { status: 'loading' }
  | { status: 'ready'; artistHandle: string; artistId: string; artistName: string }
  | { status: 'error' };

export const useSelectedArtist = (): SelectedArtistState => {
  const { loggedInUser, loginStatus } = useAuthContext();
  const { isLoading, value } = SelectedArtistPersistence.useStore();

  if (isLoading || loginStatus === LoginStatus.LOADING) {
    return { status: 'loading' };
  }

  if (!!value) {
    return {
      status: 'ready',
      artistHandle: value.artistHandle,
      artistId: value.artistId,
      artistName: value.artistName,
    };
  }

  if (!!loggedInUser?.artist) {
    return {
      status: 'ready',
      artistHandle: loggedInUser.artist.mainLinkValue,
      artistId: loggedInUser.artist.id,
      artistName: loggedInUser.artist.name,
    };
  }

  return { status: 'error' };
};
