import { useEffect } from 'react';
import msFn from 'ms';
import { Navigate, useLocation, useParams } from 'react-router';
import { gql } from '@soundxyz/gql-string';
import { View } from '../../../components/common/View';
import { ErrorView } from '../../../components/error/ErrorView';
import { useSetMetaHeaders } from '../../../components/metatags/MetatagsHeader';
import { RsvpDropView, RsvpDropViewSkeleton } from '../../../components/rsvp/RsvpDropView';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { useQuery } from '../../../graphql/client';
import { RsvpEventBySlugDocument } from '../../../graphql/generated';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useVaultTheme } from '../../../hooks/useVaultTheme';

const fiveMinutes = msFn('5 minutes');

gql(/* GraphQL */ `
  query RsvpEventBySlug($artistHandle: String!, $eventLinkValue: String!) {
    rsvpEventByLink(artistHandle: $artistHandle, eventLinkValue: $eventLinkValue) {
      id
      __typename
      artist {
        id
        name
        profileImage {
          id
          artistProfileImagedUrl: imageOptimizedUrl
        }
        isAuthUserAdmin
        mainVault {
          id
          activeSubscription {
            id
          }
        }
      }
      ... on PublicRsvpEvent {
        id
        title
        coverImage {
          id
          rsvpCoverImageUrl: imageOptimizedUrl
        }
      }
      ... on RsvpEventPrivateInfo {
        id
        title
        coverImage {
          id
          rsvpCoverImageUrl: imageOptimizedUrl
        }
      }
      ...RsvpDropView
    }
  }
`);

// TODO: In the future, probably we'll have more drop types and a DropBySlug query
// let's use this page to return the view based on the __typename
export function DropPage() {
  const location = useLocation();
  const { loggedInUser, initialLoginCheckLoading } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const { setShowMenu } = useMenuContainer();
  const { dropSlug } = useParams<{
    dropSlug: string;
  }>();

  useVaultTheme();

  const { data, isLoading, isError, refetch } = useQuery(RsvpEventBySlugDocument, {
    cacheTime: 0,
    staleTime: 0,
    variables: !!artistHandle &&
      !!dropSlug && {
        artistHandle: artistHandle.toLowerCase(),
        eventLinkValue: dropSlug,
      },
    filterQueryKey: {
      dropSlug,
      artistHandle,
      userId: loggedInUser?.id,
    },
    // Refetch to always get latest drop
    refetchInterval: fiveMinutes,
    keepPreviousData: true,
  });

  const coverImage =
    data?.data.rsvpEventByLink?.__typename === 'PublicRsvpEvent'
      ? data?.data.rsvpEventByLink.coverImage?.rsvpCoverImageUrl
      : data?.data.rsvpEventByLink?.__typename === 'RsvpEventPrivateInfo'
        ? data?.data.rsvpEventByLink.coverImage?.rsvpCoverImageUrl
        : data?.data.rsvpEventByLink?.artist.profileImage?.artistProfileImagedUrl;

  const title =
    data?.data.rsvpEventByLink?.__typename === 'PublicRsvpEvent'
      ? data?.data.rsvpEventByLink.title
      : data?.data.rsvpEventByLink?.__typename === 'RsvpEventPrivateInfo'
        ? data?.data.rsvpEventByLink.title
        : null;

  const typename = data?.data.rsvpEventByLink?.__typename;

  useSetMetaHeaders({
    title:
      data?.data.rsvpEventByLink && title
        ? `${data.data.rsvpEventByLink.artist.name} - ${title}`
        : null,
    imageUrl: coverImage ?? undefined,
  });

  useEffect(() => {
    setShowMenu(false);

    return () => setShowMenu(true);
  }, [location.pathname, setShowMenu, typename]);

  if (dropSlug == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isError) {
    return (
      <View className="flex h-full w-full items-center justify-center bg-vault_background">
        <ErrorView onRetryClick={refetch} loggingType="campaign_page" withVaultTheme />
      </View>
    );
  }

  if (isLoading || initialLoginCheckLoading) {
    return <RsvpDropViewSkeleton />;
  }

  if (data?.data.rsvpEventByLink == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return <RsvpDropView dropFrag={data.data.rsvpEventByLink} isPreview={false} />;
}
