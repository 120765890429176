import type { FC } from 'react';
import React, { useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Virtuoso } from 'react-virtuoso';
import { gql } from '@soundxyz/gql-string';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { BillingRow, SkeletonBillingRow } from '../../components/payment/BillingRow';
import { EmptyStateView } from '../../components/views/EmptyStateView';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useInfiniteQuery } from '../../graphql/client';
import {
  type BillingRowFragment,
  BillingRowFragmentDoc,
  GetBillingDocument,
  getFragment,
} from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { LoginStatus } from '../../types/authTypes';

gql(/* GraphQL */ `
  fragment BillingRow on Billing {
    id
    timestamp
    amount
    status
    recipientName
  }

  query GetBilling($after: String, $first: Int) {
    billing(after: $after, first: $first) {
      __typename
      ... on QueryBillingSuccess {
        data {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              ...BillingRow
            }
            cursor
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

const LIMIT = 10;

const BillingPage: FC = () => {
  const { loggedInUser, loginStatus } = useAuthContext();
  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });

  const {
    orderedList: billings,
    isLoading,
    isError,
    hasNextPage,
    loadMoreNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(GetBillingDocument, {
    enabled: loggedInUser != null,
    staleTime: 0,
    filterQueryKey: { userId: loggedInUser?.id },
    getNextPageParam: ({ data }) => {
      if (data.billing.__typename !== 'QueryBillingSuccess') {
        return false;
      }
      return (
        data.billing.data.pageInfo.hasNextPage && {
          after: data.billing.data.pageInfo.endCursor,
        }
      );
    },
    variables: ({ pageParam }) => {
      return {
        after: pageParam?.after ?? null,
        first: LIMIT,
      };
    },
    list: ({ billing }) => {
      if (billing.__typename !== 'QueryBillingSuccess') {
        return [];
      }
      return billing.data.edges.map(({ node }) => getFragment(BillingRowFragmentDoc, node));
    },
    uniq: ({ id, timestamp, recipientName }) => id ?? `${timestamp}_${recipientName}`,
  });

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      loadMoreNextPage();
    }
  }, [hasNextPage, isAtBottom, loadMoreNextPage, isFetchingNextPage]);

  const EmptyPlaceholder = useCallback(
    () =>
      loginStatus === LoginStatus.LOADING || isLoading ? (
        <>
          <SkeletonBillingRow />
          <SkeletonBillingRow />
          <SkeletonBillingRow />
        </>
      ) : isError ? (
        <ErrorView onRetryClick={refetch} className="h-full w-full" withVaultTheme={false} />
      ) : (
        <EmptyStateView
          title="You currently have no subscriptions"
          subtitle="Explore artists on Vault.fm and subscribe to your favorite artists"
          buttonText="Explore vaults"
          buttonHref={ROUTES.VAULTS}
          className="h-full"
        />
      ),
    [isError, isLoading, loginStatus, refetch],
  );

  const Footer = useCallback(
    () =>
      hasNextPage ? (
        <div ref={bottomRef}>
          <SkeletonBillingRow />
        </div>
      ) : (
        <div className="h-4" ref={bottomRef} />
      ),
    [bottomRef, hasNextPage],
  );

  const renderItems = useStableCallback((_i: number, billing: BillingRowFragment) => (
    <BillingRow billing={billing} />
  ));

  return (
    <SettingsLayout stretch title="Billing">
      <Virtuoso
        className="no-scrollbar box-border h-full w-full"
        data={billings}
        itemContent={renderItems}
        components={{
          EmptyPlaceholder,
          Footer,
        }}
      />
    </SettingsLayout>
  );
};

export { BillingPage };
