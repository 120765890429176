import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router';
import { gql } from '@soundxyz/gql-string';
import { EditMassMessageView } from '../../../components/massMessage/EditMassMessageView';
import { FullPageLoading } from '../../../components/views/FullPageLoading';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useQuery } from '../../../graphql/client';
import { GetAnnouncementDocument } from '../../../graphql/generated';
import { useMassMessageForm } from '../../../hooks/message/useMassMessageForm';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useOwnerOnlyAccess } from '../../../hooks/useOwnerOnlyAcess';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { useWindow } from '../../../hooks/useWindow';
import { LoginStatus } from '../../../types/authTypes';

gql(/* GraphQL */ `
  query GetAnnouncement($announcementId: UUID!) {
    announcementById(announcementId: $announcementId) {
      id
      content
      scheduledAt
      featureAccess {
        feature {
          __typename
        }
      }
      artistEventTarget {
        id
        title
      }
      locationTarget {
        isoCountry
        isoUsStateCode
      }
    }
  }
`);

export function EditMassMessagePage() {
  const { messageId } = useParams();
  const { artistHandle } = useArtistHandle();
  const { loggedInUser, loginStatus } = useAuthContext();

  const { isLg } = useWindow();

  useVaultTheme();

  useOwnerOnlyAccess();

  const {
    data: announcement,
    isLoading,
    isError,
  } = useQuery(GetAnnouncementDocument, {
    variables: !!messageId && { announcementId: messageId },
    staleTime: Infinity,
    enabled: !!loggedInUser,
    select: data => data.data.announcementById,
  });

  const artist = loggedInUser?.adminArtists?.find(({ artistLinks }) =>
    artistLinks.includes(artistHandle ?? ''),
  );

  const { clearErrors, clearFields } = useMassMessageForm({ vaultId: artist?.artistMainVaultId });

  const {
    setField,
    fields: { fetchedAnnouncementId },
  } = useMassMessageForm({ vaultId: artist?.artistMainVaultId });

  useEffect(() => {
    if (isLoading || isError || !announcement || !messageId || fetchedAnnouncementId === messageId)
      return;

    if (!!announcement.content) {
      setField('message', announcement.content);
    }

    if (!!announcement.artistEventTarget) {
      setField(
        'drops',
        announcement.artistEventTarget.map(({ id, title }) => ({ id, name: title ?? '' })),
      );
    }

    if (!!announcement.locationTarget) {
      setField(
        'locations',
        announcement.locationTarget.map(({ isoCountry, isoUsStateCode }) => ({
          code: isoCountry,
          regionCode: isoUsStateCode,
        })),
      );
    }

    if (!!announcement.featureAccess) {
      setField(
        'memberType',
        announcement.featureAccess.map(({ feature }) =>
          feature.__typename === 'FreeScheduledEvent' ? 'FREE_EVENT' : 'PAID_EVENT',
        ),
      );
    }

    if (!!announcement.scheduledAt) {
      setField('date', new Date(announcement.scheduledAt));
    }

    setField('fetchedAnnouncementId', messageId);
  }, [announcement, fetchedAnnouncementId, isError, isLoading, messageId, setField]);

  useEffect(() => {
    return () => {
      if (isLg) {
        clearFields();
        clearErrors();
      }
    };
  }, [clearErrors, clearFields, isLg]);

  if (loginStatus === LoginStatus.LOADING || isLoading) {
    return <FullPageLoading withVaultTheme />;
  }

  if (!artist || !artistHandle || loginStatus === LoginStatus.LOGGED_OUT || !messageId || isError) {
    return <Navigate to={ROUTES.NOT_FOUND} replace />;
  }

  return (
    <EditMassMessageView
      artistHandle={artistHandle}
      mainVaultId={artist.artistMainVaultId}
      artistId={artist.artistId}
      artistProfileImageUrl={artist.artistProfileImage?.artistSmallProfileImageUrl ?? null}
      artistName={artist.artistName}
      announcementId={messageId}
      isEdit
    />
  );
}
