import { type FC, useEffect, useMemo } from 'react';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { faComment } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faComment as faCommentSolid } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPlay } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPause } from '@soundxyz/font-awesome/sharp-solid-svg-icons';
import { cycleRepeatMode, seek, useAudioController } from '../../audio/AudioController';
import { togglePlayPause } from '../../audio/AudioEngineHTML5';
import type { RepeatMode } from '../../audio/AudioMeta';
import { useAudioPosition } from '../../audio/AudioPosition';
import { goToNextTrack, goToPrevTrack, toggleShuffleEnabled } from '../../audio/AudioQueue';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import type { TrackContentByIdQuery } from '../../graphql/generated';
import { useWindow } from '../../hooks/useWindow';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import { EVENTS } from '../../types/eventTypes';
import type { TypeFromGraphQLUnion } from '../../types/gql';
import { trackEvent } from '../../utils/analyticsUtils';
import { generateShareLink } from '../../utils/linkUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import {
  closeBottomAudioPlayer,
  openBottomAudioPlayer,
  openFullscreenPlayer,
} from '../main/AudioPlayer';
import { ControlButtons, ShareButton } from './FullscreenAudioPlayer';
import { Timeline } from './Timeline';

type Props = {
  content: TypeFromGraphQLUnion<
    NonNullable<TrackContentByIdQuery['vaultContentById']>,
    'VaultTrack'
  >;
  withBottomNavigator: boolean;
  withVaultTheme: boolean;
};

export const VaultBottomAudioPlayer: FC<Props> = ({
  content,
  withBottomNavigator,
  withVaultTheme,
}) => {
  const { isDesktop } = useWindow();

  const { isSelecting } = useSelectVaultContent();

  const { playing, duration } = useAudioController();
  const { percentComplete, position } = useAudioPosition();

  if (isDesktop) {
    return <VaultDesktopBottomAudioPlayer content={content} withVaultTheme={withVaultTheme} />;
  }

  if (isSelecting) {
    return null;
  }

  const {
    title,
    vault: { artist },
    commentMessageCount,
    isFullVersionAvailable,
  } = content;

  return (
    <BottomAudioPlayer
      withBottomNavigator={withBottomNavigator}
      withVaultTheme={withVaultTheme}
      leftImageUrl={artist?.profileImage?.artistSmallProfileImageUrl}
      title={title ?? 'Untitled'}
      subtitle={artist?.name ?? 'Unnamed'}
      isFullVersionAvailable={isFullVersionAvailable}
      playing={playing}
      duration={duration}
      seek={seek}
      type="vault"
      commentMessageCount={commentMessageCount}
      trackId={content.id}
      artistId={artist?.id}
      vaultId={content.vault.id}
      percentComplete={percentComplete}
      togglePlayPause={togglePlayPause}
      position={position}
    />
  );
};

const VaultDesktopBottomAudioPlayer: FC<Pick<Props, 'content' | 'withVaultTheme'>> = ({
  content,
  withVaultTheme,
}) => {
  const {
    isNextTrackDisabled,
    playing,
    loading,
    loadingActiveTrack,
    disableNextPrev,
    shuffleEnabled,
    repeatMode,
    duration,
  } = useAudioController();
  const { position } = useAudioPosition();
  const { loggedInUser } = useAuthContext();

  const {
    id,
    title,
    linkValue,
    vault: { artist },
    commentMessageCount,
    isFullVersionAvailable,
  } = content;

  const artistId = artist?.id;

  const link = useMemo(() => {
    const path = linkValue ? `/t/${linkValue}` : `/${id}`;

    return generateShareLink({
      artistLinkValue: artist?.linkValue,
      path,
      inviteCode: loggedInUser?.adminArtists?.some(adminArtist => adminArtist.artistId === artistId)
        ? null
        : loggedInUser?.inviteCode,
    });
  }, [artist?.linkValue, id, linkValue, loggedInUser, artistId]);

  return (
    <DesktopBottomAudioPlayer
      type="vault"
      isFullVersionAvailable={isFullVersionAvailable}
      commentMessageCount={commentMessageCount}
      title={title ?? 'Untitled'}
      artistName={artist?.name ?? 'Unknown'}
      link={link}
      withVaultTheme={withVaultTheme}
      isNextTrackDisabled={isNextTrackDisabled}
      playing={playing}
      loading={loading}
      loadingActiveTrack={loadingActiveTrack}
      disableNextPrev={disableNextPrev}
      shuffleEnabled={shuffleEnabled}
      repeatMode={repeatMode}
      duration={duration}
      trackId={content.id}
      togglePlayPause={togglePlayPause}
      position={position}
      seek={seek}
      toggleShuffleEnabled={toggleShuffleEnabled}
      cycleRepeatMode={cycleRepeatMode}
      goToPrevTrack={goToPrevTrack}
      goToNextTrack={goToNextTrack}
      artistId={artist?.id}
      vaultId={content.vault.id}
    />
  );
};

export const LoadingBottomAudioPlayer: FC = () => {
  useEffect(() => {
    openBottomAudioPlayer();
    return () => {
      closeBottomAudioPlayer();
    };
  }, []);

  return <LoadingSkeleton className="sticky h-[60px] w-full rounded-none md2:h-[96px]" />;
};

export function BottomAudioPlayer({
  withBottomNavigator,
  withVaultTheme,
  leftImageUrl,
  title,
  isFullVersionAvailable = true,
  subtitle,
  playing,
  duration,
  seek,
  type,
  commentMessageCount,
  trackId,
  artistId,
  vaultId,
  percentComplete,
  togglePlayPause,
  position,
  shouldOpenFullscreen = true,
}: {
  withBottomNavigator: boolean;
  withVaultTheme: boolean;
  leftImageUrl: string | undefined | null;
  title: string;
  isFullVersionAvailable?: boolean;
  subtitle: string;
  playing: boolean;
  duration: number;
  seek: (position: number) => void;
  togglePlayPause: () => void;
  position: number;
  shouldOpenFullscreen?: boolean;
} & (
  | {
      type: 'vault';
      commentMessageCount: number;
      trackId: string;
      artistId: string | undefined;
      vaultId: string;
      percentComplete: number;
    }
  | {
      type: 'spotify' | 'appleMusic';
      commentMessageCount?: undefined;
      trackId?: undefined;
      artistId?: undefined;
      vaultId?: undefined;
      percentComplete?: undefined;
    }
)) {
  useEffect(() => {
    openBottomAudioPlayer();
    return () => {
      closeBottomAudioPlayer();
    };
  }, []);

  const { openBottomsheet } = useBottomsheetContainer();
  return (
    <View
      className={twMerge(
        'sticky z-audioPlayerContainer box-border flex h-[60px] w-full cursor-pointer flex-row',
        withBottomNavigator && 'bottom-[68px]',
        withVaultTheme
          ? 'border-0 border-t border-solid border-t-vault_text/5'
          : 'border-0 border-t border-solid border-t-white/5',
      )}
      onClick={
        shouldOpenFullscreen
          ? () => {
              openFullscreenPlayer();
            }
          : undefined
      }
    >
      <View
        className={twMerge(
          'm-0 box-border w-full items-center justify-center overflow-hidden py-1',
          withVaultTheme
            ? 'border-0 border-t border-solid border-vault_text/5 bg-vault_background/75 backdrop-blur-2xl'
            : 'border-0 border-t border-solid border-white/5 bg-black/75 backdrop-blur-2xl',
        )}
      >
        <View className="box-border flex h-full w-full flex-row items-center justify-between gap-4 px-2">
          <View className="flex flex-row">
            <ArtistProfileImage
              className="mr-[8px] w-[36px] rounded-full"
              profileImageUrl={leftImageUrl}
            />
            <View className="flex flex-1 flex-shrink flex-col justify-around">
              <View className="flex w-full flex-row items-center gap-2">
                <Text
                  className={twMerge(
                    'line-clamp-1 font-title !text-title-xxs font-medium',
                    withVaultTheme ? 'text-vault_text' : 'text-white',
                  )}
                >
                  {title}
                </Text>
                {!isFullVersionAvailable && (
                  <Text
                    className={twMerge(
                      'flex h-4 w-fit items-center justify-center rounded-full border-[1px] border-solid px-2 text-[8px]',
                      withVaultTheme
                        ? 'border-vault_text text-vault_text'
                        : 'border-white text-white',
                    )}
                  >
                    Snippet
                  </Text>
                )}
              </View>
              <Text
                className={twMerge(
                  'font-base !text-base-xs font-medium',
                  withVaultTheme ? 'text-vault_text' : 'text-white',
                )}
              >
                {
                  subtitle
                  // artist?.name ?? 'Unnamed'
                }
              </Text>
            </View>
          </View>
          <View className="flex flex-row items-center justify-center gap-1">
            {type === 'vault' && (
              <Button
                labelComponent={
                  <span
                    className={twMerge(
                      'font-base text-[13px] font-semibold tabular-nums',
                      withVaultTheme ? 'text-vault_text/50' : 'text-base400',
                    )}
                  >
                    {commentMessageCount.toString()}
                  </span>
                }
                label={commentMessageCount.toString()}
                iconOnly={commentMessageCount === 0}
                leadingIcon={faCommentSolid}
                className={twMerge(
                  'text-[20px]',
                  withVaultTheme ? 'text-vault_text' : 'text-white',
                )}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();

                  trackEvent({
                    type: EVENTS.OPEN_BOTTOMSHEET,
                    properties: {
                      bottomsheetType: BOTTOMSHEET_TYPES.TRACK_COMMENTS,
                      component: 'MiniPlayer',
                      event: 'Open Bottomsheet',
                      artistId: artistId,
                      trackId,
                    },
                  });

                  openBottomsheet({
                    type: 'TRACK_COMMENTS',
                    shared: {
                      withVaultTheme,
                    },
                    trackCommentsBottomsheetProps: {
                      trackId,
                      autoFocusInput: false,
                      withVaultTheme,
                    },
                  });
                }}
              />
            )}
            <Button
              iconOnly
              label=""
              leadingIcon={!playing ? faPlay : faPause}
              className={twMerge(
                'px-5 text-[20px]',
                withVaultTheme ? 'text-vault_text' : 'text-white',
              )}
              onClick={e => {
                e.stopPropagation();
                togglePlayPause();
              }}
              event={
                type === 'vault'
                  ? {
                      type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
                      properties: {
                        trackId,
                        percentComplete,
                        artistId,
                        vaultId,
                        isPreview: !isFullVersionAvailable,
                        component: 'bottom_audio_player',
                      },
                    }
                  : undefined
              }
            />
          </View>
        </View>

        <Timeline
          type="bottom"
          withVaultTheme={withVaultTheme}
          isPlaying={playing}
          seek={seek}
          duration={duration}
          position={position}
          event={
            type === 'vault'
              ? { trackId, type: 'timeline', artistId: artistId ?? null, vaultId }
              : undefined
          }
        />
      </View>
    </View>
  );
}

export function DesktopBottomAudioPlayer({
  withVaultTheme,
  title,
  isFullVersionAvailable = true,
  isNextTrackDisabled,
  playing,
  loading,
  loadingActiveTrack,
  disableNextPrev,
  shuffleEnabled,
  repeatMode,
  duration,
  type,
  trackId,
  artistName,
  commentMessageCount,
  link,
  togglePlayPause,
  position,
  seek,
  toggleShuffleEnabled,
  goToPrevTrack,
  goToNextTrack,
  cycleRepeatMode,
  artistId,
  vaultId,
}: {
  withVaultTheme: boolean;
  title: string;
  isNextTrackDisabled: boolean;
  playing: boolean;
  loading: boolean;
  loadingActiveTrack: boolean;
  disableNextPrev: boolean;
  shuffleEnabled: boolean;
  repeatMode: keyof typeof RepeatMode;
  duration: number;
  artistName: string;
  togglePlayPause: () => void;
  position: number;
  seek: (position: number) => void;
  toggleShuffleEnabled?: () => void;
  goToPrevTrack?: () => void;
  goToNextTrack?: () => void;
  cycleRepeatMode?: () => void;
} & (
  | {
      type: 'vault';
      isFullVersionAvailable: boolean;
      trackId: string;
      commentMessageCount: number;
      link: string;
      artistId: string | null | undefined;
      vaultId: string;
    }
  | {
      type: 'spotify' | 'appleMusic';
      isFullVersionAvailable?: true;
      trackId?: undefined;
      commentMessageCount?: undefined;
      link?: undefined;
      artistId?: undefined;
      vaultId?: undefined;
    }
)) {
  const { openBottomsheet } = useBottomsheetContainer();

  useEffect(() => {
    openBottomAudioPlayer();
    return () => {
      closeBottomAudioPlayer();
    };
  }, []);

  return (
    <View
      className={twMerge(
        'sticky z-audioPlayerContainer box-border flex w-full flex-row',
        withVaultTheme
          ? 'border-0 border-t border-solid border-t-vault_text/5'
          : 'border-0 border-t border-solid border-t-white/5',
      )}
    >
      <View
        className={twMerge(
          'm-0 box-border w-full items-center justify-center overflow-hidden px-4',
          withVaultTheme
            ? 'border-0 border-t border-solid border-vault_text/5 bg-vault_background/75 backdrop-blur-2xl'
            : 'border-0 border-t border-solid border-white/5 bg-black/75 backdrop-blur-2xl',
        )}
      >
        <View className="box-border flex h-full w-full flex-row items-center justify-between ">
          <View className="flex w-[20%] flex-row">
            <View className="flex flex-1 flex-shrink flex-col justify-around">
              <View className="flex w-full flex-row items-center gap-2">
                <Text
                  className={twMerge(
                    'line-clamp-1 font-title !text-title-xxs font-medium',
                    withVaultTheme ? 'text-vault_text' : 'text-white',
                  )}
                >
                  {title}
                </Text>
                {!isFullVersionAvailable && (
                  <Text
                    className={twMerge(
                      'mb-1 flex h-4 w-fit items-center justify-center rounded-full border-[1px] border-solid px-2 text-[8px]',
                      withVaultTheme
                        ? 'border-vault_text text-vault_text'
                        : 'border-white text-white',
                    )}
                  >
                    Snippet
                  </Text>
                )}
              </View>
              <Text
                className={twMerge(
                  'font-base !text-base-xs font-medium',
                  withVaultTheme ? 'text-vault_text/50' : 'text-neutral500',
                )}
              >
                {artistName}
              </Text>
            </View>
          </View>

          <View className="flex w-[50%] flex-col items-center justify-center">
            <ControlButtons
              className="pt-[14px]"
              withVaultTheme={withVaultTheme}
              isNextTrackDisabled={isNextTrackDisabled}
              playing={playing}
              loading={loading}
              loadingActiveTrack={loadingActiveTrack}
              disableNextPrev={disableNextPrev}
              shuffleEnabled={shuffleEnabled}
              repeatMode={repeatMode}
              togglePlayPause={togglePlayPause}
              toggleShuffleEnabled={toggleShuffleEnabled}
              cycleRepeatMode={cycleRepeatMode}
              goToPrevTrack={goToPrevTrack}
              goToNextTrack={goToNextTrack}
            />
            <Timeline
              type="normal"
              className="py-0 pb-[5px]"
              timestampPosition="top"
              timestampClassName={twMerge(
                withVaultTheme ? 'text-vault_text/50' : 'text-neutral500',
              )}
              withVaultTheme={withVaultTheme}
              duration={duration}
              isPlaying={playing}
              seek={seek}
              position={position}
              event={
                type === 'vault'
                  ? { trackId, type: 'timeline', artistId: artistId ?? null, vaultId }
                  : undefined
              }
            />
          </View>

          <View className="flex min-w-[20%] flex-row justify-end ">
            {type === 'vault' && (
              <>
                <Button
                  className={twMerge(
                    'text-[16px]',
                    withVaultTheme ? 'text-vault_text' : 'text-white',
                  )}
                  label={commentMessageCount.toString()}
                  leadingIcon={faComment}
                  onClick={() => {
                    openBottomsheet({
                      type: 'TRACK_COMMENTS',
                      shared: {
                        withVaultTheme,
                      },
                      trackCommentsBottomsheetProps: {
                        trackId,
                        autoFocusInput: false,
                        withVaultTheme,
                      },
                    });
                  }}
                />

                <ShareButton
                  link={link}
                  className={twMerge('ml-[20px]', withVaultTheme && 'text-vault_text')}
                  artistName={artistName}
                  withVaultTheme={withVaultTheme}
                />
              </>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}
