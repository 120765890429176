import { memo, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export const DynamicText = memo(
  ({
    text,
    maxFontSize = 18,
    className,
  }: {
    text: string;
    maxFontSize?: number;
    className?: string;
  }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const [fontSize, setFontSize] = useState(maxFontSize);
    const hasSpace = text.includes(' ');

    useEffect(() => {
      const calculateOptimalFontSize = () => {
        if (!containerRef.current || !textRef.current) return;

        // Start with max font size
        let currentFontSize = maxFontSize;
        textRef.current.style.fontSize = `${currentFontSize}px`;

        // Keep reducing font size until text fits or we hit minimum
        while (currentFontSize > 10) {
          const containerWidth = containerRef.current.clientWidth;
          const containerHeight = containerRef.current.clientHeight;
          const textWidth = textRef.current.scrollWidth;
          const textHeight = textRef.current.scrollHeight;

          // Check if text fits in both dimensions
          if (textWidth <= containerWidth && textHeight <= containerHeight) {
            break;
          }

          // Reduce font size and try again
          currentFontSize--;
          textRef.current.style.fontSize = `${currentFontSize}px`;
        }

        setFontSize(currentFontSize);
      };

      calculateOptimalFontSize();

      // Add resize observer to recalculate on container size changes
      const resizeObserver = new ResizeObserver(calculateOptimalFontSize);
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
      }

      return () => resizeObserver.disconnect();
    }, [text, maxFontSize]);

    return (
      <div ref={containerRef} className="w-full overflow-hidden">
        <div
          ref={textRef}
          className={twMerge(
            'inline-block font-title font-medium text-vault_text',
            hasSpace ? 'whitespace-normal' : 'whitespace-nowrap',
            className,
          )}
          style={{
            fontSize: `${fontSize}px`,
            lineHeight: `${fontSize + 4}px`,
          }}
        >
          {text}
        </div>
      </div>
    );
  },
);

DynamicText.displayName = 'DynamicText';
