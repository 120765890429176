import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

export const TitleLogo = () => {
  return (
    <svg
      width="230"
      height="48"
      viewBox="0 0 230 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_4070_431110)">
        <rect width="48" height="48" rx="24" fill="#E3F41D" />
        <rect x="8.33447" y="22.879" width="2.24194" height="2.24194" rx="1.12097" fill="black" />
        <rect x="13.1826" y="20.6371" width="2.24194" height="6.72581" rx="1.12097" fill="black" />
        <rect x="18.0308" y="15.5928" width="2.24194" height="16.8145" rx="1.12097" fill="black" />
        <rect x="22.8789" y="10.9688" width="2.24194" height="26.0625" rx="1.12097" fill="black" />
        <rect x="27.7271" y="15.5928" width="2.24194" height="16.8145" rx="1.12097" fill="black" />
        <rect x="32.5752" y="20.6371" width="2.24194" height="6.72581" rx="1.12097" fill="black" />
        <rect x="37.4238" y="22.879" width="2.24194" height="2.24194" rx="1.12097" fill="black" />
      </g>
      <path
        d="M128.688 31.0775H124.828C122.888 31.0775 122.168 29.8775 122.168 28.0175V23.2775H120.068V20.3175H122.168V17.1775H126.568V20.3175H128.688V23.2775H126.568V27.1775C126.568 27.7575 126.708 28.1175 127.468 28.1175H128.688V31.0775Z"
        fill="white"
      />
      <path d="M118.377 16.1975V31.0775H113.917V16.1975H118.377Z" fill="white" />
      <path
        d="M111.557 20.3175V31.0775H107.137V29.2175C106.597 29.7175 105.037 31.3175 101.917 31.3175H101.757C98.0567 31.3175 96.6367 29.0375 96.6367 27.0175V20.3175H101.137V25.6375C101.137 27.2175 102.017 28.3175 103.897 28.3175C105.837 28.3175 107.097 27.1375 107.097 25.3975V20.3175H111.557Z"
        fill="white"
      />
      <path
        d="M89.8181 29.5975C89.3181 30.1575 88.0181 31.2775 84.8381 31.2775H84.5781C80.9781 31.2775 79.5781 29.5975 79.5781 27.7575V27.6175C79.5781 25.6375 80.8781 24.6975 84.0981 24.3975L89.0181 23.9375C89.5181 23.8975 89.6981 23.7175 89.6981 23.3775C89.6981 22.9775 89.4981 22.4975 86.8981 22.4975H86.7981C84.1381 22.4975 84.0181 23.3375 83.9781 23.6975H79.8381V23.6575C79.8381 22.2375 80.2581 20.0175 86.2581 20.0175H87.3581C93.5981 20.0175 93.9981 21.9375 93.9981 23.4975V27.6175C93.9981 27.9375 94.1181 28.1575 94.5581 28.1575H95.1381V31.0775H91.5181C90.6781 31.0775 89.9781 30.5975 89.8181 29.5975ZM89.6981 26.2975V25.9575L85.4381 26.6175C84.2981 26.7975 83.8181 27.0175 83.8181 27.6175V27.6375C83.8181 28.2175 84.2581 28.8575 85.7781 28.8575C88.1781 28.8575 89.6981 27.5775 89.6981 26.2975Z"
        fill="white"
      />
      <path
        d="M80.74 16.1975L73.54 31.0775H67.74L60 16.1975H65.28L70.76 27.1375L75.9 16.1975H80.74Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_4070_431110"
          x="-8"
          y="-8"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_4070_431110"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_4070_431110"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const Logo = memo(
  ({ variant, className }: { variant: 'default' | 'white' | 'themed'; className?: string }) => {
    const rectClassName = twMerge(variant === 'themed' ? 'fill-vault_text_opposite' : 'fill-black');

    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <g filter="url(#filter0_b_4070_431110)">
          <rect
            width="48"
            height="48"
            rx="24"
            className={twMerge(
              variant === 'themed'
                ? 'fill-vault_text'
                : variant === 'white'
                  ? 'fill-white'
                  : 'fill-yellow100',
            )}
          />
          <rect
            x="8.33447"
            y="22.879"
            width="2.24194"
            height="2.24194"
            rx="1.12097"
            className={rectClassName}
          />
          <rect
            x="13.1826"
            y="20.6371"
            width="2.24194"
            height="6.72581"
            rx="1.12097"
            className={rectClassName}
          />
          <rect
            x="18.0308"
            y="15.5928"
            width="2.24194"
            height="16.8145"
            rx="1.12097"
            className={rectClassName}
          />
          <rect
            x="22.8789"
            y="10.9688"
            width="2.24194"
            height="26.0625"
            rx="1.12097"
            className={rectClassName}
          />
          <rect
            x="27.7271"
            y="15.5928"
            width="2.24194"
            height="16.8145"
            rx="1.12097"
            className={rectClassName}
          />
          <rect
            x="32.5752"
            y="20.6371"
            width="2.24194"
            height="6.72581"
            rx="1.12097"
            className={rectClassName}
          />
          <rect
            x="37.4238"
            y="22.879"
            width="2.24194"
            height="2.24194"
            rx="1.12097"
            className={rectClassName}
          />
        </g>
        <defs>
          <filter
            id="filter0_b_4070_431110"
            x="-8"
            y="-8"
            width="64"
            height="64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_4070_431110"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_4070_431110"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  },
);
