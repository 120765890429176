import { useEffect, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useAuthContext } from '../../contexts/AuthContext';
import { VaultContentType } from '../../graphql/generated';
import { useVaultContentPagination } from '../../hooks/useVaultContent';
import { getFromList } from '../../utils/arrayUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { SkeletonTrackFile, TrackFile } from './TrackFile';

type HorizontalTrackFileCarouselProps = {
  vaultId: string;
  artist: { id: string; name: string; linkValue: string };
  selectedTrack: string | null;
  setSelectedTrack: (trackId: string | null) => void;
};

export const HorizontalTrackFileCarousel: React.FC<HorizontalTrackFileCarouselProps> = ({
  vaultId,
  artist,
  selectedTrack,
  setSelectedTrack,
}) => {
  const { loggedInUser } = useAuthContext();
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const adminArtist = useMemo(() => {
    return getFromList(
      loggedInUser?.adminArtists,
      adminArtist => adminArtist.artistId === artist.id && adminArtist,
    );
  }, [loggedInUser?.adminArtists, artist.id]);

  const {
    orderedList: vaultContents,
    loadMoreNextPage,
    hasNextPage,
    isInitialLoading,
  } = useVaultContentPagination({
    vaultId,
    pageSize: 10,
    isOwner: adminArtist?.artistId === artist.id,
    contentType: VaultContentType.Track,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      loadMoreNextPage();
    }
  }, [inView, hasNextPage, loadMoreNextPage]);

  if (vaultContents.length === 0 && !isInitialLoading) {
    return null;
  }

  if (isInitialLoading) {
    return (
      <CarouselWrapper>
        <View className="no-scrollbar flex w-full flex-row gap-6 overflow-x-auto">
          <SkeletonTrackFile withVaultTheme />
          <SkeletonTrackFile withVaultTheme />
          <SkeletonTrackFile withVaultTheme />
          <SkeletonTrackFile withVaultTheme />
          <SkeletonTrackFile withVaultTheme />
          <SkeletonTrackFile withVaultTheme />
        </View>
      </CarouselWrapper>
    );
  }

  return (
    <CarouselWrapper>
      <View className="no-scrollbar flex w-full gap-4 overflow-x-auto">
        <AnimatePresence>
          {vaultContents.map((track, i) => {
            if (track.__typename !== 'VaultTrack') return null;

            return (
              <motion.div
                key={track.id}
                className="w-28 shrink-0"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.2 }}
              >
                <TrackFile
                  track={track}
                  type="message_attachment"
                  isSelected={selectedTrack === track.id}
                  onClick={() => setSelectedTrack(selectedTrack === track.id ? null : track.id)}
                  containerRef={i === vaultContents.length - 1 ? ref : undefined}
                  vaultContentId={track.id}
                  withVaultTheme
                />
              </motion.div>
            );
          })}
        </AnimatePresence>
      </View>
    </CarouselWrapper>
  );
};

const CarouselWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <View className="flex w-full flex-col gap-4">
        <Text className="pl-4 font-title !text-title-m font-medium text-vault_text">
          From the vault
        </Text>
        <View className="relative">
          {children}
          {/* Gradient on the left */}
          <View className="pointer-events-none absolute inset-y-0 -left-4 z-overlay w-24 bg-gradient-to-r from-vault_background to-transparent" />
          {/* Gradient on the right */}
          <View className="pointer-events-none absolute inset-y-0 -right-4 z-overlay w-24 bg-gradient-to-l from-vault_background to-transparent" />
        </View>
      </View>
      <View className="my-5 h-[1px] w-full bg-vault_text/5" />
    </>
  );
};
