import { useCallback, useEffect, useState } from 'react';

/**
 * Measures if the text is scrollable and returns a boolean
 * Returns undefined if the text is not mounted yet, which can be used for SSR fallbacks
 */
export const useFadedText = (
  wrapper: React.RefObject<HTMLElement>,
  text: React.RefObject<HTMLElement>,
  isLoading = false,
) => {
  const [isScrollable, setIsScrollable] = useState<boolean | undefined>(undefined);

  const updateScroll = useCallback(() => {
    if (wrapper.current && text.current) {
      const containerWidth = wrapper.current.offsetWidth;
      const textWidth = text.current.scrollWidth;

      setIsScrollable(textWidth > containerWidth);
    }
  }, [text, wrapper]);

  useEffect(() => {
    if (!wrapper.current || !text.current) return;

    const resizeObserver = new ResizeObserver(() => {
      updateScroll();
    });

    resizeObserver.observe(wrapper.current);
    resizeObserver.observe(text.current);

    // Initial check
    updateScroll();

    return () => {
      resizeObserver.disconnect();
    };
  }, [updateScroll, isLoading, wrapper, text]);

  return isScrollable;
};
