import { useCallback, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact } from 'lodash-es';
import millify from 'millify';
import { useInView } from 'react-intersection-observer';
import { Navigate, useNavigate } from 'react-router';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { faCalendarDays, faUsers } from '@soundxyz/font-awesome/pro-light-svg-icons';
import {
  faArrowUpRightFromSquare,
  faClose,
  faEllipsis,
  faEyeSlash,
  faGlobe,
  faLink,
  faPen,
  faTrash,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faMobile } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useInfiniteQuery } from '../../graphql/client';
import {
  type FragmentType,
  getFragment,
  MemberRowFragmentDoc,
  RsvpEventStatus,
  RsvpInsightHeaderFragmentDoc,
  RsvpInsightsDocument,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useCopy } from '../../hooks/useCopy';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { InsightHeaderSkeleton } from '../../screens/EventInsightsPage';
import { MyMemberRow } from '../../screens/settings/MySubscribersPage';
import { LoginStatus } from '../../types/authTypes';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { formatDateString } from '../../utils/textUtils';
import { BackButton } from '../buttons/BackButton';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { useRsvpEventForm } from '../rsvp/useRsvpEventForm';
import { SkeletonUserRow } from '../user/UserRow';
import { EmptyStateView } from '../views/EmptyStateView';

gql(/* GraphQL */ `
  fragment RsvpInsightHeader on RsvpEventPrivateInfo {
    id
    createdAt
    title
    coverImage {
      id
      rsvpCoverImageUrl: imageOptimizedUrl
    }
    description
    eventDate
    linkValue
    newSubscriptionCount
    receiptCount
    status
    artist {
      id
      profileImage {
        id
        artistFullProfileImageUrl: imageOptimizedUrl
      }
    }
  }

  query RsvpInsights($id: UUID!, $artistHandle: String!, $after: String, $first: Int) {
    allArtistMembershipReceipts(
      artistHandle: $artistHandle
      rsvpEventIds: [$id]
      after: $after
      first: $first
    ) {
      edges {
        cursor
        node {
          id
          rsvpDate: createdAt
          user {
            id
            ...userRow
          }
          membership {
            createdAt
            receipts
            vaultSubscription {
              ...MemberRow
            }
          }
          artist {
            id
            mainVault {
              id
              type
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

const LIMIT = 20;

export function RsvpInsightView({
  rsvpEvent,
}: {
  rsvpEvent: FragmentType<RsvpInsightHeaderFragmentDoc>;
}) {
  const { id, linkValue, status, artist } = getFragment(RsvpInsightHeaderFragmentDoc, rsvpEvent);
  const { artistHandle } = useArtistHandle();

  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });

  const { loggedInUser, loginStatus } = useAuthContext();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const navigate = useNavigate();

  const { isDesktop } = useWindow();

  const { deleteRsvpEvent, rsvpEventDeleting, deactivateRsvpEvent, rsvpEventDeactivating } =
    useRsvpEventForm();

  useVaultTheme();

  const {
    orderedList: members,
    isError,
    isLoading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(RsvpInsightsDocument, {
    filterQueryKey: {
      artistHandle,
      id,
    },
    staleTime: 0,
    getNextPageParam: ({ data }) => {
      return (
        data.allArtistMembershipReceipts.pageInfo.hasNextPage && {
          after: data.allArtistMembershipReceipts.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      !!id &&
      (({ pageParam }) => {
        return {
          id,
          artistHandle,
          after: pageParam?.after ?? null,
          first: LIMIT,
        };
      }),
    list: ({ allArtistMembershipReceipts }) => {
      return allArtistMembershipReceipts.edges.map(({ node }) => node);
    },
    uniq: ({ id }) => id,
  });

  const artistId = artist?.id;

  const link = useMemo(() => {
    return generateShareLink({
      artistLinkValue: artistHandle,
      path: linkValue ? `/d/${linkValue}` : '/',
      inviteCode: loggedInUser?.adminArtists?.some(adminArtist => adminArtist.artistId === artistId)
        ? null
        : loggedInUser?.inviteCode,
    });
  }, [artistHandle, linkValue, loggedInUser, artistId]);

  const { copy } = useCopy({ successMessage: 'Copied to clipboard', text: link });

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isAtBottom, isFetchingNextPage]);

  const isOwner =
    !!artistHandle &&
    (loggedInUser?.adminArtists?.some(({ artistLinks }) => artistLinks.includes(artistHandle)) ??
      false);

  const isActive = status === RsvpEventStatus.Active;

  const buttons: ActionBottomsheetProps['buttons'] = useMemo(() => {
    const buttonClassName =
      'border-b-vault_text/5 bg-vault_text/10 hover:bg-vault_text/20 text-vault_text ease-in-out duration-300 transition-all md2:h-[45px] text-[16px]/[20px] justify-between gap-4';

    return compact([
      id &&
        isActive && {
          label: 'View public page',
          trailingIcon: faGlobe,
          type: 'secondary',
          className: buttonClassName,
          onClick: () => {
            window.open(link, '_blank');
            closeBottomsheet();
          },
        },
      id && {
        label: 'Edit',
        trailingIcon: faPen,
        type: 'secondary',
        className: buttonClassName,
        onClick: () => {
          trackEvent({
            type: EVENTS.START_RSVP_EDIT,
            properties: {
              dropId: id,
              artistHandle,
            },
          });
          navigate(artistNavigationPath(artistHandle, `/rsvp/edit/${id}`));
          closeBottomsheet();
        },
      },
      isActive && {
        label: 'Text vault members',
        className: buttonClassName,
        trailingIcon: faMobile,
        type: 'secondary',
        onClick: () => {
          navigate(artistNavigationPath(artistHandle, '/announcements/create'));
          closeBottomsheet();
        },
      },
      isActive && {
        label: 'Copy link',
        className: buttonClassName,
        trailingIcon: faLink,
        type: 'secondary',
        onClick: () => {
          copy();
          closeBottomsheet();
        },
      },
      id &&
        status &&
        isActive && {
          label: 'End drop',
          trailingIcon: faEyeSlash,
          type: 'secondary',
          className: buttonClassName,
          loading: rsvpEventDeactivating,
          onClick: async () => {
            openBottomsheet({
              type: 'CONFIRMATION',
              confirmationBottomsheetProps: {
                subText:
                  'Are you sure you want to end this drop? Your drop will not be visible to others and this cannot be reversed.',
                confirmButtonText: 'Confirm',
                onConfirm: async () => {
                  await deactivateRsvpEvent(id);
                  closeBottomsheet();
                },
              },
            });
          },
        },
      id && {
        label: 'Delete',
        trailingIcon: faTrash,
        type: 'secondary',
        className: buttonClassName,
        loading: rsvpEventDeleting,
        onClick: async () => {
          openBottomsheet({
            type: 'CONFIRMATION',
            confirmationBottomsheetProps: {
              subText: 'Are you sure you want to delete this drop?',
              confirmButtonText: 'Delete',
              onConfirm: async () => {
                await deleteRsvpEvent(id);
                closeBottomsheet();
                navigate(artistNavigationPath(artistHandle, '/dashboard'));
              },
            },
          });
        },
      },
    ]);
  }, [
    id,
    isActive,
    status,
    rsvpEventDeactivating,
    rsvpEventDeleting,
    link,
    closeBottomsheet,
    navigate,
    artistHandle,
    copy,
    openBottomsheet,
    deactivateRsvpEvent,
    deleteRsvpEvent,
  ]);

  const renderItem = useCallback((_index: number, item: (typeof members)[number]) => {
    const {
      id,
      user,
      membership: { vaultSubscription, createdAt },
      artist,
      rsvpDate,
    } = item;
    const subscription = getFragment(MemberRowFragmentDoc, vaultSubscription);
    return (
      <MyMemberRow
        id={id}
        source="subscribers"
        createdAt={createdAt}
        user={user}
        phone={subscription?.phone ?? null}
        email={subscription?.email ?? null}
        userLocation={subscription?.userLocation ?? null}
        joinedViaReferralCode={subscription?.joinedViaReferralCode ?? null}
        isTrial={subscription?.isTrial ?? null}
        vaultSubscriptionSourceText={subscription?.vaultSubscriptionSourceText ?? 'Profile signup'}
        vaultSubscriptionSourceType={subscription?.vaultSubscriptionSourceType ?? null}
        artistMembership={item.membership}
        className="pb-6"
        withVaultTheme
        artist={artist}
        dateSection={{
          custom: `RSVP'd ${formatDateString({ date: rsvpDate, format: 'month_day_year' })}`,
        }}
      />
    );
  }, []);

  const Header = useCallback(() => {
    if (rsvpEvent == null) {
      return <InsightHeaderSkeleton />;
    }

    return <InsightHeader rsvpEvent={rsvpEvent} rsvpEventLink={link} isActive={isActive} />;
  }, [isActive, link, rsvpEvent]);

  const EmptyState = useCallback(() => {
    if (isLoading) {
      return (
        <>
          <SkeletonUserRow profileImageClassName="w-[64px] h-[64px] rounded-full" withVaultTheme />
          <SkeletonUserRow profileImageClassName="w-[64px] h-[64px] rounded-full" withVaultTheme />
          <SkeletonUserRow profileImageClassName="w-[64px] h-[64px] rounded-full" withVaultTheme />
        </>
      );
    }

    if (isError) {
      return (
        <ErrorView onRetryClick={refetch} loggingType="rsvp_insights_page_retry" withVaultTheme />
      );
    }

    return (
      <EmptyStateView
        className="mt-12"
        icon={faUsers}
        iconClassName="mb-4 text-vault_text/60"
        customTitle={
          <Text className="font-title text-[22px] text-vault_text/60">No RSVPs yet</Text>
        }
        customSubtitle={
          <Text
            className="mt-2 font-normal text-vault_text/60 underline"
            onClick={rsvpEvent != null ? copy : undefined}
          >
            Copy URL and share your drop
          </Text>
        }
        subtitleClassName="text-vault_text/60"
        withVaultTheme
      />
    );
  }, [isLoading, isError, rsvpEvent, copy, refetch]);

  if (loginStatus !== LoginStatus.LOADING && !isOwner) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  return (
    <DefaultLayout
      withBottomNavigator={false}
      vaultId={undefined}
      messageChannelId={undefined}
      hasChatReadAccess={undefined}
      showBorder
      showRoundedTop
      withVaultTheme
      stretch
      headerLeft={<BackButton icon={!isDesktop ? faClose : undefined} withVaultTheme />}
      headerCenter={
        <Text className="font-title text-[18px]/[22px] font-medium text-vault_text">Insights</Text>
      }
      headerRight={
        <Button
          label=""
          iconOnly
          leadingIcon={faEllipsis}
          onClick={() => {
            openBottomsheet({
              type: BOTTOMSHEET_TYPES.ACTION,
              actionBottomsheetProps: {
                buttons,
                className: 'w-full md2:w-80',
                withVaultTheme: true,
              },
            });
          }}
          className="text-[24px] text-vault_text outline-none"
        />
      }
      contentClassName="md2:bg-vault_text/3"
      headerClassName="md2:bg-vault_text/3"
      isHeaderTransparent
    >
      <Virtuoso
        data={members}
        itemContent={renderItem}
        components={{
          Header,
          EmptyPlaceholder: EmptyState,
        }}
        className="h-full w-full overflow-x-hidden"
      />

      <div ref={bottomRef} />
    </DefaultLayout>
  );
}

function InsightHeader({
  rsvpEvent,
  rsvpEventLink,
  isActive,
}: {
  rsvpEvent: FragmentType<RsvpInsightHeaderFragmentDoc>;
  rsvpEventLink: string;
  isActive: boolean;
}) {
  const { title, coverImage, description, newSubscriptionCount, receiptCount, artist } =
    getFragment(RsvpInsightHeaderFragmentDoc, rsvpEvent);

  const coverImageUrl =
    coverImage?.rsvpCoverImageUrl ?? artist.profileImage?.artistFullProfileImageUrl;

  return (
    <View className="mt-6 flex flex-col items-start justify-start md2:mt-8">
      <View className="flex w-full flex-col items-start justify-start gap-4">
        <button
          className={twMerge(
            'w-full appearance-none border-none bg-transparent outline-none focus:outline-none',
            isActive ? 'cursor-pointer' : 'cursor-text select-text',
          )}
          onClick={() => isActive && window.open(rsvpEventLink, '_blank')}
        >
          <View className="flex w-full flex-row items-center justify-start gap-2">
            <View
              className={twMerge(
                'flex h-[56px] w-[56px] flex-shrink-0 items-center justify-center rounded-md',
                !!coverImageUrl ? 'bg-transparent' : 'bg-vault_text',
              )}
            >
              {coverImageUrl && (
                <Image
                  src={coverImageUrl}
                  alt="Cover Image"
                  className="h-full w-full rounded-md object-cover"
                />
              )}
            </View>
            <View className="flex flex-col items-start justify-start gap-1">
              <View className="flex items-center">
                <Text className="line-clamp-1   text-left font-title text-[29px] font-medium text-vault_text">
                  {title}
                </Text>
                {isActive && (
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className="ml-2 text-[18px] text-vault_text"
                  />
                )}
              </View>
              <View className="flex flex-row items-center gap-1">
                <FontAwesomeIcon icon={faCalendarDays} className="text-vault_text/60" />
                <Text className="mt-[1px] font-base text-[14px]/[18px] font-normal text-vault_text opacity-60">
                  RSVP {!isActive ? '• Canceled' : ''}
                </Text>
              </View>
            </View>
          </View>
        </button>
        {!!description && (
          <Text className="font-base text-[14px]/[18px] font-normal text-vault_text opacity-50">
            {description}
          </Text>
        )}
      </View>
      <View className="my-8 flex w-full flex-row items-center justify-start gap-2">
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(receiptCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">RSVPs</Text>
        </View>
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(newSubscriptionCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">Total signups</Text>
        </View>
      </View>
    </View>
  );
}
