import { captureException } from '@sentry/react';
import { proxy } from 'valtio';
import { MediaType } from '../graphql/generated';
import { fileIdentifier } from '../utils/s3Utils';
import { processAudio } from '../utils/waveformUtils';

export type Recording = {
  identifier: string;
  progress: number;
  objectUrl: string;
  uploaded: boolean;
  mediaId: string | null;
  cdnUrl: string | null;
  type: MediaType;
  peaks?: number[];
  duration?: number;
} | null;

export const AudioAttachment = proxy<{
  recording: Recording;
  isRecording: boolean;
  isUploading: boolean;
  isProcessingAudioFile: boolean;
  setRecording: (file: File) => void;
  updateRecordingWithUploadResult: (mediaId: string | null, cdnUrl: string | null) => void;
  updateProgress: (progress: number) => void;
  clearRecording: () => void;
}>({
  recording: null,
  isRecording: false,
  isUploading: false,
  isProcessingAudioFile: false,
  setRecording(file: File) {
    AudioAttachment.isProcessingAudioFile = true;
    const newRecording = {
      identifier: fileIdentifier(file),
      progress: 0,
      objectUrl: URL.createObjectURL(file),
      uploaded: false,
      mediaId: null,
      cdnUrl: null,
      type: MediaType.Recording,
    };
    AudioAttachment.recording = newRecording;

    processAudio(file)
      .then(({ normalizedPeaks, duration }) => {
        AudioAttachment.recording = { ...newRecording, peaks: normalizedPeaks, duration };
        AudioAttachment.isProcessingAudioFile = false;
      })
      .catch(error => {
        captureException(error, {
          tags: {
            selectedFileName: file.name,
            selectedFileSize: file.size,
            selectedFileType: file.type,
            feature: 'process audio recording',
          },
        });
        AudioAttachment.isProcessingAudioFile = false;
        AudioAttachment.clearRecording();
      });
  },
  updateProgress(progress: number) {
    if (AudioAttachment.recording)
      AudioAttachment.recording = { ...AudioAttachment.recording, progress };
  },
  updateRecordingWithUploadResult(mediaId: string | null, cdnUrl: string | null) {
    if (AudioAttachment.recording) {
      AudioAttachment.recording.mediaId = mediaId;
      AudioAttachment.recording.cdnUrl = cdnUrl;
      AudioAttachment.recording.uploaded = true;
    }
  },
  clearRecording() {
    if (AudioAttachment.recording) URL.revokeObjectURL(AudioAttachment.recording.objectUrl);
    AudioAttachment.isUploading = false;
    AudioAttachment.recording = null;
  },
});
