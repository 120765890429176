import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { startCase, toLower } from 'lodash-es';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { uuidv4 } from '@soundxyz/utils';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import {
  type FragmentType,
  getFragment,
  MusicCampaignViewFragmentDoc,
  PresaveStatus,
  type PrivateMusicampaignViewFragment,
  PrivateMusicampaignViewFragmentDoc,
  type PublicMusicampaignViewFragment,
  PublicMusicampaignViewFragmentDoc,
  ReleaseCampaignStatus,
  ThirdPartyPlatform,
} from '../../graphql/generated';
import { useAppleMusicAuth } from '../../hooks/appleMusic/useAppleMusicAuth';
import { useSpotifyAuth } from '../../hooks/spotify/useSpotifyAuth';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useIsClamped } from '../../hooks/useIsClamped';
import { useStableCallback } from '../../hooks/useStableCallback';
import { getFromList } from '../../utils/arrayUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { removeSearchParam } from '../../utils/pathUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';
import { DSPRow } from './DSPRow';
import { useAppleMusicPresaveCampaign } from './presave/useAppleMusicPresaveCampaign';
import { useSpotifyPresaveCampaign } from './presave/useSpotifyPresaveCampaign';
import { type CampaignState, CampaignType } from './schema';

gql(/* GraphQL */ `
  fragment MusicCampaignView on ReleaseCampaign {
    id
    __typename
    linkValue
    artist {
      id
      name
      isAuthUserAdmin
      linkValue
      profileImage {
        id
        artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        artistProfileImageUrl: imageOptimizedUrl
      }
    }
    ...PublicMusicampaignView
    ...PrivateMusicampaignView
  }

  fragment PublicMusicampaignView on ReleaseCampaignPublicInfo {
    id
    __typename
    title
    description
    releaseDate
    linkValue
    status
    currentState
    initialReleaseImageUrl
    showSpotifyPresaveFirst
    showAppleMusicPresave
    showSpotifyPresave
    externalLinks {
      cta
      enabled
      id
      platform
      url
    }
    coverImage {
      id
      campaignCoverImageUrl: imageOptimizedUrl
    }
    artist {
      id
      name
      isAuthUserAdmin
      linkValue
      profileImage {
        id
        artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        artistProfileImageUrl: imageOptimizedUrl
      }
    }
  }
  fragment PrivateMusicampaignView on ReleaseCampaignPrivateInfo {
    id
    __typename
    title
    description
    releaseDate
    linkValue
    status
    currentState
    initialReleaseImageUrl
    showSpotifyPresaveFirst
    showAppleMusicPresave
    showSpotifyPresave
    externalLinks {
      cta
      enabled
      id
      platform
      url
    }
    coverImage {
      id
      campaignCoverImageUrl: imageOptimizedUrl
    }
    artist {
      id
      name
      isAuthUserAdmin
      linkValue
      profileImage {
        id
        artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        artistProfileImageUrl: imageOptimizedUrl
      }
    }
  }
`);

/**
 * IMPORTANT NOTE ON TAILWIND
 * Apply all desktop styles with the following logic:
 * !isPreview && "md2:class"
 * the Preview is a FAKE mobile UI, if you don't do this it will look like desktop
 */
export function MusicCampaignView(
  props:
    | {
        campaignFrag: FragmentType<MusicCampaignViewFragmentDoc>;
        isPreview: false;
      }
    | {
        artistId: string | undefined;
        fields: CampaignState['fields'];
        isPreview: true;
      },
) {
  const { value: spotifyConnectEnabled } = useGate(FEATURE_GATES.SPOTIFY_CONNECT);
  const { value: appleMusicConnectEnabled } = useGate(FEATURE_GATES.VAULT_APPLE_MUSIC_CONNECT);

  const { artistHandle } = useArtistHandle();
  const { isPreview } = props;

  const { loggedInUser } = useAuthContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const campaignData = isPreview
    ? null
    : getFragment(MusicCampaignViewFragmentDoc, props.campaignFrag);
  const publicCampaign =
    campaignData?.__typename === 'ReleaseCampaignPublicInfo'
      ? getFragment(PublicMusicampaignViewFragmentDoc, campaignData)
      : null;
  const privateCampaign =
    campaignData?.__typename === 'ReleaseCampaignPrivateInfo'
      ? getFragment(PrivateMusicampaignViewFragmentDoc, campaignData)
      : null;
  const campaign = publicCampaign || privateCampaign;

  const isStreamingCampaign = campaign?.currentState === 'STREAMING';

  const {
    isPresaving: isPresavingSpotify,
    loadingPresaveStatus: loadingSpotifyPresaveStatus,
    presave: presaveSpotify,
    presaveStatus: spotifyPresaveStatus,
  } = useSpotifyPresaveCampaign({
    campaign: campaignData,
    enabled: !isStreamingCampaign,
  });

  const {
    isPresaving: isPresavingAppleMusic,
    loadingPresaveStatus: loadingAppleMusicPresaveStatus,
    presave: presaveAppleMusic,
    presaveStatus: appleMusicPresaveStatus,
  } = useAppleMusicPresaveCampaign({
    campaign: campaignData,
  });

  const spotifyAuth = useSpotifyAuth({
    enabled: spotifyConnectEnabled,
  });

  const isSpotifyAccountLinked = !!loggedInUser?.spotifyAuthConnection?.spotifyUserId;
  const isSpotifyAccountConnected = spotifyAuth.type === 'already-connected';

  const appleMusicAuth = useAppleMusicAuth({
    enabled: appleMusicConnectEnabled,
  });

  const isAppleMusicAccountLinked = !!loggedInUser?.appleMusicAuthConnections.length;
  const isAppleMusicAccountConnected =
    appleMusicAuth.type === 'apple-music-already-linked' ||
    appleMusicAuth.type === 'apple-music-connected-without-user';

  const { openToast } = useToast();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const adminArtist = useMemo(() => {
    const compareId = isPreview ? props.artistId : campaign?.artist.id;
    if (!compareId) return undefined;

    return getFromList(
      loggedInUser?.adminArtists,
      artist => artist.artistId === compareId && artist,
    );
  }, [isPreview, props, campaign?.artist.id, loggedInUser?.adminArtists]);

  const artistName = isPreview
    ? adminArtist?.artistName || adminArtist?.artistMainLinkValue
    : campaign?.artist.name || campaign?.artist.linkValue;

  const artistImageUrl = isPreview
    ? adminArtist?.artistProfileImage?.artistSmallProfileImageUrl
    : campaign?.artist.profileImage?.artistSmallProfileImageUrl;

  const artistLinkValue = isPreview ? undefined : campaign?.artist.linkValue;

  const coverImageUrl = isPreview
    ? props.fields.image ||
      props.fields.thirdPartyImageUrl ||
      adminArtist?.artistProfileImage?.artistProfileImagedUrl
    : campaign?.coverImage?.campaignCoverImageUrl ||
      campaign?.initialReleaseImageUrl ||
      campaign?.artist.profileImage?.artistProfileImageUrl;

  const title = isPreview ? props.fields.title : campaign?.title;

  const description = isPreview ? props.fields.description : campaign?.description;

  const [isTruncatedDescription, setIsTruncatedDescription] = useState(true);
  const toggleTruncated = useCallback(() => {
    setIsTruncatedDescription(value => !value);
  }, []);
  const textRef = useRef<HTMLDivElement>(null);
  const { isClamped } = useIsClamped(textRef);

  const randomUUID = useMemo(() => uuidv4(), []);

  const background = useMemo(() => {
    return (
      <>
        <View className="absolute inset-0 z-base overflow-hidden">
          {coverImageUrl != null ? (
            <Image
              src={coverImageUrl}
              alt="Blurred Track/Album Cover"
              className="h-full w-full object-cover opacity-75 blur-2xl filter"
            />
          ) : (
            <UserPlaceholderImage
              id={randomUUID}
              className="h-full w-full object-cover opacity-75 blur-2xl filter"
            />
          )}
        </View>

        <View
          className={twMerge(
            'absolute bottom-0 z-base hidden h-full w-full bg-gradient-to-b from-transparent to-black',
            !isPreview && 'md2:block',
          )}
        />
        <View
          className={twMerge(
            'absolute bottom-0 z-base h-full w-full bg-gradient-to-b from-transparent via-black/80 to-black',
            !isPreview && 'md2:hidden',
          )}
        />
      </>
    );
  }, [coverImageUrl, isPreview, randomUUID]);

  const onPresaveClick = useStableCallback(({ platform }: { platform: ThirdPartyPlatform }) => {
    if (!campaign || isStreamingCampaign) return;

    // If the user is the owner, display a toast
    if (adminArtist?.artistId === campaign.artist.id) {
      openToast({
        text: 'Artists cannot pre-save their own songs.',
        variant: 'primary',
      });
      return;
    }

    if (platform === ThirdPartyPlatform.Spotify) {
      presaveSpotify();
    }

    if (platform === ThirdPartyPlatform.AppleMusic) {
      presaveAppleMusic();
    }
  });

  const alreadyPresavedSpotify =
    !isStreamingCampaign && !!spotifyPresaveStatus && spotifyPresaveStatus !== PresaveStatus.Failed;

  const alreadyPresavedAppleMusic =
    !isStreamingCampaign &&
    !!appleMusicPresaveStatus &&
    appleMusicPresaveStatus !== PresaveStatus.Failed;

  const loading =
    isPresavingSpotify ||
    isPresavingAppleMusic ||
    (!isStreamingCampaign && loadingSpotifyPresaveStatus) ||
    (!isStreamingCampaign && loadingAppleMusicPresaveStatus);

  const generateCta = useCallback(
    (
      link:
        | PublicMusicampaignViewFragment['externalLinks'][number]
        | PrivateMusicampaignViewFragment['externalLinks'][number],
    ) => {
      let customCta = '';

      if (link.platform === ThirdPartyPlatform.Spotify) {
        customCta = isStreamingCampaign
          ? link.cta || 'Play'
          : alreadyPresavedSpotify
            ? 'Pre-saved'
            : 'Pre-save';
      } else if (link.platform === ThirdPartyPlatform.AppleMusic) {
        customCta = isStreamingCampaign
          ? link.cta || 'Play'
          : alreadyPresavedAppleMusic
            ? 'Pre-added'
            : 'Pre-add';
      } else {
        customCta = link.cta || 'Play';
      }

      return customCta;
    },
    [alreadyPresavedAppleMusic, alreadyPresavedSpotify, isStreamingCampaign],
  );

  const previewLinks = useMemo(() => {
    if (!isPreview) return [];

    if (props.fields.campaignType === CampaignType.Presave) {
      const spotifyLink = props.fields.showSpotifyPresave
        ? [
            <DSPRow
              key={ThirdPartyPlatform.Spotify}
              type={CampaignType.Presave}
              dspInfo={{
                buttonText: 'Pre-save',
                key: ThirdPartyPlatform.Spotify,
                name: 'Spotify',
                showLink: true,
                uri: '#', // Safe, not actually being used
              }}
              onClick={undefined}
              loading={false}
              campaignCurrentState={undefined}
              disabled
              isPreview
            />,
          ]
        : [];

      const appleMusicLink = props.fields?.showAppleMusicPresave
        ? [
            <DSPRow
              key={ThirdPartyPlatform.AppleMusic}
              type={CampaignType.Presave}
              dspInfo={{
                buttonText: 'Pre-add',
                key: ThirdPartyPlatform.AppleMusic,
                name: 'Apple Music',
                showLink: true,
                uri: '#', // Safe, not actually being used
              }}
              onClick={undefined}
              loading={false}
              campaignCurrentState={undefined}
              disabled
              isPreview
            />,
          ]
        : [];

      const links = props.fields?.showSpotifyPresaveFirst
        ? [...spotifyLink, ...appleMusicLink]
        : [...appleMusicLink, ...spotifyLink];

      // Filter out Spotify or Apple Music based on their show flags
      links.filter(
        link =>
          (link.key === ThirdPartyPlatform.Spotify && props.fields.showSpotifyPresave) ||
          (link.key === ThirdPartyPlatform.AppleMusic && props.fields.showAppleMusicPresave),
      );
      return links;
    }

    return props.fields.dsps
      .filter(dsp => dsp.showLink && dsp.uri)
      .map(dsp => (
        <DSPRow
          key={dsp.key}
          type={CampaignType.Stream}
          dspInfo={{
            ...dsp,
            key: dsp.key,
            buttonText: 'Play',
          }}
          onClick={undefined}
          loading={false}
          campaignCurrentState={undefined}
          disabled
          isPreview
        />
      ));
  }, [isPreview, props]);

  const links = useMemo(() => {
    if (isPreview) return previewLinks;

    // Only shown on ongoing presave campaigns
    const spotifyLink = campaign?.showSpotifyPresave
      ? [
          <DSPRow
            key={ThirdPartyPlatform.Spotify}
            type={CampaignType.Presave}
            dspInfo={{
              buttonText: generateCta({
                id: 'presave-spotify',
                cta: 'Pre-save',
                enabled: true,
                platform: ThirdPartyPlatform.Spotify,
                url: '', // Safe, not actually being used
              }),
              key: ThirdPartyPlatform.Spotify,
              name: 'Spotify',
              showLink: true,
              uri: '', // Safe, not actually being used
            }}
            onClick={() => onPresaveClick({ platform: ThirdPartyPlatform.Spotify })}
            isPreview={false}
            loading={loading}
            disabled={isPresavingSpotify || alreadyPresavedSpotify}
            campaignCurrentState={campaign.currentState}
          />,
        ]
      : [];

    const appleMusicLink = campaign?.showAppleMusicPresave
      ? [
          <DSPRow
            key={ThirdPartyPlatform.AppleMusic}
            type={CampaignType.Presave}
            dspInfo={{
              buttonText: generateCta({
                id: 'presave-apple-music',
                cta: 'Pre-add',
                enabled: true,
                platform: ThirdPartyPlatform.AppleMusic,
                url: '#', // Safe, not actually being used
              }),
              key: ThirdPartyPlatform.AppleMusic,
              name: 'Apple Music',
              showLink: true,
              uri: '#', // Safe, not actually being used
            }}
            onClick={() => onPresaveClick({ platform: ThirdPartyPlatform.AppleMusic })}
            isPreview={false}
            loading={loading}
            disabled={isPresavingAppleMusic || alreadyPresavedAppleMusic}
            campaignCurrentState={campaign.currentState}
          />,
        ]
      : [];

    const links = campaign?.showSpotifyPresaveFirst
      ? [...spotifyLink, ...appleMusicLink]
      : [...appleMusicLink, ...spotifyLink];

    // Only used on streaming campaigns or finished presave campaigns
    const externalLinks = campaign?.externalLinks
      .filter(externalLink => externalLink.enabled)
      .map(externalLink => {
        return (
          <DSPRow
            key={externalLink.id}
            type={isStreamingCampaign ? CampaignType.Stream : CampaignType.Presave}
            dspInfo={{
              buttonText: generateCta(externalLink),
              key: externalLink.platform,
              name: startCase(toLower(externalLink.platform.replace('/_/', ' '))),
              showLink: true,
              uri: externalLink.url,
            }}
            onClick={() => onPresaveClick({ platform: externalLink.platform })}
            isPreview={false}
            loading={loading}
            disabled={isPresavingSpotify || isPresavingAppleMusic}
            campaignCurrentState={campaign.currentState}
          />
        );
      });

    return isStreamingCampaign ? externalLinks ?? [] : links;
  }, [
    isPreview,
    previewLinks,
    campaign?.showSpotifyPresaveFirst,
    campaign?.showSpotifyPresave,
    campaign?.currentState,
    campaign?.showAppleMusicPresave,
    campaign?.externalLinks,
    generateCta,
    loading,
    isPresavingSpotify,
    alreadyPresavedSpotify,
    isPresavingAppleMusic,
    alreadyPresavedAppleMusic,
    isStreamingCampaign,
    onPresaveClick,
  ]);

  const { hasSpotifyLink, hasAppleMusicLink } = useMemo(() => {
    return {
      hasSpotifyLink: links.some(link => link.props.dspInfo.key === ThirdPartyPlatform.Spotify),
      hasAppleMusicLink: links.some(
        link => link.props.dspInfo.key === ThirdPartyPlatform.AppleMusic,
      ),
    };
  }, [links]);

  const spotifyConnected = useMemo(
    () =>
      hasSpotifyLink &&
      (isSpotifyAccountLinked || isSpotifyAccountConnected) &&
      !!spotifyAuth.authCode,
    [hasSpotifyLink, isSpotifyAccountLinked, isSpotifyAccountConnected, spotifyAuth.authCode],
  );

  const appleMusicConnected = useMemo(
    () =>
      hasAppleMusicLink &&
      (isAppleMusicAccountConnected || isAppleMusicAccountLinked) &&
      !!appleMusicAuth.userToken,
    [
      hasAppleMusicLink,
      isAppleMusicAccountConnected,
      isAppleMusicAccountLinked,
      appleMusicAuth.userToken,
    ],
  );

  const isPresave = campaign?.currentState === 'PRESAVE';

  const showConnectionModal = searchParams.get('showConnectionModal') === 'true';

  const hasPlatformConnected = useMemo(() => {
    return (spotifyConnected || appleMusicConnected) && !showConnectionModal;
  }, [spotifyConnected, appleMusicConnected, showConnectionModal]);

  const showConnectSection = isPreview
    ? props.fields.campaignType === CampaignType.Stream
    : !isPresave;

  const showContentSection = links.length > 0 || showConnectSection;

  const getPaddingTop = useMemo(() => {
    const paddingValuesWithConnect = ['pt-[20vh]', 'pt-[15vh]', 'pt-[10vh]', 'pt-[5vh]'];
    const paddingValuesWithoutConnect = [
      'pt-[30vh]',
      'pt-[25vh]',
      'pt-[20vh]',
      'pt-[10vh]',
      'pt-[5vh]',
    ];

    const paddingArray = showConnectSection
      ? paddingValuesWithConnect
      : paddingValuesWithoutConnect;

    const index = Math.max(0, Math.min(links.length - 1, paddingArray.length - 1));

    return paddingArray[index] || (showConnectSection ? 'pt-[20vh]' : 'pt-[30vh]');
  }, [links.length, showConnectSection]);

  const onServiceError = useStableCallback((service: 'apple' | 'spotify') => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CUSTOM,
      customBottomsheetProps: {
        body: (
          <View className="mx-4 flex flex-col gap-4 text-center">
            <Text className="font-title text-title-l">
              {`Sorry, you must have ${service === 'apple' ? 'an Apple Music' : 'a Spotify'} Premium account`}
            </Text>

            <Text className="text-base-l text-base400">
              {`We only support ${service === 'apple' ? 'Apple Music' : 'Spotify'} Premium accounts. You can still stream on your favorite platform without connecting.`}
            </Text>

            <Button className="mt-6" type="primary" label="Ok" onClick={() => closeBottomsheet()} />
          </View>
        ),
      },
    });
  });

  useEffect(() => {
    if (isPreview) return;

    if (campaign?.status !== ReleaseCampaignStatus.Active) {
      if (adminArtist?.artistId === campaign?.artist.id && campaign?.id) {
        navigate(artistNavigationPath(artistHandle, '/dashboard'));
      } else {
        navigate(ROUTES.NOT_FOUND);
      }
    }
  }, [
    artistHandle,
    campaign?.artist.id,
    campaign?.status,
    campaign?.id,
    adminArtist?.artistId,
    navigate,
    isPreview,
  ]);

  useEffect(() => {
    if (
      !isPreview &&
      searchParams.get('openBottomSheet') === 'connect' &&
      !!campaign &&
      !isPresave &&
      !hasPlatformConnected
    ) {
      setTimeout(() => {
        // Remove the search param
        removeSearchParam('openBottomSheet');

        openBottomsheet({
          type: 'CONNECT_STREAMING_PLATFORM',
          connectStreamingPlatformBottomSheetProps: {
            campaignId: campaign.id,
            artistHandle: campaign.artist.linkValue,
            campaignLinkValue: campaign.linkValue,
            hasSpotifyLink,
            hasAppleMusicLink,
            showConnectionModal,
            onServiceError,
          },
        });
      }, 500);
    }
  }, [
    campaign,
    hasAppleMusicLink,
    hasPlatformConnected,
    hasSpotifyLink,
    isPresave,
    isPreview,
    onServiceError,
    openBottomsheet,
    searchParams,
    setSearchParams,
    showConnectionModal,
  ]);

  return (
    <View
      className={twMerge(
        'box-border min-h-screen w-full items-center justify-center overflow-hidden pt-6',
        !isPreview && 'md2:flex md2:flex-col',
      )}
    >
      {background}

      <View
        className={twMerge(
          'no-scrollbar z-above1 flex w-full flex-col items-center overflow-y-auto',
          !showContentSection && 'justify-center',
          !isPreview && 'lt:max-w-[480px]',
        )}
      >
        <View
          className={twMerge(
            'relative mx-auto flex w-full flex-shrink-0 flex-col items-center justify-center overflow-hidden pb-3',
            showContentSection ? twMerge(getPaddingTop, 'md2:!pt-0') : 'pt-6',
          )}
        >
          {coverImageUrl != null ? (
            <Image
              src={coverImageUrl}
              alt="Track/Album Cover"
              className={twMerge(
                'aspect-square w-[140px] rounded-xl object-cover',
                !isPreview && 'md2:max-h-[240px] md2:w-full md2:max-w-[240px]',
              )}
            />
          ) : (
            <UserPlaceholderImage
              id={randomUUID}
              className={twMerge(
                'aspect-square w-[140px] rounded-xl',
                !isPreview && 'md2:max-h-[240px] md2:w-full md2:max-w-[240px]',
              )}
              svgClassName="rounded-xl"
            />
          )}
        </View>

        <View className="z-above1 flex w-full flex-col items-center">
          <Text
            className={twMerge(
              'mt-2 box-border line-clamp-2 w-full px-6 text-center text-[28px]/[34px] font-semibold text-white',
              !isPreview && 'md2:text-[40px]/[46px]',
            )}
          >
            {title}
          </Text>

          <a
            className={twMerge(
              'mt-3 box-border flex w-full items-center justify-center space-x-2 px-6 no-underline',
              !isPreview && 'md2:px-0',
            )}
            href={
              !!artistLinkValue?.trim() ? artistNavigationPath(artistLinkValue, '/') : undefined
            }
          >
            <ArtistProfileImage
              className={twMerge('h-6 w-6', !isPreview && 'md2:h-8 md2:w-8')}
              profileImageUrl={artistImageUrl}
              withVaultTheme
            />

            <Text
              className={twMerge(
                'line-clamp-1 text-[14px]/[18px] text-white',
                !isPreview && 'md2:text-[16px]/[20px] md2:font-medium',
              )}
            >
              {artistName}
            </Text>
          </a>

          {description != null && (
            <View
              className={twMerge(
                'mt-4 box-border flex w-full cursor-pointer flex-col items-center justify-center px-6 text-center text-base400',
                !isPreview && 'md2:w-full md2:px-0',
              )}
              onClick={toggleTruncated}
            >
              <View
                className={clsx(
                  'w-full text-[14px]/[18px] font-normal',
                  !isPreview && 'md2:text-[16px]/[20px]',
                  isTruncatedDescription ? 'line-clamp-2' : '',
                )}
                containerRef={textRef}
              >
                {description}
              </View>

              {isTruncatedDescription && isClamped && (
                <span className="text-[14px]/[18px] font-normal text-white/50 underline">more</span>
              )}
            </View>
          )}

          {showContentSection && (
            <View
              className={twMerge(
                'mb-6 mt-6 box-border flex w-[calc(100%-48px)] flex-col gap-4 rounded-xl bg-white/10 p-6 backdrop-blur-2xl md2:w-[440px]',
              )}
            >
              {showConnectSection && (
                <View className="mb-2 flex flex-shrink-0 flex-col gap-2">
                  <Text className="text-center font-title text-[22px] font-medium text-white">
                    Are you listening?
                  </Text>
                  <Text className="text-center font-base text-[16px]/[20px] text-white/60">
                    Stream my new song and prove you were there
                  </Text>

                  <Button
                    type="primary"
                    label="Prove it"
                    disabled={isPreview}
                    className={twMerge(
                      'mt-2 hover:bg-yellow100/70',
                      links.length > 0 && 'mb-4',
                      isPreview && 'hover:bg-yellow100 hover:!opacity-100', // disable hover on preview
                    )}
                    onClick={() => {
                      if (isPreview || !campaign?.id) return;

                      // If the user is the owner (not an admin), display a toast
                      if (adminArtist?.artistId === campaign.artist.id) {
                        openToast({
                          text: 'Artists cannot claim receipts for their own drops.',
                          variant: 'primary',
                        });
                        return;
                      }

                      if (hasPlatformConnected) {
                        const path = artistNavigationPath(
                          artistLinkValue,
                          `/s/${campaign.linkValue}/claim`,
                          'step=view',
                        );

                        navigate(path);
                        return;
                      }

                      openBottomsheet({
                        type: 'CONNECT_STREAMING_PLATFORM',
                        connectStreamingPlatformBottomSheetProps: {
                          campaignId: campaign.id,
                          artistHandle: campaign.artist.linkValue,
                          campaignLinkValue: campaign.linkValue,
                          hasSpotifyLink,
                          hasAppleMusicLink,
                          showConnectionModal,
                          onServiceError,
                        },
                      });
                    }}
                  />

                  {links.length > 0 && <View className="h-[1px] w-full bg-white/5" />}
                </View>
              )}

              <View
                className={twMerge(
                  'flex h-auto flex-col gap-4 overflow-hidden',
                  !isPreview && 'md2:-mx-6 md2:px-6',
                )}
              >
                {links}
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

export const MusicCampaignSkeleton = () => {
  return (
    <View className="h-full w-full items-center justify-center overflow-hidden md2:flex">
      <View className="flex flex-col items-center pt-6 ">
        <LoadingSkeleton className="relative mx-auto flex aspect-square w-[140px] flex-col items-center justify-center overflow-hidden rounded-xl md2:w-[240px]" />

        <View className="mt-8 flex w-full flex-col items-center">
          <LoadingSkeleton className="h-[30px] w-[120px]" />

          <View className="mt-2 flex items-center justify-center space-x-2">
            <LoadingSkeleton className="aspect-square h-[24px] rounded-full" />
            <LoadingSkeleton className="h-[24px] w-[100px]" />
          </View>

          <View className="mt-8 flex w-3/4 flex-row items-center justify-center text-center text-base400 md2:w-full md2:justify-start md2:text-left">
            <LoadingSkeleton className="h-[30px] w-[250px]" />
          </View>

          <LoadingSkeleton className="mt-8 flex h-[300px] w-[80vw] flex-col gap-8 rounded-xl px-6 py-8 md2:w-full" />
        </View>
      </View>
    </View>
  );
};
