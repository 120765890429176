import React, { type FC, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { gql } from '@soundxyz/gql-string';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { BackButton } from '../../components/buttons/BackButton';
import { Text } from '../../components/common/Text';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { EditTrackView } from '../../components/views/EditTrackView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useMutation, useQuery } from '../../graphql/client';

import { invalidateOperations } from '../../graphql/client';
import {
  EditVaultContentDocument,
  TrackDetailsByIdDocument,
  VaultContentAccessFeatureInput,
  VaultContentPaginationDocument,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getFromList } from '../../utils/arrayUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';

gql(/* GraphQL */ `
  query TrackDetailsById($vaultContentId: UUID!) {
    vaultContentById(vaultContentId: $vaultContentId) {
      __typename
      id
      vault {
        id
        artistId
      }
      ... on VaultTrack {
        id
        title
        caption
        featureAccess {
          feature {
            __typename
          }
        }
        downloadEnabled
      }
    }
  }

  mutation EditVaultContent($input: MutationEditVaultContentInput!) {
    editVaultContent(input: $input) {
      __typename
      ... on MutationEditVaultContentSuccess {
        data {
          id
          ...TrackFileInfo
          ...TrackRowInfo
          ...TrackItem
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

export const EditTrackPage: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { openBottomsheet } = useBottomsheetContainer();
  const [title, setTitle] = useState<string>('Loading...');
  const [caption, setCaption] = useState<string>('');
  const [featureAccess, setFeatureAccess] = useState<VaultContentAccessFeatureInput>(
    VaultContentAccessFeatureInput.PaidVaultContent,
  );
  const [isDownloadEnabled, setIsDownloadEnabled] = useState<boolean>(false);

  const { loggedInUser, loginStatus } = useAuthContext();

  useVaultTheme();

  const { vaultContentId } = useParams();
  const { artistHandle } = useArtistHandle();

  const adminArtist = useMemo(() => {
    if (!artistHandle) return undefined;
    return getFromList(
      loggedInUser?.adminArtists,
      adminArtist => adminArtist.artistLinks.includes(artistHandle.toLowerCase()) && adminArtist,
    );
  }, [loggedInUser?.adminArtists, artistHandle]);

  useSetMetaHeaders({
    title: 'Editing track splits',
  });

  const { data: vaultContent, isLoading } = useQuery(TrackDetailsByIdDocument, {
    variables: !!vaultContentId && { vaultContentId },
    staleTime: 0,
  });

  const { mutateAsync: editVaultContent } = useMutation(EditVaultContentDocument, {
    retry: 3,
    onSuccess() {
      invalidateOperations({
        operations: [VaultContentPaginationDocument],
      });
    },
  });

  useEffect(() => {
    if (vaultContent?.data.vaultContentById?.__typename !== 'VaultTrack') return;

    setTitle(vaultContent.data.vaultContentById?.title ?? '');
    setCaption(vaultContent.data.vaultContentById?.caption ?? '');

    const featureAccessList = vaultContent.data.vaultContentById.featureAccess;
    if (featureAccessList.some(access => access.feature.__typename === 'FreeVaultContent')) {
      // Track is a snippet for free tier users only
      setFeatureAccess(VaultContentAccessFeatureInput.FreeVaultContent);
    } else {
      // Full track is available to all users
      setFeatureAccess(VaultContentAccessFeatureInput.PaidVaultContent);
    }

    setIsDownloadEnabled(vaultContent.data.vaultContentById.downloadEnabled);
  }, [vaultContent?.data.vaultContentById]);

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const { openToast } = useToast();

  const goBack = useStableCallback(() => {
    if (
      (window.history.state.idx != null && window.history.state.idx > 0) ||
      (window.history?.length && window.history.length > 1)
    ) {
      navigate(-1);
    } else {
      navigate(artistNavigationPath(artistHandle, '/'));
    }
  });

  const onBackClick = useStableCallback(() => {
    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: { bottomsheetType: BOTTOMSHEET_TYPES.EXIT_FLOW, event: EVENTS.EDIT_TRACK },
      pathname,
    });

    openBottomsheet({
      type: 'EXIT_FLOW',
      exitFlowBottomsheetProps: {
        onConfirm: goBack,
        event: EVENTS.EDIT_TRACK,
      },
    });
  });

  const onSaveClick = useStableCallback(async () => {
    if (!vaultContentId) return;

    trackEvent({
      type: EVENTS.EDIT_TRACK,
      properties: { artistHandle: artistHandle.toLowerCase(), vaultContentId },
      pathname,
    });

    const { data } = await editVaultContent({
      input: {
        vaultContentId,
        title: title.trim() || null,
        caption: caption.trim() || null,
        featureAccess,
        downloadEnabled: isDownloadEnabled,
      },
    });
    if (data.editVaultContent.__typename !== 'MutationEditVaultContentSuccess') {
      switch (data.editVaultContent.__typename) {
        default: {
          openToast({
            text: 'An unexpected error occurred. Please try editing again later.',
            variant: 'error',
          });
          return;
        }
      }
    }
    goBack();
  });

  if (!vaultContentId || !artistHandle) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (
    (!isLoading && !vaultContent?.data.vaultContentById) ||
    (loginStatus === LoginStatus.LOGGED_IN &&
      vaultContent?.data.vaultContentById &&
      vaultContent.data.vaultContentById.vault.artistId !== adminArtist?.artistId)
  ) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5"
      headerLeft={<BackButton onClick={onBackClick} className="text-vault_text" />}
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">Edit track</Text>
      }
      headerRight={
        <div onClick={onSaveClick}>
          <Text className="font-title !text-base-m font-medium text-vault_accent hover:cursor-pointer active:opacity-70">
            Save
          </Text>
        </div>
      }
    >
      <EditTrackView
        title={title}
        setTitle={setTitle}
        caption={caption}
        setCaption={setCaption}
        isDisabled={!vaultContent?.data.vaultContentById}
        featureAccess={featureAccess}
        setFeatureAccess={setFeatureAccess}
        isDownloadEnabled={isDownloadEnabled}
        setIsDownloadEnabled={setIsDownloadEnabled}
      />
    </DefaultLayout>
  );
};
