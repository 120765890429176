import React, { useState } from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router';
import TextareaAutosize from 'react-textarea-autosize';
import { twMerge } from 'tailwind-merge';
import { loadTrack, useAudioController } from '../../../audio/AudioController';
import { togglePlayPause } from '../../../audio/AudioEngineHTML5';
import { useOverlayContainer } from '../../../contexts/OverlayContext';
import { TrackUpload, useTrackUpload } from '../../../contexts/TrackUploadContext';
import type { VaultContentAccessFeatureInput } from '../../../graphql/generated';
import { MediaType } from '../../../graphql/generated';
import { makeFragmentData, WaveformTrackInfoFragmentDoc } from '../../../graphql/generated';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useWindow } from '../../../hooks/useWindow';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { formatTime } from '../../../utils/textUtils';
import { PlayButtonView } from '../../audioPlayer/PlayButtonView';
import { Button } from '../../buttons/Button';
import { Image } from '../../common/Image';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { LoadingSkeleton } from '../../loading/LoadingSkeleton';
import { MediaViewer } from '../../message/MediaViewer';
import { Waveform } from '../../waveform/Waveform';
import { TrackFreeMemberAccessForm } from '../EditTrackView';
import { EnableDownloadToggleInput } from '../EditTrackView';

export function MediaUploadView() {
  const navigate = useNavigate();
  const { artistHandle } = useArtistHandle();

  const handleFreeMemberAccessChange = (accessType: VaultContentAccessFeatureInput) => {
    TrackUpload.featureAccessType = accessType;
  };

  const handleIsDownloadEnabledChange = (isEnabled: boolean) => {
    TrackUpload.downloadEnabled = isEnabled;
  };

  const {
    isPromptOpen,
    isUploadingTrack,
    fileRef,
    isUploadingTrackPreview,
    isUploadingVideoPreview,
    featureAccessType,
    downloadEnabled,
  } = useTrackUpload();

  return (
    <View className="no-scrollbar flex w-full flex-1 flex-col justify-between overflow-y-scroll pb-4">
      <View className="my-3 flex flex-col gap-6">
        {(fileRef.current?.contentType === 'TRACK' || isUploadingTrackPreview) && <WaveFormBox />}
        {fileRef.current?.contentType === 'IMAGE' && <ImageBox />}
        {(fileRef.current?.contentType === 'VIDEO' || isUploadingVideoPreview) && <VideoBox />}

        <View className="flex flex-col gap-2">
          <Text className="!text-base-l font-semibold text-vault_text">Title</Text>
          <TitleInput />
        </View>

        {(fileRef.current?.contentType === 'TRACK' || isUploadingTrackPreview) && (
          <View className="flex flex-col gap-2">
            <Text className="!text-base-l font-semibold text-vault_text">Description</Text>
            <CaptionInput />
          </View>
        )}

        <TrackFreeMemberAccessForm
          featureAccess={featureAccessType}
          handleFreeMemberAccessChange={handleFreeMemberAccessChange}
          contentType={fileRef.current?.contentType}
        />

        {fileRef.current?.contentType === 'TRACK' && (
          <EnableDownloadToggleInput
            isEnabled={downloadEnabled}
            setIsEnabled={handleIsDownloadEnabledChange}
          />
        )}
      </View>
      <Button
        label="Next"
        type="primary-themed"
        buttonType="submit"
        disabledClassName="opacity-30"
        disabled={isPromptOpen || isUploadingTrack}
        className="w-full"
        onClick={() =>
          navigate(artistNavigationPath(artistHandle, '/finalize-upload'), { replace: true })
        }
      />
    </View>
  );
}

function WaveFormBox() {
  const { pathname } = useLocation();

  const [played, setPlayed] = useState<boolean>(false);
  const { fileRef, isUploadingTrackPreview } = useTrackUpload();
  const { playing, activeTrackId } = useAudioController();

  if (isUploadingTrackPreview) {
    return (
      <LoadingSkeleton className="relative flex aspect-[4] w-full justify-end justify-items-center rounded-[20px] bg-vault_text/10" />
    );
  }

  if (!fileRef.current || fileRef.current.contentType !== 'TRACK') return null;

  const { duration, objectUrl, normalizedPeaks, vaultId } = fileRef.current;

  const onPlay = () => {
    if (!fileRef.current) return;
    trackEvent({
      type: playing ? EVENTS.PAUSE_TRACK : EVENTS.PLAY_TRACK,
      properties: { component: 'upload_view', vaultId },
      pathname,
    });

    if (!played) {
      loadTrack({
        audioSrc: objectUrl,
        trackId: 'upload',
        vaultId: null,
        component: 'upload_view',
        folderId: null,
      });
      setPlayed(true);
    } else {
      togglePlayPause();
    }
  };

  return (
    <View className="flex flex-row items-center gap-4 rounded-xl  bg-vault_text/10 p-4">
      <PlayButtonView
        isPlaying={playing && activeTrackId === 'upload'}
        isDisabled={false}
        onClick={onPlay}
        className="text-vault_text"
      />

      <View className="flex flex-1 flex-col gap-2">
        <View className="flex flex-row items-center justify-between">
          <span className="font-base !text-base-s text-vault_text/50">{formatTime(duration)}</span>
        </View>
        <View>
          <Waveform
            height={20}
            isDisabled={false}
            track={makeFragmentData(
              {
                normalizedPeaks,
                id: 'upload',
                duration,
                vaultId,
                parentVaultContentId: null,
              },
              WaveformTrackInfoFragmentDoc,
            )}
            isAuthor={false}
          />
        </View>
      </View>
    </View>
  );
}

function TitleInput() {
  const { title, isUploadingTrack } = useTrackUpload();

  return (
    <View className="border-b-1 flex w-full flex-row items-center border-x-0 border-t-0 border-solid border-vault_text/5 py-3">
      <input
        className={clsx(
          'w-full border-0 !text-base-l focus:font-normal focus:outline-none',
          'bg-transparent placeholder:text-vault_text/50',
          isUploadingTrack ? 'text-vault_text/50' : 'text-vault_text',
        )}
        placeholder="Add a title..."
        value={title}
        onChange={e => (TrackUpload.title = e.target.value)}
        disabled={isUploadingTrack}
      />
    </View>
  );
}

function CaptionInput() {
  const { caption, isUploadingTrack } = useTrackUpload();

  return (
    <TextareaAutosize
      className={twMerge(
        'mt-3 max-h-72 min-h-[70px] w-[inherit] resize-none rounded-md border p-3 font-base !text-base-l focus:font-normal focus:outline-none',
        'border-vault_text/5 bg-transparent text-vault_text placeholder:text-vault_text/50 disabled:text-vault_text/50',
      )}
      placeholder="Share a note about your song..."
      value={caption}
      onChange={e => (TrackUpload.caption = e.target.value)}
      disabled={isUploadingTrack}
    />
  );
}

function ImageBox() {
  const { fileRef } = useTrackUpload();

  if (fileRef.current == null || fileRef.current.contentType !== 'IMAGE') return null;

  const media = fileRef.current;

  return (
    <Image
      src={media.objectUrl}
      alt="uploading image"
      className="max-h-[50vh] max-w-full object-contain"
    />
  );
}

function VideoBox() {
  const { fileRef, title, isUploadingVideoPreview } = useTrackUpload();
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { isDesktop } = useWindow();

  const onViewMedia = useStableCallback(
    (
      medias: {
        id: string;
        url: string;
        type: MediaType;
      }[],
    ) => {
      openOverlay(<MediaViewer title={title} medias={medias} onClose={closeOverlay} />);
    },
  );

  if (isUploadingVideoPreview) {
    return <View className="max-h-[50vh] max-w-full bg-vault_text/10" />;
  }

  if (fileRef.current == null || fileRef.current.contentType !== 'VIDEO') return null;

  const media = fileRef.current;

  return (
    <>
      <video
        preload="auto"
        src={media.objectUrl}
        className="h-full max-h-[50vh] w-full max-w-full bg-vault_text/10 object-contain"
        onClick={e => {
          // on mobile web, the video automatically goes full screen
          if (isDesktop) {
            e.preventDefault();
            onViewMedia([{ id: '', url: media.objectUrl, type: MediaType.Video }]);
          }
        }}
        controls
        controlsList="nodownload"
      />
    </>
  );
}
