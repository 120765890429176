/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';
import type { WithLogger } from '@soundxyz/utils';

const SHOULD_LOG_TO_CONSOLE = import.meta.env.VITE_DEPLOY_ENVIRONMENT !== 'production';

const IS_LOCAL_HOST =
  typeof window !== 'undefined' &&
  (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1');

export const logger: WithLogger = {
  info(args, msg) {
    if (IS_LOCAL_HOST) {
      return console.log(msg, args);
    }
    if (SHOULD_LOG_TO_CONSOLE) {
      console.log(msg, args);
    }
    return datadogLogs.logger.info(msg, args);
  },
  error(args, msg) {
    if (IS_LOCAL_HOST) {
      return console.error(msg, args);
    }
    if (SHOULD_LOG_TO_CONSOLE) {
      console.error(msg, args);
    }
    return datadogLogs.logger.error(msg, args);
  },
  warn(args, msg) {
    if (IS_LOCAL_HOST) {
      return console.warn(msg, args);
    }
    if (SHOULD_LOG_TO_CONSOLE) {
      console.warn(msg, args);
    }
    return datadogLogs.logger.warn(msg, args);
  },
  debug(args, msg) {
    if (IS_LOCAL_HOST) {
      return console.debug(msg, args);
    }
    if (SHOULD_LOG_TO_CONSOLE) {
      console.debug(msg, args);
    }
    return datadogLogs.logger.debug(msg, args);
  },
  child(bindings) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const parentLogger = this;

    return {
      info(args, msg) {
        parentLogger.info({ ...args, ...bindings }, msg);
      },
      error(args, msg) {
        parentLogger.error({ ...args, ...bindings }, msg);
      },
      debug(args, msg) {
        parentLogger.debug({ ...args, ...bindings }, msg);
      },
      warn(args, msg) {
        parentLogger.warn({ ...args, ...bindings }, msg);
      },
      child(newBindings) {
        return parentLogger.child({ ...bindings, ...newBindings });
      },
    };
  },
};
