import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate, isSameDay } from 'date-fns';
import { compact } from 'lodash-es';
import millify from 'millify';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faCancel } from '@soundxyz/font-awesome/pro-light-svg-icons';
import {
  faMegaphone,
  faMessages,
  faPenToSquare,
  faReceipt,
  faThumbtack,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEllipsis } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faChevronLeft } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../constants/urlConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import {
  type FragmentType,
  getFragment,
  makeFragmentData,
  MessageChannelType,
  TierTypename,
  UserChannelRowFragmentDoc,
} from '../../graphql/generated';
import { useNewMessage } from '../../hooks/useNewMessage';
import { type FiltersSchema, filtersState, useUserChannels } from '../../hooks/useUserChannels';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { pastDateInterval } from '../../utils/dateUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { usePageChecks } from '../../utils/pathUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Button } from '../buttons/Button';
import { ActionDropdown, Dropdown } from '../common/Dropdown';
import { Text } from '../common/Text';
import { View, type ViewProps } from '../common/View';
import { ErrorView } from '../error/ErrorView';
import { ListDetailLayout } from '../layouts/ListDetailLayout';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { AuthCardUI } from '../rsvp/RsvpDropView';
import { UserProfileImage } from '../user/UserProfileImage';
import { VaultNav } from '../vault/VaultNav';
import { SignInForm, VerifyForm } from '../views/VaultLandingView';

export function UserChannels({
  artistHandle,
  asArtistId,
  showOnMobile,
  vaultId,
  artistName,
  artistCoverImage,
}: {
  artistHandle: string | undefined;
  asArtistId: string | undefined;
  showOnMobile: boolean;
  vaultId: string | undefined;
  artistName: string | undefined;
  artistCoverImage: string | null | undefined;
}) {
  const navigate = useNavigate();
  const { loginStatus, loggedInUser } = useAuthContext();
  const { sort, filterType } = useSnapshot(filtersState);
  const { openBottomsheet } = useBottomsheetContainer();

  const [view, setView] = useState<'join' | 'verify'>('join');
  const { isDesktop } = useWindow();

  const newMessageInfo = useNewMessage();

  const [searchParams] = useSearchParams();
  const showNewMessageRow = !!searchParams.get('newMessage');

  const [openChannelId, setOpenChannelId] = useState<string | null>(null);
  const [state, setState] = useState<{
    sort: FiltersSchema['sort'];
    filterType: FiltersSchema['filterType'];
  }>({
    sort,
    filterType,
  });

  const placeholderChannels = useMemo(
    () => [
      {
        messageChannel: {
          id: 'placeholder-group',
          isPlaceholder: true,
          channelType: MessageChannelType.Vault,
          createdAt: new Date(new Date().setHours(new Date().getHours() - 2)).toISOString(),
          artist: null,
          details: {
            titleText: 'Group Chat',
            subtitleText: '',
            showVerifiedBadge: false,
            subscriptionTierLevel: null,
            receiptCount: null,
            coverImage: null,
          },
          latestMessage: {
            content: '',
            createdAt: new Date(new Date().setHours(new Date().getHours() - 2)).toISOString(),
          },
          participants: [],
          hasUnreadMessages: false,
        },
      },
      {
        messageChannel: {
          id: 'placeholder-dm',
          isPlaceholder: true,
          channelType: MessageChannelType.ArtistDm,
          createdAt: new Date(new Date().setHours(new Date().getHours() - 3)).toISOString(),
          artist: {
            id: 'placeholder-artist',
            name: artistName || 'Artist',
          },
          details: {
            titleText: artistName || 'Direct Message',
            subtitleText: '',
            showVerifiedBadge: false,
            subscriptionTierLevel: null,
            receiptCount: null,
            coverImage: artistCoverImage
              ? {
                  smallCoverImageUrl: artistCoverImage,
                }
              : null,
          },
          latestMessage: {
            content: '',
            createdAt: new Date(new Date().setHours(new Date().getHours() - 3)).toISOString(),
          },
          participants: [],
          hasUnreadMessages: false,
        },
      },
    ],
    [artistName, artistCoverImage],
  );

  // TODO: [Unified Inbox] Handle loading and error states
  const {
    channels,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isInitialLoading,
    isError,
    refetch,
  } = useUserChannels({
    artistHandle,
    asArtistId,
    sort: state.sort,
    filterType: state.filterType,
  });

  const showJoinFree = useMemo(() => {
    return channels.length === 0 && !isInitialLoading && showOnMobile;
  }, [channels.length, isInitialLoading, showOnMobile]);

  const onJoinFreeClick = useCallback(async () => {
    if (!vaultId || !artistHandle) return;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
        membershipConfirmationBottomsheetProps: {
          vaultId,
          isLoading: false,
          artistHandle,
          artistName,
          imageUrl: artistCoverImage,
          loggedInUserUsername: loggedInUser?.username,
          loginStatus,
          inviteCode: null,
          smsCampaignResponseShortcode: null,
          sourceReleaseCampaignId: null,
        },
        shared: {
          hideCloseBottomsheetButton: false,
          preventSwipeToDismiss: false,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          withVaultTheme: true,
        },
      });
    } else {
      const queryParams = constructQueryParams({
        artistHandle,
        openBottomSheet: 'freeTierModal',
      });

      navigate(`${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
      return;
    }
  }, [
    vaultId,
    artistHandle,
    loginStatus,
    openBottomsheet,
    artistName,
    artistCoverImage,
    loggedInUser?.username,
    navigate,
  ]);

  const renderContent = useMemo(() => {
    return (
      <div className="absolute inset-0 flex w-full items-center justify-center bg-vault_background/60 md2:hidden">
        <div className="m-4 flex w-full max-w-[360px] flex-col gap-5 rounded-[24px] bg-vault_text/10 p-4 text-center text-vault_text">
          <View className="flex w-full flex-row">
            {view === 'verify' && (
              <Button
                label=""
                iconOnly
                leadingIcon={faChevronLeft}
                className="text-[20px] text-vault_text"
                onClick={() => setView('join')}
              />
            )}

            <Text className="mx-auto text-center text-title-l font-medium">
              {view === 'join' ? 'Unlock messaging' : 'Verify'}
            </Text>

            {view === 'verify' && <View className="w-5" />}
          </View>

          {view === 'verify' && <VerifyForm className="pb-0" codeInputOnly useVaultTheme />}

          {view === 'join' && (
            <>
              {loginStatus === LoginStatus.LOGGED_IN && loggedInUser ? (
                <AuthCardUI
                  profileImageUrl={loggedInUser.avatar?.userSmallProfileImageUrl}
                  fallBackColor={loggedInUser.avatar?.dominantColor || undefined}
                  username={loggedInUser.username}
                  onClick={onJoinFreeClick}
                  buttonLabel="Join"
                  useVaultTheme
                />
              ) : (
                <>
                  <SignInForm
                    dominantColor={null}
                    setView={setView}
                    useVaultTheme
                    inputBorderColorClassName="border-vault_text/15"
                    inputTextColorClassName="text-vault_text"
                    placeholderTextColorClassName="placeholder:text-vault_text/50"
                    selectBgColorClassName="bg-vault_background/90"
                    selectTextColorClassName="text-vault_text"
                  />
                </>
              )}
            </>
          )}

          {view === 'join' && (
            <Text className="mx-auto w-full max-w-[200px] text-center font-base text-[12px]/[16px] font-normal text-vault_text/70">
              By signing up, you agree to the{' '}
              <a
                href={TOS_URL}
                target="_blank"
                className="text-vault_accent no-underline hover:cursor-pointer"
              >
                Terms
              </a>{' '}
              &{' '}
              <a
                href={PRIVACY_POLICY_URL}
                target="_blank"
                className="text-vault_accent no-underline hover:cursor-pointer"
              >
                Privacy Policy
              </a>
              .
            </Text>
          )}
        </div>
      </div>
    );
  }, [loggedInUser, loginStatus, onJoinFreeClick, view]);

  const renderItem = useCallback(
    (_index: number, item: (typeof channels)[number]) => {
      if (!artistHandle) return null;

      return (
        <SwipeableUserChannelRow
          key={item.messageChannel.id}
          artistHandle={artistHandle}
          channelFrag={item}
          isOwner={!!asArtistId}
          isOpen={openChannelId === item.messageChannel.id}
          onOpenChange={isOpen => {
            setOpenChannelId(isOpen ? item.messageChannel.id : null);
          }}
          isSwipeEnabled={!!asArtistId}
          disableSelection={showNewMessageRow && !!newMessageInfo && isDesktop}
        />
      );
    },
    [artistHandle, asArtistId, isDesktop, newMessageInfo, openChannelId, showNewMessageRow],
  );

  const EmptyPlaceholder = useCallback(() => {
    if (isInitialLoading || loginStatus === LoginStatus.LOADING) {
      return (
        <View className="flex h-full w-full flex-col">
          {Array.from({ length: 10 }).map((_, index) => (
            <SkeletonUserChannelRow key={index} />
          ))}
        </View>
      );
    }

    if (isError || loginStatus === LoginStatus.LOGGED_OUT) {
      return (
        <View className="h-full w-full">
          <ErrorView withVaultTheme onRetryClick={refetch} className="h-full w-full md2:w-full" />
        </View>
      );
    }

    // TODO: [Unified Inbox] Add empty state
    return null;
    // return (
    //   <View className="h-full w-full">
    //     <EmptyStateView
    //       withVaultTheme
    //       className="box-border h-full w-full md2:w-full"
    //       title="Text blasts"
    //       subtitle="Send a text blast to your fans"
    //       buttonHref={artistNavigationPath(artistHandle, '/messages/create')}
    //       buttonText="Create text blast"
    //       icon={faMegaphone}
    //       iconClassName="text-[48px]/[48px] mb-10"
    //     />
    //   </View>
    // );
  }, [isError, isInitialLoading, loginStatus, refetch]);

  const Header = useCallback(
    () =>
      showNewMessageRow && newMessageInfo && isDesktop ? (
        <View className="my-1 h-full w-full overflow-hidden rounded-xl">
          <UserChannelRow
            channelFrag={makeFragmentData(
              {
                hasUnreadMessages: false,
                messageChannel: {
                  id: newMessageInfo.artistDMChannelId,
                  artist: null,
                  channelType: 'ARTIST_DM',
                  createdAt: new Date().toISOString(),
                  details: {
                    coverImage: {
                      id: crypto.randomUUID(),
                      smallCoverImageUrl: newMessageInfo.profileImageUrl,
                    },
                    receiptCount: null,
                    showVerifiedBadge: false,
                    subscriptionTierLevel: newMessageInfo.subscriptionTierLevel,
                    subtitleText: null,
                    titleText: `New message to ${newMessageInfo.displayName}`,
                    singleRecipientActorId: null,
                  },
                  latestMessage: null,
                },
              },
              UserChannelRowFragmentDoc,
            )}
            showEllipsisMenu={false}
            isDropdownOpen={false}
            showDate={false}
            isOwner
            disableHover
            isSelected
          />
        </View>
      ) : null,
    [isDesktop, newMessageInfo, showNewMessageRow],
  );

  const Footer = useCallback(() => <View className="h-10" />, []);

  const Item = useCallback(
    (props: ViewProps) => <View {...props} className="my-1 h-full w-full" />,
    [],
  );
  return (
    <ListDetailLayout.List showOnMobile={showOnMobile}>
      <ListDetailLayout.ListTitle
        title="Messages"
        actionButton={null}
        titleOverride={
          <View className="flex w-full flex-col gap-4">
            <View className="flex w-full flex-row items-center justify-between gap-6">
              <Text className="flex-1 text-left font-title text-[32px] font-medium text-vault_text">
                Messages
              </Text>
              {!!asArtistId && (
                <View className="flex items-center justify-center gap-2">
                  <Button
                    label=""
                    iconOnly
                    leadingIcon={faMegaphone}
                    className="items-center justify-center rounded-full bg-transparent p-[14px] text-[20px] text-vault_text hover:bg-vault_text/10"
                    href={artistNavigationPath(artistHandle, '/messages/insights')}
                  />
                  <Button
                    label=""
                    iconOnly
                    leadingIcon={faPenToSquare}
                    className="items-center justify-center rounded-full bg-vault_accent p-[14px] text-[20px] text-vault_accent_text"
                    onClick={() => {
                      if (!vaultId) return;
                      openBottomsheet({
                        type: BOTTOMSHEET_TYPES.NEW_MESSAGE,
                        shared: {
                          withVaultTheme: true,
                          showFullScreen: true,
                          hidePulleyBar: true,
                        },
                        newMessageBottomsheetProps: {
                          artistHandle,
                          vaultId,
                        },
                      });
                    }}
                  />
                </View>
              )}
            </View>

            {!!asArtistId && (
              <View className="flex w-full flex-row gap-2">
                <Button
                  label="All"
                  className={twMerge(
                    'rounded-full px-4 py-3 font-title text-[16px]/[20px] font-medium',
                    state.filterType === 'ALL'
                      ? 'bg-vault_text text-vault_text_opposite'
                      : 'bg-vault_text/10 text-vault_text',
                  )}
                  onClick={() => setState(prev => ({ ...prev, filterType: 'ALL' }))}
                />
                <Button
                  label="Unread"
                  className={twMerge(
                    'rounded-full px-4 py-3 font-title text-[16px]/[20px] font-medium',
                    state.filterType === 'UNREAD_ONLY'
                      ? 'bg-vault_text text-vault_text_opposite'
                      : 'bg-vault_text/10 text-vault_text',
                  )}
                  onClick={() => setState(prev => ({ ...prev, filterType: 'UNREAD_ONLY' }))}
                />
                <Button
                  label="Paid"
                  className={twMerge(
                    'rounded-full px-4 py-3 font-title text-[16px]/[20px] font-medium',
                    state.filterType === 'PAID_ONLY'
                      ? 'bg-vault_text text-vault_text_opposite'
                      : 'bg-vault_text/10 text-vault_text',
                  )}
                  onClick={() => setState(prev => ({ ...prev, filterType: 'PAID_ONLY' }))}
                />
              </View>
            )}
          </View>
        }
        withBackButton={false}
        withDivider={false}
      />

      <ListDetailLayout.ListContent>
        <Virtuoso
          itemContent={renderItem}
          data={channels.length > 0 ? channels : placeholderChannels}
          className="no-scrollbar h-full w-full"
          endReached={hasNextPage && !isFetchingNextPage ? () => fetchNextPage() : undefined}
          useWindowScroll
          components={{
            Header,
            Footer,
            EmptyPlaceholder,
            Item,
          }}
        />
      </ListDetailLayout.ListContent>

      {showJoinFree && renderContent}

      {showOnMobile && (
        <View className="w-full md2:hidden">
          <VaultNav
            vaultId={vaultId}
            messageChannelId={undefined}
            hasChatReadAccess={false}
            chatAvailableForFreeUsers={false}
            variant="borderless"
            withVaultTheme
            folderId={null}
          />
        </View>
      )}
    </ListDetailLayout.List>
  );
}

const UserChannelRow = memo(function UserChannelRow({
  channelFrag,
  disableHover,
  isDropdownOpen,
  isOwner,
  isSelected,
  showDate,
  showEllipsisMenu,
}: {
  channelFrag: FragmentType<UserChannelRowFragmentDoc>;
  disableHover: boolean;
  isDropdownOpen: boolean;
  isOwner: boolean;
  isSelected: boolean;
  showDate: boolean;
  showEllipsisMenu: boolean;
}) {
  const { messageChannel: channel, hasUnreadMessages } = getFragment(
    UserChannelRowFragmentDoc,
    channelFrag,
  );

  const isGroupChat = channel.channelType === MessageChannelType.Vault;

  const messagePreview = channel.latestMessage?.content || '';

  const title = channel.details?.titleText;
  const subtitle = channel.details?.subtitleText;
  const channelType = channel.channelType;
  const coverImage = channel.details?.coverImage;
  const showVerifiedBadge = channel.details?.showVerifiedBadge || false;
  const latestMessage = channel.latestMessage;
  const createdAt = channel.createdAt;

  const subtext = channelType === MessageChannelType.Vault ? 'Pinned' : subtitle;

  const dateString = useMemo(() => {
    const now = new Date();
    const date = new Date(latestMessage?.createdAt ?? createdAt);

    if (Math.abs(now.getTime() - date.getTime()) < 1000) {
      return 'Now';
    }

    if (isSameDay(now, date)) {
      return pastDateInterval(date);
    }

    return formatDate(date, 'MMM d');
  }, [createdAt, latestMessage?.createdAt]);

  return (
    <View
      className={twMerge(
        'box-border flex w-full cursor-pointer flex-row items-center gap-3 px-3 py-2 no-underline',
        disableHover ? 'bg-vault_text/3' : 'md2:hover:bg-vault_text/3',
        isSelected && !disableHover
          ? 'bg-vault_text/10 md2:hover:bg-vault_text/3 lg:hover:bg-vault_text/20'
          : isSelected && 'lg:bg-vault_text/10',
      )}
    >
      <View
        className={twMerge(
          'flex h-[64px] w-[64px] flex-col items-center justify-center rounded-full',
          isGroupChat && isSelected ? 'bg-vault_text' : 'bg-vault_text/5',
        )}
      >
        {isGroupChat ? (
          <FontAwesomeIcon
            icon={faMessages}
            className={twMerge(
              'text-[24px]/[24px]',
              isSelected ? 'text-vault_text_opposite' : 'text-vault_text',
            )}
          />
        ) : !showVerifiedBadge ? (
          <UserProfileImage
            className="h-[64px] w-[64px]"
            profileImageUrl={coverImage?.smallCoverImageUrl}
            withVaultTheme
          />
        ) : (
          <ArtistProfileImage
            className="h-[64px] w-[64px]"
            profileImageUrl={coverImage?.smallCoverImageUrl}
            withVaultTheme
          />
        )}
      </View>
      <View className="flex flex-1 flex-shrink select-none flex-col gap-1">
        <View className="flex w-full flex-row items-center justify-between">
          <View className="flex w-full items-center gap-2">
            <Text className="line-clamp-1 font-title text-[16px]/[18px] font-medium text-vault_text">
              {title}
            </Text>
            {showVerifiedBadge && (
              <FontAwesomeIcon
                icon={faBadgeCheck}
                className="select-none text-[12px] text-vault_accent"
              />
            )}
            {isOwner &&
              !isGroupChat &&
              channel.details?.subscriptionTierLevel === TierTypename.PaidTier && (
                <View className="mr-2 rounded-full bg-vault_accent px-3 py-1">
                  <Text className="text-center font-base text-[12px]/[14px] font-medium text-vault_accent_text">
                    Paid
                  </Text>
                </View>
              )}
          </View>

          <View
            className={twMerge(
              'flex shrink-0 items-center',
              showEllipsisMenu && 'group-hover/channel:opacity-0',
              isDropdownOpen && 'opacity-0',
            )}
          >
            {showDate && (
              <Text className="font-base text-[14px]/[18px] text-vault_text/50">{dateString}</Text>
            )}
            {hasUnreadMessages && (
              <View className="ml-2 h-2 w-2 items-center justify-center rounded-full bg-vault_text" />
            )}
          </View>
        </View>
        {!!messagePreview && (
          <Text
            className={twMerge(
              'line-clamp-1 break-all font-base text-[16px]/[18px] font-normal',
              hasUnreadMessages ? 'text-vault_text' : 'text-vault_text/50',
            )}
          >
            {messagePreview}
          </Text>
        )}
        {!!subtext && (
          <Text className="line-clamp-1 font-base text-[14px]/[18px] font-normal text-vault_text/50">
            {channelType === MessageChannelType.Vault && (
              <FontAwesomeIcon icon={faThumbtack} className="text-[14px] text-vault_text/50" />
            )}{' '}
            {subtext}
            {channel.details?.receiptCount != null && (
              <>
                {' '}
                · {millify(channel.details.receiptCount)}{' '}
                <FontAwesomeIcon icon={faReceipt} className="text-[14px]" />
              </>
            )}
          </Text>
        )}
      </View>
    </View>
  );
});

const SwipeableUserChannelRow = memo(function SwipeableUserChannelRow({
  artistHandle,
  channelFrag,
  isOpen: controlledIsOpen,
  isOwner,
  isSwipeEnabled,
  onOpenChange,
  disableSelection,
}: {
  artistHandle: string;
  channelFrag: FragmentType<UserChannelRowFragmentDoc>;
  isOpen: boolean;
  isOwner: boolean;
  isSwipeEnabled: boolean;
  onOpenChange: (isOpen: boolean) => void;
  disableSelection: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDesktop } = useWindow();
  const [offset, setOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isSwiping, setIsSwiping] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { openBottomsheet } = useBottomsheetContainer();

  const [isChatPage] = usePageChecks({
    pages: ['chat'],
  });

  const { channelId } = useParams();

  useEffect(() => {
    setOffset(controlledIsOpen ? -75 : 0);
  }, [controlledIsOpen]);

  // Reset state when the location changes (user navigates away)
  useEffect(() => {
    setOffset(0);
    setIsDragging(false);
    setIsSwiping(false);
    onOpenChange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { messageChannel: channel } = getFragment(UserChannelRowFragmentDoc, channelFrag);
  const isGroupChat = channel.channelType === MessageChannelType.Vault;
  const isMessagesCurrentPage =
    location.pathname.endsWith('/messages') ||
    location.pathname.endsWith('/messages/details') ||
    isChatPage;

  // Add check for placeholder channels
  const isPlaceholder = 'isPlaceholder' in channel && channel.isPlaceholder === true;
  const isSelected =
    !isPlaceholder &&
    ((isGroupChat && isMessagesCurrentPage) || channelId === channel.id) &&
    !disableSelection;

  const handleClick = () => {
    // Prevent navigation if we're swiping or the row is open
    if (isSwiping || controlledIsOpen || isPlaceholder) return;

    navigate(
      artistNavigationPath(
        artistHandle,
        isGroupChat ? (isDesktop ? '/messages' : '/chat') : `/messages/${channel.id}`,
      ),
    );
  };

  const handlers = useSwipeable({
    trackMouse: true,
    trackTouch: true,
    preventScrollOnSwipe: true,
    delta: 10,
    onSwipeStart: () => {
      setIsDragging(true);
      setIsSwiping(true);
    },
    onSwiping: eventData => {
      if (eventData.deltaX < 0) {
        // Only allow left swipes
        const newOffset = controlledIsOpen
          ? Math.max(eventData.deltaX - 75, -75) // If open, start from -75
          : Math.max(eventData.deltaX, -75); // If closed, start from 0
        setOffset(newOffset);
      } else if (controlledIsOpen) {
        // Allow right swipes only when open
        setOffset(Math.min(eventData.deltaX - 75, 0)); // Start from -75, cap at 0
      }
    },
    onSwiped: () => {
      setIsDragging(false);
      // Snap to position
      if (offset < -50) {
        setOffset(-75);
        onOpenChange(true);
      } else {
        setOffset(0);
        onOpenChange(false);
      }
      // Add a small delay before allowing clicks again
      setTimeout(() => {
        setIsSwiping(false);
      }, 50);
    },
  });

  const sharedTransition = isDragging ? 'none' : 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)';

  const enableMoreOptions = isSwipeEnabled && !isGroupChat;
  const canSwipe = enableMoreOptions && !isDesktop;
  const showEllipsisMenu = enableMoreOptions && isDesktop;

  const buttonClassName =
    'border-b-vault_text/5 bg-vault_text/10 hover:bg-vault_text/20 text-vault_text ease-in-out duration-300 transition-all md2:h-[45px] text-[16px]/[20px] justify-between gap-4';

  const onBanClick = () => {
    setOffset(0);
    onOpenChange(false);
    setIsDropdownOpen(false);

    if (!channel.artist || !channel.details?.singleRecipientActorId) {
      return;
    }

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.BAN_USER,
      shared: {
        withVaultTheme: true,
      },
      banUserBottomsheetProps: {
        artistId: channel.artist.id,
        userId: channel.details.singleRecipientActorId,
      },
    });
  };

  const buttons = compact([
    // TODO: [Unified Inbox] add again when we have the mute feature
    // {
    //   label: 'Mute',
    //   trailingIcon: faBell,
    //   type: 'secondary',
    //   className: buttonClassName,
    //   onClick: () => null,
    // },
    {
      label: 'Ban',
      trailingIcon: faCancel,
      type: 'secondary',
      className: twMerge(buttonClassName, 'text-destructive300'),
      onClick: onBanClick,
    },
  ]) satisfies ActionBottomsheetProps['buttons'];

  return (
    <View
      className="group/channel relative select-none overflow-x-hidden rounded-xl"
      onClick={handleClick}
    >
      {(showEllipsisMenu || isDropdownOpen) && (
        <View
          className={twMerge(
            'invisible absolute right-0 z-above1 box-border h-full cursor-pointer gap-0',
            'opacity-0 group-hover/channel:visible group-hover/channel:opacity-100',
            isDropdownOpen && 'visible opacity-100',
          )}
        >
          <View className="mr-[20px] flex h-full w-full flex-col justify-center">
            <Dropdown
              sideOffset={12}
              align="center"
              disabled={false}
              className="w-[200px]"
              onOpenChange={open => setIsDropdownOpen(open)}
              trigger={
                <div className="relative">
                  <Button
                    label=""
                    iconOnly
                    leadingIcon={faEllipsis}
                    leadingIconClassName="text-[17px]"
                    className="h-8 w-8 rounded-full border border-solid border-vault_text/50 p-2 text-vault_text/50 hover:bg-vault_text/10"
                  />
                </div>
              }
            >
              <ActionDropdown buttons={buttons} withVaultTheme />
            </Dropdown>
          </View>
        </View>
      )}
      {canSwipe && (
        <View
          className="absolute right-0 top-0 flex h-full flex-row items-center gap-0"
          style={{
            transform: `translateX(${offset + 75}px)`,
            transition: sharedTransition,
          }}
        >
          <Button
            label=""
            iconOnly
            leadingIcon={faCancel}
            leadingIconClassName="text-[24px] text-white"
            onClick={onBanClick}
            className="h-full w-[75px] items-center justify-center bg-destructive300 px-6 text-white"
          />
        </View>
      )}

      <View
        {...(canSwipe ? handlers : {})}
        style={{
          transform: `translateX(${offset}px)`,
          transition: sharedTransition,
        }}
        className={twMerge(
          'overflow-hidden',
          isSwiping || controlledIsOpen
            ? 'rounded-l-xl rounded-r-none bg-destructive300'
            : 'rounded-xl',
        )}
      >
        <View
          className={twMerge(
            'w-full',
            (isSwiping || controlledIsOpen) &&
              (isSelected ? 'bg-vault_background' : 'bg-vault_background'),
          )}
        >
          <UserChannelRow
            channelFrag={channelFrag}
            isOwner={isOwner}
            disableHover={isSwiping || controlledIsOpen}
            isSelected={isSelected}
            showEllipsisMenu={showEllipsisMenu}
            isDropdownOpen={isDropdownOpen}
            showDate
          />
        </View>
      </View>
    </View>
  );
});

function SkeletonUserChannelRow() {
  return (
    <View className="box-border flex w-full flex-row gap-3 rounded-xl py-2 md2:my-1 md2:px-3">
      <LoadingSkeleton
        className="flex h-[60px] w-[60px] flex-col items-center justify-center rounded-full bg-vault_text/5"
        withVaultTheme
      />
      <View className="box-border flex flex-1 flex-shrink flex-col gap-1">
        <LoadingSkeleton className="h-[18px] w-[100px]" withVaultTheme />
        <LoadingSkeleton className="box-border h-[20px] w-full" withVaultTheme />
        <LoadingSkeleton className="h-[18px] w-[200px]" withVaultTheme />
      </View>
    </View>
  );
}
