import { datadogRum } from '@datadog/browser-rum-slim';
import type { SeverityLevel } from '@sentry/react';
import { serializeError } from 'serialize-error';
import type { RequireAtLeastOne } from '@soundxyz/utils';
import type { Primitive } from '@soundxyz/utils/src/constants/types';
import type { ErrorAction, ErrorType, Feature, Pillar } from '@soundxyz/vault-logs-utils/constants';
import { type OpenToastProps, ToastContext } from '../../contexts/ToastContext';
import { Sentry } from '../../sentry';
import { logger } from '../../utils/logger';
import { useStableCallback } from '../useStableCallback';

/*
 * SeverityLevel props:
 * error: for bugs
 * warn: interesting happened and there is a javascript error, but we handled it and its not a bug (think a validation error)
 * fatal: the page / app is broken, or the user couldn't do the thing they wanted to do.
 * info: info logging about interesting things user did like starting actions or completing actions successfully
 */
const getLoggerFromLevel = (level: SeverityLevel) => {
  switch (level) {
    case 'debug':
      return logger.debug;
    case 'error':
    case 'fatal':
      return logger.error;
    case 'info':
    case 'log':
      return logger.info;
    case 'warning':
      return logger.warn;
  }
};

export const logError = ({
  error,
  toast,
  indexedTags = {},
  unindexedExtra = {},
  toastDuration = 5000,
  level,
  action,
  errorType,
  message,
  pillar,
  openToast,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  errorType: ErrorType;
  message: string;
  toast?: OpenToastProps | string;
  toastDuration?: number;
  /**
   *  searchable tags to be used in Sentry (strings, numbers, booleans, etc. objects / arrays not allowed)
   */
  indexedTags?: Record<string, Primitive>;
  /**
   *  any additional data to attach to the event that we don't necessarily to to search on to help debugging
   */
  unindexedExtra?: Record<string, unknown>;
  /**
   * how serious the error is ('fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug')
   */
  level: SeverityLevel;
  // label for the event that is necessary for data dog
  action: ErrorAction;
  openToast?: (val: OpenToastProps) => void;
  // identifier for the pillar (set of pages / flow) this error comes from or feature (non pillar)
} & RequireAtLeastOne<{ pillar?: Pillar; feature?: Feature }>) => {
  // eslint-disable-next-line no-console
  console.error(error, {
    toast,
    unindexedExtra,
    indexedTags,
    level,
    pillar,
  });

  let sentryEventId: string | undefined = undefined;

  let errMessage: string;
  if (typeof error === 'string') {
    errMessage = error;
  } else if (typeof error?.message === 'string') {
    errMessage = error.message;
  } else if (typeof error?.cause?.message === 'string') {
    errMessage = error.cause.message;
  } else {
    errMessage = message;
  }

  // Don't log ephemeral network errors as fatal or error
  const levelToLog =
    errMessage === 'Failed to fetch' || errMessage === 'Network error, fetch failed'
      ? 'warning'
      : level;

  sentryEventId = Sentry.captureException(
    error instanceof Error ? error : new Error(JSON.stringify(error)),
    {
      tags: {
        ...indexedTags,
        action,
        errorType,
        pillar,
      },
      extra: { ...unindexedExtra, message },
      level: levelToLog,
    },
  );

  const loggerFn = getLoggerFromLevel(level);

  loggerFn(
    {
      ...indexedTags,
      ...unindexedExtra,
      error: serializeError(error),
      action,
      errorType,
      message,
      level: levelToLog,
      sentryEventId,
      pillar,
    },
    errMessage,
  );

  datadogRum.addAction(action, {
    ...indexedTags,
    ...unindexedExtra,
    error: serializeError(error),
    errorType,
    level: levelToLog,
    message,
    sentryEventId,
    pillar,
  });

  if (openToast && toast) {
    if (typeof toast === 'string') {
      openToast({
        text: toast,
        variant: 'error',
        duration: toastDuration,
      });
    } else {
      openToast({
        variant: 'error',
        duration: toastDuration,
        ...toast,
      });
    }
  }
};

export function useLogError(): (
  arg: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
    errorType: ErrorType;
    message: string;
    toast?: OpenToastProps | string;
    toastDuration?: number;
    /**
     *  searchable tags to be used in Sentry (strings, numbers, booleans, etc. objects / arrays not allowed)
     */
    indexedTags?: Record<string, Primitive>;
    /**
     *  any additional data to attach to the event that we don't necessarily to to search on to help debugging
     */
    unindexedExtra?: Record<string, unknown>;
    /**
     * how serious the error is ('fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug')
     */
    level: SeverityLevel;
    action: ErrorAction;
    openToast?: (val: OpenToastProps) => void;
  } & RequireAtLeastOne<{ pillar?: Pillar; feature?: Feature }>,
) => void {
  const { openToast } = ToastContext.useContainer();

  const innerLogError = useStableCallback(args => {
    logError({ ...args, openToast });
  });

  return innerLogError;
}
