import { fileTypeFromBuffer } from 'file-type';
import mime from 'mime';
import { useNavigate } from 'react-router';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import {
  ERROR_TYPE,
  PILLARS,
  UPLOAD_FILE_ERROR_ACTIONS,
  UPLOAD_FILE_INFO_ACTIONS,
} from '@soundxyz/vault-logs-utils/constants';
import {
  ACCEPTED_AUDIO_MIME_TYPES,
  ACCEPTED_IMAGE_TYPES,
  ACCEPTED_VIDEO_TYPES,
  MAX_AUDIO_UPLOAD_SIZE_TEXT,
  VALID_M4A_MIME_TYPES,
  VALID_MP3_MIME_TYPES,
  VALID_MP4_MIME_TYPES,
  VALID_WAV_MIME_TYPES,
} from '../constants/fileConstants';
import { useToast } from '../contexts/ToastContext';
import { TrackUpload } from '../contexts/TrackUploadContext';
import { VaultContentType } from '../graphql/generated';
import { artistNavigationPath } from '../utils/navigationUtils';
import { processAudio } from '../utils/waveformUtils';
import { logError } from './logger/useLogError';
import { logInfo } from './logger/useLogInfo';

const pillar = PILLARS.UPLOAD_FILE;

type Metrics = {
  uploadSpeed: number; // bytes per second
  processingTime: number; // milliseconds
  totalTime: number; // milliseconds
  fileSize: number; // bytes
  fileType: string; // mime type
  contentType: VaultContentType;
};

function processVideo(videoFile: File) {
  return new Promise<{ duration: number }>((resolve, reject) => {
    try {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        resolve({ duration: video.duration });
      };

      video.onerror = function () {
        reject('Invalid video. Please select a video file.');
      };

      video.src = window.URL.createObjectURL(videoFile);
    } catch (e) {
      reject(e);
    }
  });
}

export function useUploadVaultContentFile({
  onSuccess,
  onDone,
  vaultId,
  artistLinkValue,
  folderId,
}: {
  onSuccess?: () => void;
  onDone?: () => void;
  shouldShowConfirmationDialog?: boolean;
  vaultId: string;
  artistLinkValue: string;
  folderId: string | null;
}) {
  const { openToast } = useToast();
  const navigate = useNavigate();

  const saveFile = useStableCallback(
    ({
      selectedFile,
      startTime,
      processingStartTime,
      fileTypeMime,
      ...contentBasedParams
    }: {
      selectedFile: File;
      startTime: number;
      processingStartTime: number;
      fileTypeMime: string;
    } & (
      | {
          contentType: 'TRACK';
          duration: number;
          normalizedPeaks: number[];
        }
      | { contentType: 'VIDEO'; duration: number; normalizedPeaks: undefined }
      | { contentType: 'IMAGE'; duration: undefined; normalizedPeaks: undefined }
    )) => {
      onSuccess?.();

      const endTime = Date.now();

      const metrics = {
        uploadSpeed: (selectedFile.size / (endTime - startTime)) * 1000,
        processingTime: endTime - processingStartTime,
        totalTime: endTime - startTime,
        fileSize: selectedFile.size,
        fileType: fileTypeMime,
        contentType: contentBasedParams.contentType,
      } satisfies Metrics;

      logInfo({
        action: UPLOAD_FILE_INFO_ACTIONS.UPLOAD_FILE_SUCCESS,
        message: 'File uploaded successfully',
        pillar: PILLARS.UPLOAD_FILE,
        data: {
          ...metrics,
          vaultId,
          artistLinkValue,
        },
      });

      TrackUpload.title = selectedFile.name.replace(/\.[^/.]+$/, ''); // name without file format
      TrackUpload.isUploadingTrackPreview = false;
      TrackUpload.isUploadingVideoPreview = false;

      TrackUpload.fileRef.current = {
        file: selectedFile,
        vaultId,
        objectUrl: window.URL.createObjectURL(selectedFile),
        folderId,
        ...contentBasedParams,
      };

      TrackUpload.isPromptOpen = false;

      navigate(artistNavigationPath(artistLinkValue, '/upload'));
    },
  );

  const saveMusicFile = useStableCallback(
    async (
      selectedFile: File,
      fileTypeMime: string,
      mimeFromFileName: string,
      startTime: number,
    ) => {
      /**
       * We need to be flexible with mimetypes and the extensions due to small discrepancies but completely
       * different encodings should not be accepted
       */
      if (
        (VALID_WAV_MIME_TYPES.includes(fileTypeMime) &&
          !VALID_WAV_MIME_TYPES.includes(mimeFromFileName)) ||
        (VALID_MP3_MIME_TYPES.includes(fileTypeMime) &&
          !VALID_MP3_MIME_TYPES.includes(mimeFromFileName)) ||
        (VALID_M4A_MIME_TYPES.includes(fileTypeMime) &&
          !VALID_M4A_MIME_TYPES.includes(mimeFromFileName))
      ) {
        openToast({
          text: 'File type does not match file extension. Please try again with a valid file.',
          variant: 'error',
        });
        return;
      }

      if (
        !VALID_WAV_MIME_TYPES.includes(fileTypeMime) &&
        !VALID_MP3_MIME_TYPES.includes(fileTypeMime) &&
        !VALID_M4A_MIME_TYPES.includes(fileTypeMime)
      ) {
        openToast({
          text: 'Audio can only be WAV or MPEG. Please try again with a valid file.',
          variant: 'error',
        });
        return;
      }

      TrackUpload.isUploadingTrackPreview = true;
      TrackUpload.shouldSendSms = true;
      const [{ duration, normalizedPeaks }] = await Promise.all([processAudio(selectedFile)]);

      const processingStartTime = Date.now();
      saveFile({
        selectedFile,
        contentType: VaultContentType.Track,
        duration,
        normalizedPeaks,
        startTime,
        processingStartTime,
        fileTypeMime,
      });
    },
  );

  const saveVideoFile = useStableCallback(
    async (
      selectedFile: File,
      fileTypeMime: string,
      mimeFromFileName: string,
      startTime: number,
    ) => {
      if (
        fileTypeMime !== mimeFromFileName &&
        VALID_MP4_MIME_TYPES.includes(fileTypeMime) &&
        !VALID_MP4_MIME_TYPES.includes(mimeFromFileName)
      ) {
        openToast({
          text: 'File type does not match file extension. Please try again with a valid file.',
          variant: 'error',
        });
        return;
      }

      TrackUpload.isUploadingVideoPreview = true;

      const { duration } = await processVideo(selectedFile);
      const processingStartTime = Date.now();

      saveFile({
        selectedFile,
        contentType: VaultContentType.Video,
        duration,
        normalizedPeaks: undefined,
        startTime,
        processingStartTime,
        fileTypeMime,
      });
    },
  );

  const saveImageFile = useStableCallback(
    async (
      selectedFile: File,
      fileTypeMime: string,
      mimeFromFileName: string,
      startTime: number,
    ) => {
      if (fileTypeMime !== mimeFromFileName) {
        openToast({
          text: 'File type does not match file extension. Please try again with a valid file.',
          variant: 'error',
        });
        return;
      }
      const processingStartTime = Date.now();

      saveFile({
        selectedFile,
        contentType: VaultContentType.Image,
        duration: undefined,
        normalizedPeaks: undefined,
        startTime,
        processingStartTime,
        fileTypeMime,
      });
    },
  );

  const saveVaultContentFile = useStableCallback(async (selectedFile: File | undefined) => {
    if (!selectedFile) {
      openToast({
        text: 'File could not be uploaded',
        variant: 'error',
      });
      return;
    }

    const startTime = Date.now();

    try {
      TrackUpload.totalSize = selectedFile.size;
      TrackUpload.progressSize = 0;

      const buffer = await selectedFile.arrayBuffer();
      const uint8Array = new Uint8Array(buffer);
      const fileType = await fileTypeFromBuffer(uint8Array);

      const mimeFromFileName = mime.getType(selectedFile.name);

      if (!fileType || !mimeFromFileName) {
        openToast({
          text: `File could not be uploaded. Make sure it is a valid audio, video, or image file and less than ${MAX_AUDIO_UPLOAD_SIZE_TEXT}.`,
          variant: 'error',
        });
        return;
      }

      logInfo({
        action: UPLOAD_FILE_INFO_ACTIONS.UPLOAD_FILE_START,
        message: `Started processing ${fileType.mime} file`,
        pillar: PILLARS.UPLOAD_FILE,
        data: {
          fileSize: selectedFile.size,
          fileName: selectedFile.name,
          mimeType: fileType.mime,
          vaultId,
          artistLinkValue,
        },
      });

      // Note: Added this clause because of a bug where the file-type determines mime as video/quicktime but the file extension is mpr (actually audio/mpeg)
      if (fileType.mime === 'video/quicktime' && mimeFromFileName === 'audio/mpeg') {
        await saveMusicFile(selectedFile, 'audio/mpeg', mimeFromFileName, startTime);
      } else if (Object.keys(ACCEPTED_AUDIO_MIME_TYPES).includes(fileType.mime)) {
        await saveMusicFile(selectedFile, fileType.mime, mimeFromFileName, startTime);
      } else if (Object.keys(ACCEPTED_VIDEO_TYPES).includes(fileType.mime)) {
        await saveVideoFile(selectedFile, fileType.mime, mimeFromFileName, startTime);
      } else if (Object.keys(ACCEPTED_IMAGE_TYPES).includes(fileType.mime)) {
        await saveImageFile(selectedFile, fileType.mime, mimeFromFileName, startTime);
      } else {
        openToast({
          text: `File could not be uploaded. Make sure it is a valid WAV, M4A or MPEG file and less than ${MAX_AUDIO_UPLOAD_SIZE_TEXT}.`,
          variant: 'error',
        });
      }
    } catch (err) {
      logError({
        action: UPLOAD_FILE_ERROR_ACTIONS.UPLOAD_FILE_ERROR,
        error: err,
        errorType: ERROR_TYPE.UNKNOWN,
        level: 'error',
        message: 'Error uploading file',
        pillar,
        indexedTags: {
          vaultId,
          artistLinkValue,
        },
        unindexedExtra: {
          fileSize: selectedFile.size,
          fileName: selectedFile.name,
          uploadDuration: Date.now() - startTime,
        },
        openToast,
        toast: 'There was an error uploading your song. Please try again.',
      });
    } finally {
      onDone?.();
      TrackUpload.isPromptOpen = false;
    }
  });

  return { saveVaultContentFile };
}
