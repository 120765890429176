import React, { type ChangeEventHandler, type FormEventHandler, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLoginWithSms } from '@privy-io/react-auth';
import clsx from 'clsx';
import { AsYouType, type CountryCode } from 'libphonenumber-js/min';
import { useNavigate, useParams } from 'react-router';
import { Link, Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faCircleExclamation } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { ArtistProfileImage } from '../../components/artist/ArtistProfileImage';
import { BackButton } from '../../components/buttons/BackButton';
import { Button } from '../../components/buttons/Button';
import {
  PresaveSpotifyButton,
  useIsPresaved,
} from '../../components/campaign/presave/PresaveSpotify';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { Item, Select } from '../../components/forms/Select';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { CD } from '../../constants/imageConstants';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import { ROUTES } from '../../constants/routeConstants';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../constants/urlConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { getFragment, PresaveSpotifyInfoFragmentDoc } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useFreeTier } from '../../hooks/useFreeTier';
import { useSignIn } from '../../hooks/useSignIn';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useTimer } from '../../hooks/useTimer';
import { useVaultContentByIdOrSlug } from '../../hooks/useVaultContent';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { Sentry } from '../../sentry';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { wrapSendCode } from '../../utils/privy';
import { constructQueryParams } from '../../utils/stringUtils';
import { formatTime } from '../../utils/textUtils';
import { SignInStore, THIRTY_SECONDS_IN_MS } from '../auth/store';

export const TrackLandingPage = () => {
  const { vaultContentId, vaultContentSlug } = useParams<{
    vaultContentId: string;
    vaultContentSlug: string;
    artistHandle: string;
  }>();
  const { artistHandle } = useArtistHandle();

  const { loginStatus, loggedInUser } = useAuthContext();
  const { isSubscribingFreeTier } = useFreeTier();

  const navigate = useNavigate();

  useVaultTheme();

  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite'); // invite code
  const smsCampaignResponseShortcode = searchParams.get('c');

  const {
    data: track,
    isLoading,
    isError,
    refetch,
  } = useVaultContentByIdOrSlug({
    vaultContentId,
    vaultContentSlug,
    artistHandle,
  });

  const vault = track?.vault;
  const artistName = vault?.artist?.name ?? 'Unknown';
  const artistProfileImage = vault?.artist?.profileImage?.artistSmallProfileImageUrl;
  const membershipCardImage = vault?.artist?.membershipCardImage?.membershipCardImageUrl;
  const trackTitle = track?.title ?? 'Untitled';

  const isSubscriberOrAdmin = !!vault?.activeSubscription || !!vault?.isUserArtistAdmin;

  const { value: spotifyReviewPresaveEnabled, isLoading: loadingSpotifyReviewPresaveEnabled } =
    useGate(FEATURE_GATES.SPOTIFY_REVIEW_PRESAVE);

  const trackPresaveInfo =
    spotifyReviewPresaveEnabled && track?.__typename === 'VaultTrack'
      ? getFragment(PresaveSpotifyInfoFragmentDoc, track).presaveConfiguration
      : null;

  const { isPresaved } = useIsPresaved({
    presaveConfigId: trackPresaveInfo?.id,
  });

  const hasSpotifyPresave =
    spotifyReviewPresaveEnabled &&
    !!trackPresaveInfo?.spotifyAlbumURI &&
    trackPresaveInfo.status !== 'INACTIVE';

  /**
   * If the user is already subscribed, navigate to the vault page
   *
   * Browsers block autoplay when the user hasn't interacted with the page,
   * so we just navigate to the vault page with track id in the query params
   * and there is a modal with a listen CTA on the vault page
   */
  useEffect(() => {
    if (loadingSpotifyReviewPresaveEnabled) return;

    if (isSubscriberOrAdmin && track?.id) {
      if (hasSpotifyPresave) return;

      navigate(artistNavigationPath(artistHandle, '/', `trackId=${track.id}`));
    }
  }, [
    artistHandle,
    isSubscriberOrAdmin,
    navigate,
    track?.id,
    loadingSpotifyReviewPresaveEnabled,
    hasSpotifyPresave,
  ]);

  useSetMetaHeaders({
    title: track
      ? `${artistName} - ${trackTitle}`
      : /**
         * Use defaults for loading state
         */
        '',
  });

  const { openBottomsheet } = useBottomsheetContainer();

  const onJoinFreeClick = useStableCallback(() => {
    if (
      vault?.id != null &&
      loginStatus === LoginStatus.LOGGED_IN &&
      track?.id &&
      !isSubscriberOrAdmin &&
      !isSubscribingFreeTier
    ) {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
        membershipConfirmationBottomsheetProps: {
          vaultId: vault.id,
          isLoading: false,
          artistHandle: artistHandle,
          artistName: artistName,
          imageUrl: membershipCardImage,
          loggedInUserUsername: loggedInUser?.username,
          loginStatus,
          inviteCode: invite,
          smsCampaignResponseShortcode,
          sourceReleaseCampaignId: null,
        },
        shared: {
          hideCloseBottomsheetButton: false,
          preventSwipeToDismiss: false,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          withVaultTheme: true,
        },
      });
    }
  });

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isLoading) {
    return <FullPageLoading withVaultTheme />;
  }
  if (!track) {
    return !isError ? (
      <Navigate to={ROUTES.NOT_FOUND} />
    ) : (
      <DefaultLayout
        withVaultTheme
        showBorder
        showRoundedTop
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        contentClassName="md2:bg-vault_text/3"
        shouldSkipMargin
        headerLeft={<BackButton className="text-vault_text" />}
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="track_landing_page"
            withVaultTheme
          />
        }
      />
    );
  }

  if (track.__typename === 'VaultFolder') {
    const queryParams = constructQueryParams({
      invite,
      c: smsCampaignResponseShortcode,
    });
    return (
      <Navigate
        to={artistNavigationPath(
          artistHandle,
          vaultContentSlug != null ? `/f/${vaultContentSlug}` : `/folder/${track.id}`,
          queryParams,
        )}
        replace
      />
    );
  }

  if (track.__typename === 'VaultImage' || track.__typename === 'VaultVideo') {
    const typeName = track.__typename === 'VaultImage' ? 'i' : 'v';

    const queryParams = constructQueryParams({
      c: smsCampaignResponseShortcode,
    });
    return (
      <Navigate
        to={artistNavigationPath(
          artistHandle,
          track.linkValue != null
            ? `/${typeName}/${track.linkValue}`
            : `/${typeName === 'i' ? 'image' : 'video'}/${track.id}`,
          queryParams,
        )}
        replace
      />
    );
  }

  return (
    <DefaultLayout
      withVaultTheme
      showBorder
      shouldSkipMargin
      showRoundedTop
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="bg-vault_background"
      contentClassName="md2:bg-vault_text/3"
      headerCenter={
        <Link to={artistNavigationPath(artistHandle, '/')} className="no-underline">
          <View className="flex flex-row items-center gap-2">
            <ArtistProfileImage
              profileImageUrl={artistProfileImage}
              className="h-9 w-9 rounded-full md2:h-10 md2:w-10"
            />

            <Text className="select-none font-base text-[16px]/[20px] font-medium text-vault_text">
              {artistName}
            </Text>
          </View>
        </Link>
      }
    >
      <View className="box-border flex w-full flex-col pt-10">
        <TrackHero
          trackTitle={trackTitle}
          artistName={artistName}
          trackDuration={track.duration}
          artistHandle={artistHandle}
        />

        <PresaveSpotifyButton track={track} />

        <View className="flex px-6 pt-8 md:px-12">
          {loginStatus === LoginStatus.LOGGED_OUT ? (
            hasSpotifyPresave && !isPresaved ? null : (
              <SignInForm
                artistHandle={artistHandle}
                trackId={track.id}
                invite={invite}
                smsCampaignResponseShortcode={smsCampaignResponseShortcode}
                type="Track"
              />
            )
          ) : (
            <View className="flex w-full flex-row items-center justify-between rounded-[48px] md:bg-vault_text/10 md:p-2">
              <WaveformPlaceholderSVG className="hidden fill-vault_accent px-4 md:block" />
              <Button
                label="Join to listen"
                type="primary-themed"
                buttonType="submit"
                loading={isSubscribingFreeTier}
                disabled={loginStatus === LoginStatus.LOADING || isSubscribingFreeTier}
                disabledClassName="opacity-30"
                className="flex w-full text-nowrap !text-base-l font-medium text-vault_accent_text sm:w-fit"
                event={{ type: EVENTS.NEXT, properties: { type: 'Sign In' } }}
                onClick={onJoinFreeClick}
              />
            </View>
          )}
        </View>
      </View>
    </DefaultLayout>
  );
};

function TrackHero({
  trackTitle,
  artistName,
  trackDuration,
  artistHandle,
}: {
  trackTitle: string;
  artistName: string;
  trackDuration: number;
  artistHandle: string;
}) {
  return (
    <View className="relative box-border flex h-[40vh] flex-col justify-end md:h-[54vh]">
      <View className="cd-fade flex overflow-hidden">
        <View
          className={twMerge(
            'relative right-[100px] top-[20px] aspect-1 w-[580px] rotate-45 md:right-[280px] md:top-[0px] md:rotate-0',
          )}
        >
          <img src={CD} className="aspect-1 w-full" />
          <View className="absolute top-12 flex w-full flex-col items-center font-covered-by-your-grace text-black">
            <Text className="line-clamp-1 max-w-[240px] text-center text-[38px]/[34px]">
              {artistName}
            </Text>
            <Text className="line-clamp-2 max-w-[240px] pt-1 text-center text-[26px]/[21px]">
              {trackTitle}
            </Text>
          </View>
        </View>
      </View>

      <View className=" absolute left-0 right-0 px-4 md:px-12">
        <View className="flex w-full min-w-0 flex-col items-center justify-center gap-1">
          <View className="flex w-full items-center font-title !text-title-xl font-medium text-vault_text">
            {trackTitle}
          </View>
          <View className="flex w-full flex-row justify-between">
            <Link to={artistNavigationPath(artistHandle, '/')} className="no-underline">
              <Text className="font-base !text-base-l font-medium text-vault_text">
                {artistName}
              </Text>
            </Link>

            <Text className="font-base !text-base-l font-medium text-vault_text opacity-50">
              {formatTime(trackDuration)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

export function SignInForm({
  artistHandle,
  trackId,
  invite,
  smsCampaignResponseShortcode,
  type,
}: {
  artistHandle: string;
  trackId: string;
  invite: string | null;
  smsCampaignResponseShortcode: string | null;
  type: 'Media' | 'Track';
}) {
  const { pathname } = useLocation();
  const { loginStatus } = useAuthContext();

  const {
    countryCode,
    errorText,
    isOpen,
    phone,
    setCountryCode,
    setErrorText,
    setIsOpen,
    setPhone,
    validPhoneNumber,
    codeRenabled,
    lastActivePhoneNumber,
  } = useSignIn();

  const {
    sendCode,
    state: { status },
  } = useLoginWithSms();

  const { seconds: codeSentDisabledSecondsRemaining } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  const isSubmitLoading = status === 'sending-code' || status === 'submitting-code';
  const isSubmitDisabled =
    isSubmitLoading ||
    (codeSentDisabledSecondsRemaining !== 0 && !!validPhoneNumber && codeRenabled !== 1);

  useEffect(() => {
    if (codeSentDisabledSecondsRemaining === 0 || !validPhoneNumber || codeRenabled === 1) {
      setErrorText(null);
    } else {
      setErrorText(`Please wait ${codeSentDisabledSecondsRemaining} seconds before trying again`);
    }
  }, [codeRenabled, codeSentDisabledSecondsRemaining, setErrorText, validPhoneNumber]);

  const onNextClick: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (isOpen) return;

    trackEvent({ type: EVENTS.NEXT, properties: { type: 'Sign In' }, pathname });

    if (!validPhoneNumber) {
      setErrorText('This phone number cannot be used for verification');
      return;
    }

    try {
      setErrorText(null);
      await wrapSendCode(() => sendCode({ phoneNumber: validPhoneNumber }));

      const codeRenabled = Date.now() + THIRTY_SECONDS_IN_MS;

      SignInStore.produceExistingState(
        draft => {
          draft.lastActivePhoneNumber = validPhoneNumber;
          draft.codeRenabled[validPhoneNumber] = codeRenabled;
        },
        {
          codeRenabled: {
            [validPhoneNumber]: codeRenabled,
          },
          lastActivePhoneNumber: validPhoneNumber,
        },
      );
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          errorText: 'We encountered an error sending your verification code',
          phoneNumber: validPhoneNumber,
        },
      });
      setErrorText('We encountered an error sending your verification code');
      return;
    }
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    const currentInput = e.target.value;
    let rawInput = currentInput.replace(/\D/g, ''); // Remove non-digit characters

    // Check if the last character was removed and was a formatting character
    if (phone.length > currentInput.length && '()- '.includes(phone?.[phone.length - 1] ?? '')) {
      rawInput = rawInput.substring(0, rawInput.length - 1);
    }

    const formatter = new AsYouType(countryCode as CountryCode);
    const formatted = formatter.input(rawInput);
    setPhone(formatted);
  };

  if (status === 'awaiting-code-input' && lastActivePhoneNumber) {
    const queryParams = constructQueryParams({
      artistHandle,
      openBottomSheet: 'freeTierModal',
      trackId,
      invite,
      c: smsCampaignResponseShortcode,
      backToVault: 1,
    });
    return <Navigate to={`${ROUTES.VERIFY}?${queryParams}`} />;
  }

  return (
    <View className="box-border w-full flex-col items-end rounded-xl bg-vault_text/10 px-6 py-4 md:py-6">
      <form onSubmit={onNextClick} className="box-border flex w-full flex-col">
        <View className="flex w-full flex-col items-center justify-between gap-3 sm:flex-row sm:gap-1">
          <View
            className={clsx(
              'flex w-full flex-shrink flex-col ',
              type === 'Media' ? 'sm:max-w-[70%]' : 'sm:max-w-[66%]',
            )}
          >
            <View className="box-border flex flex-row items-center gap-4 overflow-hidden">
              <View className="flex w-fit">
                <Select
                  value={COUNTRY_CODES.find(({ code }) => code === countryCode)?.code ?? ''}
                  onValueChange={val => setCountryCode(val)}
                  itemProps={{
                    className:
                      'bg-transparent text-vault_text hover:bg-transparent focus:bg-transparent',
                  }}
                  className="w-[5em] bg-transparent p-0 font-base !text-base-l font-normal text-vault_text hover:bg-transparent"
                  contentClassName="w-[5em] border border-solid border-vault_text/5 bg-vault_background/90 backdrop-blur-2xl"
                  onOpenChange={setIsOpen}
                  disabled={status === 'sending-code'}
                >
                  {COUNTRY_CODES.map(({ code, flag, dial_code }) => (
                    <Item
                      key={code}
                      value={code}
                      className="box-border flex w-[5em] flex-row justify-end bg-transparent py-0 pl-0 font-base !text-base-l font-normal text-vault_text hover:bg-transparent focus:bg-transparent"
                      dropDownClassName={twMerge(
                        'box-border justify-center overflow-x-clip rounded-sm pr-1 font-base !text-base-l font-normal',
                        'bg-transparent hover:bg-transparent focus:bg-transparent',
                      )}
                    >
                      <Text className="mx-2 text-[24px]">{flag}</Text>
                      <Text className="pr-2 text-[12px] md2:pr-0">{dial_code}</Text>
                    </Item>
                  ))}
                </Select>
              </View>
              <View className="relative flex w-full items-center">
                <input
                  type="tel"
                  value={phone}
                  placeholder="Enter phone number"
                  onChange={onChange}
                  disabled={status === 'sending-code'}
                  autoFocus
                  className={twMerge(
                    'ml-2 h-8 flex-1 border-none bg-transparent pl-2 font-base !text-base-l font-normal focus:w-[unset] focus:border-none focus:outline-none md:ml-0',
                    'text-vault_text placeholder:text-vault_text/50',
                  )}
                />
                {errorText != null && (
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="absolute right-2 !text-base-xl text-destructive300"
                  />
                )}
              </View>
            </View>
            <View
              className={twMerge(
                'mt-3 h-[1px] w-full bg-vault_text/30',
                errorText != null && 'bg-destructive300',
              )}
            />
            {errorText != null && (
              <Text className="my-3 text-left font-base !text-base-m font-normal text-destructive300 sm:hidden">
                {errorText}
              </Text>
            )}
          </View>
          <Button
            label={`Join to ${type === 'Media' ? 'view' : 'listen'}`}
            type="primary-themed"
            buttonType="submit"
            loading={isSubmitLoading}
            disabled={isSubmitDisabled}
            disabledClassName="opacity-30"
            className="flex w-full text-nowrap !text-base-l font-medium sm:w-fit"
            event={{ type: EVENTS.NEXT, properties: { type: 'Sign In' } }}
          />
        </View>
        {errorText != null && (
          <Text className="my-3 hidden text-left font-base !text-base-m font-normal text-destructive300 sm:block">
            {errorText}
          </Text>
        )}
      </form>

      {loginStatus === LoginStatus.LOGGED_OUT && (
        <Text className="mt-4 w-full text-center font-base !text-base-xs font-normal text-vault_text">
          By signing up, you agree to the{' '}
          <a
            href={TOS_URL}
            target="_blank"
            className="text-vault_text/50 no-underline hover:cursor-pointer"
            onClick={e => isOpen && e.preventDefault()}
          >
            Terms
          </a>{' '}
          &{' '}
          <a
            href={PRIVACY_POLICY_URL}
            target="_blank"
            className="text-vault_text/50 no-underline hover:cursor-pointer"
            onClick={e => isOpen && e.preventDefault()}
          >
            Privacy Policy
          </a>{' '}
          .
        </Text>
      )}
    </View>
  );
}

const WaveformPlaceholderSVG = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="588"
    height="40"
    viewBox="0 0 588 40"
    className={className}
  >
    <path d="M87.5-.5h4v40h-4v-40zM111.5-.5h4v40h-4v-40zM151.5-.5h4v40h-4v-40zM167.5-.5h4v40h-4v-40zM239.5-.5h4v40h-4v-40zM319.5-.5h4v40h-4v-40zM423.5-.5h4v40h-4v-40z" />
    <path d="M-.5 9.5h4v20h-4v-20zM23.5 9.5h4v20h-4v-20zM31.5 9.5h4v20h-4v-20zM39.5 9.5h4v20h-4v-20zM47.5 9.5h4v20h-4v-20zM79.5 9.5h4v20h-4v-20zM95.5 9.5h4v20h-4v-20zM103.5 9.5h4v20h-4v-20zM119.5 9.5h4v20h-4v-20zM159.5 9.5h4v20h-4v-20zM175.5 9.5h4v20h-4v-20zM183.5 9.5h4v20h-4v-20zM191.5 9.5h4v20h-4v-20zM223.5 9.5h4v20h-4v-20zM231.5 9.5h4v20h-4v-20zM247.5 9.5h4v20h-4v-20zM255.5 9.5h4v20h-4v-20zM263.5 9.5h4v20h-4v-20zM295.5 9.5h4v20h-4v-20zM303.5 9.5h4v20h-4v-20zM311.5 9.5h4v20h-4v-20zM327.5 9.5h4v20h-4v-20zM335.5 9.5h4v20h-4v-20zM367.5 9.5h4v20h-4v-20zM375.5 9.5h4v20h-4v-20zM383.5 9.5h4v20h-4v-20zM391.5 9.5h4v20h-4v-20zM399.5 9.5h4v20h-4v-20zM407.5 9.5h4v20h-4v-20zM415.5 9.5h4v20h-4v-20zM431.5 9.5h4v20h-4v-20zM439.5 9.5h4v20h-4v-20zM447.5 9.5h4v20h-4v-20zM455.5 9.5h4v20h-4v-20zM479.5 9.5h4v20h-4v-20zM503.5 9.5h4v20h-4v-20zM527.5 9.5h4v20h-4v-20zM535.5 9.5h4v20h-4v-20zM543.5 9.5h4v20h-4v-20zM551.5 9.5h4v20h-4v-20zM559.5 9.5h4v20h-4v-20zM567.5 9.5h4v20h-4v-20zM575.5 9.5h4v20h-4v-20zM587.5 9.5v20h-4v-20h4z" />
    <path d="M7.5 13.5h4v12h-4v-12zM15.5 13.5h4v12h-4v-12zM55.5 13.5h4v12h-4v-12zM63.5 13.5h4v12h-4v-12zM71.5 13.5h4v12h-4v-12zM127.5 13.5h4v12h-4v-12zM135.5 13.5h4v12h-4v-12zM143.5 13.5h4v12h-4v-12zM199.5 13.5h4v12h-4v-12zM207.5 13.5h4v12h-4v-12zM215.5 13.5h4v12h-4v-12zM271.5 13.5h4v12h-4v-12zM279.5 13.5h4v12h-4v-12zM287.5 13.5h4v12h-4v-12zM343.5 13.5h4v12h-4v-12zM351.5 13.5h4v12h-4v-12zM359.5 13.5h4v12h-4v-12zM463.5 13.5h4v12h-4v-12zM471.5 13.5h4v12h-4v-12zM487.5 13.5h4v12h-4v-12zM495.5 13.5h4v12h-4v-12zM511.5 13.5h4v12h-4v-12zM519.5 13.5h4v12h-4v-12z" />
  </svg>
);
