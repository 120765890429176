import { useEffect } from 'react';
import { gql } from '@soundxyz/gql-string';
import { useMutation } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import { GetUserChannelsDocument, SetLastReadMessageChannelDocument } from '../graphql/generated';

gql(/* GraphQL */ `
  mutation SetLastReadMessageChannel($input: MutationSetLastReadAtChannelInput!) {
    setLastReadAtChannel(input: $input)
  }
`);

RefetchOnComplete({
  trigger: [SetLastReadMessageChannelDocument],
  refetch: [GetUserChannelsDocument],
  throttled: '5 seconds',
});

export function useSetLastReadMessageChannel({
  messageChannelId,
  asArtistId,
}: {
  messageChannelId: string | null | undefined;
  asArtistId: string | null | undefined;
}) {
  const { mutateAsync } = useMutation(SetLastReadMessageChannelDocument, {});

  useEffect(() => {
    if (!messageChannelId) return;

    mutateAsync({ input: { date: new Date().toISOString(), messageChannelId, asArtistId } });

    return () => {
      mutateAsync({ input: { date: new Date().toISOString(), messageChannelId, asArtistId } });
    };
  }, [messageChannelId, asArtistId, mutateAsync]);
}
