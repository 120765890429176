import React from 'react';
import { Navigate, useParams } from 'react-router';
import { gql } from '@soundxyz/gql-string';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { SkeletonUserRow } from '../../components/user/UserRow';
import { ManageSubscriptionView } from '../../components/views/settings/ManageSubscriptionView';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { GetSubscriptionByIdDocument } from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';

gql(/* GraphQL */ `
  query GetSubscriptionById($input: QueryVaultSubscriptionInput!) {
    vaultSubscription(input: $input) {
      __typename
      ... on QueryVaultSubscriptionSuccess {
        data {
          id
          ...ManageSubscription
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

const ManageSubscriptionPage = () => {
  const { subscriptionId } = useParams();
  const { loginStatus } = useAuthContext();
  const { data, isError, isLoading, refetch } = useQuery(GetSubscriptionByIdDocument, {
    staleTime: 0,
    variables: { input: { subscriptionId: subscriptionId ?? '' } },
    enabled: loginStatus === LoginStatus.LOGGED_IN && subscriptionId != null,
  });

  useSetMetaHeaders({
    title: 'Manage subscription',
    description: 'Manage your subscription',
  });

  if (subscriptionId == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isError || data?.data.vaultSubscription.__typename === 'NotFoundError') {
    return (
      <SettingsLayout
        title="Manage plan"
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="manage_subscription_page"
            withVaultTheme={false}
          />
        }
      />
    );
  }

  if (isLoading || data == null) {
    return (
      <SettingsLayout title="Manage plan">
        <SkeletonManageSubscriptionsView />
      </SettingsLayout>
    );
  }

  return (
    <SettingsLayout title="Manage plan">
      <ManageSubscriptionView subscription={data.data.vaultSubscription.data} />
    </SettingsLayout>
  );
};

const SkeletonManageSubscriptionsView = () => {
  return (
    <>
      <SkeletonUserRow className="mb-0 items-center rounded-none py-4" />
      <LoadingSkeleton className="mb-4 box-border flex h-[60px] w-full flex-row rounded-xl bg-base800" />
    </>
  );
};

export { ManageSubscriptionPage };
