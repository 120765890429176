import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router';

export function usePageChecks({ pages }: { pages: readonly string[] }) {
  const { pathname } = useLocation();

  /**
   * We assume pages never changes and we enforce it on types by making it readonly
   */
  const pagesRef = useRef(pages);

  return useMemo(() => {
    const pathParts = pathname.split('/');

    return pagesRef.current.map(page => pathParts.includes(page));
  }, [pagesRef, pathname]);
}

export function removeSearchParam(param: string) {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  window.history.replaceState({}, '', url.toString());
}
