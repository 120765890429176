import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getFragment, MemberRowFragmentDoc } from '../../graphql/generated';
import { useMySubscribers } from '../../hooks/useMySubscribers';
import { SelectedArtistPersistence } from '../../hooks/useSelectedArtist';
import { MyMemberRow } from '../../screens/settings/MySubscribersPage';

import { artistNavigationPath } from '../../utils/navigationUtils';
import { SkeletonUserRow } from '../user/UserRow';
import { Item } from './shared';

export function NewMembersSection({
  artistHandle,
  artistId,
  artistName,
  vaultId,
}: {
  artistHandle: string;
  artistId: string;
  artistName: string;
  vaultId: string;
}) {
  const { subscribers: members, isInitialLoading } = useMySubscribers({
    limit: 3,
    sort: 'DESC',
    vaultId,
    searchQueryString: undefined,
  });

  const hasMembers = members.length > 0;

  const firstThreeMembers = useMemo(() => members.slice(0, 3), [members]);

  if (isInitialLoading) {
    return (
      <Item header="New members">
        {Array.from({ length: 3 }).map((_, index) => (
          <SkeletonUserRow
            key={index}
            profileImageClassName="w-[64px] h-[64px] rounded-full"
            withVaultTheme
          />
        ))}
      </Item>
    );
  }

  if (!hasMembers) {
    return null;
  }

  return (
    <Item
      header="New members"
      headerLink={
        <Link
          className="font-base text-base-m font-medium  text-vault_text/50 no-underline  transition-all duration-200 ease-in hover:text-vault_text/80"
          onClick={() => {
            SelectedArtistPersistence.set({
              artistHandle,
              artistId,
              artistName,
            });
          }}
          to={artistNavigationPath(artistHandle, '/members')}
        >
          See all
        </Link>
      }
    >
      {firstThreeMembers.map(untypedMember => {
        const member = getFragment(MemberRowFragmentDoc, untypedMember);
        return (
          <MyMemberRow
            key={member.user.id}
            id={member.id}
            source="subscribers"
            user={member.user}
            tier={member.tier}
            status={member.status}
            createdAt={member.createdAt}
            phone={member.phone}
            email={member.email}
            joinedViaReferralCode={member.joinedViaReferralCode}
            isTrial={member.isTrial}
            userLocation={member.userLocation}
            vaultSubscriptionSourceText={member.vaultSubscriptionSourceText}
            vaultSubscriptionSourceType={member.vaultSubscriptionSourceType}
            artistMembership={member.artistMembership}
            withVaultTheme
            artist={member.artist}
            dateSection="joined-vault"
          />
        );
      })}
    </Item>
  );
}
