import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faChevronLeft } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../constants/urlConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { VaultType } from '../../graphql/generated';
import { useLockedMessages } from '../../hooks/useLockedMessages';
import { LoginStatus } from '../../types/authTypes';
import { constructQueryParams } from '../../utils/stringUtils';
import { Button } from '../buttons/Button';
import { SubscribeButton } from '../buttons/SubscribeButton';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { MockMessageBubble } from '../message/MockMessageBubble';
import { AuthCardUI } from '../rsvp/RsvpDropView';
import { SignInForm, VerifyForm } from './VaultLandingView';

export const LockedChatView = ({
  vaultId,
  vaultType,
  linkValue,
  vaultArtistName,
  vaultArtistImage,
  vaultArtistMembershipCardImage,
  monthlySubPrice = DEFAULT_PRICE,
  chatAvailableForFreeUsers,
  showJoinFree,
}: {
  vaultId: string | undefined;
  vaultType: VaultType | undefined;
  linkValue: string | undefined;
  vaultArtistName: string | undefined;
  vaultArtistImage: string | undefined | null;
  vaultArtistMembershipCardImage: string | undefined | null;
  monthlySubPrice: number;
  chatAvailableForFreeUsers: boolean;
  showJoinFree: boolean;
  dominantColor: string | null | undefined;
}) => {
  const navigate = useNavigate();
  const { loginStatus, loggedInUser } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();
  const messages = useLockedMessages({ vaultArtistName, vaultArtistImage });
  const messagesRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const smsCampaignResponseShortcode = searchParams.get('c');
  const [view, setView] = useState<'join' | 'verify'>('join');

  const showJoinFreeButton = vaultType === VaultType.FreeOnly || chatAvailableForFreeUsers;

  const onJoinFreeClick = useCallback(async () => {
    if (!vaultId || !linkValue) return;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
        membershipConfirmationBottomsheetProps: {
          vaultId,
          isLoading: false,
          artistHandle: linkValue,
          artistName: vaultArtistName,
          imageUrl: vaultArtistMembershipCardImage,
          loggedInUserUsername: loggedInUser?.username,
          loginStatus,
          inviteCode: null,
          smsCampaignResponseShortcode,
          sourceReleaseCampaignId: null,
        },
        shared: {
          hideCloseBottomsheetButton: false,
          preventSwipeToDismiss: false,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          withVaultTheme: true,
        },
      });
    } else {
      const queryParams = constructQueryParams({
        artistHandle: linkValue,
        openBottomSheet: 'freeTierModal',
        c: smsCampaignResponseShortcode,
      });

      navigate(`${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
      return;
    }
  }, [
    vaultId,
    linkValue,
    loginStatus,
    openBottomsheet,
    vaultArtistName,
    vaultArtistMembershipCardImage,
    loggedInUser?.username,
    smsCampaignResponseShortcode,
    navigate,
  ]);

  const renderContent = useMemo(() => {
    return (
      <>
        {showJoinFree ? (
          <div className="absolute bottom-4 left-0 right-0 mx-auto flex max-w-[360px] flex-col gap-5 rounded-[24px] bg-vault_text/10 p-4 text-center text-vault_text">
            <View className="flex w-full flex-row">
              {view === 'verify' && (
                <Button
                  label=""
                  iconOnly
                  leadingIcon={faChevronLeft}
                  className="text-[20px] text-vault_text"
                  onClick={() => setView('join')}
                />
              )}

              <Text className="mx-auto text-center text-title-l font-medium">
                {view === 'join' ? 'Unlock messaging' : 'Verify'}
              </Text>

              {view === 'verify' && <View className="w-5" />}
            </View>

            {view === 'join' && (
              <>
                {loginStatus === LoginStatus.LOGGED_IN && loggedInUser ? (
                  <AuthCardUI
                    profileImageUrl={loggedInUser.avatar?.userSmallProfileImageUrl}
                    fallBackColor={loggedInUser.avatar?.dominantColor || undefined}
                    username={loggedInUser.username}
                    onClick={onJoinFreeClick}
                    buttonLabel="Join"
                    useVaultTheme
                  />
                ) : (
                  <>
                    <SignInForm
                      dominantColor={null}
                      setView={setView}
                      useVaultTheme
                      inputBorderColorClassName="border-vault_text/15"
                      inputTextColorClassName="text-vault_text"
                      placeholderTextColorClassName="placeholder:text-vault_text/50"
                      selectBgColorClassName="bg-vault_background/90"
                      selectTextColorClassName="text-vault_text"
                    />
                  </>
                )}
              </>
            )}

            {view === 'verify' && <VerifyForm className="pb-0" codeInputOnly useVaultTheme />}

            {view === 'join' && (
              <Text className="mx-auto w-full max-w-[200px] text-center font-base text-[12px]/[16px] font-normal text-vault_text/70">
                By signing up, you agree to the{' '}
                <a
                  href={TOS_URL}
                  target="_blank"
                  className="text-vault_accent no-underline hover:cursor-pointer"
                >
                  Terms
                </a>{' '}
                &{' '}
                <a
                  href={PRIVACY_POLICY_URL}
                  target="_blank"
                  className="text-vault_accent no-underline hover:cursor-pointer"
                >
                  Privacy Policy
                </a>
                .
              </Text>
            )}
          </div>
        ) : (
          <div className="absolute bottom-4 left-0 right-0 mx-auto flex max-w-[360px] flex-col gap-5 p-4 text-center text-vault_text">
            <Text className="text-title-m font-semibold">Unlock private chat</Text>
            <Text className="text-base-m">
              Get behind the scenes, hear directly from the artist about their music and process.
            </Text>
            {linkValue &&
              vaultId &&
              (showJoinFreeButton ? (
                <Button
                  label="Join for free"
                  type="primary-themed"
                  buttonType="submit"
                  disabledClassName="opacity-30"
                  className="w-full"
                  onClick={onJoinFreeClick}
                />
              ) : (
                <SubscribeButton
                  label={`Unlock for $${monthlySubPrice}/month`}
                  className="w-full bg-vault_accent text-base-l text-vault_accent_text"
                  linkValue={linkValue}
                  artistAvatarUrl={vaultArtistImage}
                  price={monthlySubPrice}
                  vaultId={vaultId}
                  showBottomSheet={false}
                  smsCampaignResponseShortcode={smsCampaignResponseShortcode}
                  component="locked_chat"
                />
              ))}
          </div>
        )}
      </>
    );
  }, [
    linkValue,
    loggedInUser,
    loginStatus,
    monthlySubPrice,
    onJoinFreeClick,
    showJoinFree,
    showJoinFreeButton,
    smsCampaignResponseShortcode,
    vaultArtistImage,
    vaultId,
    view,
  ]);

  return (
    <View className="relative flex h-full w-full flex-1 overflow-clip overscroll-none">
      <View containerRef={messagesRef} className="flex w-full flex-1 flex-col">
        {messages.map((message, i) => (
          <MockMessageBubble
            key={i}
            className={twMerge(i !== 0 && 'mt-4')}
            author={message.author}
            content={message.content}
            profileImageUrl={message.profileImageUrl}
            reactionSummary={message.reactionSummary}
            isVaultArtist={message.isVaultArtist}
            hasVaultContent={message.hasVaultContent}
          />
        ))}
      </View>
      <View className="pointer-events-none absolute bottom-0 left-0 right-0 h-[70vh] bg-gradient-to-t from-vault_background via-vault_background to-transparent md:h-[75vh]" />

      {renderContent}
    </View>
  );
};
