import * as z from 'zod';
import { TierTypename } from '../graphql/generated';
import { PersistenceStorage } from '../utils/storeUtils';

export const NewMessageSchema = z.object({
  artistDMChannelId: z.string(),
  userId: z.string(),
  profileImageUrl: z.string().nullable(),
  displayName: z.string(),
  subscriptionTierLevel: z.nativeEnum(TierTypename),
});

export type NewMessageSchema = z.infer<typeof NewMessageSchema>;

export const NewMessagePersistence = PersistenceStorage({
  schema: NewMessageSchema,
  key: 'newMessage',
  eager: true,
});

export function useNewMessage() {
  const { value } = NewMessagePersistence.useStore();

  return value;
}
